import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/visual_6_1_do.png";
import img2 from "./../../../../../visuals/design/visual_6_1_dont.png";
import img3 from "./../../../../../visuals/design/visual_6_2_do.png";
import img4 from "./../../../../../visuals/design/visual_6_2_dont.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users with low vision and color blindness need sufficient contrast to distinguish the different parts of charts and graphs in order to understand the data displayed. This is achieved by having sufficient contrast against adjoining colors and its background. Data segments that use non-color treatments such as texture and shape for distinction don’t need to contrast with adjoining colors.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Make sure data representations in charts meet 3:1 contrast with the background`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
     <DoDontExample aspectRatio="1:1" type="do" captionTitle="" caption="With the use of labels that include key data, it's not necessary for adjoining colors to have 3:1 color contrast." mdxType="DoDontExample">
    <img src={img1} alt="Good example: wedges of donut chart have black text labels near each wedge"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
     <DoDontExample aspectRatio="1:1" type="dont" captionTitle="" caption="Don't use colors below 3:1 color contrast against background or adjoining colors for charts that don't have labels." mdxType="DoDontExample">
    <img src={img2} alt="Bad example: wedges of donut chart using light pastel colors alone"></img>
        </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
     <DoDontExample aspectRatio="1:1" type="do" captionTitle="" caption="Charts without labels must have a 3:1 color contrast with the background as well as adjoining colors." mdxType="DoDontExample">
    <img src={img3} alt="Good example: wedges of donut chart using alternating dark and light colors contrasting with both adjacent wedge and white background"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
     <DoDontExample aspectRatio="1:1" type="dont" captionTitle="" caption="Don't use colors that fall below 3:1 color contrast, such as the pink segment, for charts without labels." mdxType="DoDontExample">
    <img src={img4} alt="Bad example: wedges of donut chart using alternating dark and light colors with a light pink too light to contrast on white background"></img>
        </DoDontExample>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G209" target="_blank" rel="noopener noreferrer">G209: Provide sufficient contrast at the boundaries between adjoining colors</a>, WCAG 2.1 technique
      * <a href="https://www.carbondesignsystem.com/data-visualization/getting-started" target="_blank" rel="noopener noreferrer">Data visualization</a>, Carbon design system
      * <a href="https://www.w3.org/WAI/WCAG22/Understanding/non-text-contrast.html" target="_blank" rel="noopener noreferrer">Understanding 1.4.11 Non-text Contrast</a>, WCAG 2.1 (additional good vs bad examples)
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g209' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_7' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='wcag_6' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_11")} target="_blank" rel="noopener noreferrer">1.4.11 Non-text Contrast</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_11' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Component contrast](/toolkit/design/visual/#components-contrast), this page
      * [Visual states](/toolkit/design/visual/#visual-states), this page
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='visual_components_contrast' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='visual_visual_states' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      