import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../components/rms_resources/requirements";
import ToolkitReference from "../../../components/rms_resources/references";
import ToolkitTechnique from "../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`As one developer said, “For the most part, the web is accessible when we use semantic HTML. Our responsibility as developers is to make sure we use the elements for the purpose they were intended.  A simple example: why create a button as a `}<inlineCode parentName="p">{`div`}</inlineCode>{` with an `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` event when we can use the `}<inlineCode parentName="p">{`button`}</inlineCode>{` element?  Bottom line is that we have to pay attention to the implementation decisions we make during development because they might prevent users of assistive technologies from using what we build.”`}</p>
    <p>{`When developers use native HTML elements, they get a lot of things for free:`}</p>
    <ul>
      <li parentName="ul">{`Programmatic information for name, role and value`}</li>
      <li parentName="ul">{`Default appearance`}</li>
      <li parentName="ul">{`Keyboard interaction`}</li>
    </ul>
    <p>{`HTML elements automatically meet `}<ToolkitRequirement qid='req4_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement>{`.
If developers don’t change the default appearance of elements,
they are also exempt from meeting visual requirements for `}<ToolkitRequirement qid='req1_4_11' mdxType="ToolkitRequirement"></ToolkitRequirement>{`.
Finally, developers need not handle built-in keyboard events.
For example, an HTML `}<inlineCode parentName="p">{`select`}</inlineCode>{` element has built-in keyboard interaction,
including allowing users to jump to any item by first letter.
And, almost all browsers support the selection of multiple contiguous and non-contiguous items via the Shift and Control/Command keys.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      