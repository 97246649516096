import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`No loss of content or functionality occurs when users change letter, word and paragraph spacing, as well as line height.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures users can adjust text spacing to make it easier to read.`}</p>
    <p>{`Authors must ensure content can be transformed (such as through user style sheets) to attain all of the following specific requirements for a number of different text transformations without loss of content or functionality:`}</p>
    <ul>
      <li parentName="ul">{`Line height (line spacing) to at least 1.5 times the font size;`}</li>
      <li parentName="ul">{`Spacing following paragraphs to at least 2 times the font size;`}</li>
      <li parentName="ul">{`Letter spacing (tracking) to at least 0.12 times the font size;`}</li>
      <li parentName="ul">{`Word spacing to at least 0.16 times the font size.`}</li>
    </ul>
    <p><strong parentName="p">{`Language exception:`}</strong>{` Where the communication language used in the content, or the script in which the language is written, do not make use of one or more of these text style properties, that language need only conform using the properties that exist.`}</p>
    <p><strong parentName="p">{`Technology exception:`}</strong>{` This requirement is restricted to content implemented using markup languages that support text styling properties. Technologies that do not use markup, such as PDF, are excluded.`}</p>
    <p>{`IBM has simplified the normative language of this requirement. Refer to `}<a href="https://www.w3.org/WAI/WCAG22/UNDERSTANDING/text-spacing.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.4.12 Text Spacing`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      