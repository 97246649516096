import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Asking for the same information more than once during a multi-step process places unnecessary burden on users, especially those with some cognitive disabilities. `}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Avoid requesting repetitive user input`}</li>
      <li parentName="ul">{`Where the user needs to provide the same information twice, auto-populate or make it available for user selection`}</li>
    </ul>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G221">
  G221: Provide data from a previous step in a process
        </a>, WCAG 2.2 technique</li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><a href="https://www.ibm.com/able/requirements/requirements/?version=v7_3#3_3_7">
  3.3.7 Redundant Entry
        </a>, IBM accessibility requirements (7.3 only)</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      