import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import HomePanel from "../../components/home/homePanel";
import { withPrefix } from "gatsby";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import "./home-cards.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  _frontmatter
}) => <React.Fragment>{children}</React.Fragment>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Row className="resource-card-group homePanelRow" mdxType="Row">
    <Column offsetLg={4} colMd={4} colLg={4} colSm={4} noGutterSm mdxType="Column">
    <ResourceCard title="Blog" href="https://www.ibm.com/blog/category/accessibility/" target="_blank" rel="noopener noreferrer" aspectRatio="2:1" actionIcon="arrowRight" titleHeadingLevel={3} mdxType="ResourceCard">
    </ResourceCard>
   </Column>
   <Column colMd={4} colLg={4} colSm={4} noGutterSm mdxType="Column">
    <ResourceCard title="Events" href="/events" aspectRatio="2:1" actionIcon="arrowRight" titleHeadingLevel={3} mdxType="ResourceCard">
    </ResourceCard>
   </Column>
   <Column colMd={4} colLg={4} colSm={4} offsetLg={4} offsetMd={0} noGutterSm mdxType="Column">
    <ResourceCard title="Articles" href="https://medium.com/design-ibm/tagged/accessibility" target="_blank" rel="noopener noreferrer" aspectRatio="2:1" actionIcon="arrowRight" titleHeadingLevel={3} mdxType="ResourceCard">
    </ResourceCard>
   </Column>
    </Row>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      