import React, { Component } from 'react'
import ContactThankYouContent from "../../content/contact/contactThankYouBody.mdx"
import "./contactBody.scss";

class ContactBanner extends Component {
    render() {
        return (
            <div className="contactBody" >
                {/* <p>Contact</p> */}
                {/* <p>Get in touch with IBM Accessibility</p> */}
                <ContactThankYouContent>

                </ContactThankYouContent>
            </div>
        )

    }


}
export default ContactBanner