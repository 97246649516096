import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';
import { withPrefix, navigate } from "gatsby"

const Content = ({ buildTime: buildTime}) => {
    let t = new Date(buildTime);
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    let parts = t.toLocaleDateString("en-US", options).split(/[ ,]+/);
    let s = [
        ("0"+parts[1]).slice(-2),
        parts[0],
        parts[2]
    ].join(" ");

    return (<React.Fragment>
        <p>Have questions? Please <a href={withPrefix("/contact")} >contact</a> us.</p>
        <p>Last updated {s}<br/>
        Copyright © {t.getFullYear()} IBM
        </p>
    </React.Fragment>);
}

const links = {
  firstCol: [
    { href: 'https://www.ibm.com/privacy', linkText: 'Privacy' },
    { href: 'https://www.ibm.com/legal', linkText: 'Terms of Use' },
    { href: 'https://www.ibm.com/', linkText: 'IBM.com' },
  ],
  secondCol: [
  ],
};

const CustomFooter = ({indent}) => <Footer indent={indent} links={links} Content={Content} />;

export default CustomFooter;