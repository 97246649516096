import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/L2_visual_5_1_do.png";
import img2 from "./../../../../../visuals/design/L2_visual_5_1_dont.png";
import img3 from "./../../../../../visuals/design/L2_visual_5_2_eg.png";
import img4 from "./../../../../../visuals/design/L2_visual_5_3_eg.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Data tables can be a nightmare for users who are blind and rely on both keyboard and screen readers to navigate a page. Users with low vision who zoom in to navigate a page can also easily get lost in navigating complex tables. Designers can help to reduce keyboard effort, provide proper information for screen readers, and offer visual cues for users with low vision.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Use pagination and filters to reduce amount of data`}</li>
      <li parentName="ul">{`If multiple actions per row, consider grids to reduce keyboard effort`}</li>
      <li parentName="ul">{`Considering exposing cell’s header information for users with low vision`}</li>
      <li parentName="ul">{`Annotate cells that serve as row or column headers`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <DoDontExample type="do" mdxType="DoDontExample">
    <img src={img1} alt="Good example: data table with a button in each row"></img>
        </DoDontExample>
        <Caption fullWidth mdxType="Caption">Reduce keyboard navigation by having one action per row, or consider alternatives.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <DoDontExample type="dont" mdxType="DoDontExample">
    <img src={img2} alt="Bad example: data table with a text input field and three buttons in each row"></img>
        </DoDontExample>
        <Caption fullWidth mdxType="Caption">Avoid making keyboard operation impenetrable by forcing users to tab through many cells per row. Consider a grid with arrow navigation.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img3} alt="cursor hovering over a numeric cell on a magnified data table with tooltip content 'february sales, bowling shoes'"></img>
        <Caption fullWidth mdxType="Caption">Provide a way (such as using a tooltip) for users to discover their context when a cell's header information is not in view.
        </Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img4} alt="table with top row annotated 'set as column headers'"></img>
        <Caption fullWidth mdxType="Caption">Annotate any row or column headers in your wireframes.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://carbondesignsystem.com/components/pagination/usage" target="_blank" rel="noopener noreferrer">Pagination</a>, Carbon design system
      * <a href="https://carbondesignsystem.com/patterns/filtering" target="_blank" rel="noopener noreferrer">Filtering</a>, Carbon design system
      * <a href="https://a11y-style-guide.com/style-guide/section-navigation.html#kssref-navigation-pagination" target="_blank" rel="noopener noreferrer">Pagination navigation</a>, Accessibility style guide (this is about site pagination, but is still relevant)
      * <a href="https://www.a11ymatters.com/pattern/pagination/" target="_blank" rel="noopener noreferrer">Accessible pagination</a>, a11ymatters
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria9' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h43' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h65' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='a11ymatters_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_12' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_5' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='digital_a11y_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='a11y_style_guide_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_13' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_18' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_19' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_16' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_17' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_10")} target="_blank" rel="noopener noreferrer">1.4.10 Reflow</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Design for keyboard navigation](/toolkit/design/ux/navigation#design-for-keyboard-navigation), this page
      * [Follow established keyboard conventions](/toolkit/design/ux/keyboard#follow-established-keyboard-conventions), UX - keyboard
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, UX design</li>
      <li parentName="ul"><ToolkitTopic qid='ux_hover_content' mdxType="ToolkitTopic"></ToolkitTopic>, UX design</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      