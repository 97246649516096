import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`All functionality that uses a dragging movement for operation can be achieved by a single pointer without dragging.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that a user operating by touch or other pointing device does not need to use a dragging movement to operate any part of the author-created content. It does not forbid dragging, but rather requires that there is always a pointer alternative to dragging for all functions.`}</p>
    <p>{`As with `}<a href={withPrefix("/requirements/requirements/#2_5_1")}><u>{`Pointer Gestures`}</u></a>{` in WCAG 2.1, this feature is intended to improve usability for those who prefer to operate without a keyboard but who may not have the ability to easily carry out the press-and-hold operations required for dragging movements.`}</p>
    <p><strong parentName="p">{`Exceptions:`}</strong>{` If the dragging function is at the operating system level or wholly determined by the browser, and not modified by the author, dragging is allowed. Dragging movements that are essential are exempt, although these are expected to be rare.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/dragging-movements" target="_blank">{`2.5.7 Dragging movements`}</a>{` (external link to WCAG 2.2) for more information on intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      