import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import { withPrefix } from "gatsby";
import launch32 from "@carbon/icons-react/lib/launch/32";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import * as React from 'react';
export default {
  ArrowRight16,
  withPrefix,
  launch32,
  Row,
  Column,
  React
};