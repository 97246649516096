import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/ux_7_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`To prevent serious consequences, allow users to review their input data in a concise form, especially for transactions that involve money, legal commitments, or user-controlled data.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Summarize pending transactions so that users can check and confirm`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="Delete data? dialog with message that says, 'This action CANNOT be undone. This action will permanently delete the selected data.', followed by black Cancel and red Delete buttons"></img>
        <Caption fullWidth mdxType="Caption">A dialog box confirms with the user whether or not to go through with the permanent result of deleting selected data.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/tutorials/forms/validation/#require-user-confirmation" target="_blank" rel="noopener noreferrer">Validation by the user, Validating input</a>, W3C web accessibility tutorials
      * <a href="https://www.nngroup.com/articles/user-mistakes/" target="_blank" rel="noopener noreferrer">Preventing user errors: avoiding conscious mistakes</a>, Nielsen Norman Group
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_26' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_7' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#3_3_4")} target="_blank" rel="noopener noreferrer">3.3.4 Error Prevention (Legal, Financial, Data)</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      