import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import { withPrefix } from "gatsby";
import launch32 from "@carbon/icons-react/lib/launch/32";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  _frontmatter
}) => <React.Fragment>{children}</React.Fragment>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="contactBody">
      <Row mdxType="Row">
        <Column colLg={5} colMd={4} colSm={3} mdxType="Column">
          <div style={{
            fontSize: "14px",
            paddingTop: "1.5rem",
            paddingBottom: "50rem"
          }}>
Thank you for contacting IBM Accessibility. We will respond to your request within two business days.
          </div>
        </Column>
      </Row>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      