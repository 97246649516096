import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where ICT transmits, converts, or records video with synchronized audio,
it shall preserve audio description data such that it can be played in a manner consistent with clauses `}<em parentName="p">{`7.2.1 Audio Description Playback`}</em>{` and `}<em parentName="p">{`7.2.2 Audio Description Synchronization`}</em>{`.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The European `}<em parentName="p">{`EN 301 549`}</em>{` standard specifies requirements for information and communications technologies (ICT) such as media players and embedded players that render captions and audio descriptions.
Based on regional conventions, audio description is also known as described video, video descriptions, or descriptive narration.
This requirement is Not Applicable (N/A) to products and applications that do not play audio and video.`}</p>
    <p>{`Audio description data includes the timing, volume of the audio,
and content.
Altering the audio descriptions when transmitting, converting,
or playing back could change the meaning and should be avoided to meet this requirement.`}</p>
    <p>{`Several “`}<em parentName="p">{`Time-based Media`}</em>{`” success criteria in WCAG,
such as `}<a href={withPrefix("/requirements/requirements/#1_2_5")} target="_blank" rel="noopener noreferrer">{`1.2.5 Audio Description`}</a>{`,
require that audio descriptions are provided.
That requirement stipulates that meaningful video content has been described and is captured in a format that can be used to provide synchronized audio descriptions.
Those requirements, covering the `}<em parentName="p">{`content`}</em>{` of the audio descriptions,
are complementary to chapter 7 `}<em parentName="p">{`ICT with video capabilities`}</em>{` of EN 301 549,
which covers the actual `}<em parentName="p">{`delivery`}</em>{` of audio descriptions during playback.`}</p>
    <p>{`Ensure that the player you’re developing or deploying preserves the audio description data during playback.`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=36" target="_blank">{`7.2.3 Preservation of Audio Description`}</a>{` (external link to EN 301 549) for more information.`}</p>
    <p><strong parentName="p">{`Related requirements:`}</strong>{`
Applications that play audio and video are required to meet the following related requirements:`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Audio/Video Content requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_3")} target="_blank" rel="noopener noreferrer">1.2.3 Audio Description or Media Alternative (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_5")} target="_blank" rel="noopener noreferrer">1.2.5 Audio Description (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#10_6")} target="_blank" rel="noopener noreferrer">10.6 Audio Description Timing (Non-web Documents)</a></li>
    </ul>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Player requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_4_2")} target="_blank" rel="noopener noreferrer">1.4.2 Audio Control</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_1")} target="_blank" rel="noopener noreferrer">7.2.1 Audio Description Playback</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_2")} target="_blank" rel="noopener noreferrer">7.2.2 Audio Description Synchronization</a></li>
      <li parentName="ul">{`7.2.3 Preservation of Audio Description - `}<em parentName="li">{`this requirement`}</em></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">7.3 User Controls for Captions & Audio Description</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#503_4_2")} target="_blank" rel="noopener noreferrer">503.4.2 Audio Description Controls</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      