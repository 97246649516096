import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { withPrefix } from "gatsby";
import Default from "../gatsby-theme-carbon/templates/LandingPage";
import { TkFooter } from "../components/toolkit/TkFooter";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import WhatWeDoPanel from "../components/home/whatWeDoPanel";
import WhatsNewPanel from "../components/home/whatsNewPanel";
import NeedHelpPanel from "../components/home/needHelpPanel";
import "./index.scss";
export const _frontmatter = {
  "title": "Home",
  "description": "IBM Accessibility Homepage",
  "nav": "toolkit",
  "theme": "white"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  pageContext,
  children,
  location,
  Title
}) => Default({
  pageContext: pageContext,
  pageContext,
  children: children,
  location: location,
  Title: Title,
  hideBanner: false
});

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="homepage">
 <WhatWeDoPanel mdxType="WhatWeDoPanel"></WhatWeDoPanel>
 <WhatsNewPanel mdxType="WhatsNewPanel"></WhatsNewPanel>
 <NeedHelpPanel mdxType="NeedHelpPanel"></NeedHelpPanel>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      