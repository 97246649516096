import { withPrefix } from "gatsby";
import Default from "../../../../src/gatsby-theme-carbon/templates/Default";
import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion } from "carbon-components-react";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "../../../../src/images/cross.png";
import DetailNativeHTML from "../../../../src/pages/toolkit/develop/native-html.mdx";
import DetailFramework from "../../../../src/pages/toolkit/develop/framework.mdx";
import DetailAria from "../../../../src/pages/toolkit/develop/aria-guidance.mdx";
import DetailFree from "../../../../src/pages/toolkit/develop/for-free.mdx";
import DetailVerification from "../../../../src/pages/toolkit/develop/run-verification.mdx";
import DetailConfirmKeyboard from "../../../../src/pages/toolkit/develop/confirm-keyboard-interaction.mdx";
import DetailTabOrder from "../../../../src/pages/toolkit/develop/check-tab-order.mdx";
import DetailZoom from "../../../../src/pages/toolkit/develop/content-zoom.mdx";
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import ArticleCardImg1 from "../../../../src/images/ToolkitLandingImg1.png";
import ArticleCardImg2 from "../../../../src/images/ToolkitLandingImg2.png";
import ArticleCardImg3 from "../../../../src/images/ToolkitLandingImg3.png";
import muffin from "../../../../src/images/muffin.svg";
import { TkFooter } from "../../../../src/components/toolkit/TkFooter";
import PaceOfCompletion from "../../../../src/content/PaceOfCompletion";
import "../../../../src/pages/toolkit/toolkit-overviews.scss";
import "../../../../src/pages/toolkit/toolkit-resource-cards.scss";
import * as React from 'react';
export default {
  withPrefix,
  Default,
  TkAccordionItem,
  Accordion,
  Row,
  Column,
  img1,
  DetailNativeHTML,
  DetailFramework,
  DetailAria,
  DetailFree,
  DetailVerification,
  DetailConfirmKeyboard,
  DetailTabOrder,
  DetailZoom,
  ArrowRight16,
  ArticleCardImg1,
  ArticleCardImg2,
  ArticleCardImg3,
  muffin,
  TkFooter,
  PaceOfCompletion,
  React
};