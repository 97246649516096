import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Occasionally when testing with a screen reader you’ll discover a bug in the screen reader’s support of code that conforms to the standards.  Also debug the issue with a different browser or platform.  Consult with the bug reporting repositories to see if the bug has been previously reported, the status, or if you need to report a new bug.`}</p>
    <ul>
      <li parentName="ul"><ToolkitReference qid='freedom_scientific_1' source="hide" mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nvda_1' source="hide" mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><a href="mailto:accessibility@apple.com?subject=Reporting VoiceOver bug" rel="noopener noreferrer">VoiceOver support</a> </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      