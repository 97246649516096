import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Headings and labels describe topic or purpose.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures when headings and labels are present, they describe the topic or purpose of the item(s) with which they are associated.
Descriptive headings and labels:`}</p>
    <ul>
      <li parentName="ul">{`Enable users to easily navigate to sections of interest and understand the structure and relationships of content.`}</li>
      <li parentName="ul">{`Help users with cognitive disabilities to read and understand better.`}</li>
      <li parentName="ul">{`Help low-vision users focus on relevant content.`}</li>
      <li parentName="ul">{`Need not be lengthy. A word may suffice if it provides an appropriate cue.
Because screen reader users often use headings to navigate through content, the headings must make sense when read out of context and they must accurately describe the content that follows them. Similarly, descriptive labels allow all users to quickly understand the purpose of interactive components such as input.`}</li>
    </ul>
    <p><strong parentName="p">{`Note 1:`}</strong>{` Headings are used to describe sections of content while labels are used with controls.
In some cases, it may be unclear whether a piece of static text should be a heading or a label.
But whether treated as a label or a heading, the requirement is the same. `}</p>
    <p><strong parentName="p">{`Note 2:`}</strong>{` This requirement does not mean that headings and labels must exist in all types of content.
It requires that `}<em parentName="p">{`if`}</em>{` headings or labels are provided, they must be descriptive.
When  labeling is ”`}<em parentName="p">{`required`}</em>{`” is covered in `}<a href={withPrefix("/requirements/requirements/#3_3_2")} target="_blank" rel="noopener noreferrer">3.3.2 Labels or Instructions</a>.
Also note that headings and labels must meet <a href={withPrefix("/requirements/requirements/#1_3_1")} target="_blank" rel="noopener noreferrer">{`1.3.1 Info and Relationships`}</a>{`.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/headings-and-labels" target="_blank">{`Understanding 2.4.6 Headings and Labels`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      