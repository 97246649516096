import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion } from "carbon-components-react";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import "../../../../src/pages/toolkit/topics.scss";
import DetailTextColorContrast from "../../../../src/pages/toolkit/design/visual/level1/text-color-contrast.mdx";
import DetailLinkTextContrast from "../../../../src/pages/toolkit/design/visual/level1/link-text-contrast.mdx";
import DetailComponentsContrast from "../../../../src/pages/toolkit/design/visual/level1/components-contrast.mdx";
import DetailVisualStates from "../../../../src/pages/toolkit/design/visual/level1/visual-states.mdx";
import DetailIcons from "../../../../src/pages/toolkit/design/visual/level1/icons.mdx";
import DetailDataVisualization from "../../../../src/pages/toolkit/design/visual/level1/data-visualization.mdx";
import DetailResponsiveReflow from "../../../../src/pages/toolkit/design/visual/level1/responsive-reflow.mdx";
import DetailRequiredFields from "../../../../src/pages/toolkit/design/visual/level1/required-fields.mdx";
import DetailTextStyles from "../../../../src/pages/toolkit/design/visual/level2/text-styles.mdx";
import DetailLegends from "../../../../src/pages/toolkit/design/visual/level2/legends.mdx";
import DetailTooltipsOverlays from "../../../../src/pages/toolkit/design/visual/level2/tooltips-overlays.mdx";
import DetailLayoutOrientations from "../../../../src/pages/toolkit/design/visual/level2/layout-orientations.mdx";
import DetailDataTables from "../../../../src/pages/toolkit/design/visual/level2/data-tables.mdx";
import DetailRegions from "../../../../src/pages/toolkit/design/visual/level3/regions.mdx";
import DetailFormInputs from "../../../../src/pages/toolkit/design/visual/level3/form-inputs.mdx";
import DetailWhiteSpace from "../../../../src/pages/toolkit/design/visual/level3/white-space.mdx";
import Level1Icon from "../../../../src/visuals/plan/level_1_icon.svg";
import Level2Icon from "../../../../src/visuals/plan/level_2_icon.svg";
import Level3Icon from "../../../../src/visuals/plan/level_3_icon.svg";
import { withPrefix } from "gatsby";
import ExpandCollapseAccordion from "../../../../src/components/expand_collapse_accordion/expand_collapse_accordion.js";
import * as React from 'react';
export default {
  TkAccordionItem,
  Accordion,
  Row,
  Column,
  DetailTextColorContrast,
  DetailLinkTextContrast,
  DetailComponentsContrast,
  DetailVisualStates,
  DetailIcons,
  DetailDataVisualization,
  DetailResponsiveReflow,
  DetailRequiredFields,
  DetailTextStyles,
  DetailLegends,
  DetailTooltipsOverlays,
  DetailLayoutOrientations,
  DetailDataTables,
  DetailRegions,
  DetailFormInputs,
  DetailWhiteSpace,
  Level1Icon,
  Level2Icon,
  Level3Icon,
  withPrefix,
  ExpandCollapseAccordion,
  React
};