import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Applications shall expose information and mechanisms necessary to track focus, text insertion point,
and selection attributes of user interface components.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`Even when the software provides keyboard access so users can navigate the software,
the focus location, selection state, and text insertion point information must also be programmatically available to Assistive Technology (AT).
AT’s such as screen readers and screen magnifiers need to know the position and contents of the visual focus indicator,
so it can describe, magnify, or manipulate the focused object for the user.
For example, as a user tabs around a dialog,
a screen magnifier needs to follow the visual focus and does that using the programmatic focus information.`}</p>
    <p>{`When editing, the caret or insertion bar is the visual focus.
As a AT user moves the focus with the arrow keys,
the AT must know the position of that focus so that it can echo the current character,
word or line.
The AT uses programmatically determined text selection, boundaries,
and attributes to describe the text styling to the user.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` The selection and manipulation of text involves considerations from several requirements.
Depending on circumstances, such as whether text is static, read-only, or fully editable,
similar techniques may be required from each of `}<em parentName="p">{`502.3.x set of requirements`}</em>{`:`}</p>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#502_3_8")} target="_blank">502.3.8 Text</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#502_3_9")} target="_blank">502.3.9 Modification of Text</a></li>
      <li parentName="ul">{`502.3.12 Focus Cursor (this requirement)`}</li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#502_3_13")} target="_blank">502.3.13 Modification of Focus Cursor</a></li>
    </ul>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.12" target="_blank">{`502.3.12 Focus Cursor`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Resources`}</h4>
    <ul>
      <li parentName="ul">{`See resources listed in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      