import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where ICT has a mode of operation requiring simultaneous user actions for its operation,
such ICT shall provide at least one mode of operation that does not require simultaneous user actions to operate the ICT.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Having to use both hands to open the lid of a laptop,
having to press two or more keys at the same time,
or having to touch a surface with more than one finger are examples of simultaneous user actions.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`Although primarily applicable to `}<em parentName="p">{`platform software`}</em>{`,
this requirement ensures that users who have permanent or temporary limited dexterity and reach,
missing digits, hands and limbs,
and other impairments can operate your non-web software.
This requirement only addresses simultaneous operation in one modality since there must be a keyboard-equivalent to navigating and operating web and software.
For example, this requirement does not require the elimination of using a mouse click in combination with a key press
since there should be a keyboard equivalent to the mouse click.`}</p>
    <p><strong parentName="p">{`Related requirements:`}</strong></p>
    <ul>
      <li parentName="ul">{`Simultaneous key presses are covered by `}<a href={withPrefix("/requirements/requirements/#502_4")} target="_blank">{`502.4 Platform accessibility features`}</a>{`,
that enable sequential entry of multiple (chorded) keystrokes`}</li>
      <li parentName="ul">{`Simultaneous fingers on a touch surface is covered by `}<a href={withPrefix("/requirements/requirements/#2_5_1")} target="_blank">{`2.5.1 Pointer gesture`}</a></li>
      <li parentName="ul">{`Not requiring two hands to open the lid of a laptop is covered by `}<a href="https://pages.github.ibm.com/IBMa/able/Accessibility_requirements/Hardware_Requirements" target="_blank">{`Hardware requirement 407.6.1 Operation`}</a>{` (IBM internal use only)`}</li>
    </ul>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=29" target="_blank">{`5.9 Simultaneous User Actions`}</a>{` (external link to EN 301 549) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      