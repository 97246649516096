import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Content can reflow without loss of information or functionality, and without requiring scrolling in two dimensions.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures content can be enlarged without requiring horizontal scrolling.`}</p>
    <p>{`The key intended beneficiaries of Reflow are low-vision users who want to be able to enlarge text yet still see the content in their viewport without scrolling across the page.`}</p>
    <p>{`With Reflow, width constraints are placed on horizontally read languages, such as English, while height constraints are placed on vertically read languages, such as Chinese. The constraints are as follows:`}</p>
    <ul>
      <li parentName="ul">{`Vertical scrolling content “can be presented without loss of information or functionality, and without requiring scrolling in two dimensions” at a width equivalent to 320 CSS pixels;`}</li>
      <li parentName="ul">{`Horizontal scrolling content can be presented in the same manner at a height equivalent to 256 CSS pixels.`}</li>
    </ul>
    <p>{`“Scrolling in two dimensions” refers to forcing the user to scroll in both the X dimension (left and right) as well as the Y dimension (up and down) in order to view the content. Such scrolling makes it difficult to keep your place when relocating from the end of a long line to the start of the next line, especially when text is magnified. Since IBM mainly produces horizontally read content, we have simplified the objective of this guidance to say “horizontal scrolling” which is the scrolling to avoid in most written languages, including English.`}</p>
    <p>{`This requirement complements the existing WCAG 2.0 `}<a href={withPrefix("/requirements/requirements/#1_4_4")} target="_blank" rel="noopener noreferrer">{`1.4.4 Resize text`}</a>{`,
which requires text to be able to be doubled in size.
Resize does not require text to reflow, but merely to enlarge.
When combined with this requirement `}<em parentName="p">{`1.4.10 Reflow`}</em>{`, it means that the starting size of text must be able to resized to 200% while still having the ability to reflow so that horizontal scrolling is not needed.`}</p>
    <p>{`Authors can test for reflow on a desktop browser with typical 1280x1024 pixel dimensions by zooming the content to 400%. If the content expands without requiring scrolling in both dimensions, this is mathematically the same as reducing the window to 320x256 pixels.`}</p>
    <p><strong parentName="p">{`Exception:`}</strong>{` Parts of the content “which require two-dimensional layout for usage or meaning” do not need to meet this requirement. Two-dimensional content includes maps, videos and images, which generally rely on a maintained aspect ratio to be intelligible. As well, even relatively simple data tables may not fit within the confines of a reduced viewport and thus data tables are also excepted. It is important to note that there are advisory techniques that allow images to be displayed and tabular information to be transformed to fit within a constrained viewport. Where possible, content authors are encouraged to adopt such techniques.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/UNDERSTANDING/reflow.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.4.10 Reflow`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      