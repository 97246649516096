import React, { Component } from 'react'
import ContactContent from "../../content/contact/contactBody.mdx"
import "./contactBody.scss";

class ContactBanner extends Component {
    render() {
        return (
            <div className="contactBody" >
                {/* <p>Contact</p> */}
                {/* <p>Get in touch with IBM Accessibility</p> */}
                <ContactContent>

                </ContactContent>
            </div>
        )

    }


}
export default ContactBanner