import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from 'gatsby';
import ToolkitRequirement from '../../../../components/rms_resources/requirements';
import ToolkitReference from '../../../../components/rms_resources/references';
import ToolkitTechnique from '../../../../components/rms_resources/techniques';
import ToolkitTopic from '../../../../components/rms_resources/topics';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Pointer operations do not cause contrast failures`}</li>
      <li parentName="ul">{`Pointer operations do not rely on multipoint activation or path-based gestures`}</li>
      <li parentName="ul">{`Pointer operations do not rely on dragging (`}<strong parentName="li">{`WCAG 2.2 only`}</strong>{`)`}</li>
      <li parentName="ul">{`Pointer operations can be aborted, undone, or reversed`}</li>
      <li parentName="ul">{`Targets are of sufficient size or are sufficiently spaced (`}<strong parentName="li">{`WCAG 2.2 only`}</strong>{`)`}</li>
      <li parentName="ul">{`Pointer interactions that alter content are noted for keyboard tests`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`When a pointer acts on content via a click or similar action, resulting indications of a change of state — such as expanded, selected, or pressed — should not cause content to fail minimum contrast. As well, if state changes are indicated only through color differences, they should be tested using high contrast mode.`}</p>
    <p>{`Check all functionality that uses multipoint (multi-touch) activation or path-based gestures can also be operated with a single pointer without such complex gestures, unless they are essential. If your product is aiming to meet the newer WCAG 2.2 requirements, also ensure there is a single pointer alternative to drag and drop.`}</p>
    <p>{`If testing for WCAG 2.2, confirm targets are at least 24x24 pixels, or sufficiently spaced from other targets.`}</p>
    <p>{`Pointer operations need to have the ability to be cancelled. Finally, for any interaction offered using the pointer, there must be a keyboard equivalent, so any unusual pointer interactions should be noted for future keyboard testing.`}</p>
    <h5 className="accordionH5">Completes testing for:</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req1_4_11" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><a href="../../../requirements/requirements/?version=7_3#2_5_8">
  2.5.8 Target Size (Minimum)
        </a>, IBM accessibility requirements (7.3 only)</li>
    </ul>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req2_5_1" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req2_5_2" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><a href="../../../requirements/requirements/?version=7_3#2_5_7">
  2.5.7 Dragging Movements
        </a>, IBM accessibility requirements (7.3 only)</li>
    </ul>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req1_4_3" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req2_4_7" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      