import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Error reporting is an important aspect of user interaction. Designers are responsible for ensuring errors are obvious to everyone, and wireframes should contain annotations on the desired interaction. In general, implementing errors accessibly includes the following:`}</p>
    <ul>
      <li parentName="ul">{`Inject error messages in the correct reading order in the DOM.`}</li>
      <li parentName="ul">{`If a suggestion for fixing an error is provided, associate it with the `}<inlineCode parentName="li">{`input`}</inlineCode>{` element using `}<inlineCode parentName="li">{`aria-describedby`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Set the `}<inlineCode parentName="li">{`aria-invalid`}</inlineCode>{` attribute to “true” on elements with an input error (and remove it once the error is fixed).`}</li>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`alert`}</inlineCode>{` role or `}<inlineCode parentName="li">{`aria-live`}</inlineCode>{` regions to surface error messages that do not take focus.`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`<!-- Example of an input marked invalid with a properly associated error message -->
<label for “email”>Email*</label>
<input id=”email” type=”text” required aria-describedby=”errmsg” aria-invalid=”true”>
<span aria-live=”polite” id=”errmsg”>Please enter an email address</span>
`}</code></pre>
    <p>{`Depending on the use case in the design, additional implementation techniques are used to make them accessible.`}</p>
    <p>{`List of errors:`}</p>
    <ul>
      <li parentName="ul">{`When the form is reloaded, ensure it contains all previous user-supplied input.`}</li>
    </ul>
    <p>{`Inline errors:`}</p>
    <ul>
      <li parentName="ul">{`The text identification of elements with errors must be programmatically associated with the field. See the topic on `}<ToolkitTopic qid='dev_dynamic_implement_validation' name="minimizing interruptions" mdxType="ToolkitTopic"></ToolkitTopic>{` below for specifics.`}</li>
      <li parentName="ul">{`See the `}<ToolkitReference qid='w3c_web_accessibility_tutorials_24' name="inline feedback section" source="hide" mdxType="ToolkitReference"></ToolkitReference>{` of the W3C web accessibility tutorial for
additional approaches.`}</li>
    </ul>
    <p>{`Error dialogs:`}</p>
    <ul>
      <li parentName="ul">{`Ensure the element has a role of `}<inlineCode parentName="li">{`dialog`}</inlineCode>{` or `}<inlineCode parentName="li">{`alertdialog`}</inlineCode>{` and is modal (`}<inlineCode parentName="li">{`aria-modal`}</inlineCode>{` set to true)`}</li>
      <li parentName="ul">{`Set focus, typically on the first interactive element`}</li>
      <li parentName="ul">{`Ensure the dialog has a name (typically by using `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` with the visible title)`}</li>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`aria-describedby`}</inlineCode>{` to surface any important static text in the dialog to screen readers`}</li>
      <li parentName="ul">{`When the dialog is dismissed, ensure focus returns to the trigger of the dialog`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`<!-- Example of an ARIA alert dialog -->
<div role="alertdialog" aria-labelledby="heading" aria-describedby="description" aria-modal="true">
<h1 id="heading">Error</h1>
<div id="description">Description of the error and suggestions for how to fix it.</div>
<button>Return to page and correct error</button>
</div>
`}</code></pre>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G139" target="_blank" rel="noopener noreferrer">G139: Creating a mechanism that allows users to jump to errors</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA18" target="_blank" rel="noopener noreferrer">ARIA18: Using aria-alertdialog to identify errors</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA19" target="_blank" rel="noopener noreferrer">ARIA19: Using aria role=”alert” or live regions to identify errors</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR18" target="_blank" rel="noopener noreferrer">SCR18: Providing client-side validation and alert</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR32" target="_blank" rel="noopener noreferrer">SCR32: Providing client-side validation and adding error text via the DOM</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g139' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria18' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria19' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr18' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr32' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#3_3_1")} target="_blank" rel="noopener noreferrer">3.3.1 Error Identification</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#3_3_3")} target="_blank" rel="noopener noreferrer">3.3.3 Error Suggestion</a>, IBM accessibility requirements
      * <a href="https://developer.paciellogroup.com/blog/2018/06/the-current-state-of-modal-dialog-accessibility/" target="_blank" rel="noopener noreferrer">The current state of modal dialog accessibility</a>, Paciello Group
      * <a href="https://www.youtube.com/watch?v=JS68faEUduk" target="_blank" rel="noopener noreferrer">Accessible modal dialogs, a11ycasts #19</a>, Google resource (video)
      * <a href="https://www.w3.org/WAI/tutorials/forms/notifications/" target="_blank" rel="noopener noreferrer">User notifications</a>, W3C web accessibility tutorials
      * <a href="https://www.accessibilityoz.com/videos/error-messages-in-forms/" target="_blank" rel="noopener noreferrer">Error messages in forms</a>, AccessibilityOz (video)
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='paciello_group_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='google_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_23' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='accessibilityoz_1' mdxType="ToolkitReference"></ToolkitReference> (video)</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      