import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Screen reader assistive technology uses the DOM order to convert the text on the web page to speech output for users. Add content to the DOM in an order that preserves the meaning when read sequentially from the DOM.`}</p>
    <p>{`Common places where there are reading order issues:`}</p>
    <ul>
      <li parentName="ul">{`Multi-column content, such as newspaper layouts`}</li>
      <li parentName="ul">{`Tables used to layout content rather than present data`}</li>
      <li parentName="ul">{`When CSS is used to reorder content`}</li>
      <li parentName="ul">{`When white space characters are used to position content rather than CSS or structural markup`}</li>
    </ul>
    <p>{`Be sure to `}<ToolkitTopic qid='verify_reader_check_meaningful' name="check the reading order using a screen reader" mdxType="ToolkitTopic"></ToolkitTopic>{`, as it is the only way to verify the order is correct.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G57" target="_blank" rel="noopener noreferrer">G57: Ordering the content in a meaningful sequence</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/css/C27.html" target="_blank" rel="noopener noreferrer">C27: Making the DOM order match the visual order (CSS)</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/failures/F49.html" target="_blank" rel="noopener noreferrer">F49: Failure of Success Criterion 1.3.2 due to using an HTML layout table that does not make sense when linearized</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g57' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='c27' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='f49' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_3_2")} target="_blank" rel="noopener noreferrer">1.3.2 Meaningful Sequence</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      