import { withPrefix } from "gatsby";
import Default from "../../../../src/gatsby-theme-carbon/templates/Default";
import checkmark from "../../../../src/images/checkmark.svg";
import "../../../../src/pages/toolkit/topics.scss";
import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion, StructuredListWrapper, StructuredListBody, StructuredListHead, StructuredListRow, StructuredListCell } from "carbon-components-react";
import ToolkitRequirement from "../../../../src/components/rms_resources/requirements";
import ToolkitReference from "../../../../src/components/rms_resources/references";
import ToolkitTechnique from "../../../../src/components/rms_resources/techniques";
import ToolkitTopic from "../../../../src/components/rms_resources/topics";
import * as React from 'react';
export default {
  withPrefix,
  Default,
  checkmark,
  TkAccordionItem,
  Accordion,
  StructuredListWrapper,
  StructuredListBody,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  ToolkitRequirement,
  ToolkitReference,
  ToolkitTechnique,
  ToolkitTopic,
  React
};