import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Any relationship that a component has as a label for another component,
or of being labeled by another component, shall be programmatically determinable.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`In addition to knowing the `}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`state`}</inlineCode>{`, `}<inlineCode parentName="p">{`boundary`}</inlineCode>{`, `}<inlineCode parentName="p">{`properties`}</inlineCode>{`,
and `}<inlineCode parentName="p">{`description`}</inlineCode>{` of a user interface object (component or widget) as covered in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`,
it is important for the assistive technology (AT) user to understand the `}<em parentName="p">{`relationship`}</em>{` a
component (widget or UI object) may have as a `}<inlineCode parentName="p">{`label for`}</inlineCode>{` or be `}<inlineCode parentName="p">{`labeled by`}</inlineCode>{` another component,
and that the AT can programmatically determine that relationship.`}</p>
    <p>{`This requirements is in addition the requirement that a UI object have a `}<inlineCode parentName="p">{`name`}</inlineCode>{` such as icon names,
window titles, button labels, and edit field labels.
The UI object and its `}<inlineCode parentName="p">{`name`}</inlineCode>{` must be associated programmatically with the related UI object or content for which it is a `}<em parentName="p">{`label for`}</em>{`
or for which it is `}<em parentName="p">{`labeled by`}</em>{`,
such that the AT can render that information to the user.`}</p>
    <p>{`Labels and relationships are used to describe sections of content, controls, as well as groups of controls.
In some cases,
it may be unclear whether a piece of static text is a label or additional description or instructions.
But whether treated as a label, a description, or instructions the requirement is the same:
if labels, descriptions or instructions are present, they must be programmatically determinable.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{`
For non-web software to which this requirement applies,
programmatic determinability is best achieved through the use of accessibility services or API
provided by platform software to enable interoperability between software and assistive technologies.
See `}<a href="https://www.w3.org/TR/wcag2ict/#content-structure-separation-programmatic" target="_blank">{`WCAG2ICT - 1.3.1 Info and relationships`}</a>{`.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.6" target="_blank">{`502.3.6 Label Relationships`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Resources`}</h4>
    <ul>
      <li parentName="ul">{`See resources listed in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      