import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`All functionality that uses multipoint or path-based gestures for operation can be operated with a single pointer
without a path-based gesture.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures users can operate interactive content with a simple action.`}</p>
    <p>{`The key intended beneficiaries are users with some physical disabilities,
who may lack the ability to carry out complex gestures on mobile devices or other touchscreens.
When designers and developers create a custom gesture,
they need to ensure it is a simple action using a single contact point,
or that there is a simple action to achieve the same result.`}</p>
    <p>{`An example of a multipoint gesture is a two-finger pinch to zoom.
An example of a path-based gesture is a swipe-up since the direction of the swipe — it’s path — determines the interpreted action.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` This requirement does not apply to operating system and browser gestures,
which are not under the developer’s control.
Most devices have platform accessibility features which offer alternatives for device-based complex gestures.`}</p>
    <p><strong parentName="p">{`Essential Exception:`}</strong>{` Multipoint or patterned gestures such as those that require the drawing of a specific shape
are allowed if that is the only way to achieve something, such as a user’s signature.
Note that in many cases a signature may not be essential if there are other acceptable ways to,
for instance, validate a user’s identify or capture their consent.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/pointer-gestures" target="_blank" rel="noopener noreferrer">{`Understanding 2.5.1 Pointer Gestures`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      