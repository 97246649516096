import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Non-sensory words are provided for sensory-worded instructions`}</li>
      <li parentName="ul">{`Headings and labels are clear and concise`}</li>
      <li parentName="ul">{`Instructions are always provided for required user input, expected formats, and keyboard interaction`}</li>
      <li parentName="ul">{`Headings, labels, instructions, and foreign words and pages are noted for later confirmation that they are also compatible with the screen reader`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`This step is to ensure that your content achieves the WCAG principle of `}<ToolkitReference qid='wcag_3' source="hide" name="Understandable" mdxType="ToolkitReference"></ToolkitReference>{`. You will quickly read through all text on the page looking
for any confusing or vague textual content. Headings and labels should be
clear, concise, yet descriptive. Instructions must be provided where users require
guidance to complete a task easily, especially where an unusual keyboard interaction is required.`}</p>
    <p>{`Language that uses sensory terms for position
(such as “left”, “top” or “corner”) or shape (“circle”, “square”) must have
an additional non-sensory way of describing such information. Some text
must be tested with a screen reader in a later step to confirm support for assistive technology: words and phrases in a different language should be flagged for further confirmation, as should headings, labels, and instructions.`}</p>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_6' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      