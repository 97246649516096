import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from 'gatsby';
import ToolkitRequirement from '../../../../components/rms_resources/requirements';
import ToolkitReference from '../../../../components/rms_resources/references';
import ToolkitTechnique from '../../../../components/rms_resources/techniques';
import ToolkitTopic from '../../../../components/rms_resources/topics';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Dynamic update triggers and effects work with the screen reader`}</li>
      <li parentName="ul">{`Mechanism is available to pause or stop the audio that plays automatically, or a mechanism is available to control audio volume independently from the overall system volume level`}</li>
      <li parentName="ul">{`User’s point of regard is properly handled and announced by the screen reader`}</li>
      <li parentName="ul">{`Error handling and instructions, including programmatic identification of associated inputs, and required or invalid states, are exposed to the screen reader`}</li>
      <li parentName="ul">{`Status messages appearing on screen are announced by the screen reader`}</li>
      <li parentName="ul">{`Error messages and time limit notifications are properly announced by the screen reader`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`This step reconfirms the findings in the `}<ToolkitTopic qid='verify_manual_confirm_page' mdxType="ToolkitTopic"></ToolkitTopic>{` manual testing, but using the screen reader in combination with a keyboard.
Testers should pay particular attention that the speech output of the screen reader matches the visual information and that messages and dynamic updates are properly detected and announced by the screen reader.
Testers may wish to inspect code to verify if properties, relationships, and states are properly set.
Also check that messages and time limit notifications that appear on screen and do not take focus are still read by the screen reader depending on the verbosity settings.`}</p>
    <h5 className="accordionH5">Completes testing for:</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req1_3_1" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req1_4_2" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req2_2_1" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req3_3_2" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req4_1_2" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req4_1_3" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      