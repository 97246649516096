import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The purpose of each input field that collects information about the user can be programmatically determined when the field serves a common purpose.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that meaning of common inputs is available via technology.`}</p>
    <p>{`The key beneficiaries of this requirement are users with some cognitive disabilities.
In situations where users are asked to provide information about themselves,
if the purpose of each input can be programmatically determined,
then the prompts may be customized or auto-filled through Assistive Technologies and browsers to make them more understandable to a specific user,
and potentially easier to complete.`}</p>
    <p>{`Not all inputs must meet this.
The requirement is restricted to common ones identified in WCAG’s list of `}<a href="https://www.w3.org/TR/WCAG22/#input-purposes" target="_blank" rel="noopener noreferrer">{`Input Purposes for User Interface Components`}</a>{`,
such as name, address and contact information.
As well, this only needs to be met when a technology can programmatically indicate the meaning.
The most common usage will be employing auto-fill attributes.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/UNDERSTANDING/identify-input-purpose.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.3.5 Identify Input Purpose`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      