import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Button from 'carbon-components-react/lib/components/Button';
import Form from 'carbon-components-react/lib/components/Form';
import FormGroup from 'carbon-components-react/lib/components/FormGroup';
import TextInput from 'carbon-components-react/lib/components/TextInput';
import TextArea from 'carbon-components-react/lib/components/TextArea';
import Checkbox from 'carbon-components-react/lib/components/Checkbox';
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import { withPrefix } from "gatsby";
import launch32 from "@carbon/icons-react/lib/launch/32";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  _frontmatter
}) => <React.Fragment>{children}</React.Fragment>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Row mdxType="Row">
 <Column colLg={3} mdxType="Column"> {
          /* START Left column */
        }
   <h5>Contact us</h5>
 </Column> {
        /* END Left column */
      }
 <Column colLg={9} colMd={6} colSm={3} mdxType="Column"> {
          /* START Right column */
        }
 <div className="tkContactFormHeaderText">
          <p>{` If you need help with access credentials for an IBM account or have a general question for IBM, please contact `}<a href="https://www.ibm.com/mysupport/s/?language=en_US" target="_blank" rel="noopener noreferrer">{`IBM Support`}</a>{`.`}</p>
          <p>{` Use the form below for questions about IBM Accessibility, the accessibility of IBM products and web sites, or our approach to access for people with disabilities.`}</p>
 </div>
 <div style={{
          "paddingTop": "48px"
        }}> All fields are required, except where marked &#8220;(optional)&#8221;. </div>
 
 <div> {
            /* BEGIN Form */
          }
          <Form id="form" method="post" action="https://www-50.ibm.com/systems/campaignmail/campaignmail.wss" mdxType="Form">
            <input type="hidden" value="contact_ibma11y22" name="campaignid" />
            <input type="hidden" name="ncountry" value="us" />
            {
              /* Redirect to /able/contact_thank_you can only be test in production. */
            }
            <input type="hidden" name="thankyoupage" value="http://www.ibm.com/able/contact_thank_you" />
            <input type="hidden" name="sort" value="First name,Last name,Company/Organization,title,Phone number,email,IBM Sales Representative,Additional questions" />
            <input type="hidden" name="requiredfields" value="First name,Last name,Company/Organization,title,Phone number,email,Additional questions" />
            <input type="hidden" name="error_First name" value="First name" />
            <input type="hidden" name="error_Last name" value="Last name" />
            <input type="hidden" name="error_Company/Organization" value="Company/Organization" />
            <input type="hidden" name="error_title" value="Job title" />
            <input type="hidden" name="error_Phone number" value="Phone number" />
            <input type="hidden" name="error_email" value="Email" />
            <input type="hidden" name="error_Additional questions" value="Message" />
            <Row mdxType="Row">
              <Column colLg={3} colMd={6} colSm={4} mdxType="Column">
                <div className="tkFormField"></div>
                <TextInput light className="tkText" id="firstname" name="First name" aria-required="true" invalidText="A valid value is required" labelText="First name" required mdxType="TextInput" />
              </Column>
              <Column colLg={3} colMd={6} colSm={4} mdxType="Column">
                <div className="tkFormField"></div>
                <TextInput light className="tkText" id="lastname" name="Last name" aria-required="true" invalidText="A valid value is required" labelText="Last name" required mdxType="TextInput" />
              </Column>
            </Row>
            <Row mdxType="Row">
              <Column colLg={3} colMd={6} colSm={4} mdxType="Column">
                <div style={{
                  "paddingTop": "32px"
                }}></div>
                <TextInput light className="tkText" id="company" name="Company/Organization" aria-required="true" invalidText="A valid value is required" labelText="Organization" required mdxType="TextInput" />
              </Column>
              <Column colLg={3} colMd={6} colSm={4} mdxType="Column">
                <div style={{
                  "paddingTop": "32px"
                }}></div>
                <TextInput light className="tkText" id="title" name="Title" aria-required="true" invalidText="A valid value is required" labelText="Job title" required mdxType="TextInput" />
              </Column>
            </Row>
            <Row mdxType="Row">
              <Column colLg={3} colMd={6} colSm={4} mdxType="Column">
                <div style={{
                  "paddingTop": "32px"
                }}></div>
                <TextInput light className="tkText" id="phone" name="Phone Number" aria-required="true" invalidText="A valid value is required" labelText="Phone number" required mdxType="TextInput" />
              </Column>
              <Column colLg={3} colMd={6} colSm={4} mdxType="Column">
                <div style={{
                  "paddingTop": "32px"
                }}></div>
                <TextInput light className="tkText" id="email" name="Email" aria-required="true" invalidText="A valid value is required" labelText="E-mail" required mdxType="TextInput" />
              </Column>
            </Row>
            <Row mdxType="Row">
              <Column colLg={3} colMd={6} colSm={4} mdxType="Column">
                <div style={{
                  "paddingTop": "32px"
                }}></div>
                <TextInput light className="tkText" id="salesrep" name="IBM Sales Representative" labelText="IBM Sales Representative (optional)" mdxType="TextInput" />
              </Column>
            </Row>
            <Row mdxType="Row">
              <Column colLg={6} colMd={6} colSm={4} mdxType="Column">
                <div style={{
                  "paddingTop": "32px"
                }}></div>
                <TextArea light className="tkText" id="message" name="Additional questions" aria-required="true" labelText="Message" cols={50} rows={8} required mdxType="TextArea" />
              </Column>
            </Row>
            <div style={{
              "paddingTop": "32px"
            }}></div>
            <Row mdxType="Row">
              <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
                <div className="tkSubmit">
By clicking submit, I accept <a href="https://www.ibm.com/privacy/us/en/" target="_blank" rel="noopener noreferrer" alt="IBM’s privacy statement">IBM’s privacy statement</a>. <br /><br />
                  <Button type="submit" mdxType="Button">
    Submit
                  </Button>
                </div>
              </Column>
            </Row>
          </Form>
        </div> {
          /* END Form */
        }
 </Column> {
        /* END Right column */
      }
    </Row>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      