import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from 'gatsby';
import ToolkitRequirement from '../../../../components/rms_resources/requirements';
import ToolkitReference from '../../../../components/rms_resources/references';
import ToolkitTechnique from '../../../../components/rms_resources/techniques';
import ToolkitTopic from '../../../../components/rms_resources/topics';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Applications do not prevent orientation changes`}</li>
      <li parentName="ul">{`Applications do not rely on motion`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`Orientation changes and motion testing are normally carried out on mobile devices. When a mobile device
is tilted 90 degrees, its sensors trigger a change in orientation of content.
This is expected behavior at the platform level, so the test ensures the application does not prevent the content from displaying in both landscape and portrait.`}</p>
    <p>{`In the same way the platform offers a lock to prevent the user from
accidentally triggering orientation changes, any application supplied actions that
are triggered by motion must include a mechanism to disable the motion activation.
Check the application provides another means than motion detection for the activation.`}</p>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req1_3_4" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req2_5_4" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      