import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Custom components may not expose proper role, state, and property information needed for assistive technologies. As a result,
people with disabilities have difficulty identifying and interacting with them. Common examples are menus, trees, data grids,
accordions, and dialog boxes.`}</p>
    <p>{`All custom interactive user interface components need accessibility markup so that assistive technologies can programmatically obtain
the role, name, value, and states of the element. ARIA is used to provide the markup which is well supported in modern browsers.
Choose the role that best relates to the interaction in the design. The name of the element comes from the content design.
Use the ARIA authoring practices for implementation guidance on custom components.`}</p>
    <pre><code parentName="pre" {...{}}>{`<!-- The following code fragment creates a toolbar widget by assigning a  -->
<!-- toolbar role to a <div> incorporating three HTML buttons. Note, this -->
<!-- example does not show the keyboard event handling required to make   -->
<!-- the toolbar fully keyboard accessible. -->
    <div class="format" role="toolbar" aria-label="Text formatting" aria-controls="textarea1">
    <button type="button" class="item bold popup" aria-pressed="false" value="bold" tabindex="0">
        <span class="fas fa-bold" aria-hidden="true"></span>
        <span class="popup-label">Bold</span>
    </button>
    <button type="button" class="item italic popup" aria-pressed="false" value="italic" tabindex="-1">
        <span class="fas fa-italic" aria-hidden="true"></span>
        <span class="popup-label">Italic</span>
    </button>
    <button type="button" class="item underline popup" aria-pressed="false" value="underline" tabindex="-1">
        <span class="fas fa-underline" aria-hidden="true"></span>
        <span class="popup-label">Underline</span>
    </button>
</div>
`}</code></pre>
    <p>{`Pro tip: Using ARIA also means you must implement keyboard access. Make sure the element is `}<ToolkitTopic qid='dev_keyboard_ensure_interactive' name="reachable by keyboard" mdxType="ToolkitTopic"></ToolkitTopic>{` and `}<ToolkitTopic qid='dev_keyboard_implement_keyboard' name="all functionality is operable by the keyboard" mdxType="ToolkitTopic"></ToolkitTopic>{`. Any time a keyboard event handler is added to a `}<em parentName="p">{`non-form`}</em>{` or `}<em parentName="p">{`non-anchor`}</em>{` element (e.g. `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` or `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{`), it must have a valid ARIA role.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA4.html" target="_blank" rel="noopener noreferrer">ARIA4: Using a WAI-ARIA role to expose the role of a user interface component</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA5.html" target="_blank" rel="noopener noreferrer">ARIA5: Using WAI-ARIA state and property attributes to expose the state of a user interface component</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA16.html" target="_blank" rel="noopener noreferrer">ARIA16: Using aria-labelledby to provide a name for user interface controls</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria4' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria5' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria16' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#4_1_2")} target="_blank" rel="noopener noreferrer">4.1.2 Name, Role, Value</a>, IBM accessibility requirements
      * <a href="http://w3c.github.io/aria-practices/#aria_ex" target="_blank" rel="noopener noreferrer">Design patterns and widgets</a>, ARIA authoring practices
      * <a href="http://w3c.github.io/aria-practices/#naming_with_aria-labelledby" target="_blank" rel="noopener noreferrer">Naming with referenced content via aria-labelledby</a>, ARIA authoring practices
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req4_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_6' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      