import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`The `}<inlineCode parentName="p">{`prefers-reduced-motion`}</inlineCode>{` media query is part of the level 5 CSS specification. It allows developers to query the user’s system preference for reduced motion, via the browser, and reduce or eliminate animations accordingly. In some cases, designers will simply ensure their design is operable if motion effects are disabled, and developers will hide animation based on the media query. However, designers may provide variations in design based on whether users prefer reduced (or no) motion, and these may lead to what is effectively a responsive design implementation. These decisions should be annotated in the wireframes, so that developers can properly implement.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/css/C39" target="_blank" rel="noopener noreferrer">C39: Using the CSS reduce-motion query to prevent motion</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='c39' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.smashingmagazine.com/2020/09/design-reduced-motion-sensitivities/" target="_blank" rel="noopener noreferrer">Designing with Reduced Motion for Motion Sensitivities</a>, Val Head on Smashing magazine
      * <a href="https://www.w3.org/WAI/WCAG22/Understanding/animation-from-interactions.html" target="_blank" rel="noopener noreferrer">Understanding 2.3.3 Motion from Interactions</a>, WCAG 2.1 (level AAA)
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='smashing_magazine_4' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='wcag_level_aaa_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      