import React from 'react';
import { UpToTop20 } from '@carbon/icons-react';
import { button } from './BackToTopBtn.module.scss';

const BackToTopBtn = () => (
  <div id="nav_back" role="complementary" aria-label="Back to Top">
  <button
    onClick={() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      if(document.getElementById("page-title")){
        document.getElementById("page-title").focus();
        console.log(document.getElementById("page-title"));
      }
    } }
    className={button}
    type="button"
    aria-labelledby="nav_back"
  >
    <UpToTop20 />
  </button>
  </div>
);

export default BackToTopBtn;
