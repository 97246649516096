import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For content that causes legal commitments or financial transactions for the user to occur,
that modifies or deletes user-controllable data in data storage systems,
or that submits user test responses, the user can reverse, correct, or confirm the action.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures users can avoid irreversible consequences that may occur when performing an action.
This includes submitting financial transactions that cannot be reversed,
deleting data that cannot be recovered, and purchasing items.`}</p>
    <p>{`A disability can contribute to the possibility of making a mistake when performing a device-based action.
For example, people with reading disabilities may transpose numbers and letters,
and people who have motor disabilities may hit keys by mistake.
Providing the ability to review and correct information or to reverse actions allows users to avoid mistakes that could have serious consequences.`}</p>
    <p>{`To comply with this requirement, one of the following must be true:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Reversible:`}</strong>{` Submissions are reversible.`}</li>
      <li parentName="ul"><strong parentName="li">{`Checked:`}</strong>{` Data entered by the user is checked for input errors and the user is provided an opportunity to correct them.`}</li>
      <li parentName="ul"><strong parentName="li">{`Confirmed:`}</strong>{` A mechanism is available for reviewing, confirming, and correcting information before finalizing the submission.`}</li>
    </ul>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/error-prevention-legal-financial-data" target="_blank" rel="noopener noreferrer">{`Understanding 3.3.4 Error Prevention (Legal, Financial, Data)`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      