import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A list of all actions that can be executed on an object shall be programmatically determinable.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that the list of possible actions offered by each UI object (component or widget) is made available programmatically.
Assistive technology (AT) can then give access to all the supported actions without the user resorting to accessing them through a mechanism like a context menu,
which would require extra steps.
For example, a node in a flow diagram could be opened when the user presses Enter or Spacebar,
deleted via the Delete key,
or copied via Ctrl+C.
These 3 possible actions would be available to ATs.
This is especially beneficial for AT’s that offer assistance to users with mobility impairment,
such as voice control software and on-screen keyboards.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.10" target="_blank">{`502.3.10 List of Actions`}</a>{` (external link to US 508) for more information.`}</p>
    <p><strong parentName="p">{`Notes:`}</strong></p>
    <ul>
      <li parentName="ul">{`The first action in a list should be identified as the default action.
See `}<a href="https://accessibility.linuxfoundation.org/a11yspecs/ia2/docs/html/interface_i_accessible_action.html" target="_blank">{`IAccessibleAction Interface`}</a>{` specifications.`}</li>
      <li parentName="ul"><strong parentName="li">{`Apple iOS:`}</strong>{` Using standard controls from the `}<a href="https://developer.apple.com/reference/uikit" target="_blank">{`UIKit framework`}</a>{` exposes actions automatically.
Additional actions can be described using `}<a href="https://developer.apple.com/library/content/documentation/UserExperience/Conceptual/iPhoneAccessibility/Making_Application_Accessible/Making_Application_Accessible.html#//apple_ref/doc/uid/TP40008785-CH102-SW4" target="_blank">{`Hints`}</a>{`
and `}<a href="https://developer.apple.com/library/archive/documentation/UserExperience/Conceptual/iPhoneAccessibility/Making_Application_Accessible/Making_Application_Accessible.html#//apple_ref/doc/uid/TP40008785-CH102-SW7" target="_blank">{`Traits`}</a>{`.
When gestures are assigned to a component,
use the `}<a href="https://developer.apple.com/documentation/uikit/accessibility/uiaccessibilityaction" target="_blank">{`accessibilityCustomActions`}</a>{` method to add the gesture-based actions.`}</li>
      <li parentName="ul"><strong parentName="li">{`Windows:`}</strong>{` Implement `}<a href="https://accessibility.linuxfoundation.org/a11yspecs/ia2/docs/html/interface_i_accessible_action.html" target="_blank">{`IAccessibleAction Interface`}</a>{`,`}<a href="https://msdn.microsoft.com/en-us/library/windows/desktop/dd318477(v=vs.85).aspx" target="_blank">get_accDefaultAction</a>,
        <a href="https://msdn.microsoft.com/en-us/library/windows/desktop/dd318470(v=vs.85).aspx" target="_blank">accDoDefaultAction</a>,
and <a href="https://docs.microsoft.com/en-us/windows/win32/api/oleacc/nf-oleacc-iaccessible-get_acckeyboardshortcut" target="_blank">get_accKeyboardShortcut</a>.</li>
      <li parentName="ul">{`Also see resources listed in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      