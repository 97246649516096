import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where ICT is a non-web document that contains synchronized media with captions,
the captions should not obscure relevant information in the synchronized media.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{`
The numbering of this requirement was updated to match the latest version of the EN 301 549 standard.
It is otherwise identical to the prior version 7.0 requirement `}<a href="https://www.ibm.com/able/guidelines/ci162/caption_position.html" target="_blank">{`10.2.39 Caption Positioning`}</a>{`.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The European `}<em parentName="p">{`EN 301 549`}</em>{` standard specifies requirements for non-web documentation.
This requirement ensures that the position of captions do not cover any meaningful information in the video content.
This requirement is Not Applicable (N/A) to documentation that does not play audio and video.
This requirement only applies to non-web documents, including downloadable documents,
and associated media player.`}</p>
    <p>{`Several “`}<em parentName="p">{`Time-based Media`}</em>{`” success criteria in WCAG, such as `}<a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">{`1.2.2 Captions (Prerecorded)`}</a>{`,
require that captions are provided.
Those requirements stipulate that audio content has been transcribed and is captured in a format that can be used to display captions.
Those WCAG requirements covering the `}<em parentName="p">{`content`}</em>{` of the captions are complementary to
chapter 10 `}<em parentName="p">{`Non-Web documents`}</em>{` of EN 301 549,
but which only covers non-web documentation.`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=62" target="_blank" rel="noopener noreferrer">{`10.5 Caption Positioning`}</a>{` (external link to EN 301 549) for more information.`}</p>
    <h4><h5 style={{
        fontSize: "16px",
        fontWeight: "600"
      }}>{`Supplemental techniques`}</h5></h4>
    <p><strong parentName="p">{`Place captions outside content area:`}</strong>{`
Where the media is shot in a different aspect ratio from the viewing area,
or displayed in a different viewing area,
it may be possible to place the captions in blank or black space outside the video area.
This ensures the captions never obscure any important information in the video.`}</p>
    <p><strong parentName="p">{`Reposition captions to avoid covering up important visual information:`}</strong>{`
The following guidance is culled from the `}<a href="https://www.dcmp.org/caai/nadh218.pdf" target="_blank">{`CBC Captioning Style Guide`}</a>{`:
Captions generally appear centered at the bottom of the screen.
Re-position the caption during playback in order to avoid covering up important visual information such as on screen text, graphics, a person’s mouth, eyes, etc.
Captions should be one or two lines in length.
Three line scrolling captions are acceptable when time or space is not limited.`}</p>
    <p><strong parentName="p">{`Related requirements:`}</strong>{`
Applications that play audio and video are required to meet the following related requirements:`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Audio/Video Content requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.2 Captions (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_4")} target="_blank" rel="noopener noreferrer">1.2.4 Captions (Live)</a></li>
      <li parentName="ul">{`10.5 Captioning Positioning (Non-web Documents) - `}<em parentName="li">{`this requirement`}</em></li>
    </ul>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Player requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_1")} target="_blank" rel="noopener noreferrer">7.1.1 Captioning Playback</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_2")} target="_blank" rel="noopener noreferrer">7.1.2 Captioning Synchronization</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_3")} target="_blank" rel="noopener noreferrer">7.1.3 Preservation of Captioning</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_4")} target="_blank" rel="noopener noreferrer">7.1.4 Captions Characteristics</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_5")} target="_blank" rel="noopener noreferrer">7.1.5 Spoken Subtitles</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">7.3 User Controls for Captions & Audio Description</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#503_4_1")} target="_blank" rel="noopener noreferrer">503.4.1 Caption Controls</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      