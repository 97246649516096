import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import HomePanel from "../../components/home/homePanel";
import { withPrefix } from "gatsby";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import CardLeft from "../../visuals/homepage/card-back-1.png";
import CardMiddle from "../../visuals/homepage/card-back-2.png";
import CardRight from "../../visuals/homepage/card-back-3.png";
import "./home-cards.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  _frontmatter
}) => <React.Fragment>{children}</React.Fragment>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Row className="resource-card-group3" mdxType="Row">
      <Column colSm={4} colMd={4} colLg={4} noGutterSm mdxType="Column">
        <ResourceCard title="Equal Access Toolkit" href="/toolkit" aspectRatio="1:1" actionIcon="arrowRight" titleHeadingLevel={3} mdxType="ResourceCard" />
      </Column>
      <Column colSm={4} colMd={4} colLg={4} noGutterSm mdxType="Column">
        <ResourceCard title="Requirements" href="/requirements/requirements" aspectRatio="1:1" actionIcon="arrowRight" titleHeadingLevel={3} mdxType="ResourceCard" />
      </Column>
      <Column colLg={4} colMd={4} colSm={4} noGutterSm mdxType="Column">
        <ResourceCard title="Product Accessibility (ACR/VPAT)" href="/product_accessibility" aspectRatio="1:1" actionIcon="arrowRight" titleHeadingLevel={3} mdxType="ResourceCard" />
      </Column>
    </Row>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      