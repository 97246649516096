import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Authoring tools capable of exporting PDF files that conform to ISO 32000-1:2008 (PDF 1.7)
shall also be capable of exporting PDF files that conform to ANSI/AIIM/ISO 14289-1:2016 (PDF/UA-1).`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`The US Revised 508 Standards states the following:`}</p>
    <p>{`”`}<em parentName="p">{`Any authoring tools that export PDF files are required to allow a user to comply with the International Standard for Accessible PDF Technology (PDF/UA-1). PDF/UA-1 provides a technical, interoperable standard for the authoring, remediation, and validation of PDF content to ensure accessibility for people with disabilities who use AT such as screen readers, screen magnifiers, and joysticks to navigate and read electronic content`}</em>{`.”`}</p>
    <p>{`Where authoring tools provide the ability to create accessible PDFs,
products need to confirm that the accessibility standard is also supported by the authoring tool.`}</p>
    <p><strong parentName="p">{`Notes:`}</strong></p>
    <ul>
      <li parentName="ul">{`Due to copyright, IBM is unable to provide a copy of the `}<a href="https://www.iso.org/standard/64599.html" target="_blank">{`ISO standard`}</a>{` (external link to ISO).
It is available as a paid download.
The `}<a href="https://www.pdfa.org/new/aiim-announces-publication-of-ansiaiimiso-14289-12016/?cm_mc_uid=17338328449114871674733&cm_mc_sid_50200000=1493064985" target="_blank">{`ANSI/AIIM/ISO 14289-1:2016`}</a>{` (external link to ETSI) specification in the US US Revised 508 Standards contains the same exact requirements text as the ISO standard.
Either download version can be used for conforming to PDF/UA-1.`}</li>
      <li parentName="ul">{`If the version of PDF file output is not at version 1.7 or later,
this requirement is Not Applicable.`}</li>
    </ul>
    <p><strong parentName="p">{`Resources:`}</strong>{`
See requirement `}<a href={withPrefix("/requirements/requirements/#602_3")} target="_blank">{`602.3 Electronic support documentation`}</a>{` for resources on accessible PDF.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#504.2.2" target="_blank">{`504.2.2 PDF Export`}</a>{` (external link to US 508) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      