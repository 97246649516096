import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Notification of events relevant to user interactions,
including but not limited to changes in the component’s state(s), value, name, description, or boundary,
shall be available to assistive technology.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`In addition to providing semantic information about user interface objects (widgets, components, content) to Assistive Technology (AT),
software must alert the AT `}<em parentName="p">{`when`}</em>{` events occur that cause changes to the semantic information.
These events include the following:`}</p>
    <ul>
      <li parentName="ul">{`focus changes`}</li>
      <li parentName="ul">{`caret moves`}</li>
      <li parentName="ul">{`selection changes`}</li>
      <li parentName="ul">{`components (widgets or UI objects) are added or removed`}</li>
      <li parentName="ul">{`state(s), value, name, description or boundary has changed`}</li>
    </ul>
    <p>{`For example, an event is when a mailbox is refreshed or updated with new unread items.`}</p>
    <p>{`It is essential that such change are programmatically available to the AT, such as via a listener,
so that AT can describe the changes to the AT users,
enabling them to understand and interact with the controls and content.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.14" target="_blank">{`502.3.14 Event Notification`}</a>{` (external link to US 508) for more information.`}</p>
    <p><strong parentName="p">{`Resources:`}</strong>{` See resources listed in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      