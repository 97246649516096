import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion } from "carbon-components-react";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import "../../../../src/pages/toolkit/topics.scss";
import DetailHeadings from "../../../../src/pages/toolkit/design/content/level1/headings.mdx";
import DetailAlternativeText from "../../../../src/pages/toolkit/design/content/level1/alternative-text-for-visuals.mdx";
import DetailInputLabels from "../../../../src/pages/toolkit/design/content/level1/input-labels.mdx";
import DetailErrorMessages from "../../../../src/pages/toolkit/design/content/level1/error-messages.mdx";
import DetailFlashingContent from "../../../../src/pages/toolkit/design/content/level1/flashing-content.mdx";
import DetailVideoCaptions from "../../../../src/pages/toolkit/design/content/level1/video-captions.mdx";
import DetailPageTitles from "../../../../src/pages/toolkit/design/content/level2/page-titles.mdx";
import DetailImagesOfText from "../../../../src/pages/toolkit/design/content/level2/images-of-text.mdx";
import DetailAutoplayingContent from "../../../../src/pages/toolkit/design/content/level2/autoplaying-content.mdx";
import DetailTextContentForVideos from "../../../../src/pages/toolkit/design/content/level2/text-content-for-videos.mdx";
import DetailLinkText from "../../../../src/pages/toolkit/design/content/level2/link-text.mdx";
import DetailDataFormatForInputs from "../../../../src/pages/toolkit/design/content/level2/data-format-for-inputs.mdx";
import DetailPlaceholderText from "../../../../src/pages/toolkit/design/content/level2/placeholder-text.mdx";
import DetailVisualContentInVideos from "../../../../src/pages/toolkit/design/content/level3/visual-content-in-videos.mdx";
import DetailVideoTranscripts from "../../../../src/pages/toolkit/design/content/level3/video-transcripts.mdx";
import DetailForeignLanguage from "../../../../src/pages/toolkit/design/content/level3/foreign-language.mdx";
import DetailFigureAndTableCaptions from "../../../../src/pages/toolkit/design/content/level3/figure-and-table-captions.mdx";
import DetailConsistentPages from "../../../../src/pages/toolkit/design/content/level3/consistent-across-pages.mdx";
import Level1Icon from "../../../../src/visuals/plan/level_1_icon.svg";
import Level2Icon from "../../../../src/visuals/plan/level_2_icon.svg";
import Level3Icon from "../../../../src/visuals/plan/level_3_icon.svg";
import { withPrefix } from "gatsby";
import ExpandCollapseAccordion from "../../../../src/components/expand_collapse_accordion/expand_collapse_accordion.js";
import * as React from 'react';
export default {
  TkAccordionItem,
  Accordion,
  Row,
  Column,
  DetailHeadings,
  DetailAlternativeText,
  DetailInputLabels,
  DetailErrorMessages,
  DetailFlashingContent,
  DetailVideoCaptions,
  DetailPageTitles,
  DetailImagesOfText,
  DetailAutoplayingContent,
  DetailTextContentForVideos,
  DetailLinkText,
  DetailDataFormatForInputs,
  DetailPlaceholderText,
  DetailVisualContentInVideos,
  DetailVideoTranscripts,
  DetailForeignLanguage,
  DetailFigureAndTableCaptions,
  DetailConsistentPages,
  Level1Icon,
  Level2Icon,
  Level3Icon,
  withPrefix,
  ExpandCollapseAccordion,
  React
};