import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Authoring tools shall provide a mode of operation that prompts authors to create content that conforms to Level A and Level AA Success Criteria
and Conformance Requirements in WCAG 2.1 for supported features and,
as applicable, to file formats supported by the authoring tool.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`The `}<a href="https://www.w3.org/TR/ATAG20/" target="_blank">{`Authoring Tools Accessibility Guidelines`}</a>{` (ATAG) emphasize that by guiding authors
from the outset toward the creation and maintenance of accessible content,
accessibility problems are mitigated and less repair effort is required.
For example, prompts to create accessible content can be as simple as:`}</p>
    <ul>
      <li parentName="ul">{`including with the ability to resize text the ability to format text as a heading level`}</li>
      <li parentName="ul">{`including a place to add `}<inlineCode parentName="li">{`alt`}</inlineCode>{` text when a user inserts an image`}</li>
    </ul>
    <p>{`Guiding users to create accessible content can also include more sophisticated approaches,
such as integrating an accessibility checker into the tool or functionality.`}</p>
    <p>{`See requirement `}<a href={withPrefix("/requirements/requirements/#504_2")} target="_blank">{`504.2 Content Creation or Editing`}</a>{` to see the `}<em parentName="p">{`US Revised 508 Standard’s`}</em>{` definition of an authoring tool.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#504.3" target="_blank">{`504.3 Prompts`}</a>{` (external link to US 508) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      