import HomePanel from "../../../../src/components/home/homePanel";
import { withPrefix } from "gatsby";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import CardLeft from "../../../../src/visuals/homepage/card-back-1.png";
import CardMiddle from "../../../../src/visuals/homepage/card-back-2.png";
import CardRight from "../../../../src/visuals/homepage/card-back-3.png";
import "../../../../src/content/home/home-cards.scss";
import * as React from 'react';
export default {
  HomePanel,
  withPrefix,
  Row,
  Column,
  CardLeft,
  CardMiddle,
  CardRight,
  React
};