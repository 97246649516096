import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Captions are provided for all prerecorded audio content in synchronized media.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures that captions are provided as text equivalent for audio content.
They are synchronized to appear on screen with the relevant audio information,
such as dialogue, music, and sound effects.`}</p>
    <p>{`Captions are of the following two types:`}</p>
    <ul>
      <li parentName="ul">{`Open captions cannot be turned off by the user; they are typically burned into the image so that the user cannot adjust their appearance.`}</li>
      <li parentName="ul">{`Closed captions can be turned on or off by the user. They are provided in a separate data stream that is synchronized with the multimedia. The user has the potential to alter their size, typeface and color.`}</li>
    </ul>
    <p>{`The source material for captions can also serve as a transcript or be transformed by assistive technology into other formats.`}</p>
    <p><strong parentName="p">{`Exception:`}</strong>{` Captions are not needed when the media itself is an alternate presentation of text information. For example, if information on a page is accompanied by a synchronized media presentation that presents no more information than is already presented in text, but is easier for people with cognitive, language, or learning disabilities to understand, then the media would not need to be captioned. Such media should be labelled as a media alternative.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/captions-prerecorded.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.2.2 Captions (Prerecorded)`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      