import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/ux_1_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Keyboard users, especially users who are blind, navigate interactive elements on a page by pressing the Tab key. The tab order is the sequence that the interactive elements follow. The standard behavior follows the reading order: top to bottom, left to right.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Annotate the tab order if it doesn’t follow the standard behavior`}</li>
      <li parentName="ul">{`Reduce tabbing by grouping components`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="arrow keys are notated between individual components, with the group of components notated as 'single tab stop'
"></img>
        <Caption fullWidth mdxType="Caption">
Consider grouping components as a single tab stop. Annotate that arrows are used to navigate between items.
        </Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://w3c.github.io/aria-practices/#toolbar" target="_blank" rel="noopener noreferrer">Toolbar</a>, ARIA authoring practices
      * <a href="https://w3c.github.io/aria-practices/#grid" target="_blank" rel="noopener noreferrer">Grids: Interactive tabular data and layout containers</a>, ARIA authoring practices
      * <a href="https://www.bbc.co.uk/gel/guidelines/how-to-design-for-accessibility#annotating-wireframes-and-ux-designs" target="_blank" rel="noopener noreferrer">Annotating wireframes and UX designs</a>, BBC
      * <a href="https://ux.stackexchange.com/questions/107601/how-do-you-present-tabbing-order" target="_blank" rel="noopener noreferrer">How do you present tabbing order</a>, StackExchange
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_3' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_5' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='bbc_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='stackexchange_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_1_1")} target="_blank" rel="noopener noreferrer">2.1.1 Keyboard</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_4_3")} target="_blank" rel="noopener noreferrer">2.4.3 Focus Order</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Keyboard interaction](/toolkit/design/ux/#keyboard-interaction), this page
      * [Data tables](/toolkit/design/visual/navigation#tab-grouping), Visual design
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='visual_data_tables' mdxType="ToolkitTopic"></ToolkitTopic>, Visual design</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      