import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import { IconList, IconListRow, IconListIcon, IconListContent } from '../../../components/IconList';
import Checkmark32 from "../../../images/Checkmark32.svg";
import ToolkitRequirement from "../../../components/rms_resources/requirements";
import ToolkitReference from "../../../components/rms_resources/references";
import ToolkitTechnique from "../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Developers can quickly validate whether they can adjust the content size while remaining accessible. Use the browser to zoom the content to 200% and ensure that text still fits in its containers and nothing odd happens to positioning of controls and other elements. Then keep increasing up to 400%. Ensure that the breakpoints match the design specifications. All content should be visible without requiring horizontal scrolling.`}</p>
    <p>{`Addresses `}<ToolkitRequirement qid='req1_4_4' source="hide" mdxType="ToolkitRequirement"></ToolkitRequirement>{` and `}<ToolkitRequirement qid='req1_4_10' source="hide" mdxType="ToolkitRequirement"></ToolkitRequirement>{`.`}</p>
    <IconList mdxType="IconList">
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
Text can zoom to 200% without becoming truncated or hidden
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
Content can zoom to 400% without horizontal scroll bars appearing
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
Break points match responsive design specifications
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
Content reflows according to design specifications
        </IconListContent>
    </IconListRow>
    </IconList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      