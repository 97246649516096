import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Question the purpose of text styled differently than body text. Larger point-sized text or standalone sentence fragments in bold are probably serving as headings. If such text is reduced to body text and the usability of the page is reduced, it should be annotated as a heading.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Consider headings in place of bold sentence fragments and larger-sized text`}</li>
      <li parentName="ul">{`Avoid underlying anything except interactive text to reduce confusion`}</li>
    </ul>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/tutorials/page-structure/headings/" target="_blank" rel="noopener noreferrer">Headings</a>, W3C web accessibility tutorial
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='montana_state_university_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='penn_state_accessibility_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_10' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='webaim_5' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='penn_state_accessibility_2' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_3_1")} target="_blank" rel="noopener noreferrer">1.3.1 Info and Relationships</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      