import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Platforms and platform software shall conform to the requirements in ANSI/HFES 200.2, Human Factors Engineering of Software User Interfaces — Part 2: Accessibility (copyright 2008) listed below:`}</p>
    <ul>
      <li parentName="ul">{`Section 9.3.3 Enable sequential entry of multiple (chorded) keystrokes`}</li>
      <li parentName="ul">{`Section 9.3.4 Provide adjustment of delay before key acceptance`}</li>
      <li parentName="ul">{`Section 9.3.5 Provide adjustment of same-key double-strike acceptance`}</li>
      <li parentName="ul">{`Section 10.6.7 Allow users to choose visual alternative for audio output`}</li>
      <li parentName="ul">{`Section 10.6.8 Synchronize audio equivalents for visual events`}</li>
      <li parentName="ul">{`Section 10.6.9 Provide speech output services, and`}</li>
      <li parentName="ul">{`Section 10.7.1 Display any captions provided`}</li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{`
This requirement applies to `}<em parentName="p">{`platform software`}</em>{` and is Not Applicable (N/A) for most application software.
Examples of platforms are: desktop operating systems, mobile and embedded operating systems,
Web browsers, and software that supports macros or scripting.
See `}<a href="https://www.access-board.gov/ict/#E103-definitions" target="_blank">{`definitions in the Revised 508 Standards`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`Platforms are essential in providing the user basic access to the system under use.
This requirement outlines the requirements for platform software to provide basic accessibility services as outlined
in the `}<a href="https://www.hfes.org/Publications/Technical-Standards" target="_blank">{`ANSI/HFES`}</a>{` `}<em parentName="p">{`200.2 Human Factors Engineering of Software User Interfaces — Part 2: Accessibility`}</em>{` (copyright 2008).`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.4" target="_blank">{`502.4 Platform Accessibility Features`}</a>{` (external link to US 508) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      