import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from 'gatsby';
import ToolkitRequirement from '../../../../components/rms_resources/requirements';
import ToolkitReference from '../../../../components/rms_resources/references';
import ToolkitTechnique from '../../../../components/rms_resources/techniques';
import ToolkitTopic from '../../../../components/rms_resources/topics';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Any indication of pointer location does not negate state indicators, such as focus`}</li>
      <li parentName="ul">{`Rollover and hover effects do not cause contrast failures`}</li>
      <li parentName="ul">{`Content exposed via hover behaves predictably`}</li>
      <li parentName="ul">{`Content exposed via hover is also keyboard operable`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`When a pointer is moved around on a screen, changes to content may occur without the user otherwise intentionally interacting with the page. Such effects include ‘rollovers’ on buttons and content exposed by hover.`}</p>
    <p>{`It is essential that hover effects do not reduce the accessibility of the page, which can happen if the user cannot distinguish between which item has focus and which item happens to be under the pointer’s current position. Similarly, visual rollover effects should not cause content to fail minimum contrast, for either text or non-text.`}</p>
    <p>{`Where hover causes new meaningful content to appear, check that the content behaves predictably. It should remain on screen until dismissed by the user. The user should be able to bring the pointer over the top of the new content without it disappearing (allowing users with low vision to see it).`}</p>
    <p>{`Any new content that appears or is operable via hover should also trigger via the keyboard. Common failures of keyboard equivalence include exposing label or help information only via tooltips, or not providing a keyboard method (normally pressing the Escape key) to dismiss the new content.`}</p>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req1_4_3" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req1_4_11" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req1_4_13" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req2_4_7" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      