import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The visual presentation of text and images of text has a contrast ratio of at least 4.5:1, with a 3:1 ratio for large-scale text.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures enough contrast is provided between text and its background to enable users with moderately low vision to read the text without contrast-enhancing technology.
(Users requiring more contrast will often use an assistive technology or platform feature such as Windows High Contrast mode.)`}</p>
    <p>{`WCAG 2.0 makes a distinction between “large” text and small or body text.
Large text, which is defined as text that is at least 18 point or at least 14 point when bold,
has a contrast requirement of 3 to 1.
Body text (less than 18 point and less than 14 point when bold) has a contrast requirement of 4.5 to 1.`}</p>
    <p>{`This requirement also applies to images of text that are intended to be understood as text.
Refer to the requirement `}<a href={withPrefix("/requirements/requirements/#1_4_5")} target="_blank" rel="noopener noreferrer">{`1.4.5 Images of text`}</a>{`.
However, this `}<em parentName="p">{`contrast`}</em>{` requirement does not apply to decorative text which conveys no meaning,
or text that is part of a logo or brand name.`}</p>
    <p>{`This requirement specifically addresses text contrast,
WCAG 2.0 recommends sufficient color contrast for data presented in charts and graphs as well.
WCAG 2.1 introduced a new specific requirement for `}<a href={withPrefix("/requirements/requirements/#1_4_11")} target="_blank" rel="noopener noreferrer">{`1.4.11 Non-text contrast`}</a>{`.`}</p>
    <p><strong parentName="p">{`Incidental Exception:`}</strong>{`
Text or images of text that are part of an inactive user interface component,
that are pure decoration, that are not visible to anyone,
or that are part of a picture that contains significant other visual content,
have no contrast requirement.`}</p>
    <p><strong parentName="p">{`Logotypes Exception:`}</strong>{`
Text that is part of a logo or brand name has no minimum contrast requirement.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/contrast-minimum.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.4.3 Contrast (Minimum)`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      