import React, { useContext } from 'react';
// import { Link } from 'gatsby';
import {
    Header as ShellHeader,
    HeaderMenuButton,
    SkipToContent,
    HeaderGlobalBar,
    // HeaderGlobalAction,
    HeaderNavigation,
    // HeaderMenu,
    HeaderMenuItem,
    HeaderName,
    // HeaderMenu,
    HeaderGlobalAction,
} from 'carbon-components-react/lib/components/UIShell';
import { AbleSwitcher } from "../../components/AbleSwitcher";
// import { AppSwitcher20, Close20 } from '@carbon/icons-react';
import cx from 'classnames';

import GlobalSearch from 'gatsby-theme-carbon/src/components/GlobalSearch';
import NavContext from 'gatsby-theme-carbon/src/util/context/NavContext';
import useMetadata from 'gatsby-theme-carbon/src/util/hooks/useMetadata';
import LeftNav from 'gatsby-theme-carbon/src/components/LeftNav';
import { withPrefix, navigate } from "gatsby"
import { keys, matches } from 'carbon-components-react/lib/internal/keyboard';
import Launch16 from '@carbon/icons-react/lib/launch/16';
import { AppSwitcher20, Close20 } from '@carbon/icons-react';
import usePathprefix from 'gatsby-theme-carbon/src/util/hooks/usePathprefix';
import { useWindowSize } from 'gatsby-theme-carbon/src/util/hooks';
import "./Header.scss";

import {
    header,
    switcherButtonOpen,
    skipToContent,
    headerName,
    collapsed,
    headerButton,
    switcherButton,
} from 'gatsby-theme-carbon/src/components/Header/Header.module.scss';

const onKeyDown = (url, event) => {
    // Handle enter or space key for toggling the expanded state of the menu.
    if (matches(event, [keys.Enter, keys.Space])) {
        event.stopPropagation();
        event.preventDefault();

        onClick(url);

        return;
    }
};

const onClick = function (url) {
    if (url.to) {
        navigate(url.to);
    } else {
        if (typeof document !== "undefined") {
            document.location.href = url.href;
        }
    }
}

const CustomHeader = ({ children,
    homepage,
    theme,
    is404Page,
    nav,
    topNav,
    location,
    ...rest }) => {
    let {
        leftNavIsOpen,
        toggleNavState,
        switcherIsOpen,
        searchIsOpen
    } = useContext(NavContext);
    const windowSize = useWindowSize();
    if (windowSize.innerWidth > 1056 && !leftNavIsOpen) {
        if (nav && nav !== "none") {
            setTimeout(() => {
                toggleNavState('leftNavIsOpen', 'open');
            }, 0);
        }
    }

    if (windowSize.innerWidth > 1056 ) {
        if (leftNavIsOpen && (!nav || nav === "none")) {
            setTimeout(() => {
                toggleNavState('leftNavIsOpen', 'close');
            }, 0);
        }
    }

    const { isSearchEnabled } = useMetadata();
    const pathPrefix = usePathprefix();
    const pathname = !location ? ""
        : (pathPrefix ? location.pathname.replace(pathPrefix, '')
            : location.pathname);

    // Show header only on landing page
    var headerNav = <React.Fragment></React.Fragment>;
    
    // if( pathname.includes("/toolkit") == false &&
    //     pathname.includes("/requirements") == false &&
    //     pathname.includes("/product_accessibility") == false &&
    //     pathname.includes("/events") == false &&
    //     pathname.includes("/contact") == false ){
    //         headerNav = <React.Fragment>
    //         {topNav && <React.Fragment>
    //             <HeaderMenuItem onClick={(evt) => { toggleNavState('leftNavIsOpen', 'close'); }} className={pathname.includes("/toolkit") ? "active" : ""} href={withPrefix("/toolkit")}>Equal&nbsp;Access&nbsp;Toolkit</HeaderMenuItem>
    //             <HeaderMenuItem onClick={(evt) => { toggleNavState('leftNavIsOpen', 'close'); }} className={pathname.includes("/requirements") ? "active" : ""} href={withPrefix("/requirements/requirements")}>Requirements</HeaderMenuItem>
    //             <HeaderMenuItem onClick={(evt) => { toggleNavState('leftNavIsOpen', 'close'); }} className={pathname.includes("/product_accessibility") ? "active" : ""} href={withPrefix("/product_accessibility")}>Conformance&nbsp;Reports</HeaderMenuItem>
    //             <HeaderMenuItem onClick={(evt) => { toggleNavState('leftNavIsOpen', 'close'); }} href="https://www.ibm.com/blogs/age-and-ability/" target="_blank">Blog&nbsp;<Launch16 className="headerPopout" width={16} height={16} style={{ verticalAlign: "middle" }} alt="pop out" /></HeaderMenuItem>
    //         </React.Fragment>}
    //     </React.Fragment>;
    // }

    let closeRef = React.createRef();
    return (
        <React.Fragment>
            <ShellHeader className={header}>
                <SkipToContent className={skipToContent} />
                <HeaderMenuButton
                    className={cx('bx--header__action--menu', headerButton)}
                    aria-label="Site"
                    aria-haspopup="true"
                    onClick={() => {
                        toggleNavState('leftNavIsOpen');
                        toggleNavState('switcherIsOpen', 'close');
                    }}
                    onKeyDown={(evt) => {
                        if (evt.key === "Escape") {
                            toggleNavState('leftNavIsOpen', 'close');
                        }
                    }}
                    isActive={leftNavIsOpen}
                />
                <div style={{ maxWidth: "99rem", width: "100%" }}>
                    <div className="bx--grid">
                        <div className="bx--row">
                            <div className="bx--col-lg-3">

                                <HeaderName className={cx(headerName, {
                                    [collapsed]: searchIsOpen,
                                })} href={withPrefix("/")} prefix="IBM">
                                    Accessibility
                                </HeaderName>
                            </div>
                            <div>
                                <HeaderNavigation aria-label="IBM Accessibility">
                                    {headerNav}
                                </HeaderNavigation>
                            </div></div>
                    </div>
                </div>
                <div onKeyDown={(evt) => {
                    if (evt.key === "Escape") {
                        toggleNavState('leftNavIsOpen', 'close');
                    }
                }} >{/* A11y Fix https://github.ibm.com/IBMa/E2E/issues/598*/}
                    <LeftNav nav={nav} homepage={false} is404Page={is404Page} theme={theme} headerNav={headerNav} />
                </div>
                <HeaderGlobalBar>
                    {isSearchEnabled && <GlobalSearch />}
                    <HeaderGlobalAction
                        ref={closeRef}
                        className={cx(headerButton, switcherButton, {
                            [switcherButtonOpen]: switcherIsOpen,
                        })}
                        aria-label="Switch IBM product"
                        onClick={() => {
                            toggleNavState('switcherIsOpen');
                            toggleNavState('searchIsOpen', 'close');
                            toggleNavState('leftNavIsOpen', 'close');
                        }}
                        onKeyDown={(evt) => {
                            if (evt.key === "Escape") {
                                toggleNavState('switcherIsOpen', 'close');
                            }
                        }}
                    >
                        {switcherIsOpen ? <Close20 /> : <AppSwitcher20 />}
                    </HeaderGlobalAction>
                </HeaderGlobalBar>
                {switcherIsOpen && <AbleSwitcher onKeyDown={(evt) => {
                    if (evt.key === "Escape") {
                        toggleNavState('switcherIsOpen', 'close');
                        closeRef.current.focus();
                    }
                }} /> }
            </ShellHeader>
        </React.Fragment>
    );
};

const DefaultHeaderText = () => (
    <React.Fragment>
        IBM&nbsp;<span>Accessibility</span>
    </React.Fragment>
);

CustomHeader.defaultProps = {
    children: <DefaultHeaderText />,
};

export default CustomHeader;
