import React from 'react';
import ReviewContent from "./reviewContent";

const Review = () => {    
    return (
        <div>
            <ReviewContent></ReviewContent>
        </div>
    )
}

export default Review;