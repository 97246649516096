import React, { Component } from 'react'
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import "./license.scss";


class LicenseBody extends Component {
    render() {
        return (
            
            <div className="licensePanel">
                <div className="bx--grid">
                <Row>
                    <Column colLg="7" colMd="8" colSm="4">
                        <p className="licenseBody">
                            The copyrightable content and works of authorship directed to the Equal Access Toolkit are licensed under the <a target="_blank" rel="noopener noreferrer" href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution (CC BY) license</a> unless otherwise marked.
                            <br /><br />
                            No license is granted to IBM trademarks/logos or other third-party marks/logos. Such marks are subject to their own license terms, if any (<a target="_blank" rel="noopener noreferrer" href="https://www.ibm.com/legal/copytrade">IBM copyright and trademarks</a>).

                            <br /><br />
                            Apple® and macOS® are registered trademarks of Apple Inc.

                            <br /><br />
                            Adobe®, the Adobe logo, PostScript®, and the PostScript logo are either registered trademarks or trademarks of Adobe Systems Incorporated in the United States, and/or other countries. 

                            <br /><br />
                            Icons by <a target="_blank" rel="noopener noreferrer" href="https://carbondesignsystem.com/">Carbon Design System</a> licensed under <a target="_blank" rel="noopener noreferrer" href="https://github.com/carbon-design-system/carbon/blob/main/LICENSE">Apache 2.0</a>. 

                            <br /><br />
                            The Firefox logo is a trademark of the Mozilla Foundation in the U.S. and other countries. 

                            <br /><br />
                            IOS® is a trademark or registered trademark of Cisco in the U.S. and other countries and is used under license.

                            <br /><br />
                            Copyright © 2015 W3C® (MIT, ERCIM, Keio, Beihang). This software or document includes material copied from or derived from  W3C Techniques for WCAG 2.1 <a target="_blank" rel="noopener noreferrer" href="https://www.w3.org/WAI/WCAG21/Techniques">https://www.w3.org/WAI/WCAG21/Techniques</a>, W3C WAI Tutorials <a target="_blank" rel="noopener noreferrer" href="https://www.w3.org/WAI/tutorials/">https://www.w3.org/WAI/tutorials/</a>,Authoring Tool Accessibility Guidelines (ATAG) 2.0 <a target="_blank" rel="noopener noreferrer" href="https://www.w3.org/TR/ATAG20/">https://www.w3.org/TR/ATAG20/</a>, and WAI-ARIA Authoring Practices <a target="_blank" rel="noopener noreferrer" href="https://w3c.github.io/aria-practices/">https://w3c.github.io/aria-practices/</a>.    

                            <br /><br />
                            Microsoft®, Windows®, Windows NT®, and the Windows logo are trademarks of Microsoft Corporation in the United States, other countries, or both." 
                                  
                        </p>
                    </Column>              
                </Row>

                </div>
            </div>
        )

    }


}
export default LicenseBody