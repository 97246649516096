import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { pageBanner, withTabs } from './LandingPageHeader.module.scss';
import BannerImage from '../../../visuals/homepage/illustration.png';
import Bee from '../../../visuals/homepage/bee.svg';
import './LandingPageHeader.scss';

const LandingPageHeader = ({ title, description, tabs = [] }) => (
  <div className={cx(pageBanner, { [withTabs]: tabs.length })}>
      <div 
        className="bx--grid headerHoneyCombLandingPage" 
        style={{ backgroundImage: `url(${BannerImage})`, minHeight:'18rem'}}
      >
            <div className="bx--row">
              <h1 className='expressiveXLG04'>Accessibility is a team effort.</h1>
            </div> 
            <img src={Bee} alt="" className="bee" />
        </div>
  </div>
);

LandingPageHeader.propTypes = {
  /**
   * Specify the title for the page
   */
  title: PropTypes.node,
};

export default LandingPageHeader;

