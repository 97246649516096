import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Where indicated in the wireframes, use `}<inlineCode parentName="p">{`<abbr>`}</inlineCode>{` with the title attribute to provide the meaning of abbreviations in the content. Although this is not a requirement, it is helpful to persons with disabilities, especially in table headers or other constrained spaces where it is not possible to spell out words, or the abbreviations are unusual. It makes the text expansion available visually in a tooltip, and text-to-speech software can announce the expanded words.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H28" target="_blank" rel="noopener noreferrer">H28: Providing definitions for abbreviations by using the abbr element</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='h28' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/TR/WCAG22/#abbreviations" target="_blank" rel="noopener noreferrer">3.1.4 Abbreviations</a>, WCAG 2.1 (Level AAA)
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='wcag_level_aaa_2' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      