import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Any keyboard operable user interface has a mode of operation where the keyboard focus indicator is visible.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that the keyboard focus indicator can be visually located so that a person knows which element has the keyboard focus.
All focus indicators should be visible and distinguishable.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/focus-visible" target="_blank">{`2.4.7 Focus visible`}</a>{` (external link to WCAG) for more information on intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      