import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import HomePanel from "../../components/home/homePanel";
import { withPrefix } from "gatsby";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import locked from "../../images/locked-light.svg";
import slack from "../../images/slack-light-20.svg";
import "./home-cards.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  _frontmatter
}) => <React.Fragment>{children}</React.Fragment>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Row className="resource-card-group homePanelRow" mdxType="Row">
    <Column offsetLg={4} colMd={4} colLg={4} colSm={4} noGutterSm mdxType="Column">
    <ResourceCard title="Contact IBM Accessibility" href="/contact" aspectRatio="2:1" actionIcon="arrowRight" titleHeadingLevel={3} mdxType="ResourceCard">
    </ResourceCard>
   </Column>
   <Column colMd={4} colLg={4} colSm={4} noGutterSm mdxType="Column">
    <ResourceCard title="Contact IBM" href="https://www.ibm.com/planetwide/" target="_blank" rel="noopener noreferrer" aspectRatio="2:1" actionIcon="arrowRight" titleHeadingLevel={3} mdxType="ResourceCard">
    </ResourceCard>
   </Column>
   <Column colMd={4} colLg={4} colSm={4} offsetLg={4} offsetMd={0} noGutterSm mdxType="Column">
    <ResourceCard title="Slack IBM Accessibility" href="https://ibm-studios.slack.com/archives/C036P1CTN" target="_blank" rel="noopener noreferrer" aspectRatio="2:1" actionIcon="arrowRight" titleHeadingLevel={3} actionIcon={<img src={locked} alt="Open IBM resource" width={20} height={20} style={{}} />} mdxType="ResourceCard">
        <img src={slack} alt="Open IBM resource" width={32} height={32} style={{}} />
    </ResourceCard>
   </Column>
    </Row>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      