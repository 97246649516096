import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`People who are blind do not use a mouse to interact with media players, which means all of the player’s features must be keyboard accessible. If the content designer obtains a video for use with a specific embedded player, the keyboard accessibility should have already been verified and you won’t need to do anything other than embed the multimedia.`}</p>
    <p>{`If you create your own media player using the HTML media API, the design should provide the keyboard shortcuts. Implement full keyboard operability of the applicable features of the player:`}</p>
    <ul>
      <li parentName="ul">{`Play and pause`}</li>
      <li parentName="ul">{`Volume control`}</li>
      <li parentName="ul">{`Screen size and exiting from full-screen`}</li>
      <li parentName="ul">{`Closed caption settings`}</li>
      <li parentName="ul">{`Subtitle and audio description settings`}</li>
      <li parentName="ul">{`Advancing and rewinding`}</li>
      <li parentName="ul">{`Any other available player features and settings`}</li>
    </ul>
    <p>{`Be sure to unit test your implementation to ensure all functions are keyboard operable and the visual focus indicator is visible for all of the player’s controls.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR2.html" target="_blank" rel="noopener noreferrer">SCR2: Using redundant keyboard and mouse event handlers</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR20.html" target="_blank" rel="noopener noreferrer">SCR20: Using both keyboard and other device-specific functions</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR35.html" target="_blank" rel="noopener noreferrer">SCR35: Making actions keyboard accessible by using the onclick event of anchors and buttons</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='scr2' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr20' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr35' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_1_1")} target="_blank" rel="noopener noreferrer">2.1.1 Keyboard</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_1_2")} target="_blank" rel="noopener noreferrer">2.1.2 No Keyboard Trap</a>, IBM accessibility requirements
      * <a href="https://developers.google.com/web/updates/2016/07/autoplay" target="_blank" rel="noopener noreferrer">Muted autoplay on mobile: Say goodbye to canvas hacks and animated GIFs!</a>, Google developers
      * <a href="https://developer.mozilla.org/en-US/docs/Learn/Accessibility/Multimedia" target="_blank" rel="noopener noreferrer">Accessible multimedia</a>, Mozilla Developer Network
      * <a href="https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Video_and_audio_content/Test_your_skills:_Multimedia_and_embedding" target="_blank" rel="noopener noreferrer">Test your skills: Multimedia and embedding</a>, Mozilla Developer Network
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='google_3' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='mozilla_developer_network_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='mozilla_developer_network_4' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Embed multimedia content that supports closed captions](/toolkit/develop/text-and-non-text#media-captions), Develop – Non-text content
      * [Audio, video, and auto-updating content can be manipulated](/toolkit/design/ux/keyboard#audio-video-and-auto-updating-content-can-be-manipulated), UX design – Keyboard
      * [Test with a keyboard](/toolkit/verify/manual#test-with-a-keyboard), Verify - Manual
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='dev_non-text_embed_multimedia' mdxType="ToolkitTopic"></ToolkitTopic>, Develop – Non-text content</li>
      <li parentName="ul"><ToolkitTopic qid='ux_media_players' mdxType="ToolkitTopic"></ToolkitTopic>, UX design – Media players</li>
      <li parentName="ul"><ToolkitTopic qid='verify_manual_test_with' mdxType="ToolkitTopic"></ToolkitTopic>, Verify - Manual</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      