import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Table captions are properly associated with tables`}</li>
      <li parentName="ul">{`Table row and column headers are available to the screen reader`}</li>
      <li parentName="ul">{`Regions are appropriately structured and labeled`}</li>
      <li parentName="ul">{`Page, dialog, and frame titles are unique and concise`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`Testing with the screen reader is an effective way of confirming the structure of certain elements of the content such as tables (captions, summaries, and header cells) and regions (WAI-ARIA landmarks). Also confirm that titles of pages, dialogs, and frames (or panes) are programmatically provided to the screen reader. Screen readers provide a means to list and navigate these structures as a way to improve testing efficiency.`}</p>
    <h5 className="accordionH5">Completes testing for:</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req4_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      