import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Documentation in electronic format, including Web-based self-service support,
shall conform to Level A and Level AA Success Criteria and Conformance Requirements in WCAG 2.1.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The US Revised 508 Standards requires that electronic support documentation,
both web-based and non-web-based, must conform to WCAG 2.0 Level A and AA success criteria.
In Europe, `}<em parentName="p">{`EN 301 549`}</em>{` requires that the same documentation must also conform to WCAG,
but at version 2.1 of the standard which includes additional requirements.
This is a departure from the reduced accessibility requirements formerly applied to documentation
in these jurisdictions.`}</p>
    <p>{`Some examples of how documentation can be made accessible are:`}</p>
    <ul>
      <li parentName="ul">{`If the documentation is a video, captions and audio descriptions are provided.`}</li>
      <li parentName="ul">{`If the documentation contains illustrations, they include alternative text descriptions.`}</li>
      <li parentName="ul">{`The documentation is fully usable with only a keyboard.`}</li>
    </ul>
    <p>{`Although following guidance from documentation format vendors such as PDF and Word can assist with creating accessible content,
it is the responsibility of the product team,
website and application owners to ensure all relevant WCAG 2.1 requirements have been addressed.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#602.2" target="_blank">{`602.2 Accessibility and Compatibility Features`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Technology and Format-specific techniques`}</h4>
    <p>{`Ensuring that documentation meets all relevant WCAG 2.1 requirements will have similar considerations regardless of the technology platform or format being used.
Each section provides links to vendor or third-party supplied information that can help create accessible content:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Microsoft® Word:`}</strong>{`
Word has built-in features for people with different abilities and includes an accessibility checker that locates elements that might cause problems for people with disabilities.
Visit Microsoft’s information on `}<a href="https://support.office.com/en-us/article/Make-your-Word-documents-accessible-d9bf3683-87ac-47ea-b91a-78dcacb3c66d?ui=en-US&rs=en-US&ad=US" target="_blank">{`Word accessibility`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Microsoft PowerPoint®:`}</strong>{`
Because PowerPoint presentations usually consist of many images and decorative flourishes,
people who are blind or have low vision can have trouble reading them.
Visit Microsoft’s information on how to `}<a href="https://support.office.com/en-us/article/Make-your-PowerPoint-presentations-accessible-6f7772b2-2f33-4bd2-8ca7-dae3b2b3ef25" target="_blank">{`Make your PowerPoint presentations accessible`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Microsoft Excel®:`}</strong>{`
Although a spreadsheet is rarely used as a tool for documentation,
see Microsoft’s guidance on how to `}<a href="https://support.office.com/en-us/article/Make-your-Excel-spreadsheets-accessible-6cc05fc5-1314-48b5-8eb3-683e49b3e593" target="_blank">{`Make your Excel spreadsheets accessible`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Microsoft HTML Help:`}</strong>{`
Most help systems contain substantial information on how to produce accessible content.
See the Microsoft article `}<a href="https://msdn.microsoft.com/en-us/library/windows/desktop/ms670065%28v=vs.85%29.aspx" target="_blank">{`Making Your Help System Accessible`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`EPub:`}</strong>{`
At its foundation, EPub is HTML.
If instructions to make HTML accessible are followed,
documentation can be exported as accessible EPub.
Visit Adobe’s guidance for creating `}<a href="https://www.adobe.com/accessibility/products/indesign.html" target="_blank">{`EPub documentation with InDesign®`}</a>{`.
For other authoring tools that create EPub documents,
locate and implement accessibility features of those tools.`}</li>
      <li parentName="ul"><strong parentName="li">{`DITA:`}</strong>{`
Where DITA is used to produce documentation,
follow `}<a href="https://www.oxygenxml.com/dita/styleguide/webhelp-feedback/#Artefact/Graphics_and_Figures/c_WAI_Compliance.html" target="_blank">{`techniques to ensure accessibility of DITA output`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`PDF:`}</strong>{`
Electronic support documentation and marketing materials in PDF format must be accessible.
To understand the requirements for individual documents, visit Adobe’s information on how to `}<a href="https://helpx.adobe.com/acrobat/using/create-verify-pdf-accessibility.html" target="_blank">{`Create and verify PDF accessibility`}</a>{`.
Review third-party guidance such as `}<a href="https://www.aiim.org/Global/AIIM_Widgets/Community_Widgets/Achieving_WCAG" target="_blank">{`AIIM®‘s Achieving WCAG 2.0 with PDF/UA`}</a>{`,`}<a href="https://www.w3.org/WAI/GL/WCAG20-TECHS/pdf.html" target="_blank">PDF Techniques for WCAG 2.0</a>,
the <a href="https://www.hhs.gov/sites/default/files/hhs-508-pdf-checklist.xlsx" target="_blank">US Department of Health and Human Service's PDF checklist</a>,
and their <a href="https://www.hhs.gov/sites/default/files/os-a11y-pdf-reference.pdf" target="_blank">making accessible PDF resources</a>.
To understand the accessibility of large scale PDF production systems,
such as monthly statements and report generation systems,
ask those vendors about their capabilities and support for producing accessible PDFs.</li>
    </ul>
    <p>{`Rather than publish a separate documentation requirements list,
IBM requires that when documentation is provided in electronic format,
it must meet all requirements in `}<em parentName="p">{`WCAG 2.1`}</em>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      