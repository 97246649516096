import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Authoring tools shall provide a mode of operation to create or edit content that conforms to Level A and Level AA Success Criteria and Conformance Requirements in WCAG 2.1 for all supported features and, as applicable, to file formats supported by the authoring tool.
Authoring tools shall permit authors the option of overriding information required for accessibility.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`If a product provides the ability for users to author content, the output from the authoring tool must be verified against all requirements filtered by WCAG in these IBM requirements.`}</p>
    <h5>{`What constitutes an authoring tool?`}</h5>
    <p>{`The US Revised 508 Standards defines an authoring tool as:`}</p>
    <p>{`”`}<em parentName="p">{`Any software, or collection of software components, that can be used by authors, alone or collaboratively, to create or modify content for use by others, including other authors`}</em>{`.”`}</p>
    <p>{`Generally, any application or website that offers an ability for authors or end users to create rich text content is considered to include authoring tool functionality. A comment mechanism on a page which offered only an ability to include text with no styling would `}<strong parentName="p">{`not`}</strong>{` be considered an authoring tool. However, any such comment mechanism that allowed for text styling (bold, italics or headings) or the inclusion of actionable elements like hyperlinks would be considered an authoring tool.`}</p>
    <p>{`The `}<em parentName="p">{`Authoring Tool Accessibility Guidelines`}</em>{` (ATAG) provide `}<a href="https://www.w3.org/TR/ATAG20/#def-Authoring-Tool" target="_blank">{`examples of software that are generally considered authoring tools`}</a>{`, including “software for rapidly updating portions of web pages” (e.g., online forums and wikis).`}</p>
    <h5>{`“A mode of operation”`}</h5>
    <p>{`The US Access Board has clarified that they only require one accessible mode of operation which need not be the main mode of operation.
So where an application offers multiple formats for authored content output,
only one of these formats needs to be accessible to meet `}<em parentName="p">{`504.2`}</em>{`.`}</p>
    <h5>{`“For all supported features”`}</h5>
    <p>{`The output need only support accessibility to the degree it is possible for that format.
So if the format does not support multimedia,
all the time-based media requirements would be Not Applicable (N/A).
To use an extreme example,
if an application offered the ability to author a plain-text document,
this would meet `}<em parentName="p">{`504.2`}</em>{` as long as the document used standard text formatting conventions for `}<a href="https://www.w3.org/WAI/WCAG22/Techniques/text/T1" target="_blank">{`paragraphs`}</a>{`, `}<a href="https://www.w3.org/WAI/WCAG22/Techniques/text/T2" target="_blank">{`lists`}</a>{`,
and `}<a href="https://www.w3.org/WAI/WCAG22/Techniques/text/T3" target="_blank">{`headings`}</a>{`.
In such a situation,
almost all WCAG requirements (except potentially `}<em parentName="p">{`1.3.1`}</em>{`, `}<em parentName="p">{`1.3.3`}</em>{`, and `}<em parentName="p">{`2.4.6`}</em>{`) would be N/A,
since they are not supported in plain text documents.`}</p>
    <p><strong parentName="p">{`Exception:`}</strong>{` Authoring tools shall not be required to conform to `}<em parentName="p">{`504.2`}</em>{` when used to directly edit plain text source code.`}</p>
    <p><strong parentName="p">{`Notes:`}</strong></p>
    <ul>
      <li parentName="ul">{`The `}<em parentName="li">{`Authoring Tools`}</em>{` section of the `}<em parentName="li">{`US Revised 508 Standards`}</em>{` cover both features of the authoring tool that promote the generation of accessible output, and the accessibility of the output itself.`}</li>
      <li parentName="ul">{`Although the `}<em parentName="li">{`US Revised 508 Standard`}</em>{` only require conformance to WCAG 2.0,
the European EN 301 549 Standard requires WCAG 2.1.
To ensure products conform in both jurisdictions,
IBM requires that the more recent version of WCAG is met.`}</li>
    </ul>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#504.2" target="_blank">{`504.2 Content Creation or Editing`}</a>{` (external link to US 508) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      