import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/L2_ux_5_1_eg.png";
import img2 from "./../../../../../visuals/design/L2_ux_5_2_eg.png";
import img3 from "./../../../../../visuals/design/L2_ux_5_3_eg.png";
import img4 from "./../../../../../visuals/design/L2_ux_5_4_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Discoverability of feedback and notifications is especially challenging for users with low vision who enlarge content in order to more easily read it. However, feedback that doesn’t require immediate attention should be implemented as such so users with screen readers don’t get interrupted by feedback that moves their keyboard focus to the message.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Determine whether feedback is important enough to disrupt users`}</li>
      <li parentName="ul">{`Optimally position pop-ups and overlays`}</li>
      <li parentName="ul">{`Mark non-disruptive feedback as status messages, when appropriate`}</li>
      <li parentName="ul">{`Distinguish between errors and status messages`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="modal dialog titled 'delete data?' followed by text 'this action will permanently delete the selected data' with cancel button and delete button"></img>
        <Caption fullWidth mdxType="Caption">You can flag errors or warn users by changing the focus (such as by using a dialog)</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img2} alt="error notification with text 'error: please fill out all required fields'"></img>
        <Caption fullWidth mdxType="Caption">If you need to provide important information without disrupting users (by causing a change of focus), mark the new content as “alert role” in the wireframe for developers.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img3} alt="success notification with text 'success: your file has been successfully uploaded!'"></img>
        <Caption fullWidth mdxType="Caption">When you need to provide a status update based on user actions or system’s state, provide a screen update and mark as “status” for developers.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img4} alt="informational notification with text 'system update is available'"></img>
        <Caption fullWidth mdxType="Caption">When you want to promote a new product or tell the user about an available update, provide information but do not assign any “alert role” or “status role.”</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA22" target="_blank" rel="noopener noreferrer">ARIA22: Using role=status to present status messages</a>, WCAG 2.1 technique
      * <a href="https://www.nngroup.com/articles/pop-up-adaptive-help/?lm=tooltip-guidelines&pt=article" target="_blank" rel="noopener noreferrer">Pop-ups and adaptive help get a refresh</a>, Nielsen Norman Group
      * <a href="https://www.nngroup.com/videos/change-blindness/" target="_blank" rel="noopener noreferrer">Change blindness in the user interfaces</a>, Nielsen Norman Group
      * <a href="https://www.nngroup.com/articles/mobile-instructional-overlay/?lm=tooltip-guidelines&pt=article" target="_blank" rel="noopener noreferrer">Instructional overlays and coach marks for mobile apps</a>, Nielsen-Norman Group
      * <a href="https://www.nngroup.com/articles/modal-nonmodal-dialog/" target="_blank" rel="noopener noreferrer">Modal and nonmodal dialogs: when (and when not) to use them</a>, Nielsen Norman Group
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria22' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_3' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_9' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_17' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_18' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_13")} target="_blank" rel="noopener noreferrer">1.4.13 Content on Hover or Focus</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#3_3_1")} target="_blank" rel="noopener noreferrer">3.3.1 Error Identification</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#4_1_3")} target="_blank" rel="noopener noreferrer">4.1.3 Status Messages</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_13' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req4_1_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Tooltips and overlays](/toolkit/design/visual/#tooltips-overlays), this page
      * [Hover content](/toolkit/design/ux/#hover-content), this page
      * [Make content accessible that appears on hover or focus](/toolkit/develop/dynamic-updates#make-content-accessible-that-appears-on-hover-or-focus), Develop - Dynamic content
      * [Properly code status messages](/toolkit/develop/dynamic-updates#properly-code-status-messages), Develop - Dynamic content
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='visual_tooltips_overlays' mdxType="ToolkitTopic"></ToolkitTopic>, Visual design</li>
      <li parentName="ul"><ToolkitTopic qid='ux_hover_content' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='dev_dynamic_make_content' mdxType="ToolkitTopic"></ToolkitTopic>, Develop – Dynamic content</li>
      <li parentName="ul"><ToolkitTopic qid='dev_dynamic_properly_code' mdxType="ToolkitTopic"></ToolkitTopic>, Develop – Dynamic content</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      