import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A cognitive function test (such as remembering a password or solving a puzzle) is not required for any step in an authentication process.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that logins can be completed with less mental effort. Some people with cognitive disabilities cannot solve problems, memorize a username or password, or retype passcodes, and so an author should not make people perform these actions to log in. Authors can easily meet this requirement by allowing users to paste information into authentication inputs. This provides a mechanism for users to use password manager applications for authentication, and to copy and paste passcodes.`}</p>
    <p><strong parentName="p">{`Exceptions:`}</strong>{` The requirement provides a list of four exceptions where cognitive function tests are allowed. If an `}<strong parentName="p">{`Alternative`}</strong>{` method is available that doesn’t rely on such a test. If a `}<strong parentName="p">{`Mechanism`}</strong>{` is available to assist the user in completing the test, such as the ability to paste into password fields. If the text involves the recognition of `}<strong parentName="p">{`Common objects`}</strong>{`, or `}<strong parentName="p">{`Personal content`}</strong>{` previously provided by the user.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/accessible-authentication-minimum.html" target="_blank">{`2.5.8 Accessible authentication`}</a>{` (external link to WCAG 2.2) for more information on intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      