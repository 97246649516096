/*eslint-disable no-extra-bind*/
import React, { Component } from 'react';
import { DataTable, DataTableSkeleton, TextInput, CopyButton, Pagination, Tag, OverflowMenu, OverflowMenuItem, Modal, ToastNotification } from 'carbon-components-react';
import { getCellId } from 'carbon-components-react/lib/components/DataTable/tools/cells';
import View16 from '@carbon/icons-react/lib/view/16';
import Share16 from '@carbon/icons-react/lib/share/16';
import { navigate } from "gatsby"
import { Config } from "../../Config";
import Email16 from '@carbon/icons-react/lib/email/16';

import './report.scss';
import Button from 'carbon-components-react/es/components/Button/Button';
const {
    Table,
    TableToolbar,
    TableToolbarSearch,
    TableHead,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableToolbarContent,
    TableBatchActions,
    TableBatchAction,
    TableSelectRow
} = DataTable;

export default class Report extends Component {
    chDataMap = null;
    state = {
        // id, name, version, pid, clearing_house_id
        "tableData": null,
        "currentPage": 1,
        "totalOnPage": 10,
        "modalOpen": false,
        "shareURL": "",
        "showMaximumAllowedReportsNotification": false,
        "maximumAllowedReports":25
    }

    modalRef = React.createRef();
    emailInputRef = React.createRef();

    constructor(props) {
        super(props);
        this.getTableData()
    }

    async getRawData(url) {
        if (typeof fetch !== "undefined") {
            const response = await fetch(url)
            const myJson = await response.json();
            return myJson;
        } else {
            return [];
        }
    }

    async getTableData() {
        let url = Config.API_ENDPOINT + "/request/api/v1/deliverableCH";
        try {
            let preselect = {}
            if (typeof document !== "undefined" && document.location.search) {
                let chids = document.location.search.match(/chids=([0-9A-Fa-f,]+)/);
                if (chids) {
                    chids = chids[1].split(",");
                    for (const chid of chids) {
                        preselect[chid] = true;
                    }
                }
            }

            let srcData = await this.getRawData(url);
            let rows = [] // reset the default row info
            let index = 0;
            srcData.sort(function (a, b) {
                let nameOrd = a.name.localeCompare(b.name);
                if (nameOrd !== 0) return nameOrd;
                return a.version.localeCompare(b.version);
            })
            //alert("srcData=" + JSON.stringify(srcData));
            this.chDataMap = {};
            for (let item of srcData) {
                this.chDataMap[item.clearing_house_id] = item.display_name;
                let row = {}
                row.id = index.toString()
                index = index + 1
                row.name = item.name
                row.version = item.version
                row.pid = item.pid
                // row.display_name = item.display_name
                row.clearing_house_id = item.clearing_house_id
                if (row.clearing_house_id in preselect) {
                    row.isSelected = true;
                }
                rows.push(row)
            }
            return this.setState({ "tableData": rows });
        } catch (err) {
            console.log(err);
            // document.location.href = "/error";
        }
    }

    async getRequestId(chids) {
        if (localStorage) {
            let names = []
            for (const chid of chids) {
                names.push(this.chDataMap[chid]);
            }
            let history = JSON.parse(localStorage.getItem("requestHistory") || "[]");
            for (const item of history) {
                if (item.products.length === names.length) {
                    let found = true;
                    for (const name of names) {
                        found = found && item.products.includes(name);
                    }
                    if (found) {
                        return item.requestId;
                    }
                }
            }
        }

        let url = Config.API_ENDPOINT + "/request/api/v2/request?";
        chids = chids.join(",")
        try {
            let srcData = await fetch(url + "chids=" + chids)
            const myJson = await srcData.json();
            return myJson.id;
        } catch (err) {
            console.log(err);
        }
    }

    getSelectedIds(rows) {
        let ids = []
        for (let i = 0; i < rows.length; i++) {
            ids.push(this.state.tableData[rows[i].id].clearing_house_id);
        }
        // console.log(ids)
        return ids
    }

    async generateShareUrl(selectedRows) {
        let chids = await this.getSelectedIds(selectedRows);
        let requestId = await this.getRequestId(chids);
        // location.href includes query string info, so we need to use the origin plus pathname
        let shareUrl = window.location.origin + window.location.pathname + "/request?requestId=" + requestId;
        shareUrl = shareUrl.replace("//request","/request");
        this.setState({ "requestId": requestId })
        this.setState({ "modalOpen": true })
        this.setState({ "shareURL": shareUrl })
        setTimeout((() => { this.modalRef.current.focus() }).bind(this), 100);
        return shareUrl;

    }

    async generateSingleShareUrl(row) {
        let chids = [this.state.tableData[row.id].clearing_house_id];
        let requestId = await this.getRequestId(chids);
        // location.href includes query string info, so we need to use the origin plus pathname
        let shareUrl = window.location.origin + window.location.pathname + "/request?requestId=" + requestId;
        shareUrl = shareUrl.replace("//request","/request");
        this.setState({ "requestId": requestId })
        this.setState({ "modalOpen": true })
        this.setState({ "shareURL": shareUrl })
        setTimeout((() => { this.modalRef.current.focus() }).bind(this), 100);
        return shareUrl;

    }

    async generateSingleShareNoModal(row) {
        let chids = [this.state.tableData[row.id].clearing_house_id];
        let requestId = await this.getRequestId(chids);
        // location.href includes query string info, so we need to use the origin plus pathname
        // let shareUrl = window.location.origin + window.location.pathname + "/request?requestId=" + requestId;
        // shareUrl = shareUrl.replace("//request","/request");
        navigate('/product_accessibility/request?requestId=' + requestId)
        return;

    }


    async callReportPage(selectedRows) {
        let trimmedSelectedRows = selectedRows
        if(selectedRows.length > this.state.maximumAllowedReports){
            trimmedSelectedRows = selectedRows.slice(0,this.state.maximumAllowedReports)
        }
        let chids = await this.getSelectedIds(trimmedSelectedRows);
        let requestId = await this.getRequestId(chids);
        navigate('/product_accessibility/request?requestId=' + requestId)
        return requestId;

    }

    viewButtonText(rows) {
        if (rows.length === 0) {
            return "Please select a report"
        } else {
            return "View " + rows.length + " Reports"
        }
    }

    async sendMail(e) {
        await fetch(`${Config.API_ENDPOINT}/request/api/v2/request/${this.state.requestId}/mail?to=${encodeURIComponent(this.emailInputRef.current.value)}`);
        this.setState({ modalOpen: false });
    }

    tableRender = ({
        rows,
        getRowProps,
        headers,
        getHeaderProps,
        getSelectionProps,
        onInputChange,
        getBatchActionProps,
        selectedRows,
        getTableProps,
    }) => {
        let paginationProps = {
            onChange: ({ page, pageSize }) => {
                this.setState({
                    currentPage: Number(page),
                    totalOnPage: Number(pageSize)
                });
            },
            pageSizes: [10, 50, 100]
        };
        function splitDataScheduled(list, pageNumber, pageItems) {
            let totalList = 0;
            if (pageNumber === 1 || list.length <= 10) {
                totalList = pageItems;
                pageNumber = 1;
            } else {
                totalList = pageItems * pageNumber;
            }
            return list.slice((pageNumber - 1) * pageItems, totalList);
        }
        let pageRows = splitDataScheduled(
            rows,
            this.state.currentPage,
            this.state.totalOnPage
        );

        let deselectRow = (id, e) => {
            // console.log("deselect");
            if (e.keyCode && e.keyCode !== 13 && e.keyCode !== 32) return;
            for (const row of selectedRows) {
                if (row.id === id) {
                    getSelectionProps({ row }).onSelect(e);
                }
            }
            e.preventDefault();
        }
        let tags = [];
        selectedRows.map((row, index) => {
            // console.log("select");
            if(selectedRows.length > this.state.maximumAllowedReports){
                // console.log("showMaximumAllowedReportsNotification is True");
                this.state.showMaximumAllowedReportsNotification = true;
            }else{
                this.state.showMaximumAllowedReportsNotification = false;
            }
            tags.push(<Tag type="purple" key={"tag_" + index} filter onKeyDown={deselectRow.bind(this, row.id)} onClose={deselectRow.bind(this, row.id)}>{row.cells[0].value} {row.cells[1].value}</Tag>)
            tags.push(<React.Fragment> </React.Fragment>)
            return row;
        })
        let tagToolbar = <React.Fragment></React.Fragment>;
        if (tags.length > 0) {
            tagToolbar = <div className="selectedTagBar"><TableToolbar>{tags}
            </TableToolbar></div>

        }

        let batchToolbar = <React.Fragment></React.Fragment>
        if (selectedRows.length > 0) {
            batchToolbar =
                <TableToolbar>
                    <TableToolbarContent>
                        <TableBatchActions className="bx--batch-actions--active" {...getBatchActionProps()}>
                            <TableBatchAction onClick={() => this.generateShareUrl(selectedRows)} renderIcon={Share16}>
                                Share
                </TableBatchAction>
                            <TableBatchAction onClick={this.callReportPage.bind(this, selectedRows)} renderIcon={View16}>
                                {/* {this.viewButtonText(selectedRows)} */}
                                See Reports
                </TableBatchAction>
                        </TableBatchActions>
                    </TableToolbarContent>
                </TableToolbar>
        }

        const copyShareUrl = () => {
            try {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(this.state.shareURL);
                } else if (window.clipboardData) {  // IE
                    window.clipboardData.setData('text', this.state.shareURL);
                } else {  // other browsers, iOS, Mac OS
                    const input = document.getElementById("shareURL");
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(input);
                    selection.removeAllRanges();

                    selection.addRange(range);
                    input.setSelectionRange(0, 999999);

                    document.execCommand('copy');
                }
            } catch (e) {
            }
        }


        let retVal = (
            <React.Fragment>
                { this.state.showMaximumAllowedReportsNotification &&
                <div className="revertToast">
                    <ToastNotification
                    caption=""
                    iconDescription="close button"
                    kind="warning"
                    timeout={0}
                    title="A maximum number of 25 reports can be selected at one time. Selections that exceed the maximum are not returned."                      
                    />
                </div>}
                
                <TableContainer>
                    <TableToolbar>
                        <TableToolbarSearch style={{backgroundColor: "white"}} placeHolderText="Search reports" expanded={true} id="data-table-search-0" onChange={onInputChange} />
                    </TableToolbar>
                    {batchToolbar}
                    {tagToolbar}
                    <Table {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                <TableHeader>Selection</TableHeader>
                                {headers.map((header) => (
                                    <TableHeader {...getHeaderProps({ header })}>
                                        {header.header}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageRows.map((row) => (
                                <TableRow {...getRowProps({ row })} >
                                    <TableSelectRow  {...getSelectionProps({ row })}  />
                                    <React.Fragment >
                                        {row.cells.map((cell) => {
                                            if (cell.id.indexOf(":menu") === -1) {
                                                return <TableCell key={cell.id} id={cell.id}>
                                                    {cell.value}
                                                </TableCell>
                                            } else {
                                                return <React.Fragment></React.Fragment>;
                                            }
                                        })}
                                    </React.Fragment>
                                    <TableCell>
                                        <OverflowMenu flipped>
                                            <OverflowMenuItem itemText="See report" onClick={(() => { this.generateSingleShareNoModal(row) }).bind(this)}></OverflowMenuItem>
                                            <OverflowMenuItem itemText="Share" onClick={(() => { this.generateSingleShareUrl(row) }).bind(this)}></OverflowMenuItem>
                                        </OverflowMenu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination
                    {...paginationProps}
                    totalItems={rows.length}
                    page={this.state.currentPage}
                    style={{backgroundColor: "white"}}
                />
                <Modal aria-label="Share Reports" modalHeading="Share Reports"
                    primaryButtonText="Send Email"
                    selectorPrimaryFocus="#copyButtonFirst"
                    passiveModal={true}
                    open={this.state.modalOpen}
                    onRequestClose={(() => { this.setState({ "modalOpen": false }) }).bind(this)}>
                    <div ref={this.modalRef} tabIndex="-1"></div>
                    <label htmlFor="text-input-3" className="bx--label">
                        Copy the URL below to share.
                        <br></br>
                    </label>

                    <div className="bx--form-item bx--text-input-wrapper">
                        <div className="bx--text-input__field-wrapper">
                            <div className="bx--text-input bx--text__input">
                                <input id="shareURL"
                                    className="bx--text-input bx--text__input" type="text" padding-right="40px" readOnly value={this.state.shareURL} />
                            </div>
                            <CopyButton id="copyButtonFirst" style={{ position: "relative" }} feedbackTimeout={3000} onClick={copyShareUrl.bind(this)} />
                        </div>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                        <TextInput
                            id="email-input"
                            labelText={<React.Fragment>Enter an email address if you would like to send the selected reports.</React.Fragment>}
                            placeholder="Enter an email address"
                            type="email"
                            ref={this.emailInputRef} />
                    </div>
                    <br></br>
                    <Button kind="secondary" size="small" renderIcon={Email16} style={{ float: "right" }} onClick={this.sendMail.bind(this)}>Send</Button>
                </Modal>
            </React.Fragment>
        )
        return retVal;

    }

    filterRows({ rowIds, headers, cellsById, inputValue }) {
        return rowIds.filter(rowId => {
            let rowStr = "";
            for (const header of headers) {
                const headerKey = header.key;
                const cellId = getCellId(rowId, headerKey);
                if (typeof cellsById[cellId].value === 'boolean') continue;
                let cellContents = ('' + cellsById[cellId].value).toLowerCase();
                // Remove IBM from search
                rowStr += cellContents.replace(/ibm/g,"");
            }
            let terms = inputValue.toLowerCase().replace(/ibm/g,"").split(" ");
            let includesTerms = true;
            for (const term of terms) {
                includesTerms = includesTerms && rowStr.includes(term);
            }
            return includesTerms;
        })
    }

    componentDidMount() {
        document.title= "IBM Accessibility Conformance Report Requests";
    }

    render() {
        let mainTable = (<DataTableSkeleton headers={['Selection', 'Product name', 'Version', 'PIDs', 'Actions']} />);
        if (this.state.tableData) {
            const headers = [
                {
                    key: 'name',
                    header: 'Product name',
                },
                {
                    key: 'version',
                    header: 'Version',
                },
                {
                    key: 'pid',
                    header: 'PIDs',
                },
                {
                    key: 'menu',
                    header: '',
                }
            ];
            // JCH: the 2nd key unique warning is in mainTable
            //      which contains pretty much the whole table
            mainTable = (<DataTable
                rows={this.state.tableData}
                headers={headers}
                render={this.tableRender}
                filterRows={this.filterRows}
            />
            );
        }
        
        return <div className="mainTable">
                  {mainTable}
               </div>;
               

        //return <div className="acrLandingPage" style={{backgroundColor: "white"}}>
        //     {children}
        //</div>
    }
}
