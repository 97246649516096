import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The parts of graphical objects required to understand the content, and the visual information required to identify UI components and states, have a contrast ratio of at least 3:1 against adjacent colors.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures important visual information, such as graphics and icons, meets a 3:1 contrast ratio against adjacent colors (the same minimum contrast required for large or bold text) for two types of non-text content:`}</p>
    <ul>
      <li parentName="ul">{`User interface components`}</li>
      <li parentName="ul">{`Graphical objects`}</li>
    </ul>
    <p>{`The key intended beneficiaries of this requirement are low-vision users who need to perceive active UI components and their states, as well as meaningful graphics.`}</p>
    <p>{`For User Interface components, the visual information that identifies a component as well as its state needs to meet the contrast ratio. This typically means that the outer edge of a component will need to be distinguishable from the background, as will any indicators for focus, selection or other states`}</p>
    <p>{`For every graphical object required for understanding the content, whether it be a UI component or a non-interactive object, the parts of the graphic  must meet a 3:1 contrast with the neighboring information. The WCAG Understanding document provides a range of examples for everything from monochrome icons to pie charts with different colored wedges. Considerations for such things as gradients and infographics are also discussed.`}</p>
    <p><strong parentName="p">{`Unmodified Exception:`}</strong>{` UI components whose appearance is entirely determined by the user agent (e.g., stock HTML radio buttons and checkboxes) do not need to meet the 3:1 contrast if they are not modified by authors. This exception is intended to prevent making authors responsible for any shortcomings of a particular user agent default implementation.`}</p>
    <p><strong parentName="p">{`Inactive Exception:`}</strong>{` UI Components that are not active (i.e., disabled) are not required to meet a minimum contrast.
This matches the exception for text that is “part of an inactive user interface component” in `}<a href={withPrefix("/requirements/requirements/#1_4_3")} target="_blank" rel="noopener noreferrer">{`1.4.3 Contrast (Minimum)`}</a>{`.`}</p>
    <p><strong parentName="p">{`Essential Exception:`}</strong>{` If a particular presentation of a graphical object is essential to the information being conveyed, it also does not need to meet the 3:1 contrast requirement. Examples of such essential graphics may be logos and flags, as well as reproductions of physical objects, whether drawn or photographed.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/UNDERSTANDING/non-text-contrast.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.4.11 Non-text Contrast`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      