import React, { Component } from "react";
var requirementsData = require('../../data/requirements.json')
var jsonQuery = require('json-query')

const REQUIREMENTS_PATH = "/requirements/requirements/"; 

class Requirements extends Component {

    render() {
        return (
            <>
                {getLinkObject(this.props.qid, this.props.source, this.props.name)}
            </>
        )
    }
}
export default Requirements


function getLinkObject(qid, source, name) {
    var result = jsonQuery(qid, {
        data: requirementsData
    })

    var retValue
    if(result.value){  
        
        var prefixedURI = REQUIREMENTS_PATH + result.value.Target

        // console.log("prefixedURI",prefixedURI)

        // console.info("process.env.NODE_ENV",process.env.NODE_ENV)
        if(process.env.NODE_ENV === "production"){
            prefixedURI = "/able" + prefixedURI
        }
    
        var displayName;
        if(name !== "" && name !== null && name !== undefined){ displayName = name } // Override Name in RMS with user specified name
        else { displayName = result.value.Name } // Default name from RMS

        if(source === "hide"){ // Override Source in RMS, and don't display source
            retValue = <>
            <a href={prefixedURI} class="bx--link" target="_blank" style={{ paddingRight: "0px", fontSize: "16px", textDecoration: "underline" }} rel="noopener noreferrer">{result.value.Number} {displayName}</a>
            </>
        }
        else if(source !== "hide" && source !== null && source !== undefined){ // // Override Source in RMS with user specified source
            retValue = <>
            <a href={prefixedURI} class="bx--link" target="_blank" style={{ paddingRight: "0px", fontSize: "16px", textDecoration: "underline" }} rel="noopener noreferrer">{result.value.Number} {displayName}</a>, {source}
            </>
        }
        else{ // Default source from RMS
            retValue = <>
            <a href={prefixedURI} class="bx--link" target="_blank" style={{ paddingRight: "0px", fontSize: "16px", textDecoration: "underline" }} rel="noopener noreferrer">{result.value.Number} {displayName}</a>, {result.value.Source} 
            </>              
        }


    }else{
        retValue = <></>
    }

    return retValue

}