import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/visual_5_do.png";
import img2 from "./../../../../../visuals/design/visual_5_dont.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users with low vision and color blindness need sufficient contrast to see icons more easily. Exceptions that don’t need to meet contrast requirements include: disabled icons that users can’t interact with.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Make sure icons meet 3:1 contrast with its background`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
     <DoDontExample aspectRatio="1:1" type="do" captionTitle="" caption="Have icons with a contrast of at least 3:1. Copyright IBM; all rights reserved." mdxType="DoDontExample">
    <img src={img1} alt="Good example: 3 icons in black with light purple background"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
     <DoDontExample aspectRatio="1:1" type="dont" captionTitle="" caption="Don't have icons with a contrast lower than 3:1. Copyright IBM; all rights reserved." mdxType="DoDontExample">
    <img src={img2} alt="Bad example: 3 icons in white with lighter purple background"></img>
        </DoDontExample>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G207" target="_blank" rel="noopener noreferrer">G207: Ensuring that a contrast ratio of 3:1 is provided for icons</a>, WCAG 2.1 technique
      * <a href="https://www.carbondesignsystem.com/guidelines/icons/usage/" target="_blank" rel="noopener noreferrer">Icon usage</a>, Carbon design system
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g207' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_10' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_11")} target="_blank" rel="noopener noreferrer">1.4.11 Non-text Contrast</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_11' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Component contrast](/toolkit/design/visual/#components-contrast), this page
      * [Visual states](/toolkit/design/visual/#visual-states), this page
      * [Provide a short name for icons that lack text labels](/toolkit/design/content/text-equivalents#icon), Content design – Text equivalents
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='visual_components_contrast' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='visual_visual_states' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='content_alternative_text_for_visuals' mdxType="ToolkitTopic"></ToolkitTopic>, Content design</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      