import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/L2_ux_1_1_eg.png";
import img2 from "./../../../../../visuals/design/L2_ux_1_2_eg.png";
import img3 from "./../../../../../visuals/design/L2_ux_1_3_do.png";
import img4 from "./../../../../../visuals/design/L2_ux_1_3_dont.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Content that appears on hover can be be missed by low vision users who zoom in to navigate a page. Determine whether or not it makes more sense to surface dynamic content through persistent labels in the UI. Users with cognitive disabilities may also need more time to read hover content, so make sure the content persists until dismissed by user actions.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Surface dynamic content on click rather than hover, if possible`}</li>
      <li parentName="ul">{`Avoid dismissing hover content after a certain amount of time`}</li>
      <li parentName="ul">{`Hover content must remain visible until dismissed by mouse or keyboard actions`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="Pointer is positioned over the unexpanded View menu in a menu bar consisting of File, Edit, and View."></img>
 <Caption fullWidth mdxType="Caption">Use click rather than hover to trigger dynamic content.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img2} alt="the pointer is over an information icon, but no tooltip content is displayed on hover."></img>
        <Caption fullWidth mdxType="Caption">Include necessary instructions permanently in the UI, or consider clickable icons for displaying tips.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" captionTitle="" caption="Display hover content until users move the pointer off the trigger and hover content, presses Esc key, or move keyboard focus." mdxType="DoDontExample">
     <img src={img3} alt="Good example: the pointer is over an information icon and tooltip content is displayed on hover"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" captionTitle="" caption="Don't allow content to disappear after a set period of time, or without proper user dismissal, such as clicking the trigger." mdxType="DoDontExample">
     <img src={img4} alt="Bad example: the pointer is over an information icon, but no tooltip content is displayed on hover"></img>
        </DoDontExample>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://w3c.github.io/aria-practices/#tooltip" target="_blank" rel="noopener noreferrer">Tooltip pattern</a>, ARIA authoring practices
      * <a href="https://uxmovement.com/navigation/why-hover-menus-do-users-more-harm-than-good/" target="_blank" rel="noopener noreferrer">Why hover menus do users more harm than good</a>, UX Movement
      * <a href="https://www.liquidlight.co.uk/blog/navigation-drop-downs-should-they-be-hover-or-click/" target="_blank" rel="noopener noreferrer">Navigation drop-downs. Should they be hover or click?</a>, Mike Street, Liquid Light
      * <a href="https://www.nngroup.com/articles/tooltip-guidelines/" target="_blank" rel="noopener noreferrer">Tooltip guidelines</a>, Nielsen Norman Group
      * <a href="https://www.carbondesignsystem.com/components/tooltip/usage/#icon-tooltip" target="_blank" rel="noopener noreferrer">Icon tooltip usage</a>, Carbon design system
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_11' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='ux_movement_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='liquid_light_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_6' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_6' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_13")} target="_blank" rel="noopener noreferrer">1.4.13 Content on Hover or Focus</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_13' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Mouse operable](/toolkit/design/ux/#mouse-operable), this page
      * [Input labels](/toolkit/design/content/#input-labels), Content design
      * [Make content accessible that appears on hover or focus](/toolkit/develop/dynamic-updates#make-content-accessible-that-appears-on-hover-or-focus), Develop - Dynamic content
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_mouse_operable' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='content_input_labels' mdxType="ToolkitTopic"></ToolkitTopic>, Content design</li>
      <li parentName="ul"><ToolkitTopic qid='dev_dynamic_make_content' mdxType="ToolkitTopic"></ToolkitTopic>, Develop - Dynamic content</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      