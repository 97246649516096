import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If a keyboard shortcut is implemented using only letter, punctuation, number or symbol characters,
then the shortcut can be turned off, remapped or activated only on focus.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures custom shortcuts are not accidentally triggered.`}</p>
    <p>{`The key intended beneficiaries are users primarily operating content via speech or keyboard.
Such users may inadvertently activate a shortcut key in the process of navigating or updating content.
This problem occurs with custom shortcuts that do not include modifier keys, such as `}<inlineCode parentName="p">{`Ctrl`}</inlineCode>{`.`}</p>
    <p>{`The easiest and best solution is to ensure that all shortcut keys are a combination of character and modifier keys.
However when not possible, any of the following are acceptable ways of meeting this requirement:`}</p>
    <ul>
      <li parentName="ul">{`A mechanism to turn the shortcut off`}</li>
      <li parentName="ul">{`A mechanism to remap the shortcut to use one or more modifier keys, such as `}<inlineCode parentName="li">{`Alt`}</inlineCode></li>
      <li parentName="ul">{`The keyboard shortcut for a user interface component is only active when that component has focus`}</li>
    </ul>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/character-key-shortcuts.html" target="_blank" rel="noopener noreferrer">{`Understanding 2.1.4 Character Key Shortcuts`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      