import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/visual_7_do.png";
import img2 from "./../../../../../visuals/design/visual_7_dont.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users with low vision will often scale content up to 400% (and beyond) using browser zoom features to make pages easier to read. Without responsive reflow, users will have to scroll horizontally, causing them to lose their place. Although horizontal scrolling is allowed for specific usage such as maps, tables, and diagrams, it’s possible to design such content to reflow gracefully as well.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Reflow content without horizontal scrolling`}</li>
      <li parentName="ul">{`If responsive design isn’t supported, provide a design option at 256 pixels wide`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" aspectRatio="1:1" captionTitle="" caption="Use responsive design so that as the user scales up the zoom level, the content adjusts its placement and reflow." mdxType="DoDontExample">
    <img src={img1} alt="Good example: text reflows while zooming to avoid horizontal scrolling"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" aspectRatio="1:1" captionTitle="" caption="Don't cut off content that will require the user to scroll both horizontally and vertically to read information." mdxType="DoDontExample">
    <img src={img2} alt="Bad example: the 'available by ...'  text is cut off by the right side of the view, requiring the user to horizontally scroll to the right to see the rest of the phrase"></img>
        </DoDontExample>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://react.carbondesignsystem.com/?path=/story/ui-shell--header-base-w-sidenav" target="_blank" rel="noopener noreferrer">Header base with side navigation</a>, Carbon design system
      * <a href="https://nosetothepage.org/Fitz/2dScroll.html" target="_blank" rel="noopener noreferrer">Operational overhead caused by horizontal scrolling text</a>, Knowbility
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='c28' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='c35' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='c36' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='f104' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_15' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_10")} target="_blank" rel="noopener noreferrer">1.4.10 Reflow</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_10' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_12' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      