import ToolkitCard from "../../../../src/components/home/toolkitCard";
import Toolkit1 from "../../../../src/visuals/homepage/toolkit_pictogram_1.svg";
import Toolkit2 from "../../../../src/visuals/homepage/toolkit_pictogram_2.svg";
import Toolkit3 from "../../../../src/visuals/homepage/toolkit_pictogram_3.svg";
import * as React from 'react';
export default {
  ToolkitCard,
  Toolkit1,
  Toolkit2,
  Toolkit3,
  React
};