import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Where hover or focus actions cause additional content to become visible and hidden, the additional content is dismissible, hoverable and persistent.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures when receiving and then removing pointer hover or keyboard focus triggers additional content to become visible and then hidden, the following are true:`}</p>
    <ul>
      <li parentName="ul">{`Dismissible: A mechanism is available to dismiss the additional content without moving pointer hover or keyboard focus, unless the additional content communicates an input error or does not obscure or replace other content;`}</li>
      <li parentName="ul">{`Hoverable: If pointer hover can trigger the additional content, then the pointer can be moved over the additional content without the additional content disappearing;`}</li>
      <li parentName="ul">{`Persistent: The additional content remains visible until the hover or focus trigger is removed, the user dismisses it, or its information is no longer valid.`}</li>
    </ul>
    <p>{`There are two main objectives achieved by these requirements.
The first (covered by `}<strong parentName="p">{`hoverable`}</strong>{`) is that low vision users,
particularly those who rely on magnification to enlarge the content,
are able to move their pointer toward the newly revealed content so they can read it.
Magnification often results in only part of the new information being visible in the viewport until the user reorients the pointer.
If the new content disappears the moment the user moves the mouse off the triggering element,
or after some arbitrary time period,
it will be difficult or impossible for some users to read it.`}</p>
    <p>{`The second objective is that blind users and others who depend on the keyboard for operation,
will be able to both `}<strong parentName="p">{`trigger`}</strong>{` and `}<strong parentName="p">{`dismiss`}</strong>{` the new content without disrupting their work.
If the new information obscures other content,
the user should be able to dismiss it easily without navigating — unless the new content is a warning about an error.
The `}<strong parentName="p">{`persistent`}</strong>{` requirement ensures that the information will remain present until the user has reviewed it.`}</p>
    <p><strong parentName="p">{`Exception:`}</strong>{` WCAG allows situations where “The visual presentation of the additional content is controlled by the user agent and is not modified by the author.”
This primarily covers use of the HTML `}<inlineCode parentName="p">{`<title>`}</inlineCode>{`attribute.
Browsers determine the display position and timing of `}<em parentName="p">{`title text`}</em>{`,
so it is not developer-controlled.
However, `}<inlineCode parentName="p">{`title`}</inlineCode>{` as the means of providing additional content is discouraged by many accessibility advocates.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/UNDERSTANDING/content-on-hover-or-focus.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.4.13 Content on Hover or Focus`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      