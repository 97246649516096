import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Content does not contain anything that flashes more than three times in any one second period,
or the flash is below the general flash and red flash thresholds.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures users can access content without inducing seizures due to photosensitivity.`}</p>
    <p>{`For people with photosensitive seizure disorders,
sizeable content that flashes at certain frequencies for more than a few flashes can trigger a seizure.
Because people are more sensitive to red flashing than to other colors,
you must perform a special test for saturated red flashing.
This requirement is based on the broadcasting industry guidelines,
but they have been adapted for computer monitors,
which allow content to be viewed from a short distance using a larger angle of vision.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Since any content that does not meet this requirement can interfere with a user’s ability to use the whole page/application,
all content, whether it is used to meet other requirements or not, must meet this requirement.
See WCAG `}<a href="https://www.w3.org/TR/WCAG20/#cc5" target="_blank">{`Conformance Requirement 5 Non-interference`}</a>{` (external link to WCAG).`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/three-flashes-or-below-threshold" target="_blank">{`Understanding 2.3.1 Three Flashes or Below Threshold`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      