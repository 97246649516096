import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4><strong parentName="h4">{`Level 1`}</strong></h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/overview#for-free")} target="_blank" rel="noopener noreferrer">Follow core considerations in reducing effort by understanding what you get from design</a></li>
    </ul>
    <h4><strong parentName="h4">{`Level 2`}</strong></h4>
    <ul>
      <li parentName="ul">{`No level 2 developer tasks for this requirement?`}</li>
    </ul>
    <h4><strong parentName="h4">{`Level 3`}</strong></h4>
    <ul>
      <li parentName="ul">{`No level 3 developer tasks for this requirement?`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      