import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where ICT primarily displays materials containing video with associated audio content,
user controls to activate subtitling and audio description shall be provided to the user at the same level of interaction (i.e. the number of steps to complete the task) as the primary media controls.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The European `}<em parentName="p">{`EN 301 549`}</em>{` standard specifies requirements for information and communications technologies such as media players and embedded players that render captions and audio descriptions.
This requirement is Not Applicable to products and applications that do not play audio and video.`}</p>
    <p>{`Several “`}<em parentName="p">{`Time-based Media`}</em>{`” success criteria in WCAG,
such as `}<a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.2 Captions (Prerecorded)</a>,
and <a href={withPrefix("/requirements/requirements/#1_2_5")} target="_blank" rel="noopener noreferrer">{`1.2.5 Audio Description (Prerecorded)`}</a>{`,
require that captions and audio description are provided.
Those requirements stipulate that meaningful audio and video `}<em parentName="p">{`content`}</em>{` has been transcribed and described
and is captured in a format that can be used to provide closed captions and audio descriptions.
Those requirements, covering the `}<em parentName="p">{`content`}</em>{` of the captions and audio descriptions,
are complementary to chapter 7 `}<em parentName="p">{`ICT with video capabilities`}</em>{` of EN 301 549,
which covers the actual `}<em parentName="p">{`delivery`}</em>{` of the captions and audio descriptions during playback.`}</p>
    <h4>{`Provide user controls`}</h4>
    <ul>
      <li parentName="ul">{`a control to display captions at the same level of interaction as the primary media controls`}</li>
      <li parentName="ul">{`a control to activate audio descriptions (or alternate audio tracks) at the same level of interaction as the primary media controls`}</li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{`
Where the requirements of 7.3 `}<em parentName="p">{`User Controls for Captions and Audio Description`}</em>{` are met,
the following requirements can also be considered to be met:`}</p>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_1")} target="_blank" rel="noopener noreferrer">7.1.1 Captioning Playback</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_1")} target="_blank" rel="noopener noreferrer">7.2.1 Audio Description Playback</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#503_4_1")} target="_blank" rel="noopener noreferrer">503.4.1 Caption Controls</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#503_4_2")} target="_blank" rel="noopener noreferrer">503.4.2 Audio Description Controls</a></li>
    </ul>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=36" target="_blank" rel="noopener noreferrer">{`7.3 User Controls for Captions and Audio Description`}</a>{` (external link to EN 301 549) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      