import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Changing the `}<a href="https://www.w3.org/TR/2008/REC-WCAG20-20081211/#context-changedef?" target="_blank" rel="noopener noreferrer">{`context or focus`}</a>{` when a user makes a selection or types input in a field is unexpected behavior and disorients users. This is especially true when the user has a vision disability, and cannot see what happened. You can update content of other elements on the page based on a user’s selection, but avoid automatically changing focus at the same time. One way to do this is to use an `}<inlineCode parentName="p">{`onchange`}</inlineCode>{` event to trigger updating content based on the user’s action, such as making a selection. Another way is to incorporate a Submit button that provides an overt action where the user would expect a focus change.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR19" target="_blank" rel="noopener noreferrer">SCR19: Using an onchange event on a select element without causing a change of context</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H32" target="_blank" rel="noopener noreferrer">H32: Providing submit buttons</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H84" target="_blank" rel="noopener noreferrer">H84: Using a button with a select element to perform an action</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='scr19' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h32' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h84' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#3_2_2")} target="_blank" rel="noopener noreferrer">3.2.2 On Input</a>, IBM accessibility requirements
      * <a href="https://www.w3.org/WAI/WCAG22/Understanding/on-input" target="_blank" rel="noopener noreferrer">Understanding 3.2.2 On Input</a>, W3C Understanding WCAG
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_2_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='wcag_8' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      