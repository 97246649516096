import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Functionality that can be operated by motion can also be operated by user interface components,
and the motion trigger can be disabled.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that motion actuation can be disabled, and that there is also an alternative, non-motion method of performing the desired functionality.`}</p>
    <p>{`It primarily benefits users who are unable to physically manipulate devices
and those who inadvertently trigger motion features due to tremors.
Where designers and developers offer functionality that is triggered by motion,
they must provide an alternative accessible means of operation and also provide an ability to disable motion as an interaction method.`}</p>
    <p>{`An example of a motion actuation feature is a `}<em parentName="p">{`Shake To Undo`}</em>{` feature.
This can be made accessible by providing a user setting to disable the feature and offering an `}<inlineCode parentName="p">{`Undo`}</inlineCode>{` button to perform the same action.`}</p>
    <p><strong parentName="p">{`Spatial Exception:`}</strong>{` This requirement concerns input through sensors which respond directly to motions such as gesturing towards,
tilting, or shaking a device.
It does not cover the movement of users through space as registered by geolocation sensors or beacons.`}</p>
    <p><strong parentName="p">{`Essential Exception:`}</strong>{` This requirement does not apply where the motion is essential for the function,
and, disabling or, bypassing it would invalidate the activity.
A pedometer that relies on device motion to count steps is an example of such an essential activity.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/motion-actuation" target="_blank" rel="noopener noreferrer">{`Understanding 2.5.4 Motion Actuation`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      