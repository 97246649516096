import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where ICT has a keyboard or keypad, the delay after any keystroke,
during which an additional key-press will not be accepted if it is identical to the previous keystroke,
shall be adjustable up to at least 0.5 seconds.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The European `}<em parentName="p">{`EN 301 549`}</em>{` standard is similar to `}<a href={withPrefix("/requirements/requirements/#502_4")} target="_blank" rel="noopener noreferrer">{`502.4 Platform Accessibility`}</a>{` requirement of the US Revised 508 Standard.
However, where `}<em parentName="p">{`502.4 Platform Accessibility`}</em>{` mentions a need to ”`}<em parentName="p">{`provide adjustment of same-key double-strike acceptance`}</em>{`” the EU standard gives specific timings for the adjustment.
Since the EU requirement is more stringent, meeting it will also satisfy the US Revised 508 Standard.`}</p>
    <p>{`This requirement is primarily aimed at platform software (i.e., operating systems).
Applications meet this requirement automatically through features offered by the platform.`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=29" target="_blank">{`5.8 Double-strike  Key Acceptance`}</a>{` (external link to EN 301 549) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      