import ImpactCard from "../../../../src/components/home/impactCard";
import ImpactFeatureCard from "../../../../src/components/home/impactFeatureCard";
import ImpactLarge from "../../../../src/visuals/homepage/impact_a11y_ultimate_experience.png";
import Impact1 from "../../../../src/visuals/homepage/feature_card_desktop113021.png";
import Impact2 from "../../../../src/images/impact_5_checker.png";
import Impact3 from "../../../../src/visuals/homepage/impact_3_pwdDay.png";
import * as React from 'react';
export default {
  ImpactCard,
  ImpactFeatureCard,
  ImpactLarge,
  Impact1,
  Impact2,
  Impact3,
  React
};