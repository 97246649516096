import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where ICT is a non-web document that contains synchronized media with audio description,
the audio description should not interfere with relevant audio information in the synchronized media.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{`
The numbering of this requirement was updated to match the latest version of the EN 301 549 standard.
It is otherwise identical to the prior version 7.0 requirement `}<a href="https://www.ibm.com/able/guidelines/ci162/description_timing.html" target="_blank">{`10.2.40 Audio Description Timing`}</a>{`.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The European `}<em parentName="p">{`EN 301 549`}</em>{` standard specifies requirements for non-web documentation.
This requirement ensures that audio descriptions do not overlap with any meaningful information in the audio track.
This requirement is Not Applicable (N/A) to documentation that does not play audio and video.
This requirement only applies to non-web documents, including downloadable documents,
and associated media player.`}</p>
    <p>{`A similar WCAG principle exists for all audio descriptions,
so any team following best practices will not need to do additional work to meet this requirement.
As noted in each of the WCAG techniques for audio descriptions:`}</p>
    <p>{`”`}<em parentName="p">{`Since it is not helpful to have this new information obscure key audio information in the original sound track (or be obscured by loud sound effects),
the new audio description is added during pauses in dialogue and sound effects.`}</em>{`”`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=63" target="_blank">{`10.6 Audio Description Timing`}</a>{` (external link to EN 301 549) for more information.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Development techniques</h5>
    <p>{`Ensure audio descriptions do not overlap dialogue and sound effects or other important information by using any of the following techniques:`}</p>
    <ul>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G78" target="_blank">G78: Providing a second, user-selectable, audio track that includes audio descriptions</a></li>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G173" target="_blank">G173: Providing a version of a movie with audio descriptions</a></li>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G8" target="_blank">G8: Providing a movie with extended audio descriptions</a></li>
    </ul>
    <p><strong parentName="p">{`Related requirements:`}</strong>{`
Applications that play audio and video are required to meet the following related requirements:`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Audio/Video Content requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_3")} target="_blank" rel="noopener noreferrer">1.2.3 Audio Description or Media Alternative (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_5")} target="_blank" rel="noopener noreferrer">1.2.5 Audio Description (Prerecorded)</a></li>
      <li parentName="ul">{`10.6 Audio Description Timing (Non-web Documents) - `}<em parentName="li">{`this requirement`}</em></li>
    </ul>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Player requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_4_2")} target="_blank" rel="noopener noreferrer">1.4.2 Audio Control</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_1")} target="_blank" rel="noopener noreferrer">7.2.1 Audio Description Playback</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_2")} target="_blank" rel="noopener noreferrer">7.2.2 Audio Description Synchronization</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_3")} target="_blank" rel="noopener noreferrer">7.2.3 Preservation of Audio Description</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">7.3 User Controls for Captions & Audio Description</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#503_4_2")} target="_blank" rel="noopener noreferrer">503.4.2 Audio Description Controls</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      