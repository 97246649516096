import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The default human language of Web pages, non-web documents, or software can be programmatically determined.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures that developers identify the primary language of the content so that browsers and assistive technology can present text and other linguistic content correctly. This enables assistive technologies to load the correct pronunciations rules for the language.`}</p>
    <p>{`For a web page the `}<inlineCode parentName="p">{`lang`}</inlineCode>{` attribute of the `}<inlineCode parentName="p">{`<html>`}</inlineCode>{` element allows you to specify the primary language and enables both assistive technologies and browsers to render the content more accurately.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Where software platforms provide a `}<inlineCode parentName="p">{`locale / language`}</inlineCode>{` setting, applications that use that setting and render their interface in that `}<inlineCode parentName="p">{`locale / language`}</inlineCode>{` would comply with this requirement. Applications that do not use that setting, but instead use an accessibility-supported method for exposing the human language of the software would also comply.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/language-of-page" target="_blank" rel="noopener noreferrer">{`Understanding 3.1.1 Language of Page`}</a>{` (external link to WCAG) for more information on intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      