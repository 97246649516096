import React, { useEffect, useState } from 'react';
import { withPrefix } from "gatsby"
import {
    ContentSwitcher,
    Switch
} from "carbon-components-react";

import Checkmark32 from "../../../../images/Checkmark32.svg";
import "./reviewContent.scss";

const ReviewContent = () => {

    const [content, setContent] = useState('')

    const level1Content = (
        <React.Fragment>

            {/* CONTENT */}
            <div style={{ "marginTop": "48px" }}></div>
            <p className="checklistHeader">Content</p>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#headings")} target="_blank" rel="noopener noreferrer">
                    <p>Headings</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#alternative-text-for-visuals")} target="_blank" rel="noopener noreferrer">
                    <p>Alternative text for visuals</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#input-labels")} target="_blank" rel="noopener noreferrer">
                    <p>Input labels</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#error-messages")} target="_blank" rel="noopener noreferrer">
                    <p>Error messages</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#flashing-content")} target="_blank" rel="noopener noreferrer">
                    <p>Flashing content</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#video-captions")} target="_blank" rel="noopener noreferrer">
                    <p>Video captions</p>
                </a>
            </div>

            {/* UX */}
            <div style={{ "marginTop": "48px" }}></div>
            <p className="checklistHeader">UX</p>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#tab-order")} target="_blank" rel="noopener noreferrer">
                    <p>Tab order</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#keyboard-interaction")} target="_blank" rel="noopener noreferrer">
                    <p>Keyboard interaction</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#mouse-operable")} target="_blank" rel="noopener noreferrer">
                    <p>Mouse-operable components</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#pop-ups")} target="_blank" rel="noopener noreferrer">
                    <p>Pop-ups and overlays</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#input-components")} target="_blank" rel="noopener noreferrer">
                    <p>Input components</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#custom-component")} target="_blank" rel="noopener noreferrer">
                    <p>Custom component keyboard guidance</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#error-messages")} target="_blank" rel="noopener noreferrer">
                    <p>Surfacing error messages</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#transactions")} target="_blank" rel="noopener noreferrer">
                    <p>Transactions</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#reverse")} target="_blank" rel="noopener noreferrer">
                    <p>Reverse and undo</p>
                </a>
            </div>

            {/* VISUAL */}
            <div style={{ "marginTop": "48px" }}></div>
            <p className="checklistHeader">Visual</p>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#text-color-contrast")} target="_blank" rel="noopener noreferrer">
                    <p>Text color contrast</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#link-text-contrast")} target="_blank" rel="noopener noreferrer">
                    <p>Link text contrast</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#components-contrast")} target="_blank" rel="noopener noreferrer">
                    <p>Component contrast</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#visual-states")} target="_blank" rel="noopener noreferrer">
                    <p>Visual states</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#icons")} target="_blank" rel="noopener noreferrer">
                    <p>Icons</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#data-visualization")} target="_blank" rel="noopener noreferrer">
                    <p>Data visualization</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#responsive-reflow")} target="_blank" rel="noopener noreferrer">
                    <p>Responsive reflow</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#required-fields")} target="_blank" rel="noopener noreferrer">
                    <p>Required fields</p>
                </a>
            </div>

        </React.Fragment>
    );

    const level2Content = (
        <React.Fragment>

            {/* CONTENT */}
            <div style={{ "marginTop": "48px" }}></div>
            <p className="checklistHeader">Content</p>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#page-titles")} target="_blank" rel="noopener noreferrer">
                    <p>Page titles</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#images-of-text")} target="_blank" rel="noopener noreferrer">
                    <p>Images of text</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#autoplaying-content")} target="_blank" rel="noopener noreferrer">
                    <p>Autoplaying content</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#text-content-for-videos")} target="_blank" rel="noopener noreferrer">
                    <p>Text content for videos</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#link-text")} target="_blank" rel="noopener noreferrer">
                    <p>Link text</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#data-format-for-inputs")} target="_blank" rel="noopener noreferrer">
                    <p>Data format for inputs</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#placeholder-text")} target="_blank" rel="noopener noreferrer">
                    <p>Placeholder text</p>
                </a>
            </div>


            {/* UX */}
            <div style={{ "marginTop": "48px" }}></div>
            <p className="checklistHeader">UX</p>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#hover-content")} target="_blank" rel="noopener noreferrer">
                    <p>Hover content</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#media-players")} target="_blank" rel="noopener noreferrer">
                    <p>Media players</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#pointer-operation")} target="_blank" rel="noopener noreferrer">
                    <p>Pointer operation</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#device-motion")} target="_blank" rel="noopener noreferrer">
                    <p>Device motion</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#feedback")} target="_blank" rel="noopener noreferrer">
                    <p>Feedback and notifications</p>
                </a>
            </div>


            {/* VISUAL */}
            <div style={{ "marginTop": "48px" }}></div>
            <p className="checklistHeader">Visual</p>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#text-styles")} target="_blank" rel="noopener noreferrer">
                    <p>Text styles</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#legends")} target="_blank" rel="noopener noreferrer">
                    <p>Legends</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#tooltips-overlays")} target="_blank" rel="noopener noreferrer">
                    <p>Tooltips and overlays</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#layout-orientations")} target="_blank" rel="noopener noreferrer">
                    <p>Layout orientations</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#data-tables")} target="_blank" rel="noopener noreferrer">
                    <p>Data tables</p>
                </a>
            </div>
        </React.Fragment>
    );

    const level3Content = (
        <React.Fragment>

            {/* CONTENT */}
            <div style={{ "marginTop": "48px" }}></div>
            <p className="checklistHeader">Content</p>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#visual-content-in-videos")} target="_blank" rel="noopener noreferrer">
                    <p>Visual content in videos</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#video-transcripts")} target="_blank" rel="noopener noreferrer">
                    <p>Video transcripts</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#figure-and-table-captions")} target="_blank" rel="noopener noreferrer">
                    <p>Figure and table captions</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#foreign-language")} target="_blank" rel="noopener noreferrer">
                    <p>Foreign language</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#consistent-across-pages")} target="_blank" rel="noopener noreferrer">
                    <p>Consistent across pages</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/content#consistent-across-pages")} target="_blank" rel="noopener noreferrer">
                    <p>Consistent across pages (WCAG 2.2. only)</p>
                </a>
            </div>

            {/* UX */}
            <div style={{ "marginTop": "48px" }}></div>
            <p className="checklistHeader">UX</p>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#motion-effects")} target="_blank" rel="noopener noreferrer">
                    <p>Motion effects</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#reading-order")} target="_blank" rel="noopener noreferrer">
                    <p>Reading order</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#toc-sitemap")} target="_blank" rel="noopener noreferrer">
                    <p>Table of contents and site maps</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#search-function")} target="_blank" rel="noopener noreferrer">
                    <p>Links and Search function</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#patterns")} target="_blank" rel="noopener noreferrer">
                    <p>Predicatable patterns</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#pointer-interaction")} target="_blank" rel="noopener noreferrer">
                    <p>Pointer interaction</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#time-limits")} target="_blank" rel="noopener noreferrer">
                    <p>Time limits</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#multi-step")} target="_blank" rel="noopener noreferrer">
                    <p>Multi-step processes (WCAG 2.2 only)</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/ux#authentication")} target="_blank" rel="noopener noreferrer">
                    <p>Authentication (WCAG 2.2 only)</p>
                </a>
            </div>

            {/* VISUAL */}
            <div style={{ "marginTop": "48px" }}></div>
            <p className="checklistHeader">Visual</p>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#regions")} target="_blank" rel="noopener noreferrer">
                    <p>Regions</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#form-inputs")} target="_blank" rel="noopener noreferrer">
                    <p>Form inputs</p>
                </a>
            </div>
            <div className="checklistRow checklistContent">
                <img className="iconImg" src={Checkmark32} alt="Checkmark" />
                <a href={withPrefix("toolkit/design/visual#white-space")} target="_blank" rel="noopener noreferrer">
                    <p>White space</p>
                </a>
            </div>
        </React.Fragment>
    );

    // Set content dynamically based on selected index
    function handleChange(e) {
        if (e.index === 0) {
            setContent(level1Content);
        }
        else if (e.index === 1) {
            setContent(level2Content);
        }
        else if (e.index === 2) {
            setContent(level3Content);
        }
    }

    // Set default content after component load
    useEffect(() => {
        setContent(level1Content);
    }, []);

    return (
        <div>
            <ContentSwitcher selectedIndex={0} onChange={handleChange}>
                <Switch name="level1" text="Level 1" />
                <Switch name="level2" text="Level 2" />
                <Switch name="level3" text="Level 3" />
            </ContentSwitcher>
            {content}
        </div>
    )
}

export default ReviewContent;