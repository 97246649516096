
import React, { Component, Children } from 'react'
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import Styles from './eventBodyCard.scss'
// import testImg from './img_avatar.png';

class EventTitleCard extends Component {
    render() {
        return (
            <div className="bx--grid">
                <div id={this.props.eventName} class="bx--row" style={{ paddingBottom: "5rem", paddingTop: "5rem" }}>
                    <div class="bx--col-lg-3 bx--col-sm-4 bx--type-expressive-heading-02" style={{marginTop:"40px"}} role="heading" aria-level="2">
                        IBM's Involvement
                        {/* <img alt="" src={this.props.image} style={{ width: "380px", paddingTop: "1.5rem", paddingBottom: "2.5rem",paddingRight: "1rem" }} /> */}
                    </div>
                    <div class="bx--col-lg-6 bx--col-sm-4">
                        {/* <div className="eventBodyCardTitle">
                            {this.props.eventName}
                        </div>
                        <div className="eventBodyCardDateAndLocation">
                            {this.props.eventDate}<br />
                            {this.props.eventLocation}
                        </div> */}
                        <p className="eventTitleCardBody">
                            We strive to make our own events and presentations as accessible as possible, and to help others do the same. We have led the development of guidance on <a href="http://www.sigaccess.org/welcome-to-sigaccess/resources/accessible-conference-guide/">Planning an Accessible Conference</a> for the <a href="http://acm.org/">Association of Computing Machinery</a> (ACM), the world’s largest educational and scientific computing society. Accessibility considerations for online events are equally important, especially now that many events are moving online, and we also contributed accessibility guidance to the ACM’s new report: <a href="https://people.clarkson.edu/~jmatthew/acm/VirtualConferences_GuideToBestPractices_CURRENT.pdf">Virtual Conferences: A Guide to Best Practices</a>.
                        </p>


                    </div>
                </div>
            </div>


        )

    }


}
export default EventTitleCard