import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For user interface components with labels that include text or images of text,
the accessible name contains the text that is presented visually.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The requirement ensures that a component’s visible text label is the same as (or included in) the `}<em parentName="p">{`accessible name`}</em>{` for the component. This makes the visual label for controls to be a trigger for speech activation.
It primarily benefits users of speech recognition,
but it also benefits low-vision and blind users who use a screen reader.`}</p>
    <p>{`The `}<em parentName="p">{`accessible name`}</em>{` is the text that is programmatically associated with the component — what an assistive technology will use.
A screen reader will announce the `}<em parentName="p">{`accessible name`}</em>{` when it lands on a button or an input field,
so if users still retain the ability to see the screen,
it can be confusing if what is announced doesn’t match what they see.
A similar situation can occur when the blind user only knows how to refer to the `}<em parentName="p">{`accessible name`}</em>{` based on what the screen reader renders,
while a sighted colleague or remote Help-desk may only see the visible label.`}</p>
    <p>{`More crucially, when users of speech recognition navigate around a page by voice,
they can say the `}<em parentName="p">{`accessible name`}</em>{` of the component to perform operations.
If the visible label is different from the `}<em parentName="p">{`accessible name`}</em>{`, two unwelcome things can happen.
The first is the user will not be able to say the visible label to interact with the item.
The second is that users may accidentally trigger the wrong component by saying its `}<em parentName="p">{`accessible name`}</em>{`, if the visible label is not the same.`}</p>
    <p>{`These problems are avoided by ensuring that the visible label is also the programmatic name of the component — or that the label’s text is included in the accessible name,
preferably at the beginning.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/label-in-name" target="_blank">{`Understanding 2.5.3 Label in Name`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      