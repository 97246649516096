import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The purpose of each link can be determined from the link text alone
or from the link text together with its programmatically determined link context.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures the purpose of a link is clear and easy to understand for all users.
Whenever possible, the text of the link should describe its purpose without needing additional context.`}</p>
    <p>{`Some assistive technologies provide a list of all links on the page to its user.
If the text of the links do not clearly give the purpose,
or if multiple links with the same name point to different targets (e.g., “read more”),
users are forced to locate the link on the page and search surrounding information for context.`}</p>
    <p>{`When it is not possible to provide descriptive link text,
the content that provides the context must be in the same sentence or be associated with the link programmatically.
Ideally, this descriptive content should precede the link,
so that users who read content sequentially encounter the link context before the link.`}</p>
    <p><strong parentName="p">{`Exception:`}</strong>{` An exception to this requirement is where the purpose of a link would be ambiguous to all users in general.
For example, a game may create suspense for all users by presenting links with no indication of what the links will do until activated.
However, whatever context is available must be programmatically associated with the link.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` In non-web software,
a “`}<em parentName="p">{`link`}</em>{`” is any non-UI-control text string or image that behaves like a hypertext link.
See `}<a href="https://www.w3.org/TR/wcag2ict/" target="_blank">{`Applying WCAG to Non-Web Information and Communications Technologies`}</a>{` (external link to WCAG2ICT).`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/link-purpose-in-context" target="_blank">{`Understanding 2.4.4 Link Purpose (in context)`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      