import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Sometimes an input element doesn’t have a visible label. Multi-line address fields and telephone numbers are the most common
examples. The content designer should `}<ToolkitTopic qid='content_input_specify_the' name="specify the accessible name for inputs without visible labels" mdxType="ToolkitTopic"></ToolkitTopic>{`. The developer’s job is to
use ARIA or another technology to programmatically assign the accessible name to the element.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA14" target="_blank" rel="noopener noreferrer">ARIA14: Using aria-label to provide an invisible label where a visible label cannot be used</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA16" target="_blank" rel="noopener noreferrer">ARIA16: Using aria-labelledby to provide a name for user interface controls</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H65" target="_blank" rel="noopener noreferrer">H65: Using the title attribute to identify form controls when the label element cannot be used</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G211#example-7-range-of-inputs-with-few-labels" target="_blank" rel="noopener noreferrer">G211: Matching the accessible name to the visible label - Example 7: range of inputs with few labels</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria14' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria16' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h65' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g211' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#4_1_2")} target="_blank" rel="noopener noreferrer">4.1.2 Name, Role, Value</a>, IBM accessibility requirements
      * <a href="https://www.w3.org/WAI/tutorials/forms/labels/#hiding-label-text" target="_blank" rel="noopener noreferrer">Labeling controls: hiding label text</a>, W3C web accessibility tutorials
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req4_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_22' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      