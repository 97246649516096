import { withPrefix } from "gatsby";
import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion } from "carbon-components-react";
import ConfirmInteraction from "../../../../src/pages/toolkit/verify/screen-reader/confirminteraction.mdx";
import KeyboardOperation from "../../../../src/pages/toolkit/verify/screen-reader/keyboardoperation.mdx";
import MeaningfulSequence from "../../../../src/pages/toolkit/verify/screen-reader/meaningfulsequence.mdx";
import PageStructure from "../../../../src/pages/toolkit/verify/screen-reader/pagestructure.mdx";
import VerifyLabelling from "../../../../src/pages/toolkit/verify/screen-reader/verifylabelling.mdx";
import ReportingBugs from "../../../../src/pages/toolkit/verify/screen-reader/reportingbugs.mdx";
import locked from "../../../../src/images/locked.svg";
import ibmLogo from "../../../../src/images/ibm_logo.svg";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import "../../../../src/pages/toolkit/topics.scss";
import ExpandCollapseAccordion from "../../../../src/components/expand_collapse_accordion/expand_collapse_accordion.js";
import * as React from 'react';
export default {
  withPrefix,
  TkAccordionItem,
  Accordion,
  ConfirmInteraction,
  KeyboardOperation,
  MeaningfulSequence,
  PageStructure,
  VerifyLabelling,
  ReportingBugs,
  locked,
  ibmLogo,
  Row,
  Column,
  ExpandCollapseAccordion,
  React
};