import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Custom components often don’t have standard HTML keyboard behavior. The ARIA authoring practices provide detailed guidance to ensure such components meet expected keyboard behavior and are accessible to users with assistive technology.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <p>{`–  Follow the ARIA keyboard guidance for custom components`}</p>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="http://w3c.github.io/aria-practices/#aria_ex" target="_blank" rel="noopener noreferrer">Design patterns and widgets</a>, ARIA authoring practices
      * <a href="http://w3c.github.io/aria-practices/#keyboard" target="_blank" rel="noopener noreferrer">Developing a keyboard interface</a>, ARIA authoring practices
      * <a href="https://webaim.org/techniques/keyboard/#testing" target="_blank" rel="noopener noreferrer">Standard keystrokes</a>, WebAIM
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='webaim_6' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_1_1")} target="_blank" rel="noopener noreferrer">2.1.1 Keyboard</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_1_2")} target="_blank" rel="noopener noreferrer">2.1.2 No Keyboard Trap</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Keyboard interaction](/toolkit/design/ux/#keyboard-interaction), this page
      * [Tab order](/toolkit/design/ux/#tab-order), this page
      * [Pointer operation](/toolkit/design/ux/#pointer-operation), this page
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_tab_order' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_pointer_operation' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      