import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Any current value(s), and any set or range of allowable values associated with an object,
shall be programmatically determinable.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`In addition to knowing the `}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`state`}</inlineCode>{`, `}<inlineCode parentName="p">{`boundary`}</inlineCode>{`, `}<inlineCode parentName="p">{`properties`}</inlineCode>{`,
and `}<inlineCode parentName="p">{`description`}</inlineCode>{` of an object as covered in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`, it is important for the assistive technology (AT) user to understand the `}<em parentName="p">{`current`}</em>{` value and `}<em parentName="p">{`range`}</em>{` of values available for input controls.
For example, for a blind or sight impaired user to be able to interact with a spinner widget,
it is important for the user to know the `}<em parentName="p">{`current`}</em>{` `}<inlineCode parentName="p">{`value`}</inlineCode>{`,
as well as the allowed `}<em parentName="p">{`minimum`}</em>{` to `}<em parentName="p">{`maximum`}</em>{` values.
In order to communicate these values to the user,
they must be programmatically obtainable by the AT.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.4" target="_blank">{`502.3.4 Values`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Resources`}</h4>
    <ul>
      <li parentName="ul">{`See resources listed in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      