import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from 'gatsby';
import ToolkitRequirement from '../../../../components/rms_resources/requirements';
import ToolkitReference from '../../../../components/rms_resources/references';
import ToolkitTechnique from '../../../../components/rms_resources/techniques';
import ToolkitTopic from '../../../../components/rms_resources/topics';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Images have meaningful and appropriate ALT text`}</li>
      <li parentName="ul">{`Form field labels are clear and properly associated with the fields`}</li>
      <li parentName="ul">{`The label text matches the output from the screen reader`}</li>
      <li parentName="ul">{`Required form fields are announced`}</li>
      <li parentName="ul">{`Headings are descriptive and at an appropriate hierarchy`}</li>
      <li parentName="ul">{`Link names are descriptive`}</li>
      <li parentName="ul">{`Foreign phrases and pages are indicated or pronounced properly by the screen reader`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`Testing alternative text, labels, headings, and foreign phrases with a screen reader is an effective means of verifying an equivalent experience. Testers should navigate to each image or bring up a list of images in the content and confirm that meaningful images have appropriate ALT text, are not redundant with the role of the control, and are not redundant with caption text. Navigate through each form field or bring up a list of form fields to confirm the labels are clear and properly associated with the fields. Test that the form information announced by the screen reader (the input’s “name”) matches or contains the text that appears in the label. Check that all the link names are descriptive.`}</p>
    <p>{`Confirm all headings are properly properly spoken or bring up a list of headings and confirm they match all the headings in the content and are nested. Navigate to any phrases that are in a language that is different from the default language and confirm the screen reader either pronounces the language properly or indicates the presence of another language.`}</p>
    <h5 className="accordionH5">Completes testing for:</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req1_1_1" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req1_4_5" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req2_4_6" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req2_5_3" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req3_1_1" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req3_1_2" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req3_3_2" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      