import { withPrefix } from "gatsby";
import Default from "../../../../src/gatsby-theme-carbon/templates/Default";
import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion } from "carbon-components-react";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "../../../../src/images/cross.png";
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import ArticleCardImg1 from "../../../../src/images/ToolkitLandingImg1.png";
import ArticleCardImg2 from "../../../../src/images/ToolkitLandingImg2.png";
import ArticleCardImg3 from "../../../../src/images/ToolkitLandingImg3.png";
import muffin from "../../../../src/images/muffin.svg";
import { TkFooter } from "../../../../src/components/toolkit/TkFooter";
import LaunchIcon from "../../../../src/images/spaceship.svg";
import VerifyIcon from "../../../../src/images/touch--screen.svg";
import DevelopIcon from "../../../../src/images/build.svg";
import DesignIcon from "../../../../src/images/expand--vert.svg";
import PlanIcon from "../../../../src/images/calendar.svg";
import PaceOfCompletion from "../../../../src/content/PaceOfCompletion";
import "../../../../src/pages/toolkit/toolkit-overviews.scss";
import * as React from 'react';
export default {
  withPrefix,
  Default,
  TkAccordionItem,
  Accordion,
  Row,
  Column,
  img1,
  ArrowRight16,
  ArticleCardImg1,
  ArticleCardImg2,
  ArticleCardImg3,
  muffin,
  TkFooter,
  LaunchIcon,
  VerifyIcon,
  DevelopIcon,
  DesignIcon,
  PlanIcon,
  PaceOfCompletion,
  React
};