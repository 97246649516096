import React, { Component } from "react";
var toolkitTopicsData = require('../../data/toolkitTopics.json')
var jsonQuery = require('json-query')

class TopicLink extends Component {

    render() {
            return (
                <>
                {getToolkitTopic(this.props.qid, this.props.source, this.props.name)}
                </>
            )    
        }
    }
    export default TopicLink
    
    function getToolkitTopic(qid, source, name) {        
        var result = jsonQuery(qid, {
            data: toolkitTopicsData
        })
    
        var retValue
        if(result.value){ 
            
            var uri = result.value.Target

            // console.info("process.env.NODE_ENV",process.env.NODE_ENV)
            if(process.env.NODE_ENV === "production"){
                uri = "/able" + uri
            }
        
            var displayName;
            if(name !== "" && name !== null && name !== undefined){ displayName = name } // Override Name in RMS with user specified name
            else { displayName = result.value.Title } // Default name from RMS
                
            if(source !== "hide" && source !== null && source !== undefined){ // User specified source
                retValue = <>
                <a href={uri} class="bx--link" target="_blank" style={{ paddingRight: "0px", fontSize: "16px",  textDecoration: "underline" }} rel="noopener noreferrer">{displayName}</a>, {source}
                </>
            }
            else{ // RMS return is default
                retValue = <>
                <a href={uri} class="bx--link" target="_blank" style={{ paddingRight: "0px", fontSize: "16px",  textDecoration: "underline" }} rel="noopener noreferrer">{displayName}</a>
                </>
            }
        }       
        else{
            retValue = <></>
        }
    
        return retValue
    
    }    




