export const reqData = [
  {
    "requirement": "1.1.1 Non-text Content",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.2.1 Audio-only and Video-only (Prerecorded)",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.2.2 Captions (Prerecorded)",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": false,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.2.3 Audio Description or Media Alternative (Prerecorded)",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.2.4 Captions (Live)",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": false,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.2.5 Audio Description (Prerecorded)",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.3.1 Info and Relationships",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.3.2 Meaningful Sequence",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.3.3 Sensory Characteristics",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.3.4 Orientation",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.3.5 Identify Input Purpose",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.4.1 Use of Color",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.4.2 Audio Control",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.4.3 Contrast (Minimum)",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.4.4 Resize Text",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.4.5 Images of Text",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.4.10 Reflow",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": false,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.4.11 Non-text Contrast",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.4.12 Text Spacing",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "1.4.13 Content on Hover or Focus",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.1.1 Keyboard",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.1.2 No Keyboard Trap",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.1.4 Character Key Shortcuts",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.2.1 Timing Adjustable",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.2.2 Pause, Stop, Hide",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.3.1 Three Flashes or Below Threshold",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": false,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.4.1 Bypass Blocks",
    "web": true,
    "software": false,
    "doc_web": true,
    "doc_nonweb": false,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.4.2 Page Titled",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.4.3 Focus Order",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": false,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.4.4 Link Purpose (In Context)",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.4.5 Multiple Ways",
    "web": true,
    "software": false,
    "doc_web": true,
    "doc_nonweb": false,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.4.6 Headings and Labels",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": false,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.4.7 Focus Visible",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.4.11 Focus Not Obscured (Minimum) *WCAG 2.2",
    "web": true,
    "software": false,
    "doc_web": true,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": true,
    "us_508": false,
    "en_301_549": false,
    "v7_1": false,
    "v7_2": false,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.5.1 Pointer Gestures",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.5.2 Pointer Cancellation",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.5.3 Label in Name",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.5.4 Motion Actuation",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.5.7 Dragging Movements *WCAG 2.2",
    "web": true,
    "software": false,
    "doc_web": true,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": true,
    "us_508": false,
    "en_301_549": false,
    "v7_1": false,
    "v7_2": false,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "2.5.8 Target Size (Minimum) *WCAG 2.2",
    "web": true,
    "software": false,
    "doc_web": true,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": true,
    "us_508": false,
    "en_301_549": false,
    "v7_1": false,
    "v7_2": false,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.1.1 Language of Page",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": false,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.1.2 Language of Parts",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.2.1 On Focus",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.2.2 On Input",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.2.3 Consistent Navigation",
    "web": true,
    "software": false,
    "doc_web": true,
    "doc_nonweb": false,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.2.4 Consistent Identification",
    "web": true,
    "software": false,
    "doc_web": true,
    "doc_nonweb": false,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": false,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.2.6 Consistent Help *WCAG 2.2",
    "web": true,
    "software": false,
    "doc_web": true,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": true,
    "us_508": false,
    "en_301_549": false,
    "v7_1": false,
    "v7_2": false,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.3.1 Error Identification",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.3.2 Labels or Instructions",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.3.3 Error Suggestion",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": false,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.3.4 Error Prevention (Legal, Financial, Data)",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": false,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.3.7 Redundant Entry *WCAG 2.2",
    "web": true,
    "software": false,
    "doc_web": true,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": true,
    "us_508": false,
    "en_301_549": false,
    "v7_1": false,
    "v7_2": false,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "3.3.8 Accessible Authentication (Minimum) *WCAG 2.2",
    "web": true,
    "software": false,
    "doc_web": true,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": true,
    "us_508": false,
    "en_301_549": false,
    "v7_1": false,
    "v7_2": false,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "4.1.1 Parsing (WCAG 2.0 & 2.1 only)",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": false,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "4.1.2 Name, Role, Value",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": true,
    "level2": true,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "4.1.3 Status Messages",
    "web": true,
    "software": true,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": true,
    "wcag": true,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": true,
    "level3": false,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.2.1 User Control of Accessibility Features",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.2.2 No Disruption of Accessibility Features",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.1 Object Information",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.2 Modification of Object Information",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.3 Row, Column, and Headers",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.4 Values",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.5 Modification of Values",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.6 Label Relationships",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.7 Hierarchical Relationships",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.8 Text",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.9 Modification of Text",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.10 List of Actions",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.11 Actions on Objects",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.12 Focus Cursor",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.13 Modification of Focus Cursor",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.3.14 Event Notification",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "502.4 Platform Accessibility Features",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": false,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "503.2 User Preferences",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "503.3 Alternative User Interfaces",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "503.4.1 Caption Controls",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": false,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "503.4.2 Audio Description Controls",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": false,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "504.2 Content Creation or Editing",
    "web": false,
    "software": false,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": true,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "504.2.1 Preservation of Information Provided for Accessibility in Format Conversion",
    "web": false,
    "software": false,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": true,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "504.2.2 PDF Export",
    "web": false,
    "software": false,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": true,
    "wcag": false,
    "us_508": true,
    "en_301_549": false,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "504.3 Prompts",
    "web": false,
    "software": false,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": true,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "504.4 Templates",
    "web": false,
    "software": false,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": true,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "602.2 Accessibility and Compatibility Features",
    "web": false,
    "software": false,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "602.3 Electronic Support Documentation",
    "web": false,
    "software": false,
    "doc_web": true,
    "doc_nonweb": true,
    "authoring": false,
    "wcag": false,
    "us_508": true,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "5.2 Activation of Accessibility Features",
    "web": true,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": false,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "5.7 Key Repeat",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": false,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "5.8 Double-strike Key Acceptance",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": false,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "5.9 Simultaneous User Actions",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": false,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "7.1.1 Captioning Playback",
    "web": true,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "7.1.2 Captioning Synchronization",
    "web": true,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "7.1.3 Preservation of Captioning",
    "web": true,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "7.1.4 Captions Characteristics",
    "web": true,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": false,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "7.1.5 Spoken Subtitles",
    "web": true,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": false,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "7.2.1 Audio Description Playback",
    "web": true,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "7.2.2 Audio Description Synchronization",
    "web": true,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "7.2.3 Preservation of Audio Description",
    "web": true,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "7.3 User Controls for Captions and Audio Description",
    "web": true,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "10.5 Caption Positioning",
    "web": false,
    "software": false,
    "doc_web": false,
    "doc_nonweb": true,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "10.6 Audio Description Timing",
    "web": false,
    "software": false,
    "doc_web": false,
    "doc_nonweb": true,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "11.5.2.3 Use of Accessibility Services",
    "web": false,
    "software": true,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": false,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  },
  {
    "requirement": "11.8.4 Repair Assistance",
    "web": false,
    "software": false,
    "doc_web": false,
    "doc_nonweb": false,
    "authoring": true,
    "wcag": false,
    "us_508": false,
    "en_301_549": true,
    "v7_1": true,
    "v7_2": true,
    "v7_3": true,
    "level1": false,
    "level2": false,
    "level3": true,
    "open": false,
    "show": true
  }
];
