import { withPrefix } from "gatsby";
import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion } from "carbon-components-react";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import "../../../../src/pages/toolkit/topics.scss";
import "../../../../src/pages/toolkit/toolkit-overviews.scss";
import "../../../../src/pages/toolkit/TKStructuredList.scss";
import DetailDecorative from "../../../../src/pages/toolkit/develop/text-and-non-text/level1/decorative-images.mdx";
import DetailTechnology from "../../../../src/pages/toolkit/develop/text-and-non-text/level1/technology-specific.mdx";
import DetailText from "../../../../src/pages/toolkit/develop/text-and-non-text/level1/relevant-text.mdx";
import DetailA11yMethod from "../../../../src/pages/toolkit/develop/text-and-non-text/level1/accessible-method.mdx";
import DetailMediaCaptions from "../../../../src/pages/toolkit/develop/text-and-non-text/level1/media-captions.mdx";
import DetailHeading from "../../../../src/pages/toolkit/develop/text-and-non-text/level1/headings.mdx";
import DetailTitle from "../../../../src/pages/toolkit/develop/text-and-non-text/level1/title.mdx";
import DetailLanguage from "../../../../src/pages/toolkit/develop/text-and-non-text/level1/language.mdx";
import DetailFigures from "../../../../src/pages/toolkit/develop/text-and-non-text/level2/figures-and-tables.mdx";
import DetailLabel from "../../../../src/pages/toolkit/develop/text-and-non-text/level2/aria-labelling.mdx";
import DetailRoles from "../../../../src/pages/toolkit/develop/text-and-non-text/level2/aria-roles.mdx";
import DetailLangAttribute from "../../../../src/pages/toolkit/develop/text-and-non-text/level3/lang-attribute.mdx";
import DetailAbbr from "../../../../src/pages/toolkit/develop/text-and-non-text/level3/expand-abbreviations.mdx";
import DetailPosition from "../../../../src/pages/toolkit/develop/text-and-non-text/level3/text-position.mdx";
import DetailDOM from "../../../../src/pages/toolkit/develop/text-and-non-text/level3/dom.mdx";
import Level1Icon from "../../../../src/visuals/plan/level_1_icon.svg";
import Level2Icon from "../../../../src/visuals/plan/level_2_icon.svg";
import Level3Icon from "../../../../src/visuals/plan/level_3_icon.svg";
import ExpandCollapseAccordion from "../../../../src/components/expand_collapse_accordion/expand_collapse_accordion.js";
import * as React from 'react';
export default {
  withPrefix,
  TkAccordionItem,
  Accordion,
  Row,
  Column,
  DetailDecorative,
  DetailTechnology,
  DetailText,
  DetailA11yMethod,
  DetailMediaCaptions,
  DetailHeading,
  DetailTitle,
  DetailLanguage,
  DetailFigures,
  DetailLabel,
  DetailRoles,
  DetailLangAttribute,
  DetailAbbr,
  DetailPosition,
  DetailDOM,
  Level1Icon,
  Level2Icon,
  Level3Icon,
  ExpandCollapseAccordion,
  React
};