import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../images/tooltip.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Hover content often blocks important information in the background, but must remain available for the user to read instead of disappearing after a set period of time. Users need the ability to dismiss hover content without moving the pointer or keyboard focus, and the Escape key is a well-established method of dismissing dynamic overlay content.`}</p>
    <p>{`Ensure that the Escape key dismisses any new content revealed on hover or keyboard focus, including tooltips or dialogs. Intercept the Escape key press and ensure that Escape doesn’t also get executed on an element in the underlying layer.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR39" target="_blank" rel="noopener noreferrer">SCR39: Making content on focus or hover hoverable, dismissible, and persistent</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='scr39' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_13")} target="_blank" rel="noopener noreferrer">1.4.13 Content on Hover or Focus</a>, IBM accessibility requirements
      * <a href="https://www.carbondesignsystem.com/components/tooltip/code/#definition-tooltip" target="_blank" rel="noopener noreferrer">Tooltip</a>, Carbon design system
      * <a href="https://www.carbondesignsystem.com/components/tooltip/code#icon-tooltip" target="_blank" rel="noopener noreferrer">Icon tooltip</a>, Carbon design system
      * <a href="https://react.carbondesignsystem.com/?path=/story/tooltipdefinition--default" target="_blank" rel="noopener noreferrer">Definition tooltip</a>, Carbon design system
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_13' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_5' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_14' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      