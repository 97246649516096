import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Having different ways of navigating and searching digital platforms ensures that users can easily find what they’re looking for.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Consider links and other mechanisms to bypass blocks of content`}</li>
      <li parentName="ul">{`Provide a search function or other features to improve navigation and findability`}</li>
    </ul>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G1" target="_blank" rel="noopener noreferrer">G1: Adding a link at the top of each page that goes directly to the main content area</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G123" target="_blank" rel="noopener noreferrer">G123: Adding a link at the beginning of a block of repeated content to go to the end of the block</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G124" target="_blank" rel="noopener noreferrer">G124: Adding links at the top of the page to each area of the content</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G125" target="_blank" rel="noopener noreferrer">G125: Providing links to navigate to related Web pages</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G161" target="_blank" rel="noopener noreferrer">G161: Providing a search function to help users find content</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G185" target="_blank" rel="noopener noreferrer">G185: Linking to all of the pages on the site from the home page</a>, WCAG 2.1 technique
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g1' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g123' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g124' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g125' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g126' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g161' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g185' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_4_1")} target="_blank" rel="noopener noreferrer">2.4.1 Bypass Blocks</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_4_5")} target="_blank" rel="noopener noreferrer">2.4.5 Multiple Ways</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_5' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      