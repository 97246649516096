import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`If an object is in a data table, the occupied rows and columns,
and any headers associated with those rows or columns, shall be programmatically determinable.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`Table organization and relationship information displayed visually must also be available programmatically so that the information is accessible to Assistive Technology (AT) users.
Someone who is blind or vision impaired uses a computer with the aid of AT such as a screen reader,
screen magnifier, or Braille display.
Sighted users take many cues from the visible table and can easily see whether there are row or column headers and can read the data in a single cell, row, or column and associate it with the correct header information.
Screen readers must have this information programmatically identified so that the intended relationships and organization shown in the presentation of the table can be conveyed to the user who cannot see it.
As an example,
a table header must be coded as a header; it is not sufficient to simply place the header information in the first row or first column and give it a special visual treatment such as bold text or a colored background.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.3" target="_blank">{`502.3.3 Row, Column, and Headers`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Resources`}</h4>
    <ul>
      <li parentName="ul">{`See resources listed in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      