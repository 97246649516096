import React from 'react'
import EventContent from "../../content/home/needHelp.mdx"
import "./homePanel.scss"; 

export default () => {
    return (<React.Fragment>
        <div>
            <div className="homePanel">
                <div>
                    <div className="bx--row">
                        <div className="bx--col-lg-4 bx--col-md-12 bx--col-sm-8">
                            <h2>Need Help?</h2>
                        </div>
                    </div>
                    <EventContent />
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}

