import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import slugify from 'slugify';
import cx from 'classnames';

import {
    tabsContainer,
    list,
    selectedItem,
    listItem,
    link,
} from './PageTabsCL.module.scss';
import './PageTabsCL.scss'

export default class PageTabsCL extends React.Component {
    static propTypes = {
        tabs: PropTypes.array,
        slug: PropTypes.string,
    };

    render() {
        const { tabs, slug } = this.props;
        const currentTab = slug
            .split('/')
            .filter(Boolean)
            .slice(-1)[0];

        const baseDirectory = "requirements"

        const pageTabs = tabs.map(tab => {
            const slugifiedTab = slugify(tab, { lower: true });
            const selected = slugifiedTab === currentTab;
            const href = "/"+baseDirectory+"/"+slugifiedTab;
            return (
                <li key={tab} className={cx({ [selectedItem]: selected }, listItem)}>
                    <Link className={link} to={`${href}`}>
                        {tab}
                    </Link>
                </li>
            );
        });

        return (
            <div className={tabsContainer}>
                <div style={{ maxWidth: "99rem" }}>
                    <div className="bx--grid headerLeftPT">
                        <div className="bx--row">
                            <div class="bx--col-lg-3 bx--col-sm-4"></div>

                            <div class="bx--col-lg-6 bx--col-sm-4" style={{paddingLeft:0}}>
                                <nav aria-label={baseDirectory}>
                                    <ul className={list}>{pageTabs}</ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
