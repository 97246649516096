import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../images/error1.png";
import img11 from "../../../../../visuals/develop/coding_interactions_dynamic_2_dont.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`The design should provide insight into `}<ToolkitTopic qid='ux_feedback_determine_whether' name="how and when to surface errors" mdxType="ToolkitTopic"></ToolkitTopic>{` to the user. The method of informing the user of errors can range from immediate interruption of the user’s workflow to something that politely informs but lets the user attend to the issue in their own time. The accessible implementation depends upon the notification method chosen in the design as well as thoughtful attention to the impact of interruptions on the user. Interrupts can be especially detrimental to persons with disabilities.`}</p>
    <p>{`List of errors:`}</p>
    <ul>
      <li parentName="ul">{`If the page is not reloaded, but has a dynamically updated list of errors, add `}<inlineCode parentName="li">{`aria-live=”alert”`}</inlineCode>{` or `}<inlineCode parentName="li">{`aria-live=”polite”`}</inlineCode>{` on the container for the messages. The `}<inlineCode parentName="li">{`alert`}</inlineCode>{` setting causes a screen reader to immediately announce the updated content, whereas `}<inlineCode parentName="li">{`polite`}</inlineCode>{` announces when the user has paused typing.`}</li>
      <li parentName="ul">{`On pages that reload, `}<inlineCode parentName="li">{`aria-live`}</inlineCode>{` does not work to surface new content, since it only detects content changes that occur after reloading completes.`}</li>
    </ul>
    <p>{`Error dialogs:`}</p>
    <ul>
      <li parentName="ul">{`When the design calls for the user to be interrupted for an important error, an alert dialog may be the right choice.`}</li>
    </ul>
    <p>{`Inline errors:`}</p>
    <p>{`UX designers should provide guidance on `}<ToolkitTopic qid='ux_feedback_carry_out' name="carrying out validation in an accessible way" mdxType="ToolkitTopic"></ToolkitTopic>{`:`}</p>
    <ul>
      <li parentName="ul">{`Avoid surfacing errors before the user has provided input or as the use types.`}</li>
      <li parentName="ul">{`Validate the field when the user tabs away from it.`}</li>
      <li parentName="ul">{`Avoid immediately moving keyboard focus back to the field if there’s an error.`}</li>
    </ul>
    <p>{`As a developer, be familiar with proper use of roles:`}</p>
    <ul>
      <li parentName="ul">{`If an error is important enough to interrupt the user, give it an ARIA `}<inlineCode parentName="li">{`role=“alert”`}</inlineCode>{` or use the live region `}<inlineCode parentName="li">{`aria-live=”assertive”`}</inlineCode>{` These work the same with screen reader technology by immediately interrupting the user.`}</li>
      <li parentName="ul">{`If an error does not require immediate interruption, use `}<inlineCode parentName="li">{`aria-live=”polite”`}</inlineCode>{`.`}</li>
    </ul>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA18" target="_blank" rel="noopener noreferrer">ARIA18: Using aria-alertdialog to identify errors</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA19" target="_blank" rel="noopener noreferrer">ARIA19: Using aria role=”alert” or live regions to identify errors</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR18" target="_blank" rel="noopener noreferrer">SCR18: Providing client-side validation and alert</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR32" target="_blank" rel="noopener noreferrer">SCR32: Providing client-side validation and adding error text via the DOM</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria18' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria19' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr18' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr32' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#3_3_1")} target="_blank" rel="noopener noreferrer">3.3.1 Error Identification</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#3_3_3")} target="_blank" rel="noopener noreferrer">3.3.3 Error Suggestion</a>, IBM accessibility requirements
      * <a href="https://www.w3.org/WAI/tutorials/forms/notifications/" target="_blank" rel="noopener noreferrer">User notifications</a>, W3C web accessibility tutorials
      * <a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions" target="_blank" rel="noopener noreferrer">ARIA live regions</a>, Mozilla Developer Network
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_23' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='mozilla_developer_network_5' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      