import { withPrefix } from "gatsby";
import Default from "../../../../src/gatsby-theme-carbon/templates/Default";
import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion } from "carbon-components-react";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "../../../../src/images/cross.png";
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import ArticleCardImg1 from "../../../../src/images/ToolkitLandingImg1.png";
import ArticleCardImg2 from "../../../../src/images/ToolkitLandingImg2.png";
import ArticleCardImg3 from "../../../../src/images/ToolkitLandingImg3.png";
import muffin from "../../../../src/images/muffin.svg";
import model from "../../../../src/images/model.svg";
import figma from "../../../../src/images/figma.svg";
import keyboard from "../../../../src/visuals/design/keyboard_3_1.svg";
import shadow from "../../../../src/visuals/design/visual_design_circle.svg";
import texteq from "../../../../src/visuals/design/content_text_equivalents.svg";
import revlist from "../../../../src/visuals/design/review_list.svg";
import { TkFooter } from "../../../../src/components/toolkit/TkFooter";
import PaceOfCompletion from "../../../../src/content/PaceOfCompletion";
import { Video } from 'gatsby-theme-carbon/src/components/Video';
import "../../../../src/pages/toolkit/toolkit-overviews.scss";
import "../../../../src/pages/toolkit/TKResource-card-group.scss";
import * as React from 'react';
export default {
  withPrefix,
  Default,
  TkAccordionItem,
  Accordion,
  Row,
  Column,
  img1,
  ArrowRight16,
  ArticleCardImg1,
  ArticleCardImg2,
  ArticleCardImg3,
  muffin,
  model,
  figma,
  keyboard,
  shadow,
  texteq,
  revlist,
  TkFooter,
  PaceOfCompletion,
  Video,
  React
};