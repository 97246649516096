import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/ux_2_1_eg.png";
import img2 from "./../../../../../visuals/design/ux_2_2_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Standard HTML elements have default keyboard behaviors, however complex or custom components will need annotations on intended keyboard behavior for implementation. Another option is to surface keyboard instructions directly in the UI.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Annotate intended keyboard interaction for modified or custom components`}</li>
      <li parentName="ul">{`Provide keyboard instructions in the UI for unfamiliar keyboard operations`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="accordion with annotations 'Enter or Space bar expands or collapses accordion'">
        </img>
        <Caption fullWidth mdxType="Caption">
Annotate intended keyboard interaction for components that aren't standard HTML elements.
        </Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img2} alt="annotations show keyboard navigation and tab stops in the content">
        </img>
        <Caption fullWidth mdxType="Caption">
Provide keyboard instructions in the UI for components where keyboard operation is less familiar. For this drag and drop component, the instructions appear on keyboard focus.
        </Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.bbc.co.uk/gel/guidelines/how-to-design-for-accessibility#annotating-wireframes-and-ux-designs" target="_blank" rel="noopener noreferrer">Annotating wireframes and UX designs</a>, BBC
      * <a href="https://balsamiq.com/learn/articles/wireframe-annotations/" target="_blank" rel="noopener noreferrer">What are wireframe annotations and why use them?</a>, Balsamiq wireframing academy
      * <a href="",
      "URI": "https://w3c.github.io/aria-practices/#aria_ex" target="_blank" rel="noopener noreferrer">Design patterns and widgets</a>, ARIA authoring practices
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='bbc_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='balsamiq_wireframing_academy_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_1_1")} target="_blank" rel="noopener noreferrer">2.1.1 Keyboard</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Tab order](/toolkit/design/ux/#tab-order), this page
      * [Data tables](/toolkit/design/visual/#data-tables), this page
      * [Custom component](/toolkit/design/ux/#custom-component), this page
      * [Custom component](/toolkit/design/ux/#mouse-operable), this page
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_tab_order' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='visual_data_tables' mdxType="ToolkitTopic"></ToolkitTopic>, Visual design</li>
      <li parentName="ul"><ToolkitTopic qid='ux_custom_component' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_mouse_operable' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      