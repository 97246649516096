import React, { Component } from "react";
var referencesData = require('../../data/references.json')
var techniquesData = require('../../data/techniques.json')
var requirementsData = require('../../data/requirements.json')
var jsonQuery = require('json-query')

const REQUIREMENTS_PATH = "/requirements/requirements/?version=7_1";

class References extends Component {

    render() {
        return (
            <>
                {getLinkObject(this.props.qid, this.props.source, this.props.name)}
            </>
        )
    }
}
export default References


function getLinkObject(qid, source, name) {

    var result
    var retValue

    result = jsonQuery(qid, {
        data: referencesData
    })

    if(result.value){

        var displayName;
        if(name !== "" && name !== null && name !== undefined){ displayName = name } // Override Name in RMS with user specified name
        else { displayName = result.value.Name } // Default name from RMS


        if(source === "hide"){ // Override Source in RMS, and don't display source
            retValue = <>
            <a href={result.value.URI} class="bx--link" target="_blank" style={{ paddingRight: "0px", fontSize: "16px",  textDecoration: "underline" }} rel="noopener noreferrer">{displayName}</a>
            </>            
        }
        else if(source !== "hide" && source !== null && source !== undefined){ // // Override Source in RMS with user specified source
            retValue = <>
            <a href={result.value.URI} class="bx--link" target="_blank" style={{ paddingRight: "0px", fontSize: "16px",  textDecoration: "underline" }} rel="noopener noreferrer">{displayName}</a>, {source}
            </>
        }
        else{ // Default source from RMS
            retValue = <>
            <a href={result.value.URI} class="bx--link" target="_blank" style={{ paddingRight: "0px", fontSize: "16px",  textDecoration: "underline" }} rel="noopener noreferrer">{displayName}</a>, {result.value.Source}
            </>                
        }
    }
    else{
        retValue = <>
        </>                
    }

    return retValue

}