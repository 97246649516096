import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`There are several situations where ARIA can help provide context for non-text information. Use the `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` attribute to distinguish between images in a group, or to incorporate a label from text on the page. Where `}<ToolkitTopic qid='content_equivalents_provide_succinct' name="content designers have provided detailed information" mdxType="ToolkitTopic"></ToolkitTopic>{` about a complex image as part of the page content, use the `}<inlineCode parentName="p">{`aria-describedby`}</inlineCode>{` attribute as an easy way to associate it.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA9" target="_blank" rel="noopener noreferrer">ARIA9: Using aria-labelledby to concatenate a label from several text nodes</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA15" target="_blank" rel="noopener noreferrer">ARIA15: Using aria-describedby to provide descriptions of images</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria9' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria15' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_1_1")} target="_blank" rel="noopener noreferrer">1.1.1 Non-text Content</a>, IBM accessibility requirements
      * <a href="https://w3c.github.io/alt-techniques/#scenarios" target="_blank" rel="noopener noreferrer">Common text alternative techniques</a>, HTML5: Techniques for providing useful text alternatives, W3C editor’s draft
      * <a href="https://www.w3.org/WAI/tutorials/images/complex/#description-containing-textual-information" target="_blank" rel="noopener noreferrer">Description containing textual information</a>, W3C web accessibility tutorials
      * <a href="https://www.w3.org/WAI/tutorials/images/groups/#a-collection-of-images" target="_blank" rel="noopener noreferrer">A collection of images</a>, W3C web accessibility tutorials
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='w3c_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_3' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_7' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      