import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/L2_visual_2_1_eg.png";
import img2 from "./../../../../../visuals/design/L2_visual_2_2_eg.png";
import img3 from "./../../../../../visuals/design/L2_visual_2_3_eg.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users who have low vision or colorblindness need additional visual cues that don’t rely solely on color to distinguish the data points on a complex graph. Consider additional cues to link data points to their corresponding legend text.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Don’t rely on non-contrasting colors alone to match data points to legend text`}</li>
      <li parentName="ul">{`Don’t rely on color alone to indicate status or errors`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="A line graph showing apple, banana, and strawberry production in the US, where the lines are the same color but distinguishable as solid, dashed, and dotted."></img>
        <Caption fullWidth mdxType="Caption">You can use pattern or shape variations when colors do not sufficiently contrast.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img2} alt='Apples is selected in the legend, causing other data to go grey and become non-interactive. A tooltip on the graph says "November, 12 million apples"'></img>
        <Caption fullWidth mdxType="Caption">Consider interaction to help distinguish data points. Here the legend allows filtering; as well, users can reveal chart data points.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img3} alt="'Server is online' notification with green check mark icon and 'Server is offline' notification with red error icon"></img>
        <Caption fullWidth mdxType="Caption">In addition to the use of green and red, icons are used to distinguish status.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G14" target="_blank" rel="noopener noreferrer">: Ensuring that information conveyed by color differences is also available in text</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G111" target="_blank" rel="noopener noreferrer">G111: Using color and pattern</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G182" target="_blank" rel="noopener noreferrer">G182: Ensuring that additional visual cues are available when text color differences are used to convey information</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G183" target="_blank" rel="noopener noreferrer">G183: Using a contrast ratio of 3:1 with surrounding text and providing additional visual cues on focus for links or controls where color alone is used to identify them</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G205" target="_blank" rel="noopener noreferrer">G205: Including a text cue for colored form control labels</a>, WCAG 2.1 technique
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g14' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g111' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g182' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g183' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g205' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_1")} target="_blank" rel="noopener noreferrer">1.4.1 Use of Color</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Component contrast](/toolkit/design/visual/#component-contrast), this page
      * [Visual states](/toolkit/design/visual/#visual-states), this page
      * [Data visualization](/toolkit/design/visual/#data-visualization), this page
      * [Surfacing error messages](/toolkit/design/visual/#error-messages), this page
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='visual_components_contrast' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='visual_visual_states' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='visual_data_visualization' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_error_messages' mdxType="ToolkitTopic"></ToolkitTopic>, UX design</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      