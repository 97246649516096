import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Content can be enlarged to 400% or equivalent`}</li>
      <li parentName="ul">{`Horizontal scrollbars do not appear`}</li>
      <li parentName="ul">{`No content or functionality is lost`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`The ability to simply resize text is no longer sufficient. Applications must now provide content that reflows to fit on a typical mobile device screen without horizontal scrolling. The same responsive design that achieves this reflow of content to a small screen also allows users with low vision to magnify the content to 400% on a desktop screen without the need to scroll horizontally.`}</p>
    <p>{`During reflow, content can be repositioned (to menus, for instance); however, it must still be available.`}</p>
    <h5 className="accordionH5">Completes testing for:</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_10' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      