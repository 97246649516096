import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from 'gatsby';
import { IconList, IconListRow, IconListIcon, IconListContent } from '../../../components/IconList';
import Checkmark32 from '../../../images/Checkmark32.svg';
import ToolkitRequirement from '../../../components/rms_resources/requirements';
import ToolkitReference from '../../../components/rms_resources/references';
import ToolkitTechnique from '../../../components/rms_resources/techniques';
import ToolkitTopic from '../../../components/rms_resources/topics';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`IBM has taken several steps to ease accessibility testing and improve automation. As developers, you can
manually run the `}<a parentName="p" {...{
        "href": "/able/toolkit/tools"
      }}>{`Accessibility Checker`}</a>{` for Chrome or Firefox from your browser or IDE. You can view issues by element or by accessibility requirement. You can also add accessibility into your continuous integration testing. Ensuring that checkers run without violations helps developers meet several requirements including `}<ToolkitRequirement qid='req1_3_1' source="hide" mdxType="ToolkitRequirement"></ToolkitRequirement>{` and `}<ToolkitRequirement qid='req4_1_2' source="hide" mdxType="ToolkitRequirement"></ToolkitRequirement>{`.`}</p>
    <IconList mdxType="IconList">
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
      Accessibility Checker passes without violations
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
      Resolve recommendations and issues which need further review
        </IconListContent>
    </IconListRow>
    </IconList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      