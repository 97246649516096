import React, { Component } from "react";
var IBMRequirementsData = require('../../data/requirements.json')
var jsonQuery = require('json-query')

class RequirementReferText extends Component {

    render() {
            return (
                <>
                {getRequirementReferText(this.props.reqid)}
                </>
            )    
        }
    }
    export default RequirementReferText
    
    function getRequirementReferText(reqid) {        
        var result = jsonQuery(reqid, {
            data: IBMRequirementsData
        })
    
        var retValue
        if(result.value){  // make sure result.value is not undefined/null
            
            var standarRequirementURI = result.value.StandardRequirementURI
            
            //
            // TODO: internatationalization in data model!
            //
            retValue = <>
            <p style={{ paddingRight: "0px", fontSize: "16px", lineHeight:"1.5" }}>
                Refer to <a href={standarRequirementURI} class="bx--link" target="_blank" style={{ paddingRight: "0px", fontSize: "16px", textDecoration: "underline" }} rel="noopener noreferrer">Understanding {result.value.Number} {result.value.Name} </a> 
                for more information on exceptions, intent, benefits, and techniques (external link to W3C).
            </p>
            </>
        }else{
            retValue = <></>
        }
    
        return retValue
    
    }    




