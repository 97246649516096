
import React, { Component } from 'react'
import "./eventBodyCard.scss"

class EventBodyCard extends Component {
    render() {
        return (
            <div className="bx--grid">
                <div id={this.props.eventName} class="bx--row" style={{ paddingBottom: "64px", paddingTop: "80px" }}>
                    <div class="bx--col-lg-3 bx--col-sm-4" style={{}}>
                        <img alt="" src={this.props.image} style={{ width: "380px", paddingTop: "1.5rem", paddingBottom: "2.5rem", paddingRight: "1rem" }} />
                    </div>
                    <div class="bx--col-lg-6 bx--col-sm-4">
                        <div role="heading" aria-level="3">
                            <div className="eventBodyCardTitle">
                                {this.props.eventName}
                            </div>
                            <div className="eventBodyCardDateAndLocation">
                                {this.props.eventDate} <br />
                                {this.props.eventLocation}
                            </div>
                        </div>
                        <div className="eventBodyCardBody">
                            {this.props.children}
                        </div>


                    </div>
                </div>
            </div>


        )

    }


}
export default EventBodyCard