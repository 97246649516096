import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Text can be resized without assistive technology up to 200 percent without loss of content or functionality.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures visually rendered text can be scaled successfully so more users are able to read the information without requiring the use of assistive technology such as a screen magnifier.
This increase in size can be achieved either by zooming all content OR by increasing just the text size.
In both cases, this resizing is normally achieved by the user agent (i.e., web browser or view controller).
The author’s responsibility is to create content that does not prevent the user agent from scaling the content effectively.`}</p>
    <p>{`To meet this requirement, confirm that scaling works and ensure there are no visual or functional issues as a result of the increase, such as:`}</p>
    <ul>
      <li parentName="ul">{`a lack of appropriate line wrapping`}</li>
      <li parentName="ul">{`truncated, overlapped or obscured text`}</li>
      <li parentName="ul">{`missing scrollbars when content is zoomed`}</li>
    </ul>
    <p>{`In cases where content fails to respond to the platform’s scaling features authors must provide a way of resizing text.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` For some interactive content, such as editable cells in a table, truncation is acceptable if the component’s full content is available on focus or after user activation, and an indication that this information can be accessed is provided to the user in some way besides the fact that it is truncated.`}</p>
    <p><strong parentName="p">{`Exception:`}</strong>{` Media captions and images of text are excluded from this requirement.
Resizing the closed captions is normally a feature of the player or platform,
not the author’s responsibility.
Images of text, which are covered under `}<a href={withPrefix("/requirements/requirements/#1_4_5")} target="_blank" rel="noopener noreferrer">{`1.4.5 Images of text`}</a>{`, do not normally scale well.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/resize-text.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.4.4 Resize Text`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      