import React, { Component } from 'react'
import FeatureCard from 'gatsby-theme-carbon/src/components/FeatureCard';
import './impactFeatureCard.scss'
import { withPrefix } from "gatsby"

import {
    Launch20
  } from '@carbon/icons-react';
  

class ImpactFeatureCard extends Component {
    render() {

            return (
 
                <div className="impactFeatureCard">

                <FeatureCard
                subTitle=""
                title={this.props.hrefText}
                actionIcon="launch"
                href={this.props.href}
                target="_blank"
                rel="noopener noreferrer"
                color="light"
                titleHeadingLevel={3}
                >
                    <img alt="" src={this.props.image} />
                </FeatureCard>

                </div>

            )

        }

    }
    export default ImpactFeatureCard
