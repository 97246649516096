import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`More than one way is available to locate an item in a set of items,
whether it is a Web page within a set of Web pages,
a document within a set of non-web documents,
or a program within a set of software programs
except where the item is the result of, or a step in, a process.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures users are able to locate information in a way that best suits their needs. With access to multiple ways of locating information, users may need one way over another.
For example, a user may need to use a document’s search function to locate specific information rather than scrolling through a table of contents.
Alternatively, a user who needs to understand the content’s structure will find a table of contents or site map more useful.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Consistent with `}<a href="https://www.w3.org/TR/wcag2ict/" target="_blank">{`Applying WCAG to Non-Web Information and Communications Technologies`}</a>{` (external link to WCAG2ICT),
an individual document or software program (not in a set) should mark this requirement N/A because it applies only to things that appear in a set.
Refer to the definitions of `}<a href="https://www.w3.org/TR/wcag2ict/#keyterms_set-of-documents" target="_blank">{`set of documents`}</a>{` and `}<a href="https://www.w3.org/TR/wcag2ict/#keyterms_set-of-software" target="_blank">{`set of software programs`}</a>{` to determine when a group of documents or software is considered a set.
These definitions require that every item in the set be independently reachable,
and not include a “`}<em parentName="p">{`step in a process`}</em>{`” which cannot be reached in any other way.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/multiple-ways" target="_blank">{`Understanding 2.4.5 Multiple Ways`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      