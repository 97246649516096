import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import { IconList, IconListRow, IconListIcon, IconListContent } from '../../../components/IconList';
import Checkmark32 from "../../../images/Checkmark32.svg";
import ToolkitRequirement from "../../../components/rms_resources/requirements";
import ToolkitReference from "../../../components/rms_resources/references";
import ToolkitTechnique from "../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Every component must be keyboard operable. As developers confirm the success of the keyboard operation (which should be indicated in the wireframes), they also confirm they never become stuck in a keyboard trap. Keys used for interaction are Escape, Space bar, Enter, Home, End, Page Up, Page Down, and the arrow keys. Complex interactions may also use modifier keys such as Shift, Ctrl, and Alt. IBM recommends the `}<ToolkitReference qid='aria_authoring_practices_2' name="ARIA authoring practices" source="hide" mdxType="ToolkitReference"></ToolkitReference>{` as a model for keyboard interaction. Developers can also use this interaction test to confirm that visual indicators for states (selected/unselected, expanded/collapsed) align with the UX design.`}</p>
    <p>{`Checks `}<ToolkitRequirement qid='req2_1_1' source="hide" mdxType="ToolkitRequirement"></ToolkitRequirement>{`  and `}<ToolkitRequirement qid='req2_1_2' source="hide" mdxType="ToolkitRequirement"></ToolkitRequirement>{`.`}</p>
    <IconList mdxType="IconList">
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
      You can perform all interactions for a widget with the keyboard
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
       You can traverse all items in forward and reverse order
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
      Components support standard keyboard interaction, or provide clear and accessible documentation for keyboard interactions
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
      Visual indicators of states exist
        </IconListContent>
    </IconListRow>
    </IconList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      