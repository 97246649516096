import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Any values associated with an object that can be set by the user shall be capable of being set programmatically,
including through assistive technology.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`In addition to knowing the `}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`state`}</inlineCode>{`, `}<inlineCode parentName="p">{`boundary`}</inlineCode>{`, `}<inlineCode parentName="p">{`properties`}</inlineCode>{`,
and `}<inlineCode parentName="p">{`description`}</inlineCode>{` of a user interface object (component or widget) as covered in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`,
it is important for the assistive technology (AT) user to understand the `}<em parentName="p">{`current`}</em>{` value
and be able to set those values through the use of the AT that interfaces programmatically
with the UI object.`}</p>
    <p>{`For example, for a blind or sight impaired user to be able to interact with a spinner widget,
it is important for the user to know the `}<em parentName="p">{`current`}</em>{` `}<inlineCode parentName="p">{`value`}</inlineCode>{`,
the allowed `}<em parentName="p">{`minimum`}</em>{` to `}<em parentName="p">{`maximum`}</em>{` values,
as well as to be able to select a desired value from a list.
In order to communicate these values to the user,
they must be programmatically obtainable by the AT.`}</p>
    <p>{`Any values that can be set by other non-AT users must be capable of being set by AT users through
the use of their AT which programmatically interfaces with the UI object (widget or component).`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.5" target="_blank">{`502.3.5 Modification of Values`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Resources`}</h4>
    <ul>
      <li parentName="ul">{`See resources listed in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      