import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/visual_1_1_do.png";
import img2 from "./../../../../../visuals/design/visual_1_1_dont.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users who are blind rely on keyboard and screen readers to navigate a page. One way to improve keyboard navigation is to designate regions on the page for implementation so that such users can navigate regions faster.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Designate appropriate regions, especially header, main and footer`}</li>
    </ul>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/tutorials/page-structure/regions/" target="_blank" rel="noopener noreferrer">Page regions</a>, W3C web accessibility tutorial
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_11' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_3_1")} target="_blank" rel="noopener noreferrer">1.3.1 Info and Relationships</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_4_1")} target="_blank" rel="noopener noreferrer">2.4.1 Bypass Blocks</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#4_1_2")} target="_blank" rel="noopener noreferrer">4.1.2 Name, Role Value</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req4_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      