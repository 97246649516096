import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Designers should `}<ToolkitTopic qid='content_input_labels' name="connect each label and input in the wireframes" mdxType="ToolkitTopic"></ToolkitTopic>{`. The developer’s job is to use appropriate techniques to make
that connection programmatically.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H44" target="_blank" rel="noopener noreferrer">H44: Using label elements to associate text labels with form controls</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA16.html" target="_blank" rel="noopener noreferrer">ARIA16: Using aria-labelledby to provide a name for user interface controls</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA17.html" target="_blank" rel="noopener noreferrer">ARIA17: Using grouping roles to identify related form controls</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H65" target="_blank" rel="noopener noreferrer">H65: Using the title attribute to identify form controls when the label element cannot be used</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H71" target="_blank" rel="noopener noreferrer">H71: Providing a description for groups of form controls using fieldset and legend elements</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H85" target="_blank" rel="noopener noreferrer">H85: Using OPTGROUP to group OPTION elements inside a SELECT</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA1" target="_blank" rel="noopener noreferrer">ARIA1: Using the aria-describedby property to provide a descriptive label for user interface controls</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='h44' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria16' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria17' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h65' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h71' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h85' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria1' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_3_1")} target="_blank" rel="noopener noreferrer">1.3.1 Info and Relationships</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#3_3_2")} target="_blank" rel="noopener noreferrer">3.3.2 Labels and Instructions</a>, IBM accessibility requirements
      * <a href="https://www.w3.org/WAI/tutorials/forms/labels/" target="_blank" rel="noopener noreferrer">Labeling controls</a>, W3C web accessibility tutorials
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_21' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      