import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Platform software shall provide user control over platform features that are defined in the platform documentation as accessibility features.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{`
This requirement applies to `}<em parentName="p">{`platform software`}</em>{` and is Not Applicable (N/A) for most application software.
Examples of platforms are: desktop operating systems, mobile and embedded operating systems,
Web browsers,
and software that supports macros or scripting.
See `}<a href="https://www.access-board.gov/ict/#E103-definitions" target="_blank">{`definitions in the US Revised 508 Standard`}</a>{`.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`When a feature is documented in a platform as an accessibility feature,
it should be consistently applied throughout the entire platform.
This allows people who have disabilities to assume the reliability of accessibility features.
For example, if an operating system allows changing the contrast,
once the user makes a contrast change,
it should be reflected in the entire operating system.
If a browser allows changing the text size, once a user makes a text size change,
this should be reflected in the entire browser.`}</p>
    <p>{`All accessibility features must function and be operable by the end user as documented.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.2.1" target="_blank">{`502.2.1 User Control of Accessibility Features`}</a>{` (external link to US 508) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      