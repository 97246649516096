import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Information previously entered by or provided to the user that is required to be entered again in the same process is either auto-populated or available for the user to select.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that a user does not need to input repetitive information during the same process. Users who have difficulty remembering or transcribing information should not be forced to do so unnecessarily. In most situations, information does not to be asked for twice, and where it is necessary, authors can provide several mechanisms, such as dropdowns and checkboxes, which allow the user to validate or choose previously entered information.`}</p>
    <p><strong parentName="p">{`Exceptions:`}</strong>{` A user can be asked to re-enter data if the information previously entered is no longer valid, if re-entering the information is required for security (such as re-entering a newly created password), or if it is in some other way essential that the user re-enter it, like in a memory game.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/redundant-entry.html" target="_blank">{`3.3.7 Redundant entry`}</a>{` (external link to WCAG 2.2) for more information on intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      