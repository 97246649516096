import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Users return to the same relative position, or point of regard, when they return to a previous screen or page`}</li>
      <li parentName="ul">{`Overlays or other dynamic content do not fully obscure a component receiving focus (`}<strong parentName="li">{`WCAG 2.2 only`}</strong>{`)`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`A “user’s point of regard” refers to the portion of an interface with which the user is interacting. When a dialog or new page replaces a user’s current focus, once that overlay is dismissed, the user should return to their prior focus to maintain the user’s point of regard. When an application returns the user to the prior page but at a different location, the user is said to have lost their point of regard. A common problem is if the user has been moved to the top of the page.`}</p>
    <p>{`Maintaining a user’s point of regard can dramatically improve the usability of an application, especially for users of assistive technology. It can improve efficiency, comprehension and predictability. Maintaining a user’s point of regard is especially critical when dismissing dialogue boxes and warning messages, or when moving a user backward through a step-by-step process, such as when they are correcting errors. A point of regard can refer to an element that has focus, a region of the content (page) that was in the viewport, or a set of data or search results with which a user is carrying out tasks.`}</p>
    <p>{`A user’s point of regard can also be disrupted if other content covers part of the page. If testing for WCAG 2.2, ensure that any new content that overlays other content does not obscure any item receiving keyboard focus.`}</p>
    <h5 className="accordionH5">Completes testing for:</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><a href="../../../requirements/requirements/?version=7_3#2_4_11">2.4.11 Focus Not Obscured</a>, IBM accessibility requirements (7.3 only)</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      