import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Instructions provided for understanding and operating content do not rely solely on sensory characteristics of components such as shape, size, visual location, orientation, or sound.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures instructions to the user that use sensory characteristics such as shape or location to provide context are not the only means of conveying information.`}</p>
    <p>{`When elements are described using visual cues such as shape, size, or physical location (e.g., “select the button on the right”),
it can make it easier for a sighted user and some people who have cognitive disabilities to locate the elements.
But, someone with a vision disability may not be able to perceive those visual cues.
The element must `}<em parentName="p">{`also`}</em>{` be described in another non-visual way, such as by its label (e.g., “select the Delete button on the right”).`}</p>
    <p>{`It is important to understand that this requirement is entirely focused on referencing sensory characteristics in instructions.
The instructions need to be understandable even if the content is reflowed or transformed.
For example, a screen display without images,
or the display is reflowed on a mobile device changing the content positioning.`}</p>
    <p>{`The simplest way to prevent failures of sensory characteristics is to always have a visible text label and reference that label in the instructions.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Use of color, also a sensory characteristic, has a separate requirement,
see `}<a href={withPrefix("requirements/requirements/#1_4_1")} target="_blank" rel="noopener noreferrer">{`1.4.1 Use of Color`}</a>{`,
that governs its use as not being the only means of distinguishing content.
That applies to any reliance on the use of color, and not just references to color in instructions.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/sensory-characteristics" target="_blank" rel="noopener noreferrer">{`Understanding 1.3.3 Sensory Characteristics`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      