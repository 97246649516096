import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../images/visible_focus.png";
import img11 from "../../../../../visuals/develop/coding_interactions_keyboard_3_do.png";
import img12 from "../../../../../visuals/develop/coding_interactions_keyboard_3_dont.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`When an object takes keyboard focus, it must visually display a focus indicator. By default, interactive HTML elements will receive a focus indicator from the browser. Any time the design calls for an override of the default focus indicator, implement a replacement focus indicator that is highly visible, as per the design.`}</p>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" captionTitle="" caption="Ensure focus is highly visible, if it's not the default indicator, so that users know where the keyboard focus is." mdxType="DoDontExample">
     <img src={img11} alt="Good example: visible blue border surrounding first option in list"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" captionTitle="" caption="Don't use insufficient contrast, such as this slight variance in background color, which makes it difficult for users to know where the keyboard focus it." mdxType="DoDontExample">
     <img src={img12} alt="Bad example: light grey shaded area filling first option in list"></img>
        </DoDontExample>
      </Column>
    </Row>
    <p>{`The following practices remove the focus indicator and need remediation or a replacement focus indicator controlled by scripting:`}</p>
    <ul>
      <li parentName="ul">{`CSS style `}<inlineCode parentName="li">{`outline:0`}</inlineCode></li>
      <li parentName="ul">{`CSS style `}<inlineCode parentName="li">{`outline:none`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`onfocus="blur"`}</inlineCode>{` This should be avoided because the keyboard user cannot interact with the element.`}</li>
    </ul>
    <p>{`The following resolves a missing focus indicator by using the CSS property `}<inlineCode parentName="p">{`outline`}</inlineCode>{` (here adding a `}<em parentName="p">{`really`}</em>{` obvious thick, dotted red line!), and also adds some space between the elements and indicator with use of the CSS property `}<inlineCode parentName="p">{`outline-offset`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`/* The following resolves missing focus indicator by use of CSS properties outline and outline-offset */
*:focus {
  outline: 2px dashed red;
  outline-offset: 2px;
}
`}</code></pre>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G195" target="_blank" rel="noopener noreferrer">G195: Using an author-supplied, highly visible focus indicator</a>
      * <a href={withPrefix("/requirements/requirements/#2_4_7")} target="_blank" rel="noopener noreferrer">G195: Supplemental technique, 2.4.7 Focus Visible</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/css/C15" target="_blank" rel="noopener noreferrer">C15: Using CSS to change the presentation of a user interface component when it receives focus</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/css/C40" target="_blank" rel="noopener noreferrer">C40: Creating a two-color focus indicator to ensure sufficient contrast with all components</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR31" target="_blank" rel="noopener noreferrer">SCR31: Using script to change the background color or border of the element with focus</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/failures/F78.html" target="_blank" rel="noopener noreferrer">F78: Failure of Success Criterion 2.4.7 due to styling element outlines and borders in a way that removes or renders non-visible the visual focus indicator</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g195' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='c15' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='c40' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr31' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='f78' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_4_7")} target="_blank" rel="noopener noreferrer">2.4.7 Focus Visible</a>, IBM accessibility requirements
      * <a href="http://w3c.github.io/aria-practices/#aria_ex" target="_blank" rel="noopener noreferrer">Design patterns and widgets</a>, ARIA authoring practices
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_7' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Component contrast](/toolkit/design/visual/#component_contrast), Visual design - Color and contrast
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='visual_components_contrast' mdxType="ToolkitTopic"></ToolkitTopic>, Visual design - Component contrast</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      