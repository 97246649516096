import PageBanner from "../../../../src/components/PageBanner";
import ReportPanel from "../../../../src/components/product_accessibility/reportPanel";
import DefaultNoHead from "../../../../src/gatsby-theme-carbon/templates/DefaultNoHead";
import DefaultCL from "../../../../src/gatsby-theme-carbon/templates/DefaultCR";
import { TkFooter } from "../../../../src/components/toolkit/TkFooter";
import "../../../../src/pages/product_accessibility/acr.scss";
import * as React from 'react';
export default {
  PageBanner,
  ReportPanel,
  DefaultNoHead,
  DefaultCL,
  TkFooter,
  React
};