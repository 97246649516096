import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Any flashing content is below required thresholds`}</li>
      <li parentName="ul">{`Any audio or video has a label describing the content`}</li>
      <li parentName="ul">{`Any audio, video, or auto-playing content is noted for further testing`}</li>
      <li parentName="ul">{`If no media listed above exists, the items below are not applicable`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`The absence of time-based media, as well as flashing or auto-playing
content can quickly be confirmed and set to NA in your test results.
When flashing content is present, check that it is below the threshold.
Check for animated carousels and other looping information on the screen.
When such content is present, note where it is found so it can be fully
tested in a later step.`}</p>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_2_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_2_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_2_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_2_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_2_5' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_2_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      