import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Correctly ordering content in the Document Object Model (DOM) is the preferred way to achieve a focus order. The order can also be adjusted programmatically, but there are accessibility considerations with most of the techniques.`}</p>
    <p>{`When dynamic content is inserted, you must take care to insert the content in the correct place in the DOM to maintain a logical tab order.`}</p>
    <p>{`Ensure that when a dialog appears because of a user action, focus is set on an interactive element within it. When the dialog is dismissed, return the focus to a logical place, typically the element that caused the dialog to appear. Consult with the UX designer to ensure smooth transitions of focus.`}</p>
    <p>{`Use caution setting the `}<inlineCode parentName="p">{`tabindex`}</inlineCode>{` attribute on elements to a number greater than zero, which causes them to receive focus before focusable elements that lack `}<inlineCode parentName="p">{`tabindex`}</inlineCode>{` or have `}<inlineCode parentName="p">{`tabindex="0"`}</inlineCode>{`. After all the elements with a `}<inlineCode parentName="p">{`tabindex`}</inlineCode>{` greater than zero have received focus, the rest of the elements receive focus in the order they appear in the DOM.`}</p>
    <pre><code parentName="pre" {...{}}>{`<!-- Set tab order according to DOM -->
<div tabindex="0">This is 1st tab stop</div>
<div tabindex="0">This is 2nd tab stop</div>
<div tabindex="-1" role="dialog" aria-modal="true"
 aria-label="This won’t be in tab order after second div until set by script">
...
</div>
<div tabindex="0">This is 3rd tab stop</div>
</div>
`}</code></pre>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G59" target="_blank" rel="noopener noreferrer">G59: Placing the interactive elements in an order that follows sequences and relationships within the content</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/css/C27.html" target="_blank" rel="noopener noreferrer">C27: Making the DOM order match the visual order</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR27.html" target="_blank" rel="noopener noreferrer">SCR27: Reordering page sections using the Document Object Model</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g59' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='c27' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr27' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_4_3")} target="_blank" rel="noopener noreferrer">2.4.3 Focus Order</a>, IBM accessibility requirements
      * <a href="https://developers.google.com/web/fundamentals/accessibility/focus/dom-order-matters" target="_blank" rel="noopener noreferrer">DOM order matters</a>, Google developer guide for keyboard access
      * <a href="https://karlgroves.com/2018/11/13/why-using-tabindex-values-greater-than-0-is-bad" target="_blank" rel="noopener noreferrer">Why using `tabindex` values greater than “0” is bad</a>, KarlGroves.com
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='google_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='karlgroves_com_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Design for keyboard navigation](/toolkit/design/ux/navigation#design-for-keyboard-navigation), UX design - Navigation
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, UX design - Keyboard interaction</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      