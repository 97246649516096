import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Screen reader output is in sync with item in focus when navigating`}</li>
      <li parentName="ul">{`Keyboard interaction continues to work while using the screen reader`}</li>
      <li parentName="ul">{`Display of name, role, value, and state information matches screen reader output`}</li>
      <li parentName="ul">{`Bypass blocks navigation works with the screen reader`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`This step reconfirms the findings in the `}<ToolkitTopic qid='verify_manual_test_with' mdxType="ToolkitTopic"></ToolkitTopic>{` task using the screen reader in combination with a keyboard. Testers should pay particular attention that the speech output of the screen reader matches the visual information, and that keyboard interaction still functions with the screen reader running. Mismatches between speech output and visual information can be an indication of issues with the element’s name, role, states, and value. Testers may wish to check the screen reader’s verbosity settings and inspect code to verify if properties, relationships, and states are properly set.`}</p>
    <p>{`Also, verify that mechanisms designed to bypass blocks of content function correctly with the screen reader.`}</p>
    <h5 className="accordionH5">Completes testing for:</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_2_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req4_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      