import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../src/components/rms_resources/requirements";
import ToolkitReference from "../../../../src/components/rms_resources/references";
import ToolkitTechnique from "../../../../src/components/rms_resources/techniques";
import ToolkitTopic from "../../../../src/components/rms_resources/topics";
import img1 from "../../../../src/visuals/design/content_2_1_eg.png";
import img2 from "../../../../src/visuals/design/content_2_2_eg.png";
import img3 from "../../../../src/visuals/design/content_2_3_eg.png";
import img4 from "../../../../src/visuals/design/content_2_4_eg.png";
import img5 from "../../../../src/visuals/design/content_2_5_eg.png";
import "../../../../src/pages/toolkit/topics.scss";
import * as React from 'react';
export default {
  Row,
  Column,
  withPrefix,
  ToolkitRequirement,
  ToolkitReference,
  ToolkitTechnique,
  ToolkitTopic,
  img1,
  img2,
  img3,
  img4,
  img5,
  React
};