import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`An alternative for time-based media or audio description of the prerecorded video content is provided for synchronized media.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures that people who are blind or visually impaired have access to the visual information that is provided in multimedia.
Anything that has a play button is typically “time-based media,”
which includes anything that has duration and plays to the user over time.
Video, audio, and animation are all time-based media.`}</p>
    <p>{`The visual information is described using one of the following:`}</p>
    <ul>
      <li parentName="ul">{`An audio description (also called video description or descriptive narration) is either added to the existing audio track during pauses in the existing audio content or on an alternate audio track. A narrator describes the important visual details that are not already explained in the soundtrack, including information about actions, text information not verbally described, who is speaking, facial expressions, scene changes, and so on.`}</li>
      <li parentName="ul">{`A full text alternative is a transcript that includes descriptions of all of the visual details, including the visual context, actions and expressions of the actors, and any other visual content, as well as the auditory information - the dialogue, who is speaking, sounds (as would be contained in captions). The goal is to give a person who is blind or visually impaired the same information a sighted user would get from the media. People who are deaf, hard of hearing, or who have trouble understanding audio information also benefit because they can read the text alternative as a transcript of the audio or video information.`}</li>
    </ul>
    <p><strong parentName="p">{`Exception:`}</strong>{` When the media is a media alternative for text and is clearly labeled as such.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` This requirement overlaps with requirement `}<em parentName="p">{`1.2.5 Audio Description (Prerecorded)`}</em>{`.
If an audio description is used to meet `}<em parentName="p">{`1.2.3`}</em>{`, then `}<em parentName="p">{`1.2.5`}</em>{` is also met.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/audio-description-or-media-alternative-prerecorded.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.2.3 Audio Description or Media Alternative (Prerecorded)`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      