import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Components that have the same functionality within a set of content are identified consistently.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures consistent identification of functional elements within a set of content such as web pages on a web site, documents in a library and components in a software application. When identical functions have different labels (identification), the content can be difficult to users with disability and users who rely on their familiarity with the functions.`}</p>
    <p>{`This requirement also applies to alternative text. If icons or other non-text content has the same functionality in different content sets, then the alternative text must be the same.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Individual non-web documents and software programs (not in a set) should mark this requirement N/A because it applies only to things that appear in a set. Refer to the definitions of `}<a href="https://www.w3.org/TR/wcag2ict/#keyterms_set-of-documents.html" target="_blank" rel="noopener noreferrUndeer">{`set of documents`}</a>{` and `}<a href="https://www.w3.org/TR/wcag2ict/#keyterms_set-of-software.html" target="_blank" rel="noopener noreferrer">{`set of software programs`}</a>{` to determine when a group of documents or software programs is considered a set, as well as the `}<a href="https://www.w3.org/TR/wcag2ict/#consistent-behavior-consistent-functionality.html" target="_blank" rel="noopener noreferrer">{`WCAG2ICT 3.2.4 guidance.`}</a></p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/consistent-identification" target="_blank">{`Understanding 3.2.4 Consistent Identification`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      