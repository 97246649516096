import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../images/overlap.png";
import img2 from "./../images/tooltip.png";
import img3 from "./../images/tooltip1.png";
import img4 from "./../images/tooltip3.png";
import img14 from "../../../../../visuals/develop/coding_interactions_dynamic_11_do.png";
import img15 from "../../../../../visuals/develop/coding_interactions_dynamic_11_dont.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Tooltip hover content that contains links, buttons, or other interactive elements commonly introduces accessibility issues. Often, we see implementations where it is not possible or not intuitive for a keyboard-only user to move focus to the tooltip interactive content. It is an accessibility failure if keyboard focus automatically moves to the content when a trigger element receives focus. Special keyboard keys to switch focus to the hover’s interactive content may not be easily discoverable or easy for users to remember. When the design includes such an interaction, have a conversation with the UX designer about the accessibility challenges for users.`}</p>
    <p>{`When hover content contains interactive elements:`}</p>
    <ul>
      <li parentName="ul">{`Avoid using `}<inlineCode parentName="li">{`role=”tooltip”`}</inlineCode>{` which cannot contain interactive elements; instead use ARIA `}<inlineCode parentName="li">{`role=”dialog”`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`It is best to have tooltip dialogs (with links, buttons, or other interactive content) that are displayed using the Enter key and an `}<inlineCode parentName="li">{`onClick`}</inlineCode>{` pointer event. A possible implementation is using an element with a role of `}<inlineCode parentName="li">{`button`}</inlineCode>{` rather than a trigger element where hover or focus displays the hover content.`}</li>
      <li parentName="ul">{`Ensure the user has control over moving focus to the interactive content.`}</li>
      <li parentName="ul">{`Work with the UX designer to consider whether a modal or non-modal dialog is best. Remember, modals “trap” the Tab key interaction to remain within the dialog until the user dismisses it. This is often preferable, so dialogs don’t get left behind and cover over the background content.`}</li>
      <li parentName="ul">{`If a non-modal dialog is chosen, be sure it is inserted in the correct reading order.`}</li>
      <li parentName="ul">{`When the dialog is dismissed, focus returns to the triggering element.`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" aspectRatio="1:1" captionTitle="" caption="Restrict hover content to just text so that it's easier for keyboard users to interact with it." mdxType="DoDontExample">
    <img src={img14} alt="Good example: text 'Definition Tooltip' trigger is identified by blue, dashed underline that on hover displays text 'Brief definition of the underlined word above'"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" aspectRatio="1:1" captionTitle="" caption="Avoid adding links or operable controls in hover content, because interactive elements increase the complexity and inaccessibility of the hover content." mdxType="DoDontExample">
    <img src={img15} alt="Bad example: text 'Definition Tooltip' trigger is identified by blue, dashed underline that on hover displays text 'Brief definition of the underlined word above', followed by two links 'This is a Link.', 'This is a Link.'"></img>
        </DoDontExample>
      </Column>
    </Row>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR39" target="_blank" rel="noopener noreferrer">SCR39: Making content on focus or hover hoverable, dismissible, and persistent</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='scr39' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_13")} target="_blank" rel="noopener noreferrer">1.4.13 Content on Hover or Focus</a>, IBM accessibility requirements
      * <a href="https://www.carbondesignsystem.com/components/tooltip/code#icon-tooltip" target="_blank" rel="noopener noreferrer">Icon tooltip</a>, Carbon design system
      * <a href="https://react.carbondesignsystem.com/?path=/story/tooltipdefinition--default" target="_blank" rel="noopener noreferrer">Definition tooltip</a>, Carbon design system
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_13' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_5' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_14' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      