import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In content implemented using markup languages, status messages can be programmatically determined through role or properties so that messages can be presented by assistive technologies without receiving focus.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures users are alerted of changes in status,
such as new text appearing or a busy state ending.
The key intended beneficiaries are blind and low vision users who may not perceive status messages.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` The ”`}<strong parentName="p">{`status messages`}</strong>{`” are defined by WCAG as messages that provide information on things like the success or results of a user action,
but that do not change the user’s context (i.e., take focus).
This does not suggest that messages that take focus are forbidden;
merely, such messages do not qualify as status messages and thus do not need to meet this requirement.
The WCAG Understanding document provides multiple examples of messages that meet this criteria,
as well as examples of messages that do not.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/status-messages" target="_blank" rel="noopener noreferrer">{`Understanding 4.1.3 Status Messages`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      