import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Color is not used as the only visual means of conveying information, indicating an action, prompting a response, or distinguishing a visual element.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures that color is not the only visual means of conveying information. Many users have deficiencies in color perception and cannot identify or distinguish colors. Another non-color method for making the information available visually is required, such as with pattern, images, text, or text formatting.`}</p>
    <p>{`For example, if text in an input field is colored red to show it is invalid, a user who cannot distinguish the red is unable to detect the problem. If a warning symbol is added beside the text box, then the user can locate it.`}</p>
    <p>{`This requirement is not meant to discourage the effective use of color or color coding, but requires redundant visual indication.`}</p>
    <p><strong parentName="p">{`Exception:`}</strong>{` This requirement does not apply when color is used to indicate “visited” links.
It also does not apply when color is used to indicate the location of a mouseover focus on a navigation bar or within an application.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/use-of-color.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.4.1 Use of Color`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      