import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Authoring tools may provide a range of templates that support features and, as applicable file formats. Where templates are provided, templates shall allow content creation that conforms to Level A and Level AA Success Criteria and Conformance Requirements in WCAG 2.1.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`Where authoring tools provide templates,
there must be accessible template options.
In the case where some templates are non-accessible, the accessible templates should be indicated.
The templates identified as being accessible means that they have been verified against the WCAG subset of IBM accessibility requirements.`}</p>
    <p>{`The `}<a href="https://www.w3.org/TR/ATAG20/" target="_blank">{`Authoring Tools Accessibility Guidelines`}</a>{` (ATAG) define templates as follows:
”`}<em parentName="p">{`Content patterns that are filled in by authors or the authoring tool to produce web content for end users (e.g. document templates, content management templates, presentation themes). Often templates will pre-specify at least some authoring decisions`}</em>{`.”`}</p>
    <p>{`ATAG states that providing accessible templates can have several benefits, including:`}</p>
    <ul>
      <li parentName="ul">{`immediately improving the accessibility of the content being edited`}</li>
      <li parentName="ul">{`reducing the effort required of developers`}</li>
      <li parentName="ul">{`demonstrating the importance of accessible content`}</li>
    </ul>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#504.4" target="_blank">{`504.4 Templates`}</a>{` (external link to US 508) for more information.
Also refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=83" target="_blank">{`11.8.5 Templates`}</a>{` (external link to EN 301 549).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      