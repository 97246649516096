import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where user controls are provided for program selection,
ICT shall provide user controls for the selection of audio descriptions at the same menu level as the user controls for volume or program selection.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`Information and communications technology (ICT) products and applications that do not contain program selection should mark this `}<strong parentName="p">{`N/A`}</strong>{` (not applicable).
This requirement applies to applications and software including media players and embedded players that control program selection.
Based on regional conventions, `}<em parentName="p">{`audio descriptions`}</em>{` are also known as `}<em parentName="p">{`described video`}</em>{`, `}<em parentName="p">{`video descriptions`}</em>{`,
or `}<em parentName="p">{`descriptive narration`}</em>{`. `}</p>
    <p>{`Several “`}<em parentName="p">{`Time-based Media`}</em>{`” success criteria in WCAG, such as `}<a href={withPrefix("/requirements/requirements/#1_2_5")} target="_blank" rel="noopener noreferrer">{`1.2.5 Audio Description`}</a>{`, require that audio descriptions are provided.
That requirement stipulates that meaningful video content has been described and is captured in a format that can be used to provide synchronized audio descriptions.
Those requirements, covering the `}<em parentName="p">{`content`}</em>{` of the audio descriptions,
are complementary to chapter 7 `}<em parentName="p">{`ICT with video capabilities`}</em>{` of EN 301 594 and related `}<em parentName="p">{`US Revised 508 Standards`}</em>{`,
which covers the actual delivery of audio descriptions during playback,
and the controls for turning audio descriptions on and off provided by the media player.`}</p>
    <p>{`Where media player technologies do not have explicit and separate mechanisms for audio description,
an ICT is deemed to satisfy this requirement if the ICT enables the user to select and play alternative audio tracks, one of which provides audio descriptions in addition to other audio content.`}</p>
    <p>{`Ensure that the media player you’re developing or deploying includes a setting or button for audio descriptions or alternative audio tracks
at the same menu level as the user controls for volume or program selection.`}</p>
    <p>{`Where the requirements of `}<a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">{`7.3 User Controls for Captions and Audio Description`}</a>{` are met,
this requirement `}<em parentName="p">{`503.4.2 Audio Description Controls`}</em>{` can also be considered to be met.
`}<em parentName="p">{`7.3`}</em>{` requires that the controls for captions be at the same level of interaction as the primary media controls.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#503.4.2" target="_blank">{`503.4.2 Audio Description Controls`}</a>{` (external link to US 508) for more information.`}</p>
    <p><strong parentName="p">{`Related requirements:`}</strong>{`
Applications that play audio and video are required to meet the following related requirements:`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Audio/Video Content requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_3")} target="_blank" rel="noopener noreferrer">1.2.3 Audio Description or Media Alternative (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_5")} target="_blank" rel="noopener noreferrer">1.2.5 Audio Description (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#10_6")} target="_blank" rel="noopener noreferrer">10.6 Audio Description Timing (Non-web Documents)</a></li>
    </ul>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Player requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_4_2")} target="_blank" rel="noopener noreferrer">1.4.2 Audio Control</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_1")} target="_blank" rel="noopener noreferrer">7.2.1 Audio Description Playback</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_2")} target="_blank" rel="noopener noreferrer">7.2.2 Audio Description Synchronization</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_3")} target="_blank" rel="noopener noreferrer">7.2.3 Preservation of Audio Description</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">7.3 User Controls for Captions & Audio Description</a></li>
      <li parentName="ul">{`503.4.2 Audio Description Controls - `}<em parentName="li">{`this requirement`}</em></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      