import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Keyboard operation can be affected if items that take focus are not visible in the viewport. Temporary or secondary information such as banners, overlays, and pop-ups should be implemented so they do not potentially obscure items receiving keyboard focus. `}</p>
    <h5 className="accordionH5">Techniques</h5>
    <ul>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG22/Techniques/css/C43" target="_blank" rel="noopener noreferrer">C43: Using CSS margin and scroll-margin to un-obscure content, WCAG 2.2 technique</a>, WCAG 2.2 technique</li>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG22/Techniques/failures/F110" target="_blank" rel="noopener noreferrer">F110: Failure of Success Criterion 2.4.11 Focus Not Obscured (Minimum) due to a sticky footer or header completely hiding focused elements</a>, WCAG 2.2 technique</li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/?version=7_3#2_4_11")} target="_blank" rel="noopener noreferrer">2.4.11 Focus Not Obscured (Minimum)</a>, IBM accessibility requirements (7.3 only)</li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    <ul>
      <li parentName="ul"><a href="../../design/ux#pop-ups">Pop-ups and overlays</a>, Design - UX</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      