import { withPrefix } from 'gatsby';
import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion } from 'carbon-components-react';
import AudioVideo from "../../../../src/pages/toolkit/verify/manual/audiovideo.mdx";
import BypassBlocks from "../../../../src/pages/toolkit/verify/manual/bypassblocks.mdx";
import Color from "../../../../src/pages/toolkit/verify/manual/color.mdx";
import DynamicUpdate from "../../../../src/pages/toolkit/verify/manual/dynamicupdate.mdx";
import ErrorHandling from "../../../../src/pages/toolkit/verify/manual/errorhandling.mdx";
import FocusIndicator from "../../../../src/pages/toolkit/verify/manual/focusindicator.mdx";
import HighContrast from "../../../../src/pages/toolkit/verify/manual/highcontrast.mdx";
import KeyboardInteraction from "../../../../src/pages/toolkit/verify/manual/keyboardinteraction.mdx";
import Labeling from "../../../../src/pages/toolkit/verify/manual/labeling.mdx";
import MotionOperation from "../../../../src/pages/toolkit/verify/manual/motionoperation.mdx";
import PointerInteraction from "../../../../src/pages/toolkit/verify/manual/pointerinteraction.mdx";
import PointerOperation from "../../../../src/pages/toolkit/verify/manual/pointeroperation.mdx";
import PointOfRegard from "../../../../src/pages/toolkit/verify/manual/pointofregard.mdx";
import Process from "../../../../src/pages/toolkit/verify/manual/process.mdx";
import Reflow from "../../../../src/pages/toolkit/verify/manual/reflow.mdx";
import ResizeText from "../../../../src/pages/toolkit/verify/manual/resizetext.mdx";
import TabNavOrder from "../../../../src/pages/toolkit/verify/manual/tabnavorder.mdx";
import TextClarity from "../../../../src/pages/toolkit/verify/manual/textclarity.mdx";
import TimeLimit from "../../../../src/pages/toolkit/verify/manual/timelimit.mdx";
import VisualCuriosities from "../../../../src/pages/toolkit/verify/manual/visualcuriosities.mdx";
import ExpandCollapseAccordion from "../../../../src/components/expand_collapse_accordion/expand_collapse_accordion.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import "../../../../src/pages/toolkit/topics.scss";
import * as React from 'react';
export default {
  withPrefix,
  TkAccordionItem,
  Accordion,
  AudioVideo,
  BypassBlocks,
  Color,
  DynamicUpdate,
  ErrorHandling,
  FocusIndicator,
  HighContrast,
  KeyboardInteraction,
  Labeling,
  MotionOperation,
  PointerInteraction,
  PointerOperation,
  PointOfRegard,
  Process,
  Reflow,
  ResizeText,
  TabNavOrder,
  TextClarity,
  TimeLimit,
  VisualCuriosities,
  ExpandCollapseAccordion,
  Row,
  Column,
  React
};