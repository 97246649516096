import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For each time limit that is set by the content,
the user can turn off, adjust, or extend the limit.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures users with disabilities have adequate time to interact with content when possible.
People with disabilities may require additional time to read content or perform functions such as completing online forms.
When faced with time-dependent functions, users with low vision, dexterity impairments,
or cognitive limitations may have difficulty performing the required action before reaching a time limit,
making the service potentially inaccessible to them.`}</p>
    <p>{`A time limit includes any process that occurs (without user initiation) after a set amount of time or on a periodic basis.
Examples include partial or full updates of content such as page refreshes, changes to content,
or the expiration of a “window of opportunity” for user input requests.
Time limits also include content that is advancing or updating at a rate beyond the user’s ability to read, understand,
and/or interact, such as animated, moving, or scrolling content.`}</p>
    <p>{`By designing functions that are not time-dependent,
you can help people with disabilities complete these functions and make it easier for all users.
When a time limit is required, one of the following options must be provided, in order of preference:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Turn off:`}</strong>{` User is allowed to turn off the time limit before encountering it; or`}</li>
      <li parentName="ul"><strong parentName="li">{`Adjust:`}</strong>{` User is allowed to adjust the time limit before encountering it over a wide range that is at least ten times the length of the default setting; or`}</li>
      <li parentName="ul"><strong parentName="li">{`Extend:`}</strong>{` The user is warned before time expires and given at least 20 seconds to extend the time limit with a simple action, such as “press the space bar”,
and the user is allowed to extend the time limit at least ten times.`}</li>
    </ul>
    <p><strong parentName="p">{`Real-time Exception:`}</strong>{` This requirement does not apply if the time limit is a required part of a real-time event, such as an auction,
and no alternative to the time limit is possible.`}</p>
    <p><strong parentName="p">{`Essential Exception:`}</strong>{` This requirement does not apply if the time limit is essential and extending it would invalidate the activity.`}</p>
    <p><strong parentName="p">{`20 Hour Exception:`}</strong>{` This requirement does not apply if the time limit is longer than 20 hours.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/timing-adjustable" target="_blank">{`Understanding 2.2.1 Timing Adjustable`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      