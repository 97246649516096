import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Context changes are only triggered when users activate a control`}</li>
      <li parentName="ul">{`Context changes do not occur when users change their focus`}</li>
      <li parentName="ul">{`Context changes do not occur when users input data`}</li>
      <li parentName="ul">{`Context changes do not occur when users interact with a component`}</li>
      <li parentName="ul">{`Content changes do not contribute to timing issues for a user`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`Changes to the content of a page can be triggered by user action. A `}<ToolkitReference qid='wcag_2' source="hide" mdxType="ToolkitReference"></ToolkitReference>{`  describes major changes in the content that, if made without
user awareness, can disorient users who are not able to view the entire page
simultaneously. To be accessible, dynamic updates that change a user’s
context need to be predictable. For keyboard operation, context changes
should only be triggered when users activate a control either by pressing
Enter, or by altering a component’s state then pressing the Tab key to move to the
next component. Context change should not occur when users simply change
their focus on the page, begin inputting data, or begin interacting with a
component.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` If content on a page is updated, it is not necessarily a change of
context, especially if the change occurs for content the user has not yet
navigated to on the page. Where content is changed, it is important that the
changes do not contribute to timing issues for a user. For instance, if a country
being selected in a list box causes the values for states or provinces to be
updated in a following list box, the update needs to happen in such a way that
it does not disrupt keyboard navigation or screen reader output.`}</p>
    <p>{`On a mobile device, activation typically occurs by tapping or left/right swiping
the screen.`}</p>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_2_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_2_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Completes testing for:</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      