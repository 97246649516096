import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where ICT displays video with synchronized audio,
it shall provide a mechanism to select and play available audio description to the default audio channel.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The European `}<em parentName="p">{`EN 301 549`}</em>{` standard specifies requirements for information and communications technologies (ICT) such as media players and embedded players that render audio descriptions.
Based on regional conventions, it is also known as described video, video descriptions, or descriptive narration.
This requirement is Not Applicable (N/A) to products and applications that do not play audio and video.`}</p>
    <p>{`Several “`}<em parentName="p">{`Time-based Media`}</em>{`” success criteria in WCAG, such as `}<a href={withPrefix("/requirements/requirements/#1_2_5")} target="_blank" rel="noopener noreferrer">{`1.2.5 Audio Description`}</a>{`,
require that audio descriptions are provided.
That requirement stipulates that meaningful video content has been described and is captured in a format that can be used to provide synchronized audio descriptions.
Those WCAG requirements, covering the `}<em parentName="p">{`content`}</em>{` of the audio descriptions,
are complementary to chapter 7 `}<em parentName="p">{`ICT with video capabilities`}</em>{` of EN 301 549,
which covers the actual `}<em parentName="p">{`delivery`}</em>{` of audio descriptions during playback,
and the controls for turning audio descriptions on and off provided by the media player.`}</p>
    <p><strong parentName="p">{`Providing user controls to activate audio descriptions:`}</strong>{`
Although the wording of `}<em parentName="p">{`7.2.1 Audio Description Playback`}</em>{` specifies a mechanism that plays audio descriptions using the default audio channel,
it also contains provisions for allowing the selection of alternative audio tracks.`}</p>
    <p>{`Where video player technologies do not have explicit and separate mechanisms for audio description,
an ICT is deemed to satisfy this requirement if the ICT enables the user to select and play alternative audio tracks,
in the same manner that `}<em parentName="p">{`language`}</em>{` tracks can be played,
such as settings or buttons for English, French, or Mandarin audio tracks.
The alternative audio track must provide the audio descriptions in addition to original audio content.`}</p>
    <p>{`Where the requirements of `}<a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">{`7.3 User Controls for Captions and Audio Description`}</a>{` are met,
this requirement `}<em parentName="p">{`7.2.1 Audio Description Playback`}</em>{` can also be considered to be met.
7.3 requires that the controls for audio descriptions be at the same level of interaction as the primary media controls.`}</p>
    <p>{`Ensure that the media player you’re developing or deploying includes a setting or button for audio descriptions or alternative audio tracks.`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=36" target="_blank" rel="noopener noreferrer">{`7.2.1 Audio Description Playback`}</a>{` (external link to EN 301 549) for more information.`}</p>
    <p><strong parentName="p">{`Related requirements:`}</strong>{`
Applications that play audio and video are required to meet the following related requirements:`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Audio/Video Content requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_3")} target="_blank" rel="noopener noreferrer">1.2.3 Audio Description or Media Alternative (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_5")} target="_blank" rel="noopener noreferrer">1.2.5 Audio Description (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#10_6")} target="_blank" rel="noopener noreferrer">10.6 Audio Description Timing (Non-web Documents)</a></li>
    </ul>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Player requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_4_2")} target="_blank" rel="noopener noreferrer">1.4.2 Audio Control</a></li>
      <li parentName="ul">{`7.2.1 Audio Description Playback - `}<em parentName="li">{`this requirement`}</em></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_2")} target="_blank" rel="noopener noreferrer">7.2.2 Audio Description Synchronization</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_2_3")} target="_blank" rel="noopener noreferrer">7.2.3 Preservation of Audio Description</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">7.3 User Controls for Captions & Audio Description</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#503_4_2")} target="_blank" rel="noopener noreferrer">503.4.2 Audio Description Controls</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      