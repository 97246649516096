import React, { Component } from 'react';
import { Grid, Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import Github20 from "@carbon/icons-react/lib/launch/20";
import Button from 'carbon-components-react/lib/components/Button';

import "./TkFooter.scss";

export class TkFooter extends Component {

    render() {
        return <div className="tkFooter">
            <div style={{maxWidth: "99rem"}}>
            <Grid className="grid">
                <Row className="tkFootContent">
                    <Column colLg={5} colMd={4} colSm={4}>
                        <div style={{marginTop: "3rem"}}></div>
                        <h2 className="tkFootHead">Need help?</h2>
                        <p className="tkFootBody">Submit a github issue and we’ll help you find what you need.</p>
                        <Button
                            disabled={false}
                            iconDescription="Button icon"
                            kind="secondary"
                            size="default"
                            renderIcon={Github20}
                            type="button"
                            className="GitHubButton"
                            href="https://www.github.com/IBMa/equal-access/issues/new/choose"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div style={{ fontSize: "14px","padding-top": "4px", "padding-bottom": "4px" }}>
                            Submit an issue
                            </div>
                        </Button>
                    </Column>
                </Row>
            </Grid></div></div>;
    }
}