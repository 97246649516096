import React, { Component, useState, useEffect } from 'react'
import Button from "carbon-components-react/lib/components/Button";

class ExpandCollapseAccordion extends Component {
    constructor(props) {
        super(props);
        this.state = { expand: false };
    }

    componentDidMount() {
        var clickList = document.querySelectorAll("button.bx--accordion__heading");
        for (let index = 0; index < clickList.length; index++) {
            if (clickList[index].getAttribute("aria-expanded") === "false" && this.state.expand === true) {
                clickList[index].click();
            } else if (clickList[index].getAttribute("aria-expanded") === "true" && this.state.expand === false) {
                clickList[index].click();
            }
        }
    };

    componentDidUpdate() {
        var clickList = document.querySelectorAll("button.bx--accordion__heading");
        for (let index = 0; index < clickList.length; index++) {
            if (clickList[index].getAttribute("aria-expanded") === "false" && this.state.expand === true) {
                clickList[index].click();
            } else if (clickList[index].getAttribute("aria-expanded") === "true" && this.state.expand === false) {
                clickList[index].click();
            }
        }
    };

    render() {
        return (
            <Button style={{ marginTop: "80px" }} kind="tertiary" onClick={() => this.setState({ expand: !this.state.expand })}>
                <div>{this.state.expand ? "Collapse all" : "Expand all"}</div>
            </Button>
        )
    };
};

export default ExpandCollapseAccordion