import { withPrefix } from "gatsby";
import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion } from "carbon-components-react";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import "../../../../src/pages/toolkit/topics.scss";
import "../../../../src/pages/toolkit/toolkit-overviews.scss";
import "../../../../src/pages/toolkit/TKStructuredList.scss";
import DetailPlayerKbdAccessible from "../../../../src/pages/toolkit/develop/keyboard/level2/player-kbd-accessible.mdx";
import DetailFocusedItemVisible from "../../../../src/pages/toolkit/develop/keyboard/level2/focused-item-visible.mdx";
import DetailFocusIndicator from "../../../../src/pages/toolkit/develop/keyboard/level1/focus-indicator.mdx";
import DetailFocusTrigger from "../../../../src/pages/toolkit/develop/keyboard/level3/focus-trigger.mdx";
import DetailInteractive from "../../../../src/pages/toolkit/develop/keyboard/level1/interactive-elements.mdx";
import DetailConventions from "../../../../src/pages/toolkit/develop/keyboard/level1/keyboard-conventions.mdx";
import DetailOnchange from "../../../../src/pages/toolkit/develop/keyboard/level3/onchange-event.mdx";
import DetailHTML from "../../../../src/pages/toolkit/develop/keyboard/level1/standard-html.mdx";
import DetailTabOrder from "../../../../src/pages/toolkit/develop/keyboard/level1/tab-order.mdx";
import DetailCustom from "../../../../src/pages/toolkit/develop/keyboard/level1/custom-elements.mdx";
import Level1Icon from "../../../../src/visuals/plan/level_1_icon.svg";
import Level2Icon from "../../../../src/visuals/plan/level_2_icon.svg";
import Level3Icon from "../../../../src/visuals/plan/level_3_icon.svg";
import ExpandCollapseAccordion from "../../../../src/components/expand_collapse_accordion/expand_collapse_accordion.js";
import * as React from 'react';
export default {
  withPrefix,
  TkAccordionItem,
  Accordion,
  Row,
  Column,
  DetailPlayerKbdAccessible,
  DetailFocusedItemVisible,
  DetailFocusIndicator,
  DetailFocusTrigger,
  DetailInteractive,
  DetailConventions,
  DetailOnchange,
  DetailHTML,
  DetailTabOrder,
  DetailCustom,
  Level1Icon,
  Level2Icon,
  Level3Icon,
  ExpandCollapseAccordion,
  React
};