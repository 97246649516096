import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Software shall not disrupt platform features that are defined in the platform documentation as accessibility features.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`Platforms, such as the Windows operating system and iOS,
have a set of accessibility options and settings that enables users with disabilities to customize system wide settings to improve accessibility.
For example, a Windows user with a physical disability may not be able to press multiple key stroke sequences, such as `}<inlineCode parentName="p">{`Ctrl+Alt+Delete`}</inlineCode>{`, simultaneously.
Setting the `}<inlineCode parentName="p">{`Sticky Keys`}</inlineCode>{` option enables the user to press and release the keys separately to invoke the desired function.
For example, the user can press and release the `}<inlineCode parentName="p">{`Shift`}</inlineCode>{` key 5 times, then press and release `}<inlineCode parentName="p">{`Ctrl`}</inlineCode>{`, then `}<inlineCode parentName="p">{`Alt`}</inlineCode>{`,
then `}<inlineCode parentName="p">{`Delete`}</inlineCode>{` keys to restart the Windows operating system.`}</p>
    <p>{`Accessibility options and settings make it possible for people with a variety of disabilities to use their computer.
If the application software interferes with these options, some users may find their system unusable.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{`
This requirement addresses the need to `}<em parentName="p">{`not disrupt or override`}</em>{` platform accessibility options,
including the keyboard commands to control these options.
Requirement `}<a href={withPrefix("requirements/requirements/#503_2")} target="_blank" rel="noopener noreferrer">{`503.2 User preferences`}</a>{` addresses the need for
applications to provide a mode of operation that `}<em parentName="p">{`inherits`}</em>{` platform settings for color,
contrast, font type, font size, and focus cursor.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.2.2" target="_blank">{`502.2.2 No Disruption of Accessibility Features`}</a>{` (external link to US 508) for more information.`}</p>
    <h4><h5 style={{
        fontSize: "16px",
        fontWeight: "600"
      }}>{`Supplemental techniques`}</h5></h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Not defining application keyboard shortcut keys that interfere with documented shortcut keys for operating system accessibility features:`}</strong>{`
Operating systems frequently have dedicated shortcuts to enable or disable accessibility features.
These keyboard shortcuts need to be globally available.
Teams should not create application-specific keystrokes that conflict with these documented keystrokes in any supported operating system.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Apple iOS:`}</strong>{`
Refer to the accessibility options available on iOS systems in Settings,
General, Accessibility, and to documented `}<a href="https://help.apple.com/iphone/10/#/iph6c494dc6" target="_blank">{`VoiceOver keyboard commands`}</a>{` for iOS native applications to ensure that
the application does not overwrite any documented iOS accessibility features.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Apple® macOS®:`}</strong>{`
Refer to `}<a href="https://support.apple.com/en-us/HT204434" target="_blank">{`macOS® keyboard shortcuts`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Microsoft Windows:`}</strong>{`
Refer to `}<a href="https://www.microsoft.com/en-us/accessibility" target="_blank">{`Microsoft Accessibility`}</a>{`
features supported to ensure that the application does not overwrite any documented accessibility features,
including keyboard shortcuts.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Eclipse®:`}</strong>{`
Refer to `}<a href="https://wiki.eclipse.org/Accessibility#Accessibility_Features_in_Eclipse" target="_blank">{`Accessibility Features in Eclipse`}</a>{`.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      