import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import img1 from "./thumbnail-haac-swuiobject-swdialogbox.gif";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The object role, state(s), properties, boundary, name,
and description shall be programmatically determinable.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`Someone who is blind or vision impaired uses a computer with the aid of assistive technology (AT) such as a screen reader,
screen magnifier, or Braille display.
Software must provide semantic information about the user interface objects to the AT.
This is done through platform specific application programming interfaces (APIs) that programmatically expose UI object information to the AT
including the following:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`name`}</inlineCode>{` (identity) attribute is the text string that is used to identify a user interface element to the user.
For example, the label preceding an edit control, or the text of a push button function used as the name.
Even non-text user interface elements, such as bitmaps, and images have a text string name that can be provided to the assistive technology.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`role`}</inlineCode>{` (operation) provides a description of the type of user interface element and implies the behavior of the element.
For example, a role of push button identifies the element as a user interface object that performs an action when activated using the `}<inlineCode parentName="li">{`Spacebar`}</inlineCode>{` or `}<inlineCode parentName="li">{`Enter`}</inlineCode>{` key.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`state`}</inlineCode>{` is a description of the object’s status. For example, a state of focused indicates the object has the keyboard focus.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`boundary`}</inlineCode>{` of an object, also known as its `}<inlineCode parentName="li">{`bounds`}</inlineCode>{`,
describes the location of the object on screen.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`description`}</inlineCode>{` provides additional information about a complex object or custom widget.`}</li>
    </ul>
    <p>{`In the following sample Windows dialog box,
the UI element with the current focus has a role of `}<inlineCode parentName="p">{`radio button`}</inlineCode>{`,
a `}<inlineCode parentName="p">{`name`}</inlineCode>{` of ”`}<em parentName="p">{`Two`}</em>{`” and a `}<inlineCode parentName="p">{`state`}</inlineCode>{` reflecting `}<em parentName="p">{`checked`}</em>{`, `}<em parentName="p">{`focused`}</em>{`, and `}<em parentName="p">{`focusable`}</em>{`.
The AT communicates to the user that focus is currently located on the radio button with the `}<inlineCode parentName="p">{`name`}</inlineCode>{` ”`}<em parentName="p">{`Two`}</em>{`”,
and that there are three radio buttons from which to choose.`}</p>
    {
      /* image here: caption= A dialog box with three radio buttons* */
    }
    {
      /* .../502.3.1/thumbnail-haac-swuiobject-swdialogbox.gif */
    }
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="a dialog box with three radio buttons"></img>
      </Column>
    </Row>
    <p>{`Sample Windows dialog box with radio buttons and text input field.`}</p>
    <p>{`When the user moves focus to the edit input field,
the object information returned to the AT reflects`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`name`}</inlineCode>{` of ”`}<em parentName="li">{`Value:`}</em>{`“,`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`role`}</inlineCode>{` of editable text,`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`state`}</inlineCode>{` of `}<em parentName="li">{`focused`}</em>{` and `}<em parentName="li">{`focusable`}</em>{`,`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`value`}</inlineCode>{` of “`}<em parentName="li">{`123`}</em>{`”.
In this example, there is no `}<inlineCode parentName="li">{`description`}</inlineCode>{`.
The boundary is returned through the `}<inlineCode parentName="li">{`getBounds`}</inlineCode>{` method of each control.`}</li>
    </ul>
    <p>{`The level of effort to expose object information to assistive technologies depends on how the user interface objects have been created.
Standard user interface controls (widgets) such as Windows controls or Eclipse SWT widgets,
such as push buttons, menu bars,
and menu items typically expose object information with no additional implementation needed.
Custom controls can be created by building on standard controls, or can be custom drawn.
Controls that build on an accessible standard control are often accessible with little effort.
Custom drawn controls that are unique to the software do not share any of the standard control definitions and have no inherited accessibility properties;
therefore, all the accessible features must be provided by the software.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.1" target="_blank">{`502.3.1 Object Information`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Resources:`}</h4>
    <p><strong parentName="p">{`Apple iOS`}</strong></p>
    <ul>
      <li parentName="ul"><a href="https://developer.apple.com/library/content/documentation/UserExperience/Conceptual/iPhoneAccessibility/Introduction/Introduction.html" target="_blank">Accessibility Programming Guide for iOS®</a></li>
      <li parentName="ul"><a href="https://podcasts.apple.com/us/podcast/18-accessibility-on-ios-make/id395605774?i=1000089601807" target="_blank">Apple Podcast: Accessibility on iOS: Make an App for Everyone</a></li>
    </ul>
    <p><strong parentName="p">{`Windows`}</strong></p>
    <ul>
      <li parentName="ul"><a href="https://docs.microsoft.com/en-us/dotnet/framework/ui-automation/ui-automation-and-microsoft-active-accessibility" target="_blank">UI Automation and Microsoft Active Accessibility</a></li>
      <li parentName="ul"><a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/Implementing_MSAA_server" target="_blank">Implementing a Microsoft Active Accessibility (MSAA) Server</a></li>
      <li parentName="ul"><a href="https://msdn.microsoft.com/en-us/library/ms696102%28VS.85%29.aspx" target="_blank">Developer’s Guide for Active Accessibility Servers</a></li>
    </ul>
    <p><strong parentName="p">{`Eclipse`}</strong></p>
    <ul>
      <li parentName="ul"><a href="https://wiki.eclipse.org/Accessibility" target="_blank">Eclipse foundation - Accessibility</a></li>
      <li parentName="ul"><a href="https://wiki.linuxfoundation.org/accessibility/iaccessible2/objectattributes" target="_blank">IAccessible2® Object Attributes Specification</a></li>
      <li parentName="ul"><a href="https://www.w3.org/TR/core-aam-1.1/" target="_blank">Core Accessibility API Mappings 1.1</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      