import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Changing the setting of any user interface component does not automatically cause a change of context unless the user has been advised of the behavior before using the component.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures that when a user changes a setting in the user interface or inputs a value, it does not cause an automatic change of context unless the change can be predicted or the user is notified in advance.
Displaying new content is not necessarily a change of context. A change of context is a change in meaning that can disorient a user. Examples of changes of context are:`}</p>
    <ul>
      <li parentName="ul">{`Opening a new window`}</li>
      <li parentName="ul">{`Moving focus to a different component`}</li>
      <li parentName="ul">{`Going to a new page (including anything that would look to a user as if they had moved to a new page)`}</li>
      <li parentName="ul">{`Significantly re-arranging the content`}</li>
    </ul>
    <p>{`“Changing the setting” of any user interface component is changing some aspect in the control that will persist when the user is no longer interacting with it. So selecting a checkbox or radio button, entering text into a text field, or changing the selected option in a list control changes its setting. In each situation, a user would need prior notice of changes in context.
Activating a link or a button does not “change the setting”, and so does not fall under this requirement.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/on-input.html" target="_blank" rel="noopener noreferrer">{`Understanding 3.2.2 On Input`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      