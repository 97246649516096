import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Users with some cognitive disabilities benefit from the ability to paste passwords and one-time verification codes during authentication. This allows the use of password managers and other mechanisms. Developers should not prevent pasting into such inputs, which forces users to recall passwords or transpose information, both of which constitute cognitive function tests.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    <ul>
      <li parentName="ul">
        <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H100" target="_blank" rel="noopener noreferrer">H100: Providing properly marked up email and password inputs</a>
      </li>
      <li parentName="ul">
        <ToolkitTechnique qid='h98' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique>
      </li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/?version=v7_3#3_3_8")} target="_blank" rel="noopener noreferrer">3.3.8 Accessible Authentication</a>, IBM accessibility requirements (7.3 only)</li>
      <li parentName="ul"><a href="https://www.ncsc.gov.uk/blog-post/let-them-paste-passwords" target="_blank" rel="noopener noreferrer">Let them paste passwords"</a>,  UK's National Cyber Security Centre</li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    <ul>
      <li parentName="ul"><a href="../../design/ux#authenticattion">Authentication</a>, Design - UX</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      