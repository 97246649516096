import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users with vestibular (inner ear) disorders can experience debilitating reactions from motion on a computer screen. A known trigger is motion effects (beyond simple text scrolling) that occur as they scroll through a page, such as autoplaying looped videos of panning camera movement. Limit the risks of seizures or dizziness by limiting the use of motion effects.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Avoid using unnecessary motion effects`}</li>
      <li parentName="ul">{`Reduce size, luminosity and frequency of interaction-triggered motion effects`}</li>
      <li parentName="ul">{`Provide controls for users to turn off, hide, or reduce motion`}</li>
    </ul>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Understanding/animation-from-interactions.html" target="_blank" rel="noopener noreferrer">Understanding 2.3.3 Motion from Interactions</a>, WCAG 2.1 (level AAA)
      * <a href="https://alistapart.com/article/designing-safer-web-animation-for-motion-sensitivity/" target="_blank" rel="noopener noreferrer">Designing safer web animation for motion sensitivity</a>, Val Head on A List Apart
      * <a href="https://alistapart.com/article/designing-safer-web-animation-for-motion-sensitivity/#section3" target="_blank" rel="noopener noreferrer">Identify potentially triggering animation</a>, Designing safer web animation for motion sensitivity
      * <a href="https://alistapart.com/article/designing-safer-web-animation-for-motion-sensitivity/#section11" target="_blank" rel="noopener noreferrer">Give control to users</a>, Designing safer web animation for motion sensitivity
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG22/Understanding/animation-from-interactions.html" target="_blank">
  Understanding 2.3.3 Motion from Interactions
        </a>, WCAG 2 (level AAA)</li>
      <li parentName="ul"><ToolkitReference qid='a_list_apart_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Implement motion media queries to reduce user risk](/toolkit/develop/dynamic-updates/#media-queries), Develop - Dynamic content
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='dev_dynamic_implement_motion' mdxType="ToolkitTopic"></ToolkitTopic>, Develop - Dynamic content</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      