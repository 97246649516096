import { withPrefix } from "gatsby";
import Default from "../../../../src/gatsby-theme-carbon/templates/Default";
import checkmark from "../../../../src/images/checkmark.svg";
import "../../../../src/pages/toolkit/topics.scss";
import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion, UnorderedList, ListItem } from "carbon-components-react";
import { IconList, IconListRow, IconListIcon, IconListContent } from "../../../../src/components/IconList";
import Checkmark32 from "../../../../src/images/Checkmark32.svg";
import ToolkitRequirement from "../../../../src/components/rms_resources/requirements";
import ToolkitReference from "../../../../src/components/rms_resources/references";
import ToolkitTechnique from "../../../../src/components/rms_resources/techniques";
import ToolkitTopic from "../../../../src/components/rms_resources/topics";
import * as React from 'react';
export default {
  withPrefix,
  Default,
  checkmark,
  TkAccordionItem,
  Accordion,
  UnorderedList,
  ListItem,
  IconList,
  IconListRow,
  IconListIcon,
  IconListContent,
  Checkmark32,
  ToolkitRequirement,
  ToolkitReference,
  ToolkitTechnique,
  ToolkitTopic,
  React
};