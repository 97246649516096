import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Navigational mechanisms that are repeated throughout content occur in the same relative order each time they are repeated, unless a change is initiated by the user.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement involves designing a consistent layout that enables users to predict where repeated elements in content appear, allowing quick navigation. For example, a low-vision user may depend on a search field that appears in the same location on each page of a Web site. Or, to better locate the home link in the left navigation area, a low-vision user may depend on the link appearing in the same relative order on each of a Web site’s pages.`}</p>
    <p>{`This requirement does not restrict removing or adding content. It requires only that content that is repeated in multiple places remain in the same relative order. For example, links may be removed from the left navigation area of a Web site as a user navigates the site but the links that are present must remain in the same relative order.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` This requirement applies only to things that appear in a set, and is not applicable to individual non-web documents and software programs (not in a set). Refer to the definitions of `}<a href="https://www.w3.org/TR/wcag2ict/#keyterms_set-of-documents.html" target="_blank" rel="noopener noreferrUndeer">{`set of documents`}</a>{` and `}<a href="https://www.w3.org/TR/wcag2ict/#keyterms_set-of-software.html" target="_blank" rel="noopener noreferrer">{`set of software programs`}</a>{` to determine when a group of documents or software programs is considered a set, as well as the `}<a href="https://www.w3.org/TR/wcag2ict/#consistent-behavior-consistent-locations.html" target="_blank" rel="noopener noreferrer">{`WCAG2ICT 3.2.3 guidance`}</a>{`. When this requirement doesn’t apply, mark it as ‘N/A’ in a conformance report.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/consistent-navigation" target="_blank">{`Understanding 3.2.3 Consistent Navigation`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      