import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/L2_ux_4_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Most mobile devices have sensors that allow them to respond to user motion. However, users who have tremors may unintentionally trigger a response, and users who have no mobility in their arms will be unable to trigger any response.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Ensure device motion responses can also be activated by alternative controls`}</li>
      <li parentName="ul">{`Provide a way to turn off device motion triggers`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="A user shakes a mobile device, causing an 'Undo typing?' prompt to appear."></img>
        <Caption fullWidth mdxType="Caption">Do not rely on functionality offered through shake to undo and other motion, and make sure you can turn the motion detection off.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G213" target="_blank" rel="noopener noreferrer">G213: Provide conventional controls and an application setting for motion activated input</a>, WCAG 2.1 technique
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='f106' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g213' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_5_4")} target="_blank" rel="noopener noreferrer">2.5.4 Motion Actuation</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_5_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Check motion operation](/toolkit/verify/manual#jump_motionoperation), Verify - Manual
      * [Flashing content](/toolkit/design#flashing-content), Content design
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='verify_manual_check_motion' mdxType="ToolkitTopic"></ToolkitTopic>, Verify - Manual</li>
      <li parentName="ul"><ToolkitTopic qid='content_flashing_content' mdxType="ToolkitTopic"></ToolkitTopic>, Content design</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      