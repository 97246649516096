import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where user controls are provided for volume adjustment,
ICT shall provide user controls for the selection of captions at the same menu level as the user controls for volume or program selection.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement applies to applications and software including media players and embedded players that control volume adjustments.
Information and communications technology (ICT) products and applications that do not contain volume adjustments should mark this N/A (not applicable).`}</p>
    <p>{`Several “`}<em parentName="p">{`Time-based Media`}</em>{`” success criteria in WCAG, such as `}<a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">{`1.2.2 Captions (prerecorded)`}</a>{`, require that captions are provided.
Those requirements stipulate that audio content has been transcribed and is captured in a format that can be used to display captions.
Those WCAG requirements covering the `}<em parentName="p">{`content`}</em>{` of the captions are complementary to chapter 7 `}<em parentName="p">{`ICT with video capabilities`}</em>{` of EN 301 594 and related `}<em parentName="p">{`Revised 508 Standard`}</em>{`,
which covers the actual process of displaying closed captions during playback,
and the controls (CC button) for turning captions on and off provided by the media player.
Ensure that the player you’re developing or deploying includes a CC button or setting at the same menu level as the user controls for volume or program selection.`}</p>
    <p>{`Where the requirements of `}<a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">{`7.3 User Controls for Captions and Audio Description`}</a>{` are met, this requirement `}<em parentName="p">{`503.4.1 Captions controls`}</em>{` can also be considered to be met.
`}<em parentName="p">{`7.3`}</em>{` requires that the controls for captions be at the same level of interaction as the primary media controls.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#503.4.1" target="_blank">{`503.4.1 Caption Controls`}</a>{` (external link to US 508) for more information.`}</p>
    <p><strong parentName="p">{`Related requirements:`}</strong>{`
Applications that play audio and video are required to meet the following related requirements:`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Audio/Video Content requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.2 Captions (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.4 Captions (Live)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#10_5")} target="_blank" rel="noopener noreferrer">10.5 Captioning Positioning (Non-web Documents)</a></li>
    </ul>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Player requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_1")} target="_blank" rel="noopener noreferrer">7.1.1 Captioning Playback</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_2")} target="_blank" rel="noopener noreferrer">7.1.2 Captioning Synchronization</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_3")} target="_blank" rel="noopener noreferrer">7.1.3 Preservation of Captioning</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_4")} target="_blank" rel="noopener noreferrer">7.1.4 Captions Characteristics</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_5")} target="_blank" rel="noopener noreferrer">7.1.5 Spoken Subtitles</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">7.3 User Controls for Captions & Audio Description</a></li>
      <li parentName="ul">{`503.4.1 Caption Controls - `}<em parentName="li">{`this requirement`}</em></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      