import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`People who are deaf or hard of hearing need closed captions as an alternative to the audio content. The easiest solution is for the content designer to utilize captioning services to produce a video and captions that can then be embedded into the code. Each service typically supplies its own easy copy-and-paste method to add the necessary embed code to your web page.`}</p>
    <p>{`Media content can be embedded in other ways. You could rely on the browser default player by simply using the HTML5 `}<inlineCode parentName="p">{`<video>`}</inlineCode>{` element and add in a specially formatted captions file (.vtt) as a track using the following code snippet:`}</p>
    <pre><code parentName="pre" {...{}}>{`<video preload="auto" width="480" height="360" poster="videoname.jpg">
  <source type="video/mp4" src="videoname.mp4"/>
  <source type="video/webm" srclang="en" src="videoname.webm"/>
  <track kind="captions" srclang="en" src="videoname.vtt"/>
</video>
`}</code></pre>
    <p>{`If you want full control over the chrome of the player and its controls, you can create your own media player using the HTML media API. However, it requires some skill to accomplish.  To meet accessibility requirements, the player must support full keyboard operability, closed captions, and audio descriptions.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G87.html" target="_blank" rel="noopener noreferrer">G87: Providing closed captions</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H95.html" target="_blank" rel="noopener noreferrer">H95: Using the track element to provide captions</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g87' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h95' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.2 Captions (Prerecorded)</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#1_2_4")} target="_blank" rel="noopener noreferrer">1.2.4 Captions (Live)</a>, IBM accessibility requirements
      * <a href="https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery/Adding_captions_and_subtitles_to_HTML5_video" target="_blank" rel="noopener noreferrer">Adding captions and subtitles to HTML5 video</a>, Mozilla Developer Network
      * <a href="https://www.washington.edu/accessibility/videos/web-pages/" target="_blank" rel="noopener noreferrer">Adding captions to videos on web pages</a>, University of Washington
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_2_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_2_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='mozilla_developer_network_6' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='university_of_washington_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Audio, video, and auto-updating content can be manipulated](/toolkit/design/ux/keyboard#audio-video-and-auto-updating-content-can-be-manipulated), UX design – Keyboard
      * [Make custom media player and animation controls keyboard accessible](/toolkit/develop/keyboard#player-kbd-accessible), Develop – Keyboard interactions
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_media_players' mdxType="ToolkitTopic"></ToolkitTopic>, UX design – Media players</li>
      <li parentName="ul"><ToolkitTopic qid='dev_keyboard_make_custom' mdxType="ToolkitTopic"></ToolkitTopic>, Develop – Keyboard interactions</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      