import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`If help mechanisms are repeated on multiple Web pages, they occur in the same order relative to other page content.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that a user needing help across a set of pages can locate it in the same way on all pages where it is offered. The requirement is not that all web pages must have the same help features but that where certain types of help is offered, it is consistently provided.`}</p>
    <p>{`The four types of help covered by this requirement are:`}</p>
    <ol>
      <li parentName="ol">{`Human contact details, such as phone numbers and emails;`}</li>
      <li parentName="ol">{`Human contact mechanisms, such as chat clients and contact forms;`}</li>
      <li parentName="ol">{`Self-support options, such as Frequently Asked Questions;`}</li>
      <li parentName="ol">{`A fully automated contact mechanism, such as a chatbot.`}</li>
    </ol>
    <p><strong parentName="p">{`Exception:`}</strong>{` If a change is initiated by the user that would alter the display or layout of the page, such as changing the zoom level or orientation, the location of help on the page can change. It is recommended, to the degree possible, to retain the same basic location of help at different breakpoints in a responsive design.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/consistent-help.html" target="_blank">{`3.2.6 Consistent Help`}</a>{` (external link to WCAG 2.2) for more information on intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      