// https://www.w3.org/TR/wai-aria-practices/examples/combobox/aria1.1pattern/listbox-combo.html/#ex1
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/role-has-required-aria-props */

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { Close20, Search20 } from '@carbon/icons-react';
import _throttle from 'lodash.throttle';
import { navigate } from 'gatsby';
import cx from 'classnames';
import NavContext from 'gatsby-theme-carbon/src/util/context/NavContext';
import { useOnClickOutside } from 'gatsby-theme-carbon/src/util/hooks';

import {
  container,
  input,
  label,
  searchButton,
  searchButtonClose,
  inputWrapper,
  inputFocusWithin,
  hidden,
  inactive,
} from './GlobalSearch.module.scss';

import Menu, { MenuContext } from './Menu';

const MAX_RESULT_LIST_SIZE = 5;

const { SearchNLP } = require("../../../components/rms_resources_mgmt/search_nlp/SearchNLP");

const searchHelper = new SearchNLP();

// See search config
// https://www.gatsbyjs.com/plugins/gatsby-plugin-lunr/
// https://lunrjs.com/guides/searching.html
// https://assortment.io/posts/gatsby-site-search-lunr-js (see boost sets return priority)

const search = _throttle((queryString) => {
  if (window.__LUNR__) {
    try {
      const lunrIndex = window.__LUNR__.en;
      queryString = queryString.replace(/[~-]/g, " ").toLowerCase().trim();

      console.info("----------------")

      // Inject SearchPoC
      console.info("[user]->query: ", queryString) // TODO: remove for production

      // Display what comes back from the index
      console.info("Lunr stemmer", lunrIndex.index.pipeline.runString(queryString)) 

      queryString = searchHelper.nlpPreprocessor(queryString)

      console.info('[nlpProc]->query: ' + queryString) // TODO: remove for production

      const searchResults = lunrIndex.index

        // https://github.com/olivernn/lunr.js/issues/256
        // When a search is made without a wildcard the search terms are passed through the search pipeline, 
        // which by default includes a stemmer. When a wildcard is part of the search term lunr does not pass 
        // the term through the pipeline. This is because the term with wildcard might not be a full word and 
        // therefore stemming would give incorrect results.
        // https://github.com/olivernn/lunr.js/issues/295

        // .search(`${queryString}*`) // - ORIG
        .search(`${queryString}`)

        .slice(0, MAX_RESULT_LIST_SIZE);
      return searchResults.map(({ ref }) => lunrIndex.store[ref]);
    } catch(err) {
      console.error(`Lunr is having issues querying for '${queryString}'`);
      console.error(err)
    }
  }
}, 50);

// TODO pass magnifying ref for escape/close? keep focus within outline for input,
const GlobalSearchInput = () => {
  const optionsRef = useRef([]);
  const [focusedItem, setFocusedItem] = useState(0);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const openButtonRef = useRef(null);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const {
    toggleNavState,
    searchIsOpen,
    isManagingFocus,
    setIsManagingFocus,
  } = useContext(NavContext);

  const clearAndClose = useCallback(() => {
    setQuery('');
    toggleNavState('searchIsOpen', 'close');
    if (openButtonRef.current && isManagingFocus) {
      openButtonRef.current.focus();
    }
  }, [isManagingFocus, toggleNavState]);

  const value = useMemo(
    () => ({ optionsRef, focusedItem, setFocusedItem, clearAndClose }),
    [clearAndClose, focusedItem]
  );

  useEffect(() => {
    if (inputRef.current && searchIsOpen) {
      inputRef.current.focus();
      setInputIsFocused(true);
    }
  }, [searchIsOpen]);

  useOnClickOutside(containerRef, () => {
    toggleNavState('searchIsOpen', 'close');
    setQuery('');
  });

  useEffect(() => {
    if (query) {
      const searchResults = search(query) || [];
      setResults(searchResults);
    } else {
      setResults([]);
    }
    return () => {
      setResults([]);
    };
  }, [query]);

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        setIsManagingFocus(true);
        setFocusedItem((focusedItem + 1) % results.length);
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        setIsManagingFocus(true);
        if (focusedItem - 1 < 0) {
          setFocusedItem(results.length - 1);
        } else {
          setFocusedItem(focusedItem - 1);
        }
        break;
      }
      case 'Escape': {
        e.preventDefault();
        if (query === '') {
          clearAndClose();
        } else {
          setQuery('');
          setIsManagingFocus(true);
          inputRef.current.focus();
        }

        setIsManagingFocus(true);
        setFocusedItem(0);

        break;
      }
      case 'Enter': {
        e.preventDefault();
        if (results[focusedItem]) {
          navigate(results[focusedItem].path);
        }
        break;
      }
      default:
    }
  };

  const onInputHandler = (resultsLength) => {
    if(resultsLength > 0 && focusedItem + 1 > resultsLength){ // Check to see if the focusedItem is never past the end of the resultsArray
      setFocusedItem(resultsLength-1);
    }
  }

  const activeDescendantHandler = (query, focusedItem, setFocusedItem) => {
    if(query.length == 0){ // If there is no text inputed
      return "";
    }
    else if(focusedItem == -1){ // Incase of any error
      setFocusedItem(0);
    }
    else if(focusedItem != focusedItem){ // Checking for NaN case. Incase of any error
      setFocusedItem(0);
    }
    else{
      return `menu-item-${focusedItem}`;
    }
  }

  const ariaExpandedHandler = (resultsLength) => {
    if(resultsLength >= 1){
      return true;
    }else{
      return false;
    }
  }

  const ariaControlsHandler = (resultsLength) => {
    if(resultsLength >= 1){
      return "search-menu";
    }else{
      return "";
    }
  }


  return (
    <MenuContext.Provider value={value}>
      <div
        ref={containerRef}
        className={cx(container, {
          [hidden]: !searchIsOpen,
          [inputFocusWithin]: inputIsFocused,
        })}
        role="search"
      >
        <label htmlFor="search-input" id="search-label" className={label}>
          Search
        </label>
        <div
          className={inputWrapper}
          // aria-owns="search-menu" // Removed to fix a11y search interaction
          // aria-haspopup="menu" // Removed to fix a11y search interaction
          // aria-expanded={searchIsOpen} // Removed to fix a11y search interaction
          // aria-label="Search menu" // Removed to fix a11y search interaction
        >
          <button
            tabIndex={searchIsOpen ? '-1' : '0'}
            className={cx(searchButton, {
              [inactive]: searchIsOpen,
            })}
            ref={openButtonRef}
            type="button"
            aria-label="Open Search"
            onClick={() => {
              toggleNavState('searchIsOpen', 'open');
              toggleNavState('switcherIsOpen', 'close');
            }}
          >
            <Search20 description="Open search" />
          </button>
          <input
            autoComplete="off"
            tabIndex={searchIsOpen ? '0' : '-1'}
            onBlur={() => setInputIsFocused(false)}
            onFocus={() => setInputIsFocused(true)}
            ref={inputRef}
            type="text"
            aria-autocomplete="list"
            aria-controls={ariaControlsHandler(results.length)}
            aria-activedescendant={activeDescendantHandler(query, focusedItem, setFocusedItem)}  // Added to fix a11y search interaction
            className={cx(input, {
              [hidden]: !searchIsOpen,
            })}
            id="search-input"
            placeholder="Search"
            value={query}
            role="combobox" // Added to fix a11y search interaction
            aria-haspopup="listbox" // Added to fix a11y search interaction
            aria-expanded={ariaExpandedHandler(results.length)} // Added to fix a11y search interaction
            aria-label="Search" // Added to fix a11y search interaction
            onKeyDown={onKeyDown}
            onChange={(evt) => setQuery(evt.target.value)}
            onInput={onInputHandler(results.length)}  // Added to fix a11y search interaction
          />
          <button
            tabIndex={searchIsOpen ? '0' : '-1'}
            className={cx(searchButton, searchButtonClose, {
              [hidden]: !searchIsOpen,
            })}
            type="button"
            aria-label="Close search"
            onClick={clearAndClose}
          >
            <Close20 description="Close search" />
          </button>
        </div>
          { ariaExpandedHandler(results.length) && <Menu onKeyDown={onKeyDown} results={results} /> }
      </div>
    </MenuContext.Provider>
  );
};

export default GlobalSearchInput;
