import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Logging into a system should be as simple as possible, while still being secure. Where possible, use a single sign-on (SSO) mechanism, or other means of allowing users to easily authenticate.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Don’t make people recall or transcribe something to log in`}</li>
      <li parentName="ul">{`Don’t use CAPTCHA as part of an authentication process`}</li>
    </ul>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G218" target="_blank" rel="noopener noreferrer">G218: Email link authentications</a>, WCAG 2.2 technique</li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/?version=v7_3#3_3_8")} target="_blank" rel="noopener noreferrer">3.3.8 Accessible Authentication</a>, IBM accessibility requirements (7.3 only)</li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    <ul>
      <li parentName="ul"><a href="../../develop/user-input/#password-fields">Allow pasting and auto-fill in password fields</a>, Develop - User input</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      