import React, { Component } from 'react'
import EventsContent from "../../content/events/eventsBody.mdx"
import "./eventsBody.scss";

class EventsBanner extends Component {
    render() {
        return (
            <div className="eventsBody" >
                {/* <p>Events</p> */}
                {/* <p>Check out what IBM is doing in the world of inclusive experiences!</p> */}
                <EventsContent>

                </EventsContent>
            </div>
        )

    }


}
export default EventsBanner