import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Where ICT has documented accessibility features, it shall be possible to activate those documented accessibility features that are required to meet a specific need without relying on a method that does not support that need.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The European `}<em parentName="p">{`EN 301 549`}</em>{` standard ensures that users of information and communications technology (ICT) in need of accessibility features can successfully activate them.
For example, if a user has a need to only press one key at a time,
the user should not be forced to use a keyboard combination to activate a feature that provides single-key-press operation.
Similarly, a user that needs to operate a pointer using only single clicks should not be forced to use a double-click to activate the single-click feature.`}</p>
    <p>{`This requirement is primarily aimed at platform software (i.e., operating systems).
Applications meet this requirement automatically through features offered by the user agent or platform.`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=28" target="_blank">{`5.2 Activation of accessibility features`}</a>{` (external link to EN 301 549) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      