import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Information, structure, and relationships conveyed through presentation can be programmatically determined or are available in text.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures that Assistive Technologies (AT) can programmatically gather information,
meaningful structure,
and relationships that are displayed visually so that it can be rendered to the AT user.
ATs such as screen readers and magnifiers speak the content or change the presentation layout for the user’s needs.`}</p>
    <p>{`Each visual cue must be implemented in the standard way supported by the platform or technology.
Common visual cues to the semantics of the content on the Web are supported programmatically through `}<strong parentName="p">{`markup`}</strong>{`,
and include headings, labels, forms, tables with associated row and column headers,
images with & without captions, lists, emphasis such as bold and italics,
hyperlinks and paragraphs.`}</p>
    <p>{`In cases where there is no support for programmatic identification of the content,
an alternative must be provided in the text.
For example where identification is required, but no markup is available for emphasis,
additional characters such as a double asterisk (**) may be used.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` The requirement is not limited only to visual cues,
although they are the most common. For example, if audio cues are used to indicate required content, markup or textual identification must be additionally provided.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/info-and-relationships" target="_blank" rel="noopener noreferrer">{`Understanding 1.3.1 Info and Relationships`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      