import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from 'gatsby';
import ToolkitRequirement from '../../../../components/rms_resources/requirements';
import ToolkitReference from '../../../../components/rms_resources/references';
import ToolkitTechnique from '../../../../components/rms_resources/techniques';
import ToolkitTopic from '../../../../components/rms_resources/topics';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`All actions in a widget can be performed with the keyboard`}</li>
      <li parentName="ul">{`Items can be traversed in forward and reverse order`}</li>
      <li parentName="ul">{`Standard keyboard interaction for a component is supported`}</li>
      <li parentName="ul">{`Visual indicators of focus and, where relevant, state exist`}</li>
      <li parentName="ul">{`Audio, video, and auto-playing content (players) are fully operable with keyboard`}</li>
      <li parentName="ul">{`Audio and video contain appropriate controls to activate captions, audio descriptions, or text alternatives`}</li>
      <li parentName="ul">{`Custom shortcuts are not accidentally triggered`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`Navigation and interaction within components and widgets must also be tested
for keyboard accessibility. This includes confirming a keyboard equivalent to
interaction and information revealed by `}<inlineCode parentName="p">{`Onmouseover`}</inlineCode>{` actions. Generally, the
keys used for interaction are Escape, Spacebar, Enter, Home, End, PageUp,
PageDown and the arrow keys. Modifier keys such as Shift, Ctrl and Alt may be
used in conjunction with these keys, such as when selection of multiple items
needs to be supported. Visual indicators of focus and, where relevant, states such as
selected/unselected, expanded/collapsed are provided. If testing for WCAG 2.2, it is also important to confirm that new content or overlays that appear as a result of keyboard interaction do not persist onscreen and potentially obscure an item receiving focus.`}</p>
    <p>{`Where there are established models for keyboard interaction for a widget or
component, all standard methods for selecting and navigating the component
should be supported. We recommend using the `}<ToolkitReference qid='aria_authoring_practices_2' source="hide" name="ARIA authoring practices" mdxType="ToolkitReference"></ToolkitReference>{` as a model
for keyboard interaction. In cases where keyboard conventions are not
followed or keyboard interaction is complex, prevalent and clear keyboard
instructions or accessible documentation must be provided.`}</p>
    <p>{`Pay attention to operability and accessibility of audio-video included
in the application, as well as unusual behavior observed during the visual
inspection steps.`}</p>
    <p>{`Test that any custom keyboard shortcuts that do not contain modifier keys must be able to be remapped or turned off unless they are only activated when the component has focus.`}</p>
    <h5 className="accordionH5">Completes testing for:</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req1_2_1" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req1_2_2" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req1_2_3" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req1_2_4" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req1_2_5" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req1_4_2" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req2_1_2" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req2_2_2" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid="req2_4_7" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req2_1_4" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req2_1_1" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><a href="../../../requirements/requirements/?version=7_3#2_4_11">
  2.4.11 Focus Not Obscured
        </a>, IBM accessibility requirements (7.3 only)</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      