import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The size of the target for pointer inputs is at least 24 by 24 CSS pixels.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that controls are easier to activate with a mouse or by touch. Operable controls are either at least 24x24 pixels or have sufficient space around them, relative to other targets.`}</p>
    <p>{`Larger targets benefit many people, including those operating through finger taps, those with hand tremors, and those who lack fine motor skills. Undersized targets that are positioned with surrounding space equivalent to a 24x24 target can also provide benefit to the same users, and so are excepted.`}</p>
    <p><strong parentName="p">{`Exceptions:`}</strong>{` Besides the `}<strong parentName="p">{`Spacing`}</strong>{` exception, there are a number of other exceptions. If the target is an `}<strong parentName="p">{`Inline`}</strong>{` link in body text, it does not need to meet the minimums. If the target must be undersized for legal or `}<strong parentName="p">{`Essential`}</strong>{` reasons, or if its `}<strong parentName="p">{`Equivalent`}</strong>{` function can also be achieved by a sufficient-sized target elsewhere on the page, it does not need to meet the minimums. Finally, if the target is the browser’s default rendering, such as the items in a `}<inlineCode parentName="p">{`Select`}</inlineCode>{` element, that is excepted.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/target-size-minimum.html" target="_blank">{`2.5.8 Target size (minimum)`}</a>{` (external link to WCAG 2.2) for more information on intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      