import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Applications shall permit user preferences from platform settings for color,
contrast, font type, font size, and focus cursor.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The purpose of this requirement is to enable users who need a high contrast theme,
preferrable fonts, or a more visible focus cursor to define their preferences in one place
rather than having to modify the display settings for each application.
Each application would then need to respect the platform settings.`}</p>
    <p><strong parentName="p">{`High Contrast`}</strong>{`
For most people, color is a matter of preference,
but it is critical for many users with vision impairments.
Many users with low vision need high contrast between text and the background to be able to read information on the screen.
They may even need a particular color scheme, such as white text on a black background,
to prevent the background from “bleeding” over and obscuring the foreground text.
Some people consider the default color scheme legible but find that it causes eyestrain over longer periods of time.`}</p>
    <p>{`Some operating systems provide a “high contrast” setting.
For example, Windows users enable high contrast by going to the Ease of Access Center,
selecting Set up High Contrast and choosing a high contrast theme.`}</p>
    <p><strong parentName="p">{`System settings for color, font type and font size`}</strong>{`
Someone with a vision disability who has difficulty reading small text, certain color combinations,
or text that does not have sufficient font type (style) can use settings available through the operating system.`}</p>
    <p>{`On the Windows platform, users can customize font color, type, and size settings to meet their specific needs.
Applications should inherit those system settings and may also provide capability to customize the font color, type,
and size for use within the application.`}</p>
    <p><strong parentName="p">{`System settings for focus cursor`}</strong>{`
Someone with low vision may have difficulty following the focus cursor.
On the Windows platform, the user can modify the thickness of the focus rectangle and the blinking cursor (caret).`}</p>
    <p><strong parentName="p">{`Exception:`}</strong>{` Applications that are designed to be isolated from their underlying platform software,
including Web applications, shall not be required to conform to this requirement `}<em parentName="p">{`503.2 User Preferences`}</em>{`.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#503.2" target="_blank">{`503.2 User Preferences`}</a>{` (external link to US 508) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      