import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/visual_8_1_eg.png";
import img2 from "./../../../../../visuals/design/visual_8_2_eg.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Required fields include two considerations: how to mark each required field, and how to show what the marks indicate.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Include “(required)” in the input label to indicate required fields`}</li>
      <li parentName="ul">{`If using symbols to indicate required fields, include in label and provide a legend`}</li>
      <li parentName="ul">{`If flagging optional instead of required fields, provide a legend for clarity`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="four input fields, 3 marked with a red asterisk in the label and a legend that says '* indicates required fields'"></img>
        <Caption fullWidth mdxType="Caption">Indicate required fields in an accessible way, such as including a red asterisk in the label. Be sure to provide a legend as well to explain the asterisk's meaning.
        </Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img2} alt="four input fields, one marked with '(optional)' in label and a legend that says 'All fields are required, except where marked (optional)'"></img>
        <Caption fullWidth mdxType="Caption">When only optional fields are labeled, provide a legend that says 'all fields are required, except where marked 'optional'.
        </Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.nngroup.com/articles/required-fields/" target="_blank" rel="noopener noreferrer">Marking required fields in forms</a>, Nielsen Norman Group
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H90" target="_blank" rel="noopener noreferrer">H90: Indicating required form controls using label or legend</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G131" target="_blank" rel="noopener noreferrer">G131: Providing descriptive labels</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G184" target="_blank" rel="noopener noreferrer">G184: Providing text instructions at the beginning of a form or set of fields that describes the necessary input</a>, WCAG 2.1 technique
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='h90' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g131' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g184' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='ux_collective_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_4' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#3_3_2")} target="_blank" rel="noopener noreferrer">3.3.2 Labels or Instructions</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#1_3_1")} target="_blank" rel="noopener noreferrer">1.3.1 Info and Relationships</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Establish a clear association between labels and inputs](/toolkit/design/content/user-input/#establish-a-clear-association-between-labels-and-inputs), this page
      * [Programmatically associate inputs with labels](/toolkit/develop/user-input#inputs), Developer - Develop - User guidance
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='dev_input_programmatically_associate' mdxType="ToolkitTopic"></ToolkitTopic>, Develop - User guidance</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      