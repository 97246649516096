import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/ux_4_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Blind users navigating a page via keyboard can get stuck in a UI if keyboard navigation is not properly implemented. An easy way to reduce keyboard traps is to ensure the Esc key performs the same function as Cancel or Close mouse actions.`}</p>
    <p>{`Sighted keyboard users have a different challenge with overlays. If the new content persists after the user has left it, it can potentially obscure other content, and prevent some controls from being visible as they get focus. One option is to position pop-ups in white space. Another is to ensure notices are dismissed automatically when they no longer have focus.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Communicate that Esc key is always the keyboard equivalent for Cancel or Close`}</li>
      <li parentName="ul">{`Position pop-ups so they do not obscure other content, or dismiss pop-ups on loss of focus (`}<strong parentName="li">{`WCAG 2.2 only`}</strong>{`)`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="modal dialog with annotations saying 'Esc also activates the close button'"></img>
        <Caption fullWidth mdxType="Caption">
Communicate for development that the Esc key always triggers the same function as Cancel or Close buttons.
        </Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.carbondesignsystem.com/components/modal/accessibility" target="_blank" rel="noopener noreferrer">Modal accessibility</a>, Carbon design system
      * <a href="https://www.carbondesignsystem.com/patterns/dialog-pattern" target="_blank" rel="noopener noreferrer">Dialogs</a>, Carbon design system
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_18' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_11' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_1_1")} target="_blank" rel="noopener noreferrer">2.1.1 Keyboard</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_1_2")} target="_blank" rel="noopener noreferrer">2.1.2 No Keyboard Trap</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/?version=7_3#2_4_11")} target="_blank" rel="noopener noreferrer">2.4.11 Focus Not Obscured (Minimum)</a>, IBM accessibility requirements (7.3 only)</li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_pointer_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/able/toolkit/develop/keyboard/#focused-item-visible"
        }}>{`Keep item receiving focus visible`}</a>{`, Develop - Keyboard`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      