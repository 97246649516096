import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If the technologies being used can achieve the visual presentation, text is used to convey information rather than images of text.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures that when technology is capable of achieving a desired visual presentation, designers must use text — not images of text — to allow people with disabilities to adjust the text presentation per their personal preferences. For example, a user may require a particular text size, font family or text/background color.`}</p>
    <p>{`The definition of image of text contains the note: “This does not include text that is part of a picture that contains significant other visual content.” Examples of such pictures include graphs, screenshots, and diagrams that visually convey important information beyond text only.`}</p>
    <p><strong parentName="p">{`Customizable Exception:`}</strong>{` This requirement does not apply to images of text that can be visually customized to the user’s requirements.`}</p>
    <p><strong parentName="p">{`Essential Exception:`}</strong>{` This requirement does not apply when a particular presentation of text is essential to the information being conveyed. For example, logotypes (text that is part of a logo or brand name) are considered essential.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/images-of-text.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.4.5 Images of Text`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      