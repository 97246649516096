import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where the software provides a user interface it shall use the applicable documented platform accessibility services.
If the documented platform accessibility services do not allow the software to meet the applicable requirements of clauses 11.5.2.5 to 11.5.2.17,
then software that provides a user interface shall use other documented services to interoperate with assistive technology.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` The numbering of this requirement was updated to match the latest version of the EN 301 549 standard.
It is otherwise identical to the prior version 7.0 requirement `}<a href="https://www.ibm.com/able/guidelines/ci162/a11y_services_301.html" target="_blank">{`11.3.2.3 Use of Accessibility Services`}</a>{`.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`Interoperability with assistive technology (AT) such as a screen reader, screen magnifier, speech recognition software,
or a Braille display is only possible for software applications that use accessibility services,
also known as accessibility APIs.
Each software platform has its own accessibility services and standardized extensions to the platform’s APIs.
These APIs give applications a way to provide the required accessibility information and make it available to the ATs.
The AT then uses this information as well as interacts with the user interface (UI) elements in the application.`}</p>
    <p>{`By using the platform accessibility services, an application can provide information about UI elements such as the name,
role, value, state, and property information as well as methods to insert text, follow or move the focus,
perform actions on UI elements, and so on.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{`
Clauses 11.3.2.5 to 11.3.2.17 in the EN 301 549 standard are equivalent to the US Revised 508 Standards documented here in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank" rel="noopener noreferrer">502.3.1 Object Information</a> through <a href={withPrefix("/requirements/requirements/#502_3_14")} target="_blank" rel="noopener noreferrer">{`502.3.14 Event Notification`}</a>{`.`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=79" target="_blank">{`11.5.2.3 Use of Accessibility Services`}</a>{` (external link to EN 301 549) for more information.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Development techniques</h5>
    <p>{`Items in this section represent techniques that can be used to meet this requirement:`}</p>
    <ul>
      <li parentName="ul">{`Use documented platform accessibility APIs`}</li>
      <li parentName="ul">{`Use toolkits that incorporate the use of documented accessibility APIs`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      