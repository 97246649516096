import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Standard HTML elements are built with full keyboard operability. The keys used to operate HTML elements are predictable, and consistent with keys used to operate native operating system elements of the same type. Use of  HTML elements reduces the work for a developer because the browser handles the keyboard functionality for the element.`}</p>
    <p>{`If the design shows a certain visual treatment for an HTML element, override the default browser styling and use CSS to change its appearance.`}</p>
    <pre><code parentName="pre" {...{}}>{`<!DOCTYPE html>
<html>
<head>
<style>
input[type=text] {
  width: 100%;
  padding: 12px 18px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 3px solid #gray   ;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

input[type=text]:focus {
  border: 3px solid #555;
}
</style>
</head>
<body>
`}</code></pre>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/css/C15" target="_blank" rel="noopener noreferrer">C15: Using CSS to change the presentation of a user interface component when it receives focus</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H91" target="_blank" rel="noopener noreferrer">H91: Using HTML form controls and links</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='c15' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h91' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_1_1")} target="_blank" rel="noopener noreferrer">2.1.1 Keyboard</a>, IBM accessibility requirements
      * <a href="https://developer.mozilla.org/en-US/docs/Learn/Accessibility/HTML" target="_blank" rel="noopener noreferrer">HTML: A good basis for accessibility</a>, Mozilla Developer Network
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='mozilla_developer_network_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Choose standard HTML elements, where possible](/toolkit/design/ux/keyboard#html-standard), UX design - Keyboard
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, UX design - Keyboard</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      