import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where ICT displays captions, it shall provide a way for the user to adapt the displayed characteristics of captions to their individual needs, except where the captions are displayed as unmodifiable characters.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The European `}<em parentName="p">{`EN 301 549`}</em>{` standard specifies requirements for information and communications technologies such as media players and embedded players that render captions.
This requirement is Not Applicable (N/A) to products and applications that do not play audio and video.`}</p>
    <p>{`Allowing the user to set the following can contribute to meeting this requirement:`}</p>
    <ul>
      <li parentName="ul">{`the background and foreground color of the captions`}</li>
      <li parentName="ul">{`font type and size`}</li>
      <li parentName="ul">{`opacity of the caption background box`}</li>
      <li parentName="ul">{`the border of the fonts`}</li>
    </ul>
    <p>{`Open captions that are bitmap images (burnt into the video) are examples of unmodifiable characters.`}</p>
    <p>{`Several “`}<em parentName="p">{`Time-based Media`}</em>{`” success criteria in WCAG, such as `}<a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">{`1.2.2 Captions (prerecorded)`}</a>{`,
require that captions are provided.
Those requirements stipulate that audio content has been transcribed and is captured in a format that can be used to display captions.
Those WCAG requirements covering the `}<em parentName="p">{`content`}</em>{` of the captions are complementary to chapter 7 `}<em parentName="p">{`ICT with video capabilities`}</em>{` of EN 301 594,
which covers the actual `}<em parentName="p">{`process and mechanisms`}</em>{` of displaying captions during playback,
and the settings for displaying the captions by the media player.
Ensure that the player you’re developing or deploying includes caption display settings the user can control.`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=35" target="_blank">{`7.1.4 Captions Characteristics`}</a>{` (external link to EN 301 549) for more information.`}</p>
    <p><strong parentName="p">{`Related requirements:`}</strong>{`
Applications that play audio and video are required to meet the following related requirements:`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Audio/Video Content requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.2 Captions (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.4 Captions (Live)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#10_5")} target="_blank" rel="noopener noreferrer">10.5 Captioning Positioning (Non-web Documents)</a></li>
    </ul>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Player requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_1")} target="_blank" rel="noopener noreferrer">7.1.1 Captioning Playback</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_2")} target="_blank" rel="noopener noreferrer">7.1.2 Captioning Synchronization</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_3")} target="_blank" rel="noopener noreferrer">7.1.3 Preservation of Captioning</a></li>
      <li parentName="ul">{`7.1.4 Captions Characteristics - `}<em parentName="li">{`this requirement`}</em></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_5")} target="_blank" rel="noopener noreferrer">7.1.5 Spoken Subtitles</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">7.3 User Controls for Captions & Audio Description</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#503_4_1")} target="_blank" rel="noopener noreferrer">503.4.1 Caption Controls</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      