import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Sometimes there are situations where the link text alone doesn’t provide proper context, such as “Read more” or “Help” links. Such links are problematic for people who cannot see the surrounding information that describes where the link actually goes. Fortunately, there are various ways to improve the experience:`}</p>
    <ul>
      <li parentName="ul">{`Work with the content designer to see if the link action can be placed on more meaningful text.`}</li>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` attribute to change the programmatic link label to reference two space-separated ids: the original text label plus the contextual text that already exists on the page.`}</li>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` attribute to provide a descriptive label for a generic link that includes the exact text of the original link label. Only use this method in cases where there is no text on the page that describes the link’s purpose.`}</li>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`title`}</inlineCode>{` attribute to provide additional context. This will provide hover content making the context available to the user visually. `}</li>
    </ul>
    <p>{`Note: If using `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` or `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to change the label, be sure to include the text of the generic link name, spelled exactly as it appears visually, in the programmatic label. This is to ensure the programmatic label also satisfies 2.5.3 Label in Name.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA7" target="_blank" rel="noopener noreferrer">ARIA7: Using aria-labelledby for link purpose</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA8" target="_blank" rel="noopener noreferrer">ARIA8: Using aria-label for link purpose</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/css/C7" target="_blank" rel="noopener noreferrer">C7: Using CSS to hide a portion of the link text</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria7' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria8' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h33' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='c7' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_4_4")} target="_blank" rel="noopener noreferrer">2.4.4 Link Purpose (In Context)</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_4_4")} target="_blank" rel="noopener noreferrer">Identifying the purpose of a link using link text combined with programmatically determined link context</a> in Develop - Supplemental technique, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_5_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    <ul>
      <li parentName="ul"><ToolkitTopic qid='content_link_text' mdxType="ToolkitTopic"></ToolkitTopic>, Content design</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      