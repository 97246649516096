import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`If `}<ToolkitTopic qid='content_equivalents_provide_succinct' name="content designers have provided detailed information" source="hide" mdxType="ToolkitTopic"></ToolkitTopic>{` about a complex image, but it is too long for an `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute and does not appear in the page content, developers must use an accessible method of exposing it. A frequent implementation is to provide a long description in a separate page or overlay, which can be exposed to the user from a link or other control such as an information icon. You can also consider an expandable details section. HTML5 deprecated the HTML4 `}<inlineCode parentName="p">{`longdesc`}</inlineCode>{` attribute and then approved it as an extension. However, some browsers do not expose the link to the longdesc visually in the UI which makes it difficult for non-screen reader users to discover or access the long description.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H53" target="_blank" rel="noopener noreferrer">H53: Using the body of the object element</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H45" target="_blank" rel="noopener noreferrer">H45: Using longdesc</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='h53' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h45' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_1_1")} target="_blank" rel="noopener noreferrer">1.1.1 Non-text Content</a>, IBM accessibility requirements
      * <a href="https://www.w3.org/WAI/tutorials/images/complex/" target="_blank" rel="noopener noreferrer">Complex images</a>, W3C web accessibility tutorials
      * <a href="https://www.w3.org/TR/html-longdesc/" target="_blank" rel="noopener noreferrer">HTML5 image description extension (longdesc)</a>, W3C recommendation
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_3' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      