import React, { useContext } from 'react';
import classnames from 'classnames';
import cx from 'classnames';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import Launch16 from '@carbon/icons-react/lib/launch/16';
import Locked16 from '@carbon/icons-react/lib/locked/16';

import "./LeftNav.scss"
import {
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  // SideNavMenu
} from 'carbon-components-react/lib/components/UIShell';

import NavContext from 'gatsby-theme-carbon/src/util/context/NavContext';
import LeftNavItem from 'gatsby-theme-carbon/src/components/LeftNav/LeftNavItem';
// import LeftNavResourceLinks from 'gatsby-theme-carbon/src/components/LeftNav/ResourceLinks';
// import LeftNavWrapper from 'gatsby-theme-carbon/src/components/LeftNav/LeftNavWrapper';
import { sideNavDark } from 'gatsby-theme-carbon/src/components/LeftNav/LeftNav.module.scss';

const LeftNav = props => {
  const {
    allNavItems2Yaml: { edges },
  } = useStaticQuery(graphql`
    query LEFT_NAV_QUERY2 {
      allNavItems2Yaml {
        edges {
          node {
            nav {
              id
              pages {
                title
                pages {
                  path
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  

  const {
    leftNavIsOpen
  } = useContext(NavContext);

  const navMap = {}
  edges.map(({ node }) => navMap[node.nav[0].id] = node.nav[0].pages);
  const renderNavItems = (nav) => {
    nav = nav || "default";
    if (!(nav in navMap && navMap[nav])) { return <React.Fragment></React.Fragment> }
    let mainNav = navMap[nav].map((item, i) => (
      <div>
        <LeftNavItem items={item.pages} category={item.title} key={i} />
      </div>));

    var returnValue = []; // Creating an overall nav which combines 1) The "regular nav" aka mainNav 2) The divider line 3) The link to github for issues 

    returnValue.push(mainNav);

    returnValue.push(
      <hr 
      class={classnames({
        'sideHrDark': props.theme === 'dark', // conditionally set dark theme for pages that pass theme string
        'githubHr': 
        props.theme !== 'dark',
      })}
      style={{marginTop:"64px", marginBottom:"24px"}}></hr>
    );

    returnValue.push(
      <div>
        <li class="bx--side-nav__item" style={{marginBottom:"8px"}}>
          <a href={withPrefix("/toolkit_license")} class="bx--side-nav__link" >
            <span class="bx--side-nav__link-text">Toolkit license</span>
            <div className="githubCenterItems">
             </div>
            {/* <div className="bx--side-nav__link-text">
              <Launch16 />
             </div> */}
          </a>
        </li>
      </div>
    );

    returnValue.push(
      <div>
        <li class="bx--side-nav__item" style={{marginBottom:"8px"}}>
          <a target="_blank" rel="noopener noreferrer" class="bx--side-nav__link" href="https://github.com/IBMa/equal-access/issues/new/choose">
            <span class="bx--side-nav__link-text">Submit a GitHub issue</span>
            <div className="githubCenterItems">
             </div>
            <div className="bx--side-nav__link-text">
              <Launch16 />
             </div>
          </a>
        </li>
      </div>
    );

    returnValue.push(
      <div>
        <li class="bx--side-nav__item" style={{marginBottom:"8px"}}>
          <a target="_blank" rel="noopener noreferrer" class="bx--side-nav__link" href="https://pages.github.ibm.com/IBMa/able/">
            <span class="bx--side-nav__link-text">IBM Accessibility Central</span>
            <div className="githubCenterItems">
             </div>
            <div className="bx--side-nav__link-text">
              <Locked16 />
             </div>
          </a>
        </li>
      </div>
    );

    returnValue.push(
      <div>
        <li class="bx--side-nav__item" style={{marginBottom:"8px"}}>
          <a target="_blank" rel="noopener noreferrer" class="bx--side-nav__link" href="https://ibm-studios.slack.com/archives/C036P1CTN">
            <span class="bx--side-nav__link-text">#accessibility-at-ibm</span>
            <div className="githubCenterItems">
             </div>
            <div className="bx--side-nav__link-text">
              <Locked16 />
             </div>
          </a>
        </li>
      </div>
    );

    return returnValue;
  }
  if (!leftNavIsOpen) {
    return <React.Fragment />;
  }

  // Note: hamburger menu doesn't expand unless expanded={true}, and defaultExpanded={true} below
  let retVal = 
  <div  className={classnames({
    [sideNavDark]: props.theme === 'dark' // conditionally set dark theme for pages that pass theme string
  })}
  >
<SideNav
    expanded={true}
    defaultExpanded={true}
    aria-label="Sidebar"
    className={classnames({
      'bx--side-nav--website--dark': 
      props.theme === 'dark', 
      'bx--side-nav--website--light': 
      props.theme !== 'dark', 
    })}
  >
    <SideNavItems>
      <HeaderSideNavItems>
        {props.headerNav}
      </HeaderSideNavItems>
      {renderNavItems(props.nav)}
      {/* <LeftNavResourceLinks /> */}
    </SideNavItems>
  </SideNav>
  </div>;
  // TODO: replace old addon website styles with sass modules, move to wrapper
  return retVal;
};

export default LeftNav;