import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/L2_ux_2_1_eg.png";
import img2 from "./../../../../../visuals/design/L2_ux_2_2_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users who are blind navigate the page via keyboard and screen readers. Autoplaying audio competes with their screen reader’s audio, making it difficult or impossible to find and turn off the disruption. Autoplaying content can also be distracting, especially for users with cognitive disabilities, and affect their ability to complete a task, or even trigger debilitating physical reactions.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Inform development that all media player controls must be keyboard operable`}</li>
      <li parentName="ul">{`Stop autoplaying content on keyboard focus or provide a simple pause mechanism`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="video player with focus indicator on closed caption button"></img>
        <Caption fullWidth mdxType="Caption">Communicate with development so that all media controls are operable by keyboard. Copyright IBM; all rights reserved.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img2} alt="annotated wireframe of carousel saying tab key can focus on and exit carousel animation and carousel should stop when focused on"></img>
        <Caption fullWidth mdxType="Caption">The wireframe annotations show the carousel stops auto-playing when it receives focus.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/media/av/player/#player-accessibility-functionality" target="_blank" rel="noopener noreferrer">Media players</a>, W3C accessibility fundamentals
      * <a href="https://www.digitala11y.com/accessible-jquery-html5-media-players/" target="_blank" rel="noopener noreferrer">Accessible HTML5 media players & resources</a>, Digital a11y
      * <a href="https://www.nngroup.com/articles/video-usability/" target="_blank" rel="noopener noreferrer">Video usability</a>, Nielsen Norman Group
      * <a href="https://www.smashingmagazine.com/2018/04/designing-accessibility-inclusion/#lens-audio-video" target="_blank" rel="noopener noreferrer">Audio and video</a>, Smashing magazine
      * <a href="https://www.nngroup.com/articles/animation-usability/" target="_blank" rel="noopener noreferrer">Animation for attention and comprehension</a>, Nielsen Norman Group
      * <a href="https://www.smashingmagazine.com/2018/04/designing-accessibility-inclusion/#lens-animation-effects" target="_blank" rel="noopener noreferrer">Animation and effects</a>, Smashing magazine
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='w3c_accessibility_fundamentals_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='digital_a11y_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_8' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='smashing_magazine_3' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='smashing_magazine_2' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_2")} target="_blank" rel="noopener noreferrer">1.4.2 Audio Control</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_1_1")} target="_blank" rel="noopener noreferrer">2.1.1 Keyboard</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_1_2")} target="_blank" rel="noopener noreferrer">2.1.2 No Keyboard Trap</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_2_2")} target="_blank" rel="noopener noreferrer">2.2.2 Pause, Stop, Hide</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_4_7")} target="_blank" rel="noopener noreferrer">2.4.7 Focus visible</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_2_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_7' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Video captions](/toolkit/design/content/#video-captions), Content design
      * [Confirm component keyboard interaction](/toolkit/develop/overview#confirm-keyboard-interaction), Core considerations - Unit testing
      * [Make custom media player and animation controls keyboard accessible](/toolkit/develop/keyboard#player-kbd-accessible), Develop - Keyboard interactions
      * [Autoplay content](/toolkit/design/content/#autoplay-content), Content design
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='content_video_captions' mdxType="ToolkitTopic"></ToolkitTopic>, Content design</li>
      <li parentName="ul"><ToolkitTopic qid='dev_testing_confirm_component' mdxType="ToolkitTopic"></ToolkitTopic>, Core considerations - Unit testing</li>
      <li parentName="ul"><ToolkitTopic qid='dev_keyboard_make_custom' mdxType="ToolkitTopic"></ToolkitTopic>, Develop – Keyboard interactions</li>
      <li parentName="ul"><ToolkitTopic qid='content_autoplaying_content' mdxType="ToolkitTopic"></ToolkitTopic>, Content design</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      