import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Content does not restrict its view and operation to a single display orientation,
such as portrait or landscape.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that content does not get locked to either portrait or landscape presentation mode.`}</p>
    <p>{`The key intended beneficiaries of this requirement are users unable to modify the orientation of devices.
If designers force content to only display in portrait (or landscape) mode,
it deprives users of the option to consume the content in the perspective that they need.
If a user has a device affixed to a wheelchair or is otherwise unable to reorient the device to match the design-imposed orientation,
the content can become unusable.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` This requirement bans developer-controlled techniques to limit the display orientation of an application on the device.
Where a user activates any system-level orientation lock,
applications will be subjected to this system-level setting without any action required on the developer’s part.
System or hardware locks are user-imposed preferences and are not contradictory to this requirement.`}</p>
    <p><strong parentName="p">{`Essential Exception:`}</strong>{` This requirement does not apply where a specific display orientation is essential.
WCAG defines essential as: ”`}<em parentName="p">{`if removed, would fundamentally change the information or functionality of the content, and information and functionality cannot be achieved in another way that would conform`}</em>{`.”
Such a situation will be rare;
most applications should be able to be usable when displayed in either orientation although one orientation may be less optimal.`}</p>
    <p>{`An example of an application whose orientation would be considered essential is a piano keyboard emulator.
Since such an app mimics a physical piano keyboard, which is heavily biased to horizontal operation,
the few keys available in a portrait orientation would make the application’s intended function unusable.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/UNDERSTANDING/orientation.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.3.4 Orientation`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      