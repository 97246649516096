import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
import img1 from "./../../../../../visuals/design/content_2_1_eg.png";
import img2 from "./../../../../../visuals/design/content_2_2_eg.png";
import img3 from "./../../../../../visuals/design/content_2_3_eg.png";
import img4 from "./../../../../../visuals/design/content_2_4_eg.png";
import img5 from "./../../../../../visuals/design/content_2_5_eg.png";
import "../../../topics.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Providing proper alternative text for implementation allows blind users to perceive any meaningful visuals on a page (via a screen reader). `}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Identify and annotate decorative images`}</li>
      <li parentName="ul">{`Provide alternative text for meaningful images, including logos and icons`}</li>
      <li parentName="ul">{`Ensure the alternative text describes the purpose, not the image`}</li>
      <li parentName="ul">{`Provide both a short and a more detailed description for complex visuals`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="annotations marking two images as 'decorative'"></img>
        <Caption fullWidth mdxType="Caption">Annotate an image as decorative (or alt=“”) if it provides no meaningful info, only for visual formatting, or has a caption providing context. Copyright IBM; all rights reserved.
        </Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img2} alt="annotations marking ibm logo as 'alt=ibm logo'"></img>
        <Caption fullWidth mdxType="Caption">Provide specific and concise alt text that describes the meaning of the image. Copyright IBM; all rights reserved.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img3} alt="Annotations marking magnifying glass as 'alt=search', avatar as 'alt=profile', and bell icon as 'alt=notifications'"></img>
        <Caption fullWidth mdxType="Caption">Describe the purpose instead of the images used to represent controls (such as these icons).</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img4} alt="screenshot with caption 'IBM equal access toolkit' and annotations of its alt text"></img>
        <Caption fullWidth mdxType="Caption">For screenshots, the alternative text should list relevant text on the screen as well as provide critical visual information. Here, a caption adds some context. More details could be provided in the nearby text, including a link to the page.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img5} alt="image of graph with title 'february revenue', a caption 'monthly revenue went up by 20% year over year', and annotations saying 'alt=bar chart showing month's daily sales against historical average'"></img>
        <Caption fullWidth mdxType="Caption">For this complex visual, a caption provides the key takeaway, a title provides context, and the alt text provides a summary. Depending on context, more detailed information on the chart could be provided in a spreadsheet.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g73' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g94' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g100' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g196' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_4' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_5' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_6' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='princeton_university_digital_accessibility_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_14' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='webaim_2' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_5' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    <ul>
      <li parentName="ul"><ToolkitTopic qid='dev_non-text_give_decorative' mdxType="ToolkitTopic"></ToolkitTopic>, Develop – Non-text content</li>
      <li parentName="ul"><ToolkitTopic qid='content_images_of_text' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='dev_non-text_implement_text' mdxType="ToolkitTopic"></ToolkitTopic>, Develop – Non-text content</li>
      <li parentName="ul"><ToolkitTopic qid='dev_non-text_use_html' mdxType="ToolkitTopic"></ToolkitTopic>, Develop – Non-text content</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      