import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/visual_2_1_eg.png";
import img2 from "./../../../../../visuals/design/visual_2_2_do.png";
import img3 from "./../../../../../visuals/design/visual_2_2_dont.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users need to easily identify links in content. Removing the underline or not having enough visual contrast with the surrounding text will decrease the discoverability, especially for users with low vision or cognitive disabilities.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Confirm link text is underlined and/or contrasts 3:1 with surrounding text. Achieving both is optimal.`}</li>
      <li parentName="ul">{`Make sure an underline persists on pointer hover and keyboard focus`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img1} alt="body text with link text underlined"></img>
        <Caption fullWidth mdxType="Caption">Underlining link text is an easy way to help users identify links in content.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
     <DoDontExample aspectRatio="1:1" type="do" captionTitle="" caption="If the underline is removed, have link text contrast at least 3:1 with its surrounding text." mdxType="DoDontExample">
    <img src={img2} alt="Good example: body text with blue link text that has sufficient contrast with surrounding text and background"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
     <DoDontExample aspectRatio="1:1" type="dont" captionTitle="" caption="Don't remove underlines from link text without sufficiently contrasting with surrounding text (3:1) and the background (4.5:1)" mdxType="DoDontExample">
    <img src={img3} alt="Bad example: body text with blue link text that has insufficient contrast with surrounding text and background"></img>
        </DoDontExample>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.nngroup.com/articles/guidelines-for-visualizing-links/"  target="_blank" rel="noopener noreferrer">Guidelines for visualizing links</a>, Nielsen Norman Group
      * <a href="https://xd.adobe.com/ideas/process/ui-design/use-underline-text-improve-ux/" target="_blank" rel="noopener noreferrer">How to use underline text to improve UX</a>, Adobe
      * <a href="https://www.carbondesignsystem.com/components/link/usage" target="_blank" rel="noopener noreferrer">Link</a>, Carbon design system
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_13' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='adobe_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_17' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_1")} target="_blank" rel="noopener noreferrer">1.4.1 Use of Color</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Text styles](/toolkit/design/visual/#text-styles), this page
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='visual_text_styles' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      