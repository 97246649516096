import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Where an application provides an alternative user interface that functions as assistive technology, the application shall use platform and other industry standard accessibility services.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement clarifies that any ”`}<strong parentName="p">{`novel`}</strong>{`” interface designed by a team to support users with disabilities should be considered as an assistive technology
and must satisfy the `}<strong parentName="p">{`502.x interoperability requirements`}</strong>{` of the `}<strong parentName="p">{`US Revised 508 Standard`}</strong>{`.`}</p>
    <p>{`As discussed in the standard, “The section aims to forestall the rare, but problematic,
situation where there is a question about whether a product should be treated as assistive technology or another type of software. Examples of alternative user interfaces include on-screen keyboards for a single switch user, and screen reading software for a person who is blind”`}</p>
    <p>{`More recent smartphone-relevant examples include voice recognition and on-screen keyboards with word prediction.
In such situations, developers should use native platform versions of these features which should provide the compatibility with the assistive technologies (ATs).
If an application creates a custom version of such interfaces,
it must utilize industry standards for accessibility.`}</p>
    <p><strong parentName="p">{`Note:`}</strong></p>
    <ul>
      <li parentName="ul">{`This requirement is expected to be `}<strong parentName="li">{`Not Applicable`}</strong>{` for most software application.`}</li>
      <li parentName="ul">{`This requirement is not intended to apply in situations where an alternative but conventional presentation of material is undertaken to support users with disabilities.
For example, providing an alternative to complex visual information in the form of a sortable data table is an alternative presentation of the information in a conventional format.
The tabular information does not function as assistive technology.`}</li>
    </ul>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#503.3" target="_blank">{`503.3 Alternative User Interfaces`}</a>{` (external link to US 508) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      