import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Once the keyboard operation is fully defined, use ARIA techniques to implement any custom components. When using a UI component library, use only components that have a full implementation of keyboard access.  For all other components, use the ARIA authoring practices and examples to guide keyboard implementation. Remember that although you can assign roles through ARIA, you still need to implement all  keyboard interaction using events like `}<inlineCode parentName="p">{`onkeydown`}</inlineCode>{`.`}</p>
    <p>{`Wireframes may specify where navigation has been improved by grouping controls to reduce tabbing. This is most common in composite components, such as navigation trees and toolbars where the `}<inlineCode parentName="p">{`tabindex`}</inlineCode>{` attribute and `}<inlineCode parentName="p">{`aria-activedecendent`}</inlineCode>{` property can be used to manage navigation within a component.  The ARIA authoring practices provide keyboard operation guidance, as well as working examples where you can examine coding techniques.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='scr20' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr35' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='mozilla_developer_network_9' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_13' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    <ul>
      <li parentName="ul"><ToolkitTopic qid='dev_testing_confirm_component' mdxType="ToolkitTopic"></ToolkitTopic>, Develop - Unit testing</li>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, UX design - Keyboard</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      