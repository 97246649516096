import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`For moving, blinking, scrolling, or auto-updating information,
the user can pause, stop or hide it, or control the update frequency.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures distractions to users are avoided during their interaction with content.
There are two considerations:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Moving, blinking, scrolling:`}</strong>{` For any moving, blinking, or scrolling information that`}<ul parentName="li">
          <li parentName="ul">{`starts automatically,`}</li>
          <li parentName="ul">{`is presented in parallel with other content,`}</li>
          <li parentName="ul">{`lasts more than five seconds, there is a mechanism for the user to pause, stop, or hide it; and`}</li>
        </ul></li>
      <li parentName="ol"><strong parentName="li">{`Auto-updating:`}</strong>{` For any auto-updating information that`}<ul parentName="li">
          <li parentName="ul">{`starts automatically and`}</li>
          <li parentName="ul">{`is presented in parallel with other content,`}</li>
          <li parentName="ul">{`there is a mechanism for the user to pause, stop, or hide it, or to control the frequency of the update.`}</li>
        </ul></li>
    </ol>
    <p>{`”`}<em parentName="p">{`Moving, blinking and scrolling`}</em>{`” refers to visible content that conveys a sense of motion.
Common examples include auto-playing videos, synchronized media presentations, animations, real-time games,
and scrolling stock tickers.`}</p>
    <p>{`”`}<em parentName="p">{`Auto-updating`}</em>{`” refers to visible content that updates or disappears based on a preset time interval.
Common automatically updating content includes audio, weather information, news,
stock price updates, and auto-advancing presentations and messages.`}</p>
    <p>{`For all these types of content, the user needs the ability to pause, stop, or hide the content.
In addition, for auto-updating,
designers have the option of providing the user with a means to control the frequency of updates.`}</p>
    <p>{`Content that moves or updates automatically can be a barrier to anyone who has trouble reading stationary text quickly,
anyone who has trouble tracking moving objects,
and screen readers which may not be able to render all of the content (text) before it gets updated;
the user can lose their place if the updates happen in the middle of reading the content.`}</p>
    <p>{`Moving content can also be a severe distraction for some people.
Certain groups, particularly those with attention deficit disorders,
find blinking content distracting and have difficulty concentrating on other content.
The five-second limit is long enough to get a user’s attention,
but short enough for a user to wait out the distraction before reading the page.`}</p>
    <p>{`The requirement `}<a href="https://www.w3.org/TR/WCAG22/#seizures-and-physical-reactions" target="_blank">{`Understanding 2.3.3 Animation from Interactions`}</a>{` applies when a user’s interaction initiates animation.
In contrast this requirement, 2.2.2 `}<em parentName="p">{`Pause, stop, hide`}</em>{`, applies when the web page or application initiates animation.`}</p>
    <p><strong parentName="p">{`Note 1:`}</strong>{` For requirements related to flickering or flashing content,
see `}<a href="https://www.w3.org/TR/WCAG22/#seizures-and-physical-reactions" target="_blank">{`Guideline 2.3 Seizures and physical reactions`}</a>{`.`}</p>
    <p><strong parentName="p">{`Note 2:`}</strong>{` Since any content that does not meet this requirement can interfere with a user’s ability to use the whole page/application,
all content, whether it is used to meet other requirements or not, must meet this requirement.
WCAG `}<a href="https://www.w3.org/TR/WCAG20/#cc5" target="_blank">{`Conformance Requirement 5 Non-interference`}</a>{` states that users must not be blocked from reaching all parts of the content,
regardless of technology limitations.`}</p>
    <p><strong parentName="p">{`Essential Exception:`}</strong>{` If the movement, blinking, scrolling or auto-updating is part of an activity where it is essential,
this requirement does not apply.
An animation, such as `}<em parentName="p">{`loading…`}</em>{` that occurs as part of a preload phase or similar situation
can be considered essential if any user interaction cannot occur during that phase and if not indicating progress could confuse users or cause them to think that content was frozen or broken.`}</p>
    <p>{`Refer to the definition of `}<a href="https://www.w3.org/TR/2008/REC-WCAG20-20081211/#blinksdef" target="_blank">{`blinking`}</a>{`.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/pause-stop-hide" target="_blank">{`Understanding 2.2.2 Pause, Stop, Hide`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      