import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users who are blind rely on keyboard and screen readers to navigate a page. Related input elements that are visually indicated as a group are implemented by development to inform screen readers of their relationship. This means that users who cannot see the groups can experience them through the information the assistive technology announces aloud. Examples of such groups are a shipping address (made up of several input fields), radio buttons, and checkboxes.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Designate forms and annotate any grouping of inputs`}</li>
    </ul>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/tutorials/forms/grouping/" target="_blank" rel="noopener noreferrer">Grouping controls</a>, W3C web accessibility tutorial
      * <a href="https://www.carbondesignsystem.com/components/form/accessibility" target="_blank" rel="noopener noreferrer">Form accessibility</a>, Carbon design system
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_16' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_3_1")} target="_blank" rel="noopener noreferrer">1.3.1 Info and Relationships</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#3_3_2")} target="_blank" rel="noopener noreferrer">3.3.2 Labels or Instructions</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      