import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The human language of each passage or phrase in the content can be programmatically determined.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures that developers clearly identify language changes for any portion of the content from the primary language on a page.
The user agents and the assistive technologies need this information to correctly present content written in multiple languages, using the presentation and pronunciation rules for that language.`}</p>
    <p><strong parentName="p">{`Note 1:`}</strong>{` There are some software and non-web document technologies where there is no assistive technology supported method for marking the language for the different passages or phrases in content,
and it would not be possible to meet this requirement with those technologies.`}</p>
    <p><strong parentName="p">{`Note 2:`}</strong>{` While the European `}<a parentName="p" {...{
        "href": "https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=75"
      }}>{`EN 301 539, clause 11.3.1.2 Void`}</a>{` standard does not require `}<em parentName="p">{`3.1.2 Language of Parts`}</em>{` for non-web software,
it is still required by `}<a parentName="p" {...{
        "href": "https://www.access-board.gov/ict/#chapter-5-software"
      }}>{`US 508`}</a>{`.`}</p>
    <p><strong parentName="p">{`Exception:`}</strong>{` Proper names, technical terms, words of indeterminate language, and words or phrases that have become part of the vernacular of the immediately surrounding text are excluded from this requirement.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/language-of-parts.html" target="_blank" rel="noopener noreferrer">{`Understanding 3.1.2 Language of Parts`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      