import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If any audio plays automatically for more than 3 seconds, either a mechanism is available to pause or stop the audio, or a mechanism is available to control audio volume independently from the overall system volume level.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`Individuals who use screen reading software can find it hard to hear the speech output if there is other audio playing at the same time. This difficulty is exacerbated when the screen reader’s speech output is software-based (as most are today) and is controlled via the same volume control as the auto-playing sound. Therefore, this requirement ensures the user is able to turn off or adjust the volume of the background sound independently from the system volume control.`}</p>
    <p>{`Automatically starting sounds is highly discouraged, especially those that last for more than 3 seconds, as the sound interferes with the ability for a screen reader user to hear navigational cues to find the sound adjustment controls. It is better to have any audio content started through a user action.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Having control of the volume includes the ability to silence the sound.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/audio-control" target="_blank" rel="noopener noreferrer">{`Understanding 1.4.2 Audio Control`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      