import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`For users to be able to interact using a keyboard, components must be included in the tab order (so that they take focus when a user navigates a page with the Tab key). Wireframes should indicate to developers both what elements take focus, and in what order. They may also indicate interaction when navigation takes place within a complex widget (usually using arrow keys). Where wireframes do not provide clarity on keyboard navigation, discuss with the design team.`}</p>
    <p>{`The code techniques for including elements in the tab order vary depending on the source of the component:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Standard HTML. Interactive HTML elements are automatically added to the tab order, based on placement in the Document Object Model (DOM), with no further effort by the developer.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Components from a code framework. Check the documentation and perform a keyboard test to ensure each supports tab navigation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Custom elements. Depending on usage, either:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`tabindex=”0”`}</inlineCode>{` to add a focusable element naturally into the tab order, according to its placement in the DOM.`}</li>
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`tabindex=”-1”`}</inlineCode>{` to remove the element from the initial tab order. Then make it focusable using `}<inlineCode parentName="li">{`element.focus()`}</inlineCode>{` for dynamic elements such as dialog.`}</li>
        </ul>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`<!-- Set tab order according to DOM -->
<div tabindex="0">Div with tabindex set to 0, 1st tab stop</div>
<div>Div with no tabindex, *NO* tab stop</div>
<button type="button">Button, 2nd tab stop</button>
<a href="pretend_target.html">Link, 3rd tab stop</a>
<div tabindex="0">Div with tabindex set to 0, 4th tab stop</div>
`}</code></pre>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G59" target="_blank" rel="noopener noreferrer">G59: Placing the interactive elements in an order that follows sequences and relationships within the content</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR26.html" target="_blank" rel="noopener noreferrer">SCR26: Inserting dynamic content into the Document Object Model immediately following its trigger element</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR37.html" target="_blank" rel="noopener noreferrer">SCR37: Creating Custom Dialogs in a Device Independent Way</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g59' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr26' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr37' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_1_1")} target="_blank" rel="noopener noreferrer">2.1.1 Keyboard</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_4_3")} target="_blank" rel="noopener noreferrer">2.4.3 Focus order</a>, IBM accessibility requirements
      * <a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/Keyboard-navigable_JavaScript_widgets" target="_blank" rel="noopener noreferrer">Keyboard-navigable javascript widgets</a>, Mozilla Development Network
      * <a href="http://w3c.github.io/aria-practices/#kbd_focus_activedescendant" target="_blank" rel="noopener noreferrer">Managing focus in composite widgets</a>, ARIA authoring practices
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='mozilla_developer_network_9' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_8' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Design for keyboard navigation](/toolkit/design/ux/navigation#design-for-keyboard-navigation), UX design - Navigation
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, UX design - Keyboard interaction</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      