import React from 'react';
import slugify from 'slugify';
import { useStaticQuery, graphql } from 'gatsby';

import BackToTopBtn from 'gatsby-theme-carbon/src/components/BackToTopBtn';
import Layout from '../components/Layout';
import PageHeaderCC from '../components/PageHeaderCC';
import EditLink from 'gatsby-theme-carbon/src/components/EditLink';
// import NextPrevious from 'gatsby-theme-carbon/src/components/NextPrevious';
import PageTabsCL from 'gatsby-theme-carbon/src/components/PageTabsCL';
import MainCL from 'gatsby-theme-carbon/src/components/MainCL';
import { Helmet } from "react-helmet";

import './DefaultCL.scss'

const Default = ({ pageContext, children, location, Title, hideBanner, preFooter }) => {
    const {
        site: { pathPrefix },
    } = useStaticQuery(graphql`
        query PATH_PREFIX_QUERYCC {
          site {
            pathPrefix
          }
        }
      `);
    if (!pageContext) {
        return <React.Fragment>{children}</React.Fragment>
    }
    const { frontmatter = {}, relativePagePath, titleType } = pageContext;
    const { tabs, title, theme, description, keywords, nav } = frontmatter;
    if (frontmatter.redirect) {
        setTimeout(() => {
            if (typeof document !== "undefined") {
                document.location.href = frontmatter.redirect
            }
        },1);
        return <React.Fragment></React.Fragment>
    }
    // get the path prefix if it exists

    // let gatsby handle prefixing
    const slug = pathPrefix
        ? location.pathname.replace(pathPrefix, '')
        : location.pathname;

    const getCurrentTab = () => {
        if (!tabs) return '';
        return slug.split('/').slice(-1)[0] || slugify(tabs[0], { lower: true });
    };

    const currentTab = getCurrentTab();
    return (
        <Layout
            homepage={false}
            theme={theme}
            pageTitle={title}
            pageDescription={description}
            pageKeywords={keywords}
            titleType={titleType}
            nav={nav}
            preFooter={preFooter}
            topNav={true}
            location={location}
        >
            {typeof window !== "undefined" && !window.location.href.includes("localhost") && <Helmet>
                <script type = "text/javascript">{`
                    window.digitalData = {
                        page: {
                            category: {
                                primaryCategory: 'NO_CATEGORY_ASSIGNED',
                            },
                            pageInfo: {
                                ibm: {
                                    siteID: 'IBM_ACCESSIBILITY',
                                }
                            },
                        }
                    };`}
                </script>
                <script src="//1.www.s81c.com/common/stats/ibm-common.js" type="text/javascript" async="async"></script>
            </Helmet>}
            {!hideBanner && <PageHeaderCC title={Title ? <Title /> : title} label="label" tabs={tabs} />}
            {tabs && <PageTabsCL slug={slug} tabs={tabs} currentTab={currentTab}/>}
            <div style={{maxWidth: "99rem"}}>
            <MainCL>
                {children}
                <EditLink relativePagePath={relativePagePath} />
            </MainCL>
            </div>
            {/* <NextPrevious
                pageContext={pageContext}
                location={location}
                slug={slug}
                tabs={tabs}
                currentTab={currentTab}
            /> */}
            <BackToTopBtn />
        </Layout>
    );
};

export default Default;