import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`States and properties that can be set by the user shall be capable of being set programmatically,
including through assistive technology.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`Someone who is blind or vision impaired uses a computer with the aid of assistive technology (AT) such as a screen reader,
screen magnifier, or Braille display.
Software must provide semantic information about the user interface objects to the AT.
This is done through platform specific application programming interfaces (APIs) that programmatically expose information to the AT including `}<inlineCode parentName="p">{`name`}</inlineCode>{` (identity),
`}<inlineCode parentName="p">{`role`}</inlineCode>{` (operation), `}<inlineCode parentName="p">{`state`}</inlineCode>{`, `}<inlineCode parentName="p">{`boundary`}</inlineCode>{`, `}<inlineCode parentName="p">{`properties`}</inlineCode>{`, and `}<inlineCode parentName="p">{`description`}</inlineCode>{` of user interface objects.`}</p>
    <p>{`Often the assistive technology needs to set the attributes,
such as `}<inlineCode parentName="p">{`states`}</inlineCode>{` and `}<inlineCode parentName="p">{`properties`}</inlineCode>{` in the object information.
For example, a user inputting text in a desktop application through speech recognition software may need to style the text.
The AT (speech recognition software) needs to access these attributes via APIs to programmatically set the styling of the text.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.2" target="_blank">{`502.3.2 Modification of Object Information`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Resources`}</h4>
    <ul>
      <li parentName="ul">{`See resources listed in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      