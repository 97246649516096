import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Level 1`}</h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/keyboard/#custom-elements")} target="_blank" rel="noopener noreferrer">Implement keyboard operation for custom elements</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/overview#reducing-effort")} target="_blank" rel="noopener noreferrer">Follow core considerations in reducing effort</a>  </li>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/overview#confirm-keyboard-interaction")} target="_blank" rel="noopener noreferrer">Unit test - confirm component keyboard interaction</a>   </li>
    </ul>
    <h4>{`Level 2`}</h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/keyboard/#player-kbd-accessible")} target="_blank" rel="noopener noreferrer">Make custom media player and animation controls keyboard accessible</a> </li>
    </ul>
    <h4>{`Level 3`}</h4>
    <ul>
      <li parentName="ul">{`No level 3 developer tasks for this requirement`}</li>
    </ul>
    <h4>{`Development techniques`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`iOS hybrid:`}</strong>{`
Apple recommends using the HTML5 `}<inlineCode parentName="li">{`audio`}</inlineCode>{` and `}<inlineCode parentName="li">{`video`}</inlineCode>{` elements for mobile Safari.
These elements support playback natively in the browser using the browser’s built-in controls.`}<br parentName="li"></br>
        {`Developers may also create custom media controls for rich interactivity using CSS and JavaScript.`}<br parentName="li"></br>
        {`See Apple’s `}<a href="https://developer.apple.com/library/safari/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/Introduction/Introduction.html" target="_blank">{`About HTML5 Audio and Video`}</a>{` and`}<a href="https://developer.apple.com/library/safari/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/Device-SpecificConsiderations/Device-SpecificConsiderations.html" target="_blank">iOS-specific considerations</a> for proper implementation.</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      