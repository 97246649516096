import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/ux_8_1_eg.png";
import img2 from "./../../../../../visuals/design/ux_8_2_eg.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`To prevent serious consequences, allow users to reverse actions in order to correct a mistake, especially for transactions that involve money, legal commitments, or user-controlled data.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`For important transactions, allow users to verify, correct, or reverse actions`}</li>
      <li parentName="ul">{`Provide clear instructions when users can reverse an action`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="modal dialog titled 'delete data?' followed by text 'are you sure you want to delete XXX?' with cancel and delete buttons"></img>
        <Caption fullWidth mdxType="Caption">Allow users to confirm or cancel important transactions.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img2} alt="notification saying 'XXX was moved to Trash' with undo button"></img>
        <Caption fullWidth mdxType="Caption">Allow users to reverse actions for important transactions, such as selecting the Undo button.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G99" target="_blank" rel="noopener noreferrer">G99: Providing the ability to recover deleted information</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G164" target="_blank" rel="noopener noreferrer">G164: Providing a stated time within which an online request (or transaction) may be amended or canceled by the user after making the request</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/tutorials/forms/validation/#require-user-confirmation" target="_blank" rel="noopener noreferrer">Provide undo functionality, Validating input</a>, W3C web accessibility tutorials
      * <a href="https://www.carbondesignsystem.com/components/modal/usage#transactional-modal" target="_blank" rel="noopener noreferrer">Transactional modal</a>, Carbon design system
      * <a href="https://www.nngroup.com/articles/user-mistakes/" target="_blank" rel="noopener noreferrer">Preventing User Errors: Avoiding Conscious Mistakes</a>, Nielsen Norman Group
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g99' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g164' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_26' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_7' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#3_3_4")} target="_blank" rel="noopener noreferrer">3.3.4 Error Prevention (Legal, Financial, Data)</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Transactions](/toolkit/design/ux/#transaction), this page
      * [Reverse and undo](/toolkit/design/ux/#reverse), this page
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_transactions' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_reverse_and_undo' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      