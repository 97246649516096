import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Users can skip to main content or move between sections of content`}</li>
      <li parentName="ul">{`ARIA landmarks are consistently structured and labelled`}</li>
      <li parentName="ul">{`No text falls outside a region (orphaned) for ARIA landmarks`}</li>
      <li parentName="ul">{`There are multiple ways to navigate content`}</li>
      <li parentName="ul">{`Navigation mechanisms repeated throughout occur in the same relative order or location`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`The ability to move between regions of the page makes applications more
usable with keyboards and assistive technologies. More than one site
navigation mechanism must be provided, for example a search field, site
navigation links, landmarks, skip to main content link, or a site map. The
chosen methods must be consistently used throughout the application. For
web applications, ARIA landmarks enable keyboard and assistive
technology users to traverse page content by region. Where there is more
than one of any type of landmark on the page, each needs a label (typically
provided with `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` in association with an existing text heading).`}</p>
    <p>{`Note: Although the concepts apply, this testing step does not apply to most software applications.`}</p>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_5' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_2_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      