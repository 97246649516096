import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If keyboard focus can be moved to a component of the page/application using a keyboard interface,
then focus can be moved away from that component using only a keyboard interface, and,
if it requires more than unmodified arrow or tab keys or other standard exit methods,
the user is advised of the method for moving focus away.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The intent of this requirement is to ensure the keyboard focus does not get “trapped” within subsections of content,
leaving keyboard-only users no way to return to other content.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Since any content that does not meet this requirement can interfere with a user’s ability to use the whole page/application,
all content, whether it is used to meet other requirements or not, must meet this requirement.
WCAG `}<a href="https://www.w3.org/TR/WCAG20/#cc5" target="_blank">{`Conformance Requirement 5 Non-interference`}</a>{` states that users must not be blocked from reaching all parts of the content,
regardless of technology limitations.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/no-keyboard-trap" target="_blank">{`Understanding 2.1.2 No Keyboard Trap`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      