import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import RequirementDescription from "../../../components/rms_resources_mgmt/RequirementDescription";
import RequirementRationale from "../../../components/rms_resources_mgmt/RequirementRationale";
import RequirementReferText from "../../../components/rms_resources_mgmt/RequirementReferText";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    {
      /* For prerecorded audio-only or video-only media, an alternative provides equivalent information. */
    }
    <RequirementDescription reqid="req1_2_1" mdxType="RequirementDescription"></RequirementDescription>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    {
      /* This requirement ensures that when information is presented in a playable medium,
      the user has an alternative way of consuming it.
      For audio-only information, such as a recording of a speech,
      provide a transcript.
      A transcript is also the preferred alternative for video-only information,
      such as a soundless demonstration video. This is because a transcript – which in the case of a video describes all important visual information -- can be presented to the user in many different ways by assistive technology. The alternative for video-only material could also be provided as an audio track. */
    }
    <RequirementRationale reqid="req1_2_1" mdxType="RequirementRationale"></RequirementRationale>
    <p><strong parentName="p">{`Exception:`}</strong>{` This requirement does not apply when the audio-only or video-only content is actually the media alternative for text content, and is labeled as such. This alternative equivalent should provide no more information than is available in the text content.`}</p>
    {
      /* Refer to <a href="https://www.w3.org/WAI/WCAG22/Understanding/audio-only-and-video-only-prerecorded.html" target="_blank" rel="noopener noreferrer">Understanding 1.2.1 Audio-only and Video-only (Prerecorded)</a> for more information on exceptions,
      intent, benefits, and techniques (external link to W3C). */
    }
    <RequirementReferText reqid="req1_2_1" mdxType="RequirementReferText"></RequirementReferText>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      