import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A mechanism is available to bypass blocks of content that are repeated on multiple Web pages.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`People with disabilities often navigate content without the use of a mouse.
This makes navigating the content more tedious when there is repeating information,
such as links and search inputs that are in the tab order.`}</p>
    <p>{`When repeated interactive content is organized into logical groups and regions,
a means is provided for more efficient navigation.
Navigation for screen reader users becomes much more efficient when WAI-ARIA landmarks (regions) are implemented.
Screen reader users may navigate sequentially through landmarks using a hotkey sequence.
Alternatively, a dialog containing a list of landmarks may be invoked from which users can jump directly.
Examples of available landmarks (regions) include: `}<inlineCode parentName="p">{`banner`}</inlineCode>{`/`}<inlineCode parentName="p">{`header`}</inlineCode>{`,
`}<inlineCode parentName="p">{`sitelookup`}</inlineCode>{`/`}<inlineCode parentName="p">{`search`}</inlineCode>{`, `}<inlineCode parentName="p">{`content`}</inlineCode>{`/`}<inlineCode parentName="p">{`main`}</inlineCode>{`, `}<inlineCode parentName="p">{`footer`}</inlineCode>{`/`}<inlineCode parentName="p">{`contentinfo`}</inlineCode>{`, and `}<inlineCode parentName="p">{`navigation`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`Notes:`}</strong></p>
    <ol>
      <li parentName="ol">{`Non-web documents and software should mark this requirement N/A.`}</li>
      <li parentName="ol">{`This requirement does not require methods that are redundant to functionality provided by the browser.
Most web browsers and platforms provide keyboard shortcuts to move the user focus to the top or bottom of the page,
`}<inlineCode parentName="li">{`Home`}</inlineCode>{` or `}<inlineCode parentName="li">{`End`}</inlineCode>{`, and `}<inlineCode parentName="li">{`PagUp`}</inlineCode>{` or `}<inlineCode parentName="li">{`PageDown`}</inlineCode>{` at a time.`}</li>
      <li parentName="ol">{`Although not essential to meet the requirement,
being able to bypass blocks of content that are repeated `}<em parentName="li">{`within`}</em>{` a page directly addresses user needs
and is generally considered best practice.`}</li>
    </ol>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/bypass-blocks" target="_blank">{`Understanding 2.4.1 Bypass Blocks`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      