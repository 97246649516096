import HomePanel from "../../../../src/components/home/homePanel";
import { withPrefix } from "gatsby";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import locked from "../../../../src/images/locked-light.svg";
import slack from "../../../../src/images/slack-light-20.svg";
import "../../../../src/content/home/home-cards.scss";
import * as React from 'react';
export default {
  HomePanel,
  withPrefix,
  Row,
  Column,
  locked,
  slack,
  React
};