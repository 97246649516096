import React, { Component } from 'react'
import ArrowRight20 from '@carbon/icons-react/lib/arrow--right/20';

class EventCard extends Component {
    onClick() {
        if (typeof document !== "undefined") {
            document.location.href = this.props.href;
        }
    }
    onKeyDown(evt) {
        if (evt.keyCode === 13) {
            this.onClick();
        }
    }


    render() {
        return (
            <div className="bx--col-lg-3 bx--col-md-4 bx--col-sm-4 eventCard">
                <div className="bx--aspect-ratio bx--aspect-ratio--1x1" role="link" tabIndex="0" onClick={this.onClick.bind(this)} onKeyDown={this.onKeyDown.bind(this)}>
                    <div className="eventContent">
                        <div>{this.props.children}</div>
                    </div>
                    <ArrowRight20 alt=""/>
                </div>
            </div>
        )

    }


}
export default EventCard