import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/ux_5_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Blind users rely on proper implementation for screen readers to relay the label that an input is associated with. Communicate with development any such relationships in your designs.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Connect each label and input in your wireframes`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="Annotate labels and UI elements"></img>
        <Caption fullWidth mdxType="Caption">Annotate the association between the labels and UI elements for implementation.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_3_1")} target="_blank" rel="noopener noreferrer">1.3.1 Info and Relationships</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      