import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For functionality that can be operated using a single pointer,
the function completes on the up-event or offers an ability to abort,
undo, or reverse the outcome.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The intent of this requirement is to reduce accidental activation of controls and the effects by making their operation consistent.`}</p>
    <p>{`This requirement benefits a range of users with cognitive, physical, or visual disabilities.
Designers and developers should help ensure that pointer operation is predictable and consistent.
To prevent accidental or erroneous pointer input, ensure at least one of the following is true:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No Down Event:`}</strong>{` The down-event of the pointer is not used to execute any part of the function`}</li>
      <li parentName="ul"><strong parentName="li">{`Abort or Undo:`}</strong>{` Completion of the function is on the up-event, and a mechanism is available to abort the function before completion or to undo the function after completion`}</li>
      <li parentName="ul"><strong parentName="li">{`Up Reversal:`}</strong>{` The up-event reverses any outcome of the preceding down-event`}</li>
      <li parentName="ul"><strong parentName="li">{`Essential:`}</strong>{` Completing the function on the down-event is essential`}</li>
    </ul>
    <p>{`If users mistakenly activate a mouse button or gesture,
or if they have poor fine motor control and click the wrong thing,
they can cause unintended consequences.`}</p>
    <p>{`If no `}<inlineCode parentName="p">{`down-event`}</inlineCode>{` causes execution,
a user who mistakenly clicks a control does not immediately activate the control.
Instead, activation occurs on the up-event when the user releases the control such as by
taking their finger off the mouse button or the touchscreen.
This provides users with an opportunity to nullify their action,
for instance by moving the pointer away from the control.
Moving the pointer away from the target while the mouse button is still depressed,
then releasing the pointer when it is no longer over any control,
is a built-in example of the ability to `}<inlineCode parentName="p">{`abort`}</inlineCode>{` on the up-event.`}</p>
    <p>{`A drag-and-drop operation that completes on the up-event
and then asks for confirmation to move the object is an example of an `}<inlineCode parentName="p">{`undo`}</inlineCode>{` function.
A help icon that displays a message when pressed (on the down-event) that then disappears when it is no longer pressed (on the up-event)
is an example of `}<inlineCode parentName="p">{`up reversal`}</inlineCode>{`.
Finally, a piano keyboard emulator is an example of a function that is down-event `}<inlineCode parentName="p">{`essential`}</inlineCode>{`. A physical piano plays a note when the piano key is pressed,
and any emulator must also do so to approximate the experience.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/UNDERSTANDING/pointer-cancellation.html" target="_blank" rel="noopener noreferrer">{`Understanding 2.5.2 Pointer Cancellation`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      