import React, { Component } from 'react';
import {
    StructuredList,
    StructuredListBody,
    StructuredListHead,
    StructuredListRow,
    StructuredListCell,
    StructuredListWrapper
} from 'carbon-components-react/lib/components/StructuredList';
import { Accordion, Tabs, Tab, TabsSkeleton, UnorderedList, ListItem, CopyButton } from "carbon-components-react";
var jsonQuery = require('json-query');
var referencesData = require('../../data/references.json');
var techniquesData = require('../../data/techniques.json');
var requirmentsData = require('../../data/requirements.json');
var toolkitTopicsData = require('../../data/toolkitTopics.json');


class ToolkitReferencesVisualization extends Component {

    constructor(props) {
        super(props);
        this.state = {dataTechniques: 'Retriving Data From Server', 
                      dataReferences:{},
                      dataRequirements:{},
                      dataTechniques:{},
                      dataToolkitTopics:{}
                      };
    }

    componentDidMount() {
        this.setState({dataReferences: referencesData});
        this.setState({dataRequirements: requirmentsData});
        this.setState({dataTechniques: techniquesData});
        this.setState({dataToolkitTopics: toolkitTopicsData});
    }

    makeRows() {
        var result = jsonQuery('references', {
            data: referencesData
        })
        var jsonDB = this.state.dataReferences
        var rows = []
        for (var key in jsonDB) {
            if (jsonDB.hasOwnProperty(key)) {
                var row = <StructuredListRow> {this.makeRow(jsonDB,key)} </StructuredListRow>
                rows.push(row) 
            }
        }
        return <> {rows} </>
    }

    makeRow(jsonDB,refid) {
        var refidData = jsonDB[refid]

        var rows = []
        for (var key in refidData) {
            var row = ""
            if(key == "URI"){
                row = <ListItem><span style={{fontWeight:"bold"}}>{key}</span>: <a href={refidData[key]}>{refidData[key]}</a></ListItem>
            }
            else if(key == "Name"){
                row = <ListItem><span style={{fontWeight:"bold"}}>{key}</span>: {refidData[key]}</ListItem>
            }
            rows.push(row)
        }


        return <>
            <StructuredListCell>
                <strong id={refid} >{refid}</strong>  
            </StructuredListCell>
            <StructuredListCell>
                <UnorderedList>
                    {rows}
                </UnorderedList>
            </StructuredListCell>
        </>
    }


    render() {

            return <>
                   {this.makeRows()}
                </>

    }

}

export default ToolkitReferencesVisualization


// getTechniques() {
//     fetch('http://localhost:3000/rms/api/V1/techniques', { 
//     method: 'get', 
//     headers: new Headers({
//         'Authorization': 'Basic '+btoa('admin:blue4tux'), 
//         'Content-Type': 'application/x-www-form-urlencoded'
//         }),
//     }).then((resp) => {return resp.json()
//     }).then((data) => {console.log(JSON.stringify(data.aria1))
//     this.setState({dataTechniques: data});
//     })
// }

// getReferences() {
//     fetch('http://localhost:3000/rms/api/V1/references', { 
//     method: 'get', 
//     headers: new Headers({
//         'Authorization': 'Basic '+btoa('toolkitAdmin:kTk?9Cv5iDRG@R!hZwEgAER%cFCwZ*'), 
//         'Content-Type': 'application/x-www-form-urlencoded'
//         }),
//     }).then((resp) => {return resp.json()
//     }).then((data) => {console.log(JSON.stringify(data.aria1))
//     this.setState({dataReferences: data});
//     })
// }

// getRequirements() {
//     fetch('http://localhost:3000/rms/api/V1/requirements', { 
//     method: 'get', 
//     headers: new Headers({
//         'Authorization': 'Basic '+btoa('admin:blue4tux'), 
//         'Content-Type': 'application/x-www-form-urlencoded'
//         }),
//     }).then((resp) => {return resp.json()
//     }).then((data) => {console.log(JSON.stringify(data.aria1))
//     this.setState({dataRequirements: data});
//     })
// }

// getEqual_access_toolkit() {
//     fetch('http://localhost:3000/rms/api/V1/equal_access_toolkit', { 
//     method: 'get', 
//     headers: new Headers({
//         'Authorization': 'Basic '+btoa('admin:blue4tux'), 
//         'Content-Type': 'application/x-www-form-urlencoded'
//         }),
//     }).then((resp) => {return resp.json()
//     }).then((data) => {console.log(JSON.stringify(data.aria1))
//     this.setState({dataEqual_access_toolkit: data});
//     })
// }