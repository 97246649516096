import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`All functionality of the content is operable through a keyboard interface without requiring specific timings for individual keystrokes.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that content can be navigated with a keyboard or keyboard interface.
When interactive content is operated using a keyboard,
it is accessible to vision impaired people who cannot use devices requiring hand-eye coordination
(e.g., a mouse) and by people who use alternative keyboards or input devices that act as keyboard emulators.
Keyboard emulators include speech input, sip and puff devices, on-screen keyboards, scanning software,
and a variety of assistive technologies.
This requirement does not forbid or discourage providing mouse input or other input methods in addition to keyboard operation.`}</p>
    <p>{`The requirement `}<a href={withPrefix("/requirements/requirements/#502_2_2")} target="_blank" rel="noopener noreferrer">502.2.2 No disruption of accessibility features</a> addresses
the software requirement to not disrupt or override platform accessibility options,
including the keyboard commands to control these options.
For web applications, the need to document non-standard keyboard operation is covered under <a href={withPrefix("/requirements/requirements/#602_2")} target="_blank" rel="noopener noreferrer">{`602.2 Accessibility and compatibility features`}</a>{`.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/keyboard" target="_blank">{`Understanding 2.1.1 Keyboard`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      