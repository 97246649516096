import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Some types of status messages are important for the user to know about in a real-time manner. Some examples of important messages are
status messages that provide a suggestion or messages that warn about an error. ARIA provides the `}<inlineCode parentName="p">{`role="alert"`}</inlineCode>{` to cause screen
reader assistive technology to interrupt other spoken information to announce the important message.`}</p>
    <p><em parentName="p">{`Pro tip:`}</em>{` Not all examples in the techniques below use status messages to convey warnings or errors to users. You only need to use
the `}<inlineCode parentName="p">{`"alert"`}</inlineCode>{` where is a change of context does not take place, such as a focus change.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA19" target="_blank" rel="noopener noreferrer">ARIA19: Using ARIA role=alert or Live Regions to Identify Errors</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G83" target="_blank" rel="noopener noreferrer">G83: Providing text descriptions to identify required fields that were not completed</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G84" target="_blank" rel="noopener noreferrer">G84: Providing a text description when the user provides information that is not in the list of allowed values</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G85" target="_blank" rel="noopener noreferrer">G85: Providing a text description when user input falls outside the required format or values</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G177" target="_blank" rel="noopener noreferrer">G177: Providing suggested correction text</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G194" target="_blank" rel="noopener noreferrer">G194: Providing spell checking and suggestions for text input</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria19' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g83' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g84' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g85' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g177' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g194' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#4_1_3")} target="_blank" rel="noopener noreferrer">4.1.3 Status Messages</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req4_1_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      