import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If content can be navigated sequentially and the navigation sequences affect meaning or operation, focusable components receive focus in an order that preserves meaning and operability.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that when users navigate sequentially through content,
they encounter information in an order that is consistent with the meaning of the content and can be operated from the keyboard.`}</p>
    <p>{`When content relies on sequential navigation for the user to understand it,
it is essential for the focus order to match the visual flow of the content,
such as from left to right and top to bottom.
If there is more than one order that preserves meaning and operability,
such as navigating cells in a table by row or by column,
either order may satisfy the requirement.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/focus-order" target="_blank">{`Understanding 2.4.3 Focus Order`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      