import React from 'react';
import { iconList, iconListIcon } from './IconList.module.scss';
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';

export const IconList = ({ className, children, ...rest }) => (
    <div className={iconList} role="list">{children}</div>
);

export const IconListRow = ({ className, icon, children, ...rest }) => (
    <div role="listitem">
    <Row>{children}</Row>
    </div>
);

export const IconListIcon = ({ className, icon, children, ...rest }) => (
<Column colLg={1} colMd={1} colSm={4}>
    <div className={iconListIcon}>
        {children}
    </div>
</Column>
);

export const IconListContent = ({ className, icon, children, ...rest }) => (
<Column colLg={7} colMd={5} colSm={4}>
    {children}
</Column>
);