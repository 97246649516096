import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './PageHeaderCC.scss';
import { pageBanner, withTabs } from './PageHeaderCC.module.scss';
import Bee from '../../../visuals/homepage/banner_bee_logo.svg';



const PageHeaderCC = ({ title, description, tabs = [] }) => (
    <div className={cx(pageBanner, { [withTabs]: tabs.length })} style={{ position: "relative" }} >
        <div className="pageBannerCL">
            <div style={{ maxWidth: "99rem" }}>
                <div className="bx--grid" style={{ height: "100%" }}>
                    <div style={{ position: "absolute", bottom: "2rem", width: "100%" }}>
                        <div className="bx--row" style={{ paddingLeft: "16px", maxWidth: "100%" }}>
                            <div className="expressive04Bold" style={{ "marginTop": "16px" }}>
                                {/* <strong role="heading" aria-level="1" id="page-title" tabindex="-1">{title.slice(0,17)} {title.slice(18,34)}</strong> */}
                                <strong role="heading" aria-level="1" id="page-title" tabindex="-1" style={{ fontFamily: "IBM Plex Sans" }}>Toolkit license</strong>
                                <br />
                            </div>
                        </div>
                        <div className="bx--row" style={{ maxWidth: "100%" }}>
                            <div className="expressive04 dynamicPageHeader2 bx--col-sm-4 bx--col-md-6 bx--col-lg-6" style={{ fontSize: "28px" }}>
                            </div>
                        </div>
                        <div className="bx--col-sm-4 bx--col-md-4 bx--col-lg-6">
                            <div className="bannerImage">
                                <div className="bannerImage2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

PageHeaderCC.propTypes = {
    /**
     * Specify the title for the page
     */
    title: PropTypes.node,
};

export default PageHeaderCC;
