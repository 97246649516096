import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Elements receive focus in an order that preserves meaning`}</li>
      <li parentName="ul">{`No interactive elements are missed`}</li>
      <li parentName="ul">{`Keyboard interaction and navigation is never trapped in a component`}</li>
      <li parentName="ul">{`There is a visual indicator of focus at each tab stop`}</li>
    </ul>
    <p><strong parentName="p">{`For iOS mobile devices, substitute the first two checkboxes with:`}</strong></p>
    <ul>
      <li parentName="ul">{`All operable parts of the page can be reached using the next/prior gesture`}</li>
      <li parentName="ul">{`From the bottom, all operable parts can be reached by using the prior gesture`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`Repeatedly pressing the Tab key should allow users to navigate to all interactive elements or composite components and groups in the content. It is important to actively look for items missing from the tab order, not simply to follow along with the visual focus indicator. It is also important to confirm a user can traverse the page from bottom to top using Shift+Tab. Often, complex widgets (composite components) are accessed by a single tab stop, with arrow key navigation between the widget’s elements. This is acceptable behavior and is further discussed in the `}<ToolkitTopic qid='verify_manual_check_component' mdxType="ToolkitTopic"></ToolkitTopic>{` step.`}</p>
    <p>{`For iOS mobile devices, a similar verification of navigation order can be
obtained by turning on the VoiceOver screen reader and navigating through
the entire contents using the Flick Right/Left gesture to move to the Next/Prior
Item. To apply the following guidance to mobile, substitute references to
tabbing with Next/Prior Item gestures.`}</p>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_7' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      