import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
import img1 from "./../../../../../visuals/design/L2_content_text_meaning_3_eg.png";
import img2 from "./../../../../../visuals/design/L2_content_5_2_do.png";
import img3 from "./../../../../../visuals/design/L2_content_text_meaning_4_dont.png";
import "../../../topics.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Screen readers generate a list of all the links on a page for blind users to efficiently navigate to desired content. In this context, multiple “read more” links on a page will only confuse users. Meaningful stand-alone link text is also helpful for users with cognitive disabilities.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Provide clear, concise, and unique link text `}</li>
      <li parentName="ul">{`Contextualize generic links such as “read more”`}</li>
      <li parentName="ul">{`If using generic links, annotate the connection with text that provides context`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="dialog with list of link texts"></img>
        <Caption fullWidth mdxType="Caption">Provide clear and concise link text that can be understood out of context, as in this screen reader dialog box listing links.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <DoDontExample type="do" mdxType="DoDontExample">
    <img src={img2} alt="Good example: article headings 'peach gummies' and 'lemon gumdrops' are each followed by 'get a free sample' link and annotated as 'associate links with headings'"></img>
        </DoDontExample>
        <Caption fullWidth mdxType="Caption">Annotate the connection between generic links and text that gives context for implementation.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <DoDontExample type="dont" mdxType="DoDontExample">
    <img src={img3} alt="Bad example: article headings 'peach gummies' and 'lemon gumdrops' are each followed by 'get a free sample' links"></img>
        </DoDontExample>
        <Caption fullWidth mdxType="Caption">Don’t use generic or ambiguous links when it is possible to provide the user with more context.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g53' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g91' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='webaim_3' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='webaim_4' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_16' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      