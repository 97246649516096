import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where ICT displays video with synchronized audio, it shall have a mode of operation to provide a spoken output of the available captions,
except where the content of the displayed captions is not programmatically determinable.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`The European `}<em parentName="p">{`EN 301 549`}</em>{` standard specifies requirements for information and communications technologies (ICT) such as media players and embedded players that render captions.
This requirement is Not Applicable (N/A) to products and applications that do not play audio and video.`}</p>
    <p>{`Several “`}<em parentName="p">{`Time-based Media`}</em>{`” success criteria in WCAG, such as `}<a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">{`1.2.2 Captions (Prerecorded)`}</a>{`,
require that captions are provided.
Since the captions provide text alternatives for the spoken words and sounds in the video,
the captions themselves would not normally be announced.
However, subtitles are different.
Like captions, they appear as text on the screen,
but subtitles provide a text `}<em parentName="p">{`translation`}</em>{` of the original language.
So if the subtitles can be announced (spoken),
blind and low vision users who cannot see the subtitles on the screen can benefit from the spoken translation.`}</p>
    <p>{`Where the subtitles are available programmatically (they exist as a file or text encoding instead of being burnt directly into the video) assistive technologies can use text-to-speech to speak or Braille the subtitles.
Note that in order not to compete with the foreign-language audio,
it is preferable for most users to have the spoken translated subtitles delivered on a separate audio track from the foreign-language audio track.`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=35" target="_blank" rel="noopener noreferrer">{`7.1.5 Spoken Subtitles`}</a>{` (external link to EN 301 549) for more information.`}</p>
    <p><strong parentName="p">{`Related requirements:`}</strong>{`
Applications that play audio and video are required to meet the following related requirements:`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Audio/Video Content requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.2 Captions (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.4 Captions (Live)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#10_5")} target="_blank" rel="noopener noreferrer">10.5 Captioning positioning (Non-web Documents)</a></li>
    </ul>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Player requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_1")} target="_blank" rel="noopener noreferrer">7.1.1 Captioning Playback</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_2")} target="_blank" rel="noopener noreferrer">7.1.2 Captioning Synchronization</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_3")} target="_blank" rel="noopener noreferrer">7.1.3 Preservation of Captioning</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_4")} target="_blank" rel="noopener noreferrer">7.1.4 Captions Characteristics</a></li>
      <li parentName="ul">{`7.1.5 Spoken Subtitles - `}<em parentName="li">{`this requirement`}</em></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">7.3 User Controls for Captions & Audio Description</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#503_4_1")} target="_blank" rel="noopener noreferrer">503.4.1 Caption Controls</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      