import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If an input error is automatically detected, the item that is in error is identified and the error is described to the user in text.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures users are aware that an input error has occurred and can determine what is wrong. The error message should be as specific and detectable as possible.`}</p>
    <p>{`For example, if an unsuccessful form submission occurs due to input errors, simply re-displaying the form with a visual indicator for each bad field may be insufficient notification that errors occurred. The visual indicator may not be perceivable to a screen reader user and the form may be abandoned as not functional. Clear text messages are required.`}</p>
    <p>{`“Input error” is defined as information provided by the user that is not accepted. This includes:`}</p>
    <ul>
      <li parentName="ul">{`Information that is required but omitted by the user, or`}</li>
      <li parentName="ul">{`Information that is provided by the user but that falls outside the required data format or allowed values.`}</li>
    </ul>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/error-identification" target="_blank">{`Understanding 3.3.1 Error Identification`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      