import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`The design should already show the hierarchy of the content and headings. Code content using HTML
headings (`}<inlineCode parentName="p">{`h1`}</inlineCode>{` through `}<inlineCode parentName="p">{`h6`}</inlineCode>{`). Avoid using headings to achieve a certain visual look of text in the design;
that is what CSS is for.`}</p>
    <p>{`ARIA has `}<inlineCode parentName="p">{`role=”heading”`}</inlineCode>{` and an `}<inlineCode parentName="p">{`aria-level`}</inlineCode>{` can be assigned. However, it is very rare to need to use ARIA to
mark up headings. Use HTML as your first choice,  ARIA as a last resort.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H42" target="_blank" rel="noopener noreferrer">H42: Using h1-h6 to identify headings</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA12" target="_blank" rel="noopener noreferrer">ARIA12: Using role=heading to identify headings</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='h42' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria12' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_3_1")} target="_blank" rel="noopener noreferrer">1.3.1 Info and Relationships</a>, IBM accessibility requirements
      * <a href="https://www.w3schools.com/tags/tag_hn.asp" target="_blank" rel="noopener noreferrer">HTML h1-h6 tags</a>, w3schools.com
      * <a href="https://www.w3.org/WAI/tutorials/page-structure/example/" target="_blank" rel="noopener noreferrer">Page structure code example</a>, W3C web accessibility tutorials
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='w3schools_com_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_9' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      