import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Automated`}</h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/verify/automated/")} target="_blank" rel="noopener noreferrer">Test using the IBM Equal Access Accessibility Checker browser extension or integration testing module to identify issues detected for this requirement</a></li>
    </ul>
    <h4>{`Manual`}</h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/verify/manual/#labeling")} target="_blank" rel="noopener noreferrer">Check labeling, link names, and page titles</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/verify/manual/#textclarity")} target="_blank" rel="noopener noreferrer">Read text for clarity; Check for foreign, and sensory words</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/verify/manual/#errorhandling")} target="_blank" rel="noopener noreferrer">Error handling and instructions</a></li>
    </ul>
    <h4>{`Screen Reader`}</h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/verify/screen-reader/#verifylabelling")} target="_blank" rel="noopener noreferrer">Verify ALTs, labelling, and foreign phrases</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/verify/screen-reader/#confirminteraction")} target="_blank" rel="noopener noreferrer">Confirm content interaction works with the screen reader</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      