import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Error handling and instructions are easily detected`}</li>
      <li parentName="ul">{`Errors are described in text`}</li>
      <li parentName="ul">{`If they are known, suggestions for correction are provided`}</li>
      <li parentName="ul">{`Instructions are provided for specific formatting or values`}</li>
      <li parentName="ul">{`Inputs that collect common information about the user are populated automatically (autofill)`}</li>
      <li parentName="ul">{`Users have a chance to check, confirm, or reverse actions`}</li>
      <li parentName="ul">{`There is another indicator if color is used to convey meaning`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`A critical part of testing page interaction for accessibility is to determine how the system responds to user input errors. Frequent causes of accessibility failures include non-modal dialogs that do not prevent accidental keyboard movement to other parts of the screen potentially obscuring the viewport and interfering with assistive technologies. This includes instructions and inline messages that are not properly surfaced to the user. Enter incorrect data to produce all the known types of error handling, notifications and instructions, so that they can be verified.`}</p>
    <p>{`Check that input fields that collect typical information about a user are programmatically set to use the correct HTML autocomplete attribute.`}</p>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_5' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <p><strong parentName="p">{`Completes testing for: `}</strong></p>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      