import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Sometimes developers repurpose standard HTML elements to build elements that are not available in HTML. In such cases, ARIA is
used to give the repurposed elements a new role. Use the `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` property to provide the name, or programmatic label, of
the ARIA element by associating it with the visual label on the HTML element.`}</p>
    <p>{`The following code fragment repurposes an HTML list into a tree by assigning a tree role to the `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{` element. The `}<inlineCode parentName="p">{`<li>`}</inlineCode>{` element containing fruits is repurposed into a tree node by assigning a role=“treeitem” attribute to it. When a blind user navigates to the fruits node, the screen reader announces that the fruits node is expanded.`}</p>
    <pre><code parentName="pre" {...{}}>{`<div id="tree1" role="tree" tabindex="0">
    <li role="treeitem" tabindex="-1" aria-expanded="true" aria-selected="true" onkeydown="navKey"> Fruits </li>
    ...
</div>
`}</code></pre>
    <p>{`Pro tip: Using ARIA also means you must implement keyboard access. Make sure the element is `}<ToolkitTopic qid='dev_keyboard_ensure_interactive' name="reachable by keyboard" mdxType="ToolkitTopic"></ToolkitTopic>{` and `}<ToolkitTopic qid='dev_keyboard_implement_keyboard' name="all functionality is operable by the keyboard" mdxType="ToolkitTopic"></ToolkitTopic>{`.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA4" target="_blank" rel="noopener noreferrer">ARIA4: Using a WAI-ARIA role to expose the role of a user interface component</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA16" target="_blank" rel="noopener noreferrer">ARIA16: Using aria-labelledby to provide a name for user interface controls</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria4' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='aria16' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#4_1_2")} target="_blank" rel="noopener noreferrer">4.1.2 Name, Role, Value</a>, IBM accessibility requirements (See ARIA4 supplement on the Web tab)
      * <a href="http://w3c.github.io/aria-practices/#aria_ex" target="_blank" rel="noopener noreferrer">Design patterns and widgets</a>, ARIA authoring practices
      * <a href="http://w3c.github.io/aria-practices/#naming_with_aria-labelledby" target="_blank" rel="noopener noreferrer">Naming with referenced content via aria-labelledby</a>, ARIA authoring practices
      * <a href="https://developer.mozilla.org/en-US/docs/Learn/Accessibility/WAI-ARIA_basics" target="_blank" rel="noopener noreferrer">What is WAI-ARIA</a>, Mozilla Developer Network
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req4_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_6' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='mozilla_developer_network_3' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      