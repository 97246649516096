import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
import img11 from "./../../../../../visuals/design/content_1_do.png";
import img12 from "./../../../../../visuals/design/content_1_dont.png";
import "../../../topics.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Blind users rely heavily on clear, properly structured headings to understand and navigate content (via a screen reader). The heading structure provides an outline so blind users can quickly understand the organization and contents of the page.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Accurately describe content with succinct headings`}</li>
      <li parentName="ul">{`Indicate heading levels; avoid skipping a level`}</li>
      <li parentName="ul">{`Use only one H1 per page`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <DoDontExample type="do" mdxType="DoDontExample">
    <img src={img11} alt="Good example: annotations show heading levels in order from H1 to H3"></img>
        </DoDontExample>
        <Caption fullWidth mdxType="Caption">Annotate headings in wireframes, and use only one H1 per page, which is typically the page title.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <DoDontExample type="dont" mdxType="DoDontExample">
    <img src={img12} alt="Bad example: annotations show heading levels out of order with one H3 followed by two H1's"></img>
        </DoDontExample>
        <Caption fullWidth mdxType="Caption">Don't use unclear headings, use headings out of order, or have multiple H1s on one page.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_10' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_6' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      