import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Automated`}</h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/verify/automated/")} target="_blank" rel="noopener noreferrer">Test using the IBM Equal Access Accessibility Checker browser extension or integration testing module to identify issues detected for this requirement</a></li>
    </ul>
    <h4>{`Manual`}</h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/verify/manual/#pointeroperation")} target="_blank" rel="noopener noreferrer">Targets are of sufficient size or are sufficiently spaced</a></li>
    </ul>
    <h4>{`Screen reader`}</h4>
    <ul>
      <li parentName="ul">{`No screen reader tasks for this requirement`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      