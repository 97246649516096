import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../images/overlap.png";
import img2 from "./../images/tooltip.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`When `}<inlineCode parentName="p">{`onhover`}</inlineCode>{` or `}<inlineCode parentName="p">{`onfocus`}</inlineCode>{` triggers new content to display, it must remain visible until dismissed by the user or the hover content becomes invalid. Doing so gives users control of dismissing hover content when it overlays information they need to see.`}</p>
    <p>{`Follow the design to implement all actions that dismiss hover content:`}</p>
    <ul>
      <li parentName="ul">{`Escape key pressed.`}</li>
      <li parentName="ul">{`Pointer is moved off of both the hover trigger and hover content.`}</li>
      <li parentName="ul">{`May implement Tab key when hover has no interactive content.`}</li>
      <li parentName="ul">{`May implement activating Cancel button in hover content (both by mouse click and by keyboard)`}</li>
    </ul>
    <p>{`Additional instructions:`}</p>
    <ul>
      <li parentName="ul">{`Avoid using timers that trigger dismissing hover content.`}</li>
      <li parentName="ul">{`When hover content contains interactive elements, moving keyboard focus from the trigger to the hover content does not dismiss the hover content.`}</li>
      <li parentName="ul">{`Only use `}<inlineCode parentName="li">{`mouseOut`}</inlineCode>{` to dismiss hover content when the pointer is moved off of both the trigger and hover content.  Consider a brief delay to avoid dismissing the content due to irregular pointer movement. The pointer may briefly move off of both elements in an effort to move to the hover content.`}</li>
      <li parentName="ul">{`When hover content contains information that becomes invalid, it is OK to automatically dismiss the hover without a user action.`}</li>
    </ul>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR39" target="_blank" rel="noopener noreferrer">SCR39: Making content on focus or hover hoverable, dismissible, and persistent</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='scr39' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_13")} target="_blank" rel="noopener noreferrer">1.4.13 Content on Hover or Focus</a>, IBM accessibility requirements
      * <a href="https://www.carbondesignsystem.com/components/tooltip/code#icon-tooltip" target="_blank" rel="noopener noreferrer">Icon tooltip</a>, Carbon design system
      * <a href="https://react.carbondesignsystem.com/?path=/story/tooltipdefinition--default" target="_blank" rel="noopener noreferrer">Definition tooltip</a>, Carbon design system
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_13' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_5' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_14' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      