import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The content of text objects, text attributes, and the boundary of text rendered to the screen,
shall be programmatically determinable.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`Users depend on Assistive Technology (AT) to relay information to them about text content and attributes that are programmatically determined.
If text is editable, AT users also need the ability to interact with the text content.
The AT uses programmatically determined text attributes to describe the text styling to the user.
Where the text is editable, the caret focus, text selection, and text boundaries must also be made programmatically available to the AT.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` The selection and manipulation of text involves considerations from several requirements.
Depending on circumstances, such as whether text is static, read-only, or fully editable,
similar techniques may be required from each of `}<em parentName="p">{`502.3.x set of requirements`}</em>{`:`}</p>
    <ul>
      <li parentName="ul">{`502.3.8 Text (this requirement)`}</li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#502_3_9")} target="_blank">502.3.9 Modification of Text</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#502_3_12")} target="_blank">502.3.12 Focus Cursor</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#502_3_13")} target="_blank">502.3.13 Modification of Focus Cursor</a></li>
    </ul>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.8" target="_blank">{`502.3.8 Text`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Resources`}</h4>
    <ul>
      <li parentName="ul">{`See resources listed in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      