import React, { Component } from "react";
var IBMRequirementsData = require('../../data/requirements.json')
var jsonQuery = require('json-query')

class RequirementDescription extends Component {

    render() {
            return (
                <>
                {getRequirementDescription(this.props.reqid)}
                </>
            )    
        }
    }
    export default RequirementDescription
    
    function getRequirementDescription(reqid) {        
        var result = jsonQuery(reqid, {
            data: IBMRequirementsData
        })
    
        var retValue
        if(result.value){  // make sure result.value is not undefined/null
            
            // Replace /n carriage returns with <br/> breaks
            var formattedDescription = result.value.Description.split("\n").map(function(item, idx) {
                return (
                    <span key={idx}>
                        {item}
                        <br/>
                    </span>
                 )
            })

            retValue = <>
            <p style={{ paddingRight: "0px", fontSize: "16px", lineHeight:"1.5" }}>{formattedDescription}</p>
            </>
        }else{
            retValue = <></>
        }
    
        return retValue
    
    }    




