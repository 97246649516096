import React from 'react';
import { SwitcherLink, SwitcherDivider } from 'gatsby-theme-carbon/src/components/Switcher/Switcher';
import Switcher from 'gatsby-theme-carbon/src/components/Switcher';

// https://css-tricks.com/using-css-transitions-auto-dimensions/
// Note: if you change this, update the max-height in the switcher stylesheet
const AbleSwitcher = ({ onKeyDown }) => {
    return (
        <div onKeyDown={onKeyDown}>
            <Switcher>
                <SwitcherDivider>Foundations</SwitcherDivider>
                <SwitcherLink href="https://ibm.com/brand" isInternal>
                    IBM Brand Center
                </SwitcherLink>
                <SwitcherLink href="https://ibm.com/design/language">
                    IBM Design Language
                </SwitcherLink>
                <SwitcherDivider>Implementation</SwitcherDivider>
                <SwitcherLink href="https://www.carbondesignsystem.com/">
                    Carbon Design System
                </SwitcherLink>
                <SwitcherLink href="https://www.ibm.com/standards/web/carbon-for-ibm-dotcom/">
                    Carbon for IBM.com
                </SwitcherLink>
                <SwitcherLink href="https://www.ibm.com/design/event/">
                    IBM Event Design
                </SwitcherLink>
                <SwitcherLink href="https://www.ibm.com/design/workplace/">
                    IBM Workplace Design
                </SwitcherLink>
                <SwitcherDivider>Practices</SwitcherDivider>
                <SwitcherLink href="https://www.ibm.com/design/thinking/">
                    Enterprise Design Thinking
                </SwitcherLink>
                <SwitcherLink href="https://www.ibm.com/able/">
                    IBM Accessibility
                </SwitcherLink>
                <SwitcherLink href="https://www.ibm.com/design/ai">
                    IBM Design for AI
                </SwitcherLink>
                <SwitcherLink href="https://www.ibm.com/services/ibmix/">
                    IBM iX
                </SwitcherLink>
                <SwitcherLink
                    isInternal
                    href="https://w3.ibm.com/design/experience-standards/">
                    IBM Experience Standards
                </SwitcherLink>
                <SwitcherDivider>Community</SwitcherDivider>
                <SwitcherLink href="https://w3.ibm.com/design/" isInternal>
                    IBM Design
                </SwitcherLink>
                <SwitcherLink href="https://www.ibm.com/design/racial-equity-in-design">
                    Racial Equity in Design
                </SwitcherLink>
            </Switcher>
        </div>
    );
}

export { AbleSwitcher }
