import React from 'react'
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import Launch16 from '@carbon/icons-react/lib/launch/16';
import "./reportPanel.scss";
import Reports from "./report";

export default () => {
    return (

        <div className="reportPanel">
            <div className="bx--grid">
                <Row>
                    <Column colLg="7" colMd="8" colSm="4">
                        <p className="contentcol">
                            You can learn about the accessibility of IBM products by selecting an offering(s) from the table below. We publish the Accessibility Conformance Report (ACR) (in VPAT® 2.x format) to share the details of an offering’s compliance. If you need help with additional information, please send us an <a href="mailto: ibmacs@us.ibm.com">email</a>.
                        </p>
                    </Column>
                </Row>
                <Row>
                    <Column colLg="9" colMd="8" colSm="4" >
                        <div className="acrreport">
                            <Reports></Reports>
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column colLg="7" colMd="8" colSm="4" >
                        <div className="acsintrocontentcol">
                            <div style={{ paddingLeft: "3px", paddingTop: "0px", paddingBottom: "24px" }} role="heading" aria-level="2" className="acsintrotitlecol">
                                Accessibility Compliance System
                            </div>
                            <p>IBM has a long-standing commitment to people with diverse abilities which began in 1914 when we hired our first disabled employee. In keeping with that commitment to accessibility, IBM strongly supports the US Federal government's and other industries' use of accessibility as a criterion in the procurement of Information and Communications Technology (ICT). </p>
                            <p style={{ marginTop: "20px" }}>Our own efforts to define and deliver sensible and effective approaches to promote accessibility evolved as IBM worked with the US government to define its standards for Section 508 of the Rehabilitation Act, with the EU to define EN 301 549, and with the W3C to issue versions of WCAG 2.x</p>
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column colLg="7" colMd="8" colSm="4">
                        <div style={{ paddingLeft: "3px", paddingTop: "0px", paddingBottom: "0px" }} role="heading" aria-level="2" className="acsintrotitlecol">
                            Create and manage reports
                        </div>
                        <p>Use the <a href='https://able.ibm.com/acs' rel="noopener norefferer" target='_blank'>Accessibility Compliance System (ACS)<Launch16 className="linkPopout" width={16} height={16} alt="pop out" /></a> to create a new conformance record (IBM internal use only).</p>
                    </Column>
                </Row>
            </div>
        </div>

    )
}