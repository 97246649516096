import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/visual_3_1_eg.png";
import img2 from "./../../../../../visuals/design/visual_3_2_eg.png";
import img3 from "./../../../../../visuals/design/visual_3_3_eg.png";
import img4 from "./../../../../../visuals/design/visual_3_4_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users with low vision need sufficient contrast for the visual information needed to identify and operate components. Exceptions that don’t need to meet contrast requirements include: disabled components that users can’t interact with, and unmodified standard HTML elements.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Make sure text label meets 4.5:1 contrast on its background`}</li>
      <li parentName="ul">{`Make sure input fields meet 3:1 contrast`}</li>
      <li parentName="ul">{`Make sure elements needed to identify and operate components meet 3:1 contrast`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img1} alt="blue bounding box around input field with Zipcode label above on white background"></img>
        <Caption fullWidth mdxType="Caption">The input field has a bounding box that meets 3:1 contrast with its background.
        </Caption>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img2} alt="dark grey underlined input field with Zipcode label above on white background"></img>
        <Caption fullWidth mdxType="Caption">At a minimum, the bottom of the input region must meet 3:1 contrast with its background.
        </Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img3} alt="expanded overflow menu"></img>
        <Caption fullWidth mdxType="Caption">This overflow menu has a clear outline around it, as well as distinct dividers between grouped items.
        </Caption>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img4} alt="dark grey underlined control with black up / down incremental value widget on white background"></img>
        <Caption fullWidth mdxType="Caption">The increments for this spin button achieve 3:1, as does the darker gray underline for the input boundary, not achieved by the light gray rectangle.
        </Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G209" target="_blank" rel="noopener noreferrer">G209: Provide sufficient contrast at the boundaries between adjoining colors</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Understanding/non-text-contrast.html#active-user-interface-component-examples" target="_blank" rel="noopener noreferrer">Active user interface component examples</a>, Understanding 1.4.11 Non-text Contrast, WCAG
      * <a href="https://www.accessibility-developer-guide.com/knowledge/colours-and-contrast/user-interface-components/" target="_blank" rel="noopener noreferrer">Colour contrast for user interface components</a>, Accessibility Developer Guide
      * <a href="https://medium.com/ingeniouslysimple/contrasting-ui-components-passing-the-new-standards-cd1f96f96494" target="_blank" rel="noopener noreferrer">Contrasting UI components: passing the new standards</a>, Medium
      * <a href="https://react.carbondesignsystem.com/?path=/story/textinput--default" target="_blank" rel="noopener noreferrer">Text input</a>, Carbon design system
      * <a href="https://matthewmorek.com/articles/the-importance-of-contrast-in-interface-design/" target="_blank" rel="noopener noreferrer">The importance of contrast in interface design</a>, Matthew Morek
      * <a href="https://www.w3.org/WAI/WCAG22/Understanding/non-text-contrast.html" target="_blank" rel="noopener noreferrer">Understanding 1.4.11 Non-text Contrast</a>, WCAG
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g209' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='wcag_4' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='accessibility_developer_guide_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='medium_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_13' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='matthew_morek_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='wcag_6' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_11")} target="_blank" rel="noopener noreferrer">1.4.11 Non-text Contrast</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_11' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Tooltips and overlays](/toolkit/design/visual/#tooltips-overlays), this page
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='visual_tooltips_overlays' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      