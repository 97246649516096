import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/visual_1_1_do.png";
import img2 from "./../../../../../visuals/design/visual_1_1_dont.png";
import img3 from "./../../../../../visuals/design/visual_1_2_do.png";
import img4 from "./../../../../../visuals/design/visual_1_2_dont.png";
import img5 from "./../../../../../visuals/design/visual_1_3_do.png";
import img6 from "./../../../../../visuals/design/visual_1_3_dont.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users with low vision need sufficient contrast in order to read. The color contrast ratio follows a formula to compare the luminance of the foreground text with background colors. Take note of contrast especially for text resizing, responsive design, and motion effects (where parallax effects during scrolling or animated backgrounds) when the position of text on its background can change.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Make sure text achieves sufficient contrast against its background`}</li>
      <li parentName="ul">{`Achieve minimum contrast for all text placed over images or gradients`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" aspectRatio="1:1" captionTitle="" caption="Minimum contrast for body text (less than 18 point regular or 14 point bold) is 4.5:1." mdxType="DoDontExample">
     <img src={img1} alt="Good example: body of text fades from black to grey with sufficient contrast on white background"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" aspectRatio="1:1" captionTitle="" caption="Don’t use text below 4.5:1 contrast that makes it difficult to read." mdxType="DoDontExample">
     <img src={img2} alt="Bad example: grey body text that fades with insufficient contrast on white background"></img>
        </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" aspectRatio="1:1" captionTitle="" caption="Minimum contrast for large text (greater than 18 point regular or 14 point bold) is 3:1." mdxType="DoDontExample">
     <img src={img3} alt="Good example: large bold 'hello' text in purple with sufficient contrast on white background"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" aspectRatio="1:1" captionTitle="" caption="Don't use large text that contrasts less than 3:1 with its background." mdxType="DoDontExample">
     <img src={img4} alt="Bad example: large bold 'hello' text in grey with insufficient contrast on white background"></img>
        </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
     <DoDontExample type="do" mdxType="DoDontExample">
    <img src={img5} alt="Good example: white 'This is a title...' text on a black background box to ensure contrast over a complex background image of a wall of storage boxes"></img>
     </DoDontExample>
    <Caption fullWidth mdxType="Caption">Maintain a minimum contrast ratio for all text placed over an image. Copyright IBM; all rights reserved.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
     <DoDontExample type="dont" mdxType="DoDontExample">
    <img src={img6} alt="Bad example: white 'This is a title...' text on light gradient background with insufficient contrast"></img>
     </DoDontExample>
    <Caption fullWidth mdxType="Caption">Don't place text on a background that falls below contrast minimums at any point.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/failures/F83" target="_blank" rel="noopener noreferrer">F83: Failure of Success Criterion 1.4.3 and 1.4.6 due to using background images that do not provide sufficient contrast with foreground text (or images of text</a>, WCAG 2.1 technique
      * <a href="https://www.nngroup.com/articles/text-over-images/" target="_blank" rel="noopener noreferrer">Ensure high contrast over text images</a>, Nielsen Norman Group
      * <a href="https://www.youtube.com/watch?v=Hui87z2Vx8o" target="_blank" rel="noopener noreferrer">Color with good contrast</a>, W3C web accessibility perspectives (video)
      * <a href="https://www.carbondesignsystem.com/guidelines/color/overview" target="_blank" rel="noopener noreferrer">Color themes and accessibility</a>, Carbon design system
      * <a href="https://www.paciellogroup.com/color-contrast-checker" target="_blank" rel="noopener noreferrer">Color contrast checker</a>, Paciello Group
      * <a href="https://getstark.co/" target="_blank" rel="noopener noreferrer">Stark plugin for color contrast</a>, Stark
      * <a href="https://www.brandwood.com/a11y/" target="_blank" rel="noopener noreferrer">Text on background image a11y check</a>, Brandwood
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='f83' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_5' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_perspectives_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_9' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='paciello_group_3' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='stark_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='brandwood_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_3")} target="_blank" rel="noopener noreferrer">1.4.3 Contrast (Minimum)</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Link text contrast](/toolkit/design/visual/#link-text-contrast), this section
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='visual_link_text_contrast' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      