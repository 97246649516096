import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Information with a specific order is available to the screen reader`}</li>
      <li parentName="ul">{`When the screen reader deviates from visual order, the reading order does not alter its meaning`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`Checking meaningful sequence tests that the screen reader reads content in an order that preserves its intended meaning. It is important not to confuse the reading order with the keyboard navigation order. The tester has already verified the ability to navigate and get focus in the correct order using the keyboard. Here, the tester uses a screen reader to ensure that all content (not only interactive elements) is either read out in an order that matches the visual order of information on the screen, or that, where it deviates from the visual order, the reading order does not alter its meaning. This is accomplished by using the screen reader reading commands, such as the NextLine (Down arrow), getting lists of element types, such as headings and regions, or issuing a SayAll (Insert+Down arrow) command, which reads out all the content on the screen.`}</p>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      