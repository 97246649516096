import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Authoring tools shall,
when converting content from one format to another or saving content in multiple formats,
preserve the information required for accessibility to the extent that the information is supported by the destination format.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`When accessible content is created in one format,
the accessible content and related information, such as proper heading mark-up,
should be preserved when converting the document to another format.`}</p>
    <p>{`The chief danger when converting is that the structure that improves a content’s accessibility may be lost,
yet the visual cues that suggest its presence continue to exist.
For instance, properly structured and accessible heading levels may exist in the original format,
but when saved as another format, although the appearance of heading styles may be preserved, for instance, they still appear centered in a larger type size,
the structural information that designates them as headings is lost.`}</p>
    <p>{`In such a situation,
assistive technologies can no longer expose this heading information to the end user.
As well, technologies will no longer be able to apply user-specified styling to the headings.`}</p>
    <p>{`Other common conversion losses include tabular information which is no longer programmatically structured as a table,
form fields which are no longer interactive or are no longer associated with their labels,
and navigation elements which appear to be retained but are no longer functional.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Some formats may not support all the accessibility information contained in the original.
The requirement is to ensure that accessibility is preserved ”`}<em parentName="p">{`to the extent that the information is supported by the destination format`}</em>{`.”`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#504.2.1" target="_blank">{`504.2.1 Preservation of Information Provided for Accessibility in Format Conversion`}</a>{` (external link to US 508) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      