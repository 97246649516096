import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { Video } from 'gatsby-theme-carbon/src/components/Video';
import img1 from "./../../../../../visuals/design/ux_3_2_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users who are blind rely heavily on keyboard navigation. Any component that can be operated with a mouse should be reachable and operable by keyboard, or have a keyboard equivalent.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Ensure all mouse-operable components are reachable by keyboard`}</li>
      <li parentName="ul">{`Provide a keyboard equivalent for drag and drop`}</li>
      <li parentName="ul">{`Annotate keyboard equivalents for components with multiple mouse operations`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        {
          /* Video is a special case. Serve video content and poster from the static folder in order to avoid issues. */
        }
        {
          /* NOTE: Shadowed Video.js looks for special string 'IBM_Accessibility' in MP4 filename, and if observed,
          applies dynamic CSS styling to remove the big blue play button.  */
        }
        <Video controls src={withPrefix("/videos/IBM_Accessibility_ux_3_1_eg.mp4")} poster="/images/ux_3_1_eg.png" aria-label="annotate keyboard equivalents for drag and drop" mdxType="Video"></Video>
        <Caption fullWidth mdxType="Caption">For drag and drop components, provide the keyboard equivalent for how to select the item, how to move the item, and where to drop the item.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="annotate keyboard equivalents for components"></img>
        <Caption fullWidth mdxType="Caption">Annotate the keyboard equivalent for components (such as these tiles) that have multiple mouse operations.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://w3c.github.io/aria-practices/#keyboard" target="_blank" rel="noopener noreferrer">Developing a keyboard interface</a>, ARIA authoring practices
      * <a href="https://www.w3.org/WAI/perspective-videos/keyboard/" target="_blank" rel="noopener noreferrer">Keyboard compatibility</a>, W3C web accessibility perspectives (video)
      * <a href="https://www.nngroup.com/articles/keyboard-accessibility/" target="_blank" rel="noopener noreferrer">Keyboard-only navigation for improved accessibility</a>, Nielsen Norman Group
      * <a href="https://developers.google.com/web/fundamentals/accessibility/focus/dom-order-matters" target="_blank" rel="noopener noreferrer">DOM order matters</a>, Web fundamentals, Google
      * <a href="https://salesforce-ux.github.io/dnd-a11y-patterns/" target="_blank" rel="noopener noreferrer">Accessible drag and drop examples</a>, Salesforce
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_perspectives_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_15' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='google_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='salesforce_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_1_1")} target="_blank" rel="noopener noreferrer">2.1.1 Keyboard</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_1_2")} target="_blank" rel="noopener noreferrer">2.1.2 No Keyboard Trap</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#2_4_3")} target="_blank" rel="noopener noreferrer">2.4.3 Focus Order</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Keyboard interaction](/toolkit/design/ux/#keyboard-interaction), this page
      * [Tab order](/toolkit/design/ux/#tab-order), this page
      * [Pointer operation](/toolkit/design/ux/pointer-operation), this page
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_tab_order' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_pointer_operation' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      