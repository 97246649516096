import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Documentation lists and explains accessibility and compatibility features,
including keyboard access.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`People with disabilities cannot effectively use your software if they are not provided information on `}<em parentName="p">{`how`}</em>{` to use the accessibility features.
This is particularly important for keyboard access.
Because most products, websites, and applications focus on navigation with the mouse,
it is not always clear how to use it with the keyboard.
All keyboard navigation and operation which does not follow established system conventions must be documented.`}</p>
    <p><strong parentName="p">{`Designers:`}</strong>{` See related guidance`}</p>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/design/ux/#keyboard-interaction")} target="_blank" rel="noopener noreferrer">Keyboard interactions</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/design/ux/#mouse-operable")} target="_blank" rel="noopener noreferrer">Mouse-operable components</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/design/ux/#custom-component")} target="_blank" rel="noopener noreferrer">Custom component keyboard guidance</a></li>
    </ul>
    <p><strong parentName="p">{`Developers:`}</strong>{` See related guidance`}</p>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/keyboard/#keyboard-conventions")} target="_blank" rel="noopener noreferrer">Be familiar with established keyboard conventions</a></li>
      <li parentName="ul"><a href={withPrefix("requirements/requirements/#2_1_1")} target="_blank" rel="noopener noreferrer">2.1.1 Keyboard</a></li>
      <li parentName="ul"><a href={withPrefix("requirements/requirements/#2_1_4")} target="_blank" rel="noopener noreferrer">2.1.4 Character Key Shortcuts</a></li>
    </ul>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#602.2" target="_blank">{`602.2 Accessibility and Compatibility Features`}</a>{` (external link to US 508) for more information.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      