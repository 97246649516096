import { Column } from 'gatsby-theme-carbon/src/components/Grid';
import Launch16 from '@carbon/icons-react/lib/launch/16';
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import DocumentDownload16 from '@carbon/icons-react/lib/document--download/16';
import { withPrefix } from "gatsby";
import * as React from 'react';
export default {
  Column,
  Launch16,
  ArrowRight16,
  DocumentDownload16,
  withPrefix,
  React
};