import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
import img1 from "./../../../../../visuals/design/L2_content_user_input_5_do.png";
import img2 from "./../../../../../visuals/design/L2_content_user_input_5_dont.png";
import "../../../topics.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Placeholder text should not be the only place where relevant guidance to the input exists. Placeholder text disappears once the user starts typing, rendering any pertaining guidance useless, which is problematic for users with cognitive disabilities and can’t remember the placeholder text. It’s also problematic for users with screen readers that skip over placeholder text.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Ensure labels and instructions persist when placeholder text is no longer visible`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <DoDontExample type="do" mdxType="DoDontExample">
    <img src={img1} alt="Good example: a populated 'passport number' input with instructions, followed by an active but empty 'passport expiry date' input with instructions on date formatting"></img>
        </DoDontExample>
        <Caption fullwidth mdxType="Caption">Keep labels and important instructions visible so user guidance is consistent.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <DoDontExample type="dont" mdxType="DoDontExample">
    <img src={img2} alt="Bad example: two input fields with no labels, one filled out with numbers, and the other with placeholder text 'passport expiry date MM/DD/YYYY'"></img>
        </DoDontExample>
        <Caption fullwidth mdxType="Caption">Don't use placeholders in place of labels or critical instructions.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_10' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    <ul>
      <li parentName="ul"><ToolkitTopic qid='content_input_labels' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_input_components' mdxType="ToolkitTopic"></ToolkitTopic>, UX design</li>
      <li parentName="ul"><ToolkitTopic qid='dev_input_associate_labels' mdxType="ToolkitTopic"></ToolkitTopic>, Develop – User guidance</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      