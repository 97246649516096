import React from "react";
import Bee from "../visuals/homepage/banner_bee_logo.svg";
import "./PageBanner.scss";
import "carbon-components/css/carbon-components.css";
export default ({ children, title, description }) => {
  return (
    <div className="pageBanner" style={{ position: "relative" }}>
      <div className="bx--grid" style={{ height: "100%" }}>
        <div className="bx--row" style={{ position: "absolute", bottom: "2rem", width: "100%" }}>
          <div className="bx--col-sm-4 bx--col-md-4 bx--col-lg-4">
            <div role="heading" aria-level="1" className="title">{title}</div>
            <p className="subtitle" style={{marginTop:"0px", maxWidth:'320px'}}>{description}</p>
            {/* <img src={Bee} className="beeStyle" alt="bee icon" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
