import React, { Component } from 'react'
import { Tabs, Tab } from 'gatsby-theme-carbon/src/components/Tabs';
import './reqSwitcher.scss'


// Import mdxs for each requirement
// 01 - 1.1.1
import Req1_1_1 from "../../pages/requirements/1.1.1/1.1.1.mdx"
import Design1_1_1 from "../../pages/requirements/1.1.1/1.1.1design.mdx"
import Develop1_1_1 from "../../pages/requirements/1.1.1/1.1.1develop.mdx"
import Test1_1_1 from "../../pages/requirements/1.1.1/1.1.1test.mdx"
// 02 - 1.2.1
import Req1_2_1 from "../../pages/requirements/1.2.1/1.2.1.mdx"
import Design1_2_1 from "../../pages/requirements/1.2.1/1.2.1design.mdx"
import Develop1_2_1 from "../../pages/requirements/1.2.1/1.2.1develop.mdx"
import Test1_2_1 from "../../pages/requirements/1.2.1/1.2.1test.mdx"
// 03 - 1.2.2
import Req1_2_2 from "../../pages/requirements/1.2.2/1.2.2.mdx"
import Design1_2_2 from "../../pages/requirements/1.2.2/1.2.2design.mdx"
import Develop1_2_2 from "../../pages/requirements/1.2.2/1.2.2develop.mdx"
import Test1_2_2 from "../../pages/requirements/1.2.2/1.2.2test.mdx"
// 04 - 1.2.3
import Req1_2_3 from "../../pages/requirements/1.2.3/1.2.3.mdx"
import Design1_2_3 from "../../pages/requirements/1.2.3/1.2.3design.mdx"
import Develop1_2_3 from "../../pages/requirements/1.2.3/1.2.3develop.mdx"
import Test1_2_3 from "../../pages/requirements/1.2.3/1.2.3test.mdx"
// 05 - 1.2.4
import Req1_2_4 from "../../pages/requirements/1.2.4/1.2.4.mdx"
import Design1_2_4 from "../../pages/requirements/1.2.4/1.2.4design.mdx"
import Develop1_2_4 from "../../pages/requirements/1.2.4/1.2.4develop.mdx"
import Test1_2_4 from "../../pages/requirements/1.2.4/1.2.4test.mdx"
// 06 - 1.2.5
import Req1_2_5 from "../../pages/requirements/1.2.5/1.2.5.mdx"
import Design1_2_5 from "../../pages/requirements/1.2.5/1.2.5design.mdx"
import Develop1_2_5 from "../../pages/requirements/1.2.5/1.2.5develop.mdx"
import Test1_2_5 from "../../pages/requirements/1.2.5/1.2.5test.mdx"
// 07 - 1.3.1
import Req1_3_1 from "../../pages/requirements/1.3.1/1.3.1.mdx"
import Design1_3_1 from "../../pages/requirements/1.3.1/1.3.1design.mdx"
import Develop1_3_1 from "../../pages/requirements/1.3.1/1.3.1develop.mdx"
import Test1_3_1 from "../../pages/requirements/1.3.1/1.3.1test.mdx"
// 08 - 1.3.2
import Req1_3_2 from "../../pages/requirements/1.3.2/1.3.2.mdx"
import Design1_3_2 from "../../pages/requirements/1.3.2/1.3.2design.mdx"
import Develop1_3_2 from "../../pages/requirements/1.3.2/1.3.2develop.mdx"
import Test1_3_2 from "../../pages/requirements/1.3.2/1.3.2test.mdx"
// 09 - 1.3.3
import Req1_3_3 from "../../pages/requirements/1.3.3/1.3.3.mdx"
import Design1_3_3 from "../../pages/requirements/1.3.3/1.3.3design.mdx"
import Develop1_3_3 from "../../pages/requirements/1.3.3/1.3.3develop.mdx"
import Test1_3_3 from "../../pages/requirements/1.3.3/1.3.3test.mdx"
// 10 - 1.3.4
import Req1_3_4 from "../../pages/requirements/1.3.4/1.3.4.mdx"
import Design1_3_4 from "../../pages/requirements/1.3.4/1.3.4design.mdx"
import Develop1_3_4 from "../../pages/requirements/1.3.4/1.3.4develop.mdx"
import Test1_3_4 from "../../pages/requirements/1.3.4/1.3.4test.mdx"
// 11 - 1.3.5
import Req1_3_5 from "../../pages/requirements/1.3.5/1.3.5.mdx"
import Design1_3_5 from "../../pages/requirements/1.3.5/1.3.5design.mdx"
import Develop1_3_5 from "../../pages/requirements/1.3.5/1.3.5develop.mdx"
import Test1_3_5 from "../../pages/requirements/1.3.5/1.3.5test.mdx"
// 12 - 1.4.1
import Req1_4_1 from "../../pages/requirements/1.4.1/1.4.1.mdx"
import Design1_4_1 from "../../pages/requirements/1.4.1/1.4.1design.mdx"
import Develop1_4_1 from "../../pages/requirements/1.4.1/1.4.1develop.mdx"
import Test1_4_1 from "../../pages/requirements/1.4.1/1.4.1test.mdx"
// 13 - 1.4.2
import Req1_4_2 from "../../pages/requirements/1.4.2/1.4.2.mdx"
import Design1_4_2 from "../../pages/requirements/1.4.2/1.4.2design.mdx"
import Develop1_4_2 from "../../pages/requirements/1.4.2/1.4.2develop.mdx"
import Test1_4_2 from "../../pages/requirements/1.4.2/1.4.2test.mdx"
// 14 - 1.4.3
import Req1_4_3 from "../../pages/requirements/1.4.3/1.4.3.mdx"
import Design1_4_3 from "../../pages/requirements/1.4.3/1.4.3design.mdx"
import Develop1_4_3 from "../../pages/requirements/1.4.3/1.4.3develop.mdx"
import Test1_4_3 from "../../pages/requirements/1.4.3/1.4.3test.mdx"
// 15 - 1.4.4
import Req1_4_4 from "../../pages/requirements/1.4.4/1.4.4.mdx"
import Design1_4_4 from "../../pages/requirements/1.4.4/1.4.4design.mdx"
import Develop1_4_4 from "../../pages/requirements/1.4.4/1.4.4develop.mdx"
import Test1_4_4 from "../../pages/requirements/1.4.4/1.4.4test.mdx"
// 16 - 1.4.5
import Req1_4_5 from "../../pages/requirements/1.4.5/1.4.5.mdx"
import Design1_4_5 from "../../pages/requirements/1.4.5/1.4.5design.mdx"
import Develop1_4_5 from "../../pages/requirements/1.4.5/1.4.5develop.mdx"
import Test1_4_5 from "../../pages/requirements/1.4.5/1.4.5test.mdx"
// 17 - 1.4.10
import Req1_4_10 from "../../pages/requirements/1.4.10/1.4.10.mdx"
import Design1_4_10 from "../../pages/requirements/1.4.10/1.4.10design.mdx"
import Develop1_4_10 from "../../pages/requirements/1.4.10/1.4.10develop.mdx"
import Test1_4_10 from "../../pages/requirements/1.4.10/1.4.10test.mdx"
// 18 - 1.4.11
import Req1_4_11 from "../../pages/requirements/1.4.11/1.4.11.mdx"
import Design1_4_11 from "../../pages/requirements/1.4.11/1.4.11design.mdx"
import Develop1_4_11 from "../../pages/requirements/1.4.11/1.4.11develop.mdx"
import Test1_4_11 from "../../pages/requirements/1.4.11/1.4.11test.mdx"
// 19 - 1.4.12
import Req1_4_12 from "../../pages/requirements/1.4.12/1.4.12.mdx"
import Design1_4_12 from "../../pages/requirements/1.4.12/1.4.12design.mdx"
import Develop1_4_12 from "../../pages/requirements/1.4.12/1.4.12develop.mdx"
import Test1_4_12 from "../../pages/requirements/1.4.12/1.4.12test.mdx"
// 20 - 1.4.13
import Req1_4_13 from "../../pages/requirements/1.4.13/1.4.13.mdx"
import Design1_4_13 from "../../pages/requirements/1.4.13/1.4.13design.mdx"
import Develop1_4_13 from "../../pages/requirements/1.4.13/1.4.13develop.mdx"
import Test1_4_13 from "../../pages/requirements/1.4.13/1.4.13test.mdx"
// 21 - 2.1.1
import Req2_1_1 from "../../pages/requirements/2.1.1/2.1.1.mdx"
import Design2_1_1 from "../../pages/requirements/2.1.1/2.1.1design.mdx"
import Develop2_1_1 from "../../pages/requirements/2.1.1/2.1.1develop.mdx"
import Test2_1_1 from "../../pages/requirements/2.1.1/2.1.1test.mdx"
// 22 - 2.1.2
import Req2_1_2 from "../../pages/requirements/2.1.2/2.1.2.mdx"
import Design2_1_2 from "../../pages/requirements/2.1.2/2.1.2design.mdx"
import Develop2_1_2 from "../../pages/requirements/2.1.2/2.1.2develop.mdx"
import Test2_1_2 from "../../pages/requirements/2.1.2/2.1.2test.mdx"
// 23 - 2.1.4
import Req2_1_4 from "../../pages/requirements/2.1.4/2.1.4.mdx"
import Design2_1_4 from "../../pages/requirements/2.1.4/2.1.4design.mdx"
import Develop2_1_4 from "../../pages/requirements/2.1.4/2.1.4develop.mdx"
import Test2_1_4 from "../../pages/requirements/2.1.4/2.1.4test.mdx"
// 24 - 2.2.1
import Req2_2_1 from "../../pages/requirements/2.2.1/2.2.1.mdx"
import Design2_2_1 from "../../pages/requirements/2.2.1/2.2.1design.mdx"
import Develop2_2_1 from "../../pages/requirements/2.2.1/2.2.1develop.mdx"
import Test2_2_1 from "../../pages/requirements/2.2.1/2.2.1test.mdx"
// 25 - 2.2.2
import Req2_2_2 from "../../pages/requirements/2.2.2/2.2.2.mdx"
import Design2_2_2 from "../../pages/requirements/2.2.2/2.2.2design.mdx"
import Develop2_2_2 from "../../pages/requirements/2.2.2/2.2.2develop.mdx"
import Test2_2_2 from "../../pages/requirements/2.2.2/2.2.2test.mdx"
// 26 - 2.3.1
import Req2_3_1 from "../../pages/requirements/2.3.1/2.3.1.mdx"
import Design2_3_1 from "../../pages/requirements/2.3.1/2.3.1design.mdx"
import Develop2_3_1 from "../../pages/requirements/2.3.1/2.3.1develop.mdx"
import Test2_3_1 from "../../pages/requirements/2.3.1/2.3.1test.mdx"
// 27 - 2.4.1
import Req2_4_1 from "../../pages/requirements/2.4.1/2.4.1.mdx"
import Design2_4_1 from "../../pages/requirements/2.4.1/2.4.1design.mdx"
import Develop2_4_1 from "../../pages/requirements/2.4.1/2.4.1develop.mdx"
import Test2_4_1 from "../../pages/requirements/2.4.1/2.4.1test.mdx"
// 28 - 2.4.2
import Req2_4_2 from "../../pages/requirements/2.4.2/2.4.2.mdx"
import Design2_4_2 from "../../pages/requirements/2.4.2/2.4.2design.mdx"
import Develop2_4_2 from "../../pages/requirements/2.4.2/2.4.2develop.mdx"
import Test2_4_2 from "../../pages/requirements/2.4.2/2.4.2test.mdx"
// 29 - 2.4.3
import Req2_4_3 from "../../pages/requirements/2.4.3/2.4.3.mdx"
import Design2_4_3 from "../../pages/requirements/2.4.3/2.4.3design.mdx"
import Develop2_4_3 from "../../pages/requirements/2.4.3/2.4.3develop.mdx"
import Test2_4_3 from "../../pages/requirements/2.4.3/2.4.3test.mdx"
// 30 - 2.4.4
import Req2_4_4 from "../../pages/requirements/2.4.4/2.4.4.mdx"
import Design2_4_4 from "../../pages/requirements/2.4.4/2.4.4design.mdx"
import Develop2_4_4 from "../../pages/requirements/2.4.4/2.4.4develop.mdx"
import Test2_4_4 from "../../pages/requirements/2.4.4/2.4.4test.mdx"
// 31 - 2.4.5
import Req2_4_5 from "../../pages/requirements/2.4.5/2.4.5.mdx"
import Design2_4_5 from "../../pages/requirements/2.4.5/2.4.5design.mdx"
import Develop2_4_5 from "../../pages/requirements/2.4.5/2.4.5develop.mdx"
import Test2_4_5 from "../../pages/requirements/2.4.5/2.4.5test.mdx"
// 32 - 2.4.6
import Req2_4_6 from "../../pages/requirements/2.4.6/2.4.6.mdx"
import Design2_4_6 from "../../pages/requirements/2.4.6/2.4.6design.mdx"
import Develop2_4_6 from "../../pages/requirements/2.4.6/2.4.6develop.mdx"
import Test2_4_6 from "../../pages/requirements/2.4.6/2.4.6test.mdx"
// 33 - 2.4.7
import Req2_4_7 from "../../pages/requirements/2.4.7/2.4.7.mdx"
import Design2_4_7 from "../../pages/requirements/2.4.7/2.4.7design.mdx"
import Develop2_4_7 from "../../pages/requirements/2.4.7/2.4.7develop.mdx"
import Test2_4_7 from "../../pages/requirements/2.4.7/2.4.7test.mdx"
// 33.1 - 2.4.11
import Req2_4_11 from "../../pages/requirements/2.4.11/2.4.11.mdx"
import Design2_4_11 from "../../pages/requirements/2.4.11/2.4.11design.mdx"
import Develop2_4_11 from "../../pages/requirements/2.4.11/2.4.11develop.mdx"
import Test2_4_11 from "../../pages/requirements/2.4.11/2.4.11test.mdx"
// 34 - 2.5.1
import Req2_5_1 from "../../pages/requirements/2.5.1/2.5.1.mdx"
import Design2_5_1 from "../../pages/requirements/2.5.1/2.5.1design.mdx"
import Develop2_5_1 from "../../pages/requirements/2.5.1/2.5.1develop.mdx"
import Test2_5_1 from "../../pages/requirements/2.5.1/2.5.1test.mdx"
// 35 - 2.5.2
import Req2_5_2 from "../../pages/requirements/2.5.2/2.5.2.mdx"
import Design2_5_2 from "../../pages/requirements/2.5.2/2.5.2design.mdx"
import Develop2_5_2 from "../../pages/requirements/2.5.2/2.5.2develop.mdx"
import Test2_5_2 from "../../pages/requirements/2.5.2/2.5.2test.mdx"
// 36 - 2.5.3
import Req2_5_3 from "../../pages/requirements/2.5.3/2.5.3.mdx"
import Design2_5_3 from "../../pages/requirements/2.5.3/2.5.3design.mdx"
import Develop2_5_3 from "../../pages/requirements/2.5.3/2.5.3develop.mdx"
import Test2_5_3 from "../../pages/requirements/2.5.3/2.5.3test.mdx"
// 37 - 2.5.4
import Req2_5_4 from "../../pages/requirements/2.5.4/2.5.4.mdx"
import Design2_5_4 from "../../pages/requirements/2.5.4/2.5.4design.mdx"
import Develop2_5_4 from "../../pages/requirements/2.5.4/2.5.4develop.mdx"
import Test2_5_4 from "../../pages/requirements/2.5.4/2.5.4test.mdx"
// 37.1 - 2.5.7
import Req2_5_7 from "../../pages/requirements/2.5.7/2.5.7.mdx"
import Design2_5_7 from "../../pages/requirements/2.5.7/2.5.7design.mdx"
import Develop2_5_7 from "../../pages/requirements/2.5.7/2.5.7develop.mdx"
import Test2_5_7 from "../../pages/requirements/2.5.7/2.5.7test.mdx"
// 37.2 - 2.5.8
import Req2_5_8 from "../../pages/requirements/2.5.8/2.5.8.mdx"
import Design2_5_8 from "../../pages/requirements/2.5.8/2.5.8design.mdx"
import Develop2_5_8 from "../../pages/requirements/2.5.8/2.5.8develop.mdx"
import Test2_5_8 from "../../pages/requirements/2.5.8/2.5.8test.mdx"
// 38 - 3.1.1
import Req3_1_1 from "../../pages/requirements/3.1.1/3.1.1.mdx"
import Design3_1_1 from "../../pages/requirements/3.1.1/3.1.1design.mdx"
import Develop3_1_1 from "../../pages/requirements/3.1.1/3.1.1develop.mdx"
import Test3_1_1 from "../../pages/requirements/3.1.1/3.1.1test.mdx"
// 39 - 3.1.2
import Req3_1_2 from "../../pages/requirements/3.1.2/3.1.2.mdx"
import Design3_1_2 from "../../pages/requirements/3.1.2/3.1.2design.mdx"
import Develop3_1_2 from "../../pages/requirements/3.1.2/3.1.2develop.mdx"
import Test3_1_2 from "../../pages/requirements/3.1.2/3.1.2test.mdx"
// 40 - 3.2.1
import Req3_2_1 from "../../pages/requirements/3.2.1/3.2.1.mdx"
import Design3_2_1 from "../../pages/requirements/3.2.1/3.2.1design.mdx"
import Develop3_2_1 from "../../pages/requirements/3.2.1/3.2.1develop.mdx"
import Test3_2_1 from "../../pages/requirements/3.2.1/3.2.1test.mdx"
// 41 - 3.2.2
import Req3_2_2 from "../../pages/requirements/3.2.2/3.2.2.mdx"
import Design3_2_2 from "../../pages/requirements/3.2.2/3.2.2design.mdx"
import Develop3_2_2 from "../../pages/requirements/3.2.2/3.2.2develop.mdx"
import Test3_2_2 from "../../pages/requirements/3.2.2/3.2.2test.mdx"
// 42 = 3.2.3
import Req3_2_3 from "../../pages/requirements/3.2.3/3.2.3.mdx"
import Design3_2_3 from "../../pages/requirements/3.2.3/3.2.3design.mdx"
import Develop3_2_3 from "../../pages/requirements/3.2.3/3.2.3develop.mdx"
import Test3_2_3 from "../../pages/requirements/3.2.3/3.2.3test.mdx"
// 43 - 3.2.4
import Req3_2_4 from "../../pages/requirements/3.2.4/3.2.4.mdx"
import Design3_2_4 from "../../pages/requirements/3.2.4/3.2.4design.mdx"
import Develop3_2_4 from "../../pages/requirements/3.2.4/3.2.4develop.mdx"
import Test3_2_4 from "../../pages/requirements/3.2.4/3.2.4test.mdx"
// 43.1 - 3.2.6
import Req3_2_6 from "../../pages/requirements/3.2.6/3.2.6.mdx"
import Design3_2_6 from "../../pages/requirements/3.2.6/3.2.6design.mdx"
import Develop3_2_6 from "../../pages/requirements/3.2.6/3.2.6develop.mdx"
import Test3_2_6 from "../../pages/requirements/3.2.6/3.2.6test.mdx"
// 44 - 3.3.1
import Req3_3_1 from "../../pages/requirements/3.3.1/3.3.1.mdx"
import Design3_3_1 from "../../pages/requirements/3.3.1/3.3.1design.mdx"
import Develop3_3_1 from "../../pages/requirements/3.3.1/3.3.1develop.mdx"
import Test3_3_1 from "../../pages/requirements/3.3.1/3.3.1test.mdx"
// 45 - 3.3.2
import Req3_3_2 from "../../pages/requirements/3.3.2/3.3.2.mdx"
import Design3_3_2 from "../../pages/requirements/3.3.2/3.3.2design.mdx"
import Develop3_3_2 from "../../pages/requirements/3.3.2/3.3.2develop.mdx"
import Test3_3_2 from "../../pages/requirements/3.3.2/3.3.2test.mdx"
// 46 - 3.3.3
import Req3_3_3 from "../../pages/requirements/3.3.3/3.3.3.mdx"
import Design3_3_3 from "../../pages/requirements/3.3.3/3.3.3design.mdx"
import Develop3_3_3 from "../../pages/requirements/3.3.3/3.3.3develop.mdx"
import Test3_3_3 from "../../pages/requirements/3.3.3/3.3.3test.mdx"
// 47 - 3.3.4
import Req3_3_4 from "../../pages/requirements/3.3.4/3.3.4.mdx"
import Design3_3_4 from "../../pages/requirements/3.3.4/3.3.4design.mdx"
import Develop3_3_4 from "../../pages/requirements/3.3.4/3.3.4develop.mdx"
import Test3_3_4 from "../../pages/requirements/3.3.4/3.3.4test.mdx"
// 47.1 - 3.3.7
import Req3_3_7 from "../../pages/requirements/3.3.7/3.3.7.mdx"
import Design3_3_7 from "../../pages/requirements/3.3.7/3.3.7design.mdx"
import Develop3_3_7 from "../../pages/requirements/3.3.7/3.3.7develop.mdx"
import Test3_3_7 from "../../pages/requirements/3.3.7/3.3.7test.mdx"
// 47.2 - 3.3.8
import Req3_3_8 from "../../pages/requirements/3.3.8/3.3.8.mdx"
import Design3_3_8 from "../../pages/requirements/3.3.8/3.3.8design.mdx"
import Develop3_3_8 from "../../pages/requirements/3.3.8/3.3.8develop.mdx"
import Test3_3_8 from "../../pages/requirements/3.3.8/3.3.8test.mdx"
// 48 - 4.1.1
import Req4_1_1 from "../../pages/requirements/4.1.1/4.1.1.mdx"
import Design4_1_1 from "../../pages/requirements/4.1.1/4.1.1design.mdx"
import Develop4_1_1 from "../../pages/requirements/4.1.1/4.1.1develop.mdx"
import Test4_1_1 from "../../pages/requirements/4.1.1/4.1.1test.mdx"
// 49 - 4.1.2
import Req4_1_2 from "../../pages/requirements/4.1.2/4.1.2.mdx"
import Design4_1_2 from "../../pages/requirements/4.1.2/4.1.2design.mdx"
import Develop4_1_2 from "../../pages/requirements/4.1.2/4.1.2develop.mdx"
import Test4_1_2 from "../../pages/requirements/4.1.2/4.1.2test.mdx"
// 50 - 4.1.3
import Req4_1_3 from "../../pages/requirements/4.1.3/4.1.3.mdx"
import Design4_1_3 from "../../pages/requirements/4.1.3/4.1.3design.mdx"
import Develop4_1_3 from "../../pages/requirements/4.1.3/4.1.3develop.mdx"
import Test4_1_3 from "../../pages/requirements/4.1.3/4.1.3test.mdx"
// 51 4.1.4 removed
// 52 - 502.2.1
import Req502_2_1 from "../../pages/requirements/502.2.1/502.2.1.mdx"
// 53 - 502.2.2
import Req502_2_2 from "../../pages/requirements/502.2.2/502.2.2.mdx"
// 54 - 502.3.1
import Req502_3_1 from "../../pages/requirements/502.3.1/502.3.1.mdx"
// 55 - 502.3.2
import Req502_3_2 from "../../pages/requirements/502.3.2/502.3.2.mdx"
// 56 - 502.3.3
import Req502_3_3 from "../../pages/requirements/502.3.3/502.3.3.mdx"
// 57 - 502.3.4
import Req502_3_4 from "../../pages/requirements/502.3.4/502.3.4.mdx"
// 58 - 502.3.5
import Req502_3_5 from "../../pages/requirements/502.3.5/502.3.5.mdx"
// 59 - 502.3.6
import Req502_3_6 from "../../pages/requirements/502.3.6/502.3.6.mdx"
// 60 - 502.3.7
import Req502_3_7 from "../../pages/requirements/502.3.7/502.3.7.mdx"
// 61 - 502.3.8
import Req502_3_8 from "../../pages/requirements/502.3.8/502.3.8.mdx"
// 62 - 502.3.9
import Req502_3_9 from "../../pages/requirements/502.3.9/502.3.9.mdx"
// 63 - 502.3.10
import Req502_3_10 from "../../pages/requirements/502.3.10/502.3.10.mdx"
// 64 - 502.3.11
import Req502_3_11 from "../../pages/requirements/502.3.11/502.3.11.mdx"
// 65 - 502.3.12
import Req502_3_12 from "../../pages/requirements/502.3.12/502.3.12.mdx"
// 66 - 502.3.13
import Req502_3_13 from "../../pages/requirements/502.3.13/502.3.13.mdx"
// 67 - 502.3.14
import Req502_3_14 from "../../pages/requirements/502.3.14/502.3.14.mdx"
// 68 - 502.4
import Req502_4 from "../../pages/requirements/502.4/502.4.mdx"
// 69 - 503.2
import Req503_2 from "../../pages/requirements/503.2/503.2.mdx"
// 70 - 503.3
import Req503_3 from "../../pages/requirements/503.3/503.3.mdx"
// 71 - 503.4.1
import Req503_4_1 from "../../pages/requirements/503.4.1/503.4.1.mdx"
// 72 - 503.4.2
import Req503_4_2 from "../../pages/requirements/503.4.2/503.4.2.mdx"
// 73 - 504.2
import Req504_2 from "../../pages/requirements/504.2/504.2.mdx"
// 74 - 504.2.1
import Req504_2_1 from "../../pages/requirements/504.2.1/504.2.1.mdx"
// 75 - 504.2.2
import Req504_2_2 from "../../pages/requirements/504.2.2/504.2.2.mdx"
// 76 - 504.3
import Req504_3 from "../../pages/requirements/504.3/504.3.mdx"
// 77 - 504.4
import Req504_4 from "../../pages/requirements/504.4/504.4.mdx"


// 78 - 602.2
import Req602_2 from "../../pages/requirements/602.2/602.2.mdx"
// 79 - 602.3
import Req602_3 from "../../pages/requirements/602.3/602.3.mdx"


// 80 - 5.2
import Req5_2 from "../../pages/requirements/5.2/5.2.mdx"
// 81 - 5.7
import Req5_7 from "../../pages/requirements/5.7/5.7.mdx"
// 82 - 5.8
import Req5_8 from "../../pages/requirements/5.8/5.8.mdx"
// 83 - 5.9
import Req5_9 from "../../pages/requirements/5.9/5.9.mdx"


// 84 - 7.1.1
import Req7_1_1 from "../../pages/requirements/7.1.1/7.1.1.mdx"
// 85 - 7.1.2
import Req7_1_2 from "../../pages/requirements/7.1.2/7.1.2.mdx"
// 86 - 7.1.3
import Req7_1_3 from "../../pages/requirements/7.1.3/7.1.3.mdx"
// 87 - 7.1.4
import Req7_1_4 from "../../pages/requirements/7.1.4/7.1.4.mdx"
// 88 - 7.1.5
import Req7_1_5 from "../../pages/requirements/7.1.5/7.1.5.mdx"
// 89 - 7.2.1
import Req7_2_1 from "../../pages/requirements/7.2.1/7.2.1.mdx"
// 90 - 7.2.2
import Req7_2_2 from "../../pages/requirements/7.2.2/7.2.2.mdx"
// 91 - 7.2.3
import Req7_2_3 from "../../pages/requirements/7.2.3/7.2.3.mdx"
// 92 - 7.3
import Req7_3 from "../../pages/requirements/7.3/7.3.mdx"

// 93 - 10.5
import Req10_5 from "../../pages/requirements/10.5/10.5.mdx"
// 94 - 10.6
import Req10_6 from "../../pages/requirements/10.6/10.6.mdx"
// 95 - 11.5.2.3
import Req11_5_2_3 from "../../pages/requirements/11.5.2.3/11.5.2.3.mdx"
// 96 - 11.8.4
import Req11_8_4 from "../../pages/requirements/11.8.4/11.8.4.mdx"



class reqSwitcher extends Component {
    constructor() {
        super();
        this.state = { switchState: 0 };
    }

    changeSwitchState(index) {
        this.setState({ switchState: index });
    }

    getRationaleContent(reqMDX) {
        // console.log("getRationaleContent: reqMDX = ",reqMDX);
        let requirement = reqMDX;
        if (requirement === "1_1_1") {
            return (<Req1_1_1 />);
        } else if (requirement === "1_2_1") {
            return (<Req1_2_1 />);
        } else if (requirement === "1_2_2") {
            return (<Req1_2_2 />);
        } else if (requirement === "1_2_3") {
            return (<Req1_2_3 />);
        } else if (requirement === "1_2_4") {
            return (<Req1_2_4 />);
        } else if (requirement === "1_2_5") {
            return (<Req1_2_5 />);
        } else if (requirement === "1_3_1") {
            return (<Req1_3_1 />);
        } else if (requirement === "1_3_2") {
            return (<Req1_3_2 />);
        } else if (requirement === "1_3_3") {
            return (<Req1_3_3 />);
        } else if (requirement === "1_3_4") {
            return (<Req1_3_4 />);
        } else if (requirement === "1_3_5") {
            return (<Req1_3_5 />);
        } else if (requirement === "1_4_1") {
            return (<Req1_4_1 />);
        } else if (requirement === "1_4_2") {
            return (<Req1_4_2 />);
        } else if (requirement === "1_4_3") {
            return (<Req1_4_3 />);
        } else if (requirement === "1_4_4") {
            return (<Req1_4_4 />);
        } else if (requirement === "1_4_5") {
            return (<Req1_4_5 />);
        } else if (requirement === "1_4_10") {
            return (<Req1_4_10 />);
        } else if (requirement === "1_4_11") {
            return (<Req1_4_11 />);
        } else if (requirement === "1_4_12") {
            return (<Req1_4_12 />);
        } else if (requirement === "1_4_13") {
            return (<Req1_4_13 />);
        } else if (requirement === "2_1_1") {
            return (<Req2_1_1 />);
        } else if (requirement === "2_1_2") {
            return (<Req2_1_2 />);
        } else if (requirement === "2_1_4") {
            return (<Req2_1_4 />);
        } else if (requirement === "2_2_1") {
            return (<Req2_2_1 />);
        } else if (requirement === "2_2_2") {
            return (<Req2_2_2 />);
        } else if (requirement === "2_3_1") {
            return (<Req2_3_1 />);
        } else if (requirement === "2_4_1") {
            return (<Req2_4_1 />);
        } else if (requirement === "2_4_2") {
            return (<Req2_4_2 />);
        } else if (requirement === "2_4_3") {
            return (<Req2_4_3 />);
        } else if (requirement === "2_4_4") {
            return (<Req2_4_4 />);
        } else if (requirement === "2_4_5") {
            return (<Req2_4_5 />);
        } else if (requirement === "2_4_6") {
            return (<Req2_4_6 />);
        } else if (requirement === "2_4_7") {
            return (<Req2_4_7 />);
        } else if (requirement === "2_4_11") {
            return (<Req2_4_11 />);
        } else if (requirement === "2_5_1") {
            return (<Req2_5_1 />);
        } else if (requirement === "2_5_2") {
            return (<Req2_5_2 />);
        } else if (requirement === "2_5_3") {
            return (<Req2_5_3 />);
        } else if (requirement === "2_5_4") {
            return (<Req2_5_4 />);
        } else if (requirement === "2_5_7") {
            return (<Req2_5_7 />);
        } else if (requirement === "2_5_8") {
            return (<Req2_5_8 />);
        } else if (requirement === "3_1_1") {
            return (<Req3_1_1 />);
        } else if (requirement === "3_1_2") {
            return (<Req3_1_2 />);
        } else if (requirement === "3_2_1") {
            return (<Req3_2_1 />);
        } else if (requirement === "3_2_2") {
            return (<Req3_2_2 />);
        } else if (requirement === "3_2_3") {
            return (<Req3_2_3 />);
        } else if (requirement === "3_2_4") {
            return (<Req3_2_4 />);
        } else if (requirement === "3_2_6") {
            return (<Req3_2_6 />);
        } else if (requirement === "3_3_1") {
            return (<Req3_3_1 />);
        } else if (requirement === "3_3_2") {
            return (<Req3_3_2 />);
        } else if (requirement === "3_3_3") {
            return (<Req3_3_3 />);
        } else if (requirement === "3_3_4") {
            return (<Req3_3_4 />);
        } else if (requirement === "3_3_7") {
            return (<Req3_3_7 />);
        } else if (requirement === "3_3_8") {
            return (<Req3_3_8 />);
        } else if (requirement === "4_1_1") {
            return (<Req4_1_1 />);
        } else if (requirement === "4_1_2") {
            return (<Req4_1_2 />);
        } else if (requirement === "4_1_3") {
            return (<Req4_1_3 />);
        } else if (requirement === "502_2_1") {
            return (<Req502_2_1 />);
        } else if (requirement === "502_2_2") {
            return (<Req502_2_2 />);
        } else if (requirement === "502_3_1") {
            return (<Req502_3_1 />);
        } else if (requirement === "502_3_2") {
            return (<Req502_3_2 />);
        } else if (requirement === "502_3_3") {
            return (<Req502_3_3 />);
        } else if (requirement === "502_3_4") {
            return (<Req502_3_4 />);
        } else if (requirement === "502_3_5") {
            return (<Req502_3_5 />);
        } else if (requirement === "502_3_6") {
            return (<Req502_3_6 />);
        } else if (requirement === "502_3_7") {
            return (<Req502_3_7 />);
        } else if (requirement === "502_3_8") {
            return (<Req502_3_8 />);
        } else if (requirement === "502_3_9") {
            return (<Req502_3_9 />);
        } else if (requirement === "502_3_10") {
            return (<Req502_3_10 />);
        } else if (requirement === "502_3_11") {
            return (<Req502_3_11 />);
        } else if (requirement === "502_3_12") {
            return (<Req502_3_12 />);
        } else if (requirement === "502_3_13") {
            return (<Req502_3_13 />);
        } else if (requirement === "502_3_14") {
            return (<Req502_3_14 />);
        } else if (requirement === "502_4") {
            return (<Req502_4 />);
        } else if (requirement === "503_2") {
            return (<Req503_2 />);
        } else if (requirement === "503_3") {
            return (<Req503_3 />);
        } else if (requirement === "503_4_1") {
            return (<Req503_4_1 />);
        } else if (requirement === "503_4_2") {
            return (<Req503_4_2 />);
        } else if (requirement === "504_2") {
            return (<Req504_2 />);
        } else if (requirement === "504_2_1") {
            return (<Req504_2_1 />);
        } else if (requirement === "504_2_2") {
            return (<Req504_2_2 />);
        } else if (requirement === "504_3") {
            return (<Req504_3 />);
        } else if (requirement === "504_4") {
            return (<Req504_4 />);
        } else if (requirement === "602_2") {
            return (<Req602_2 />);
        } else if (requirement === "602_3") {
            return (<Req602_3 />);
        } else if (requirement === "5_2") {
            return (<Req5_2 />);
        } else if (requirement === "5_7") {
            return (<Req5_7 />);
        } else if (requirement === "5_8") {
            return (<Req5_8 />);
        } else if (requirement === "5_9") {
            return (<Req5_9 />);
        } else if (requirement === "7_1_1") {
            return (<Req7_1_1 />);
        } else if (requirement === "7_1_2") {
            return (<Req7_1_2 />);
        } else if (requirement === "7_1_3") {
            return (<Req7_1_3 />);
        } else if (requirement === "7_1_4") {
            return (<Req7_1_4 />);
        } else if (requirement === "7_1_5") {
            return (<Req7_1_5 />);
        } else if (requirement === "7_2_1") {
            return (<Req7_2_1 />);
        } else if (requirement === "7_2_2") {
            return (<Req7_2_2 />);
        } else if (requirement === "7_2_3") {
            return (<Req7_2_3 />);
        } else if (requirement === "7_3") {
            return (<Req7_3 />);
        } else if (requirement === "10_5") {
            return (<Req10_5 />);
        } else if (requirement === "10_6") {
            return (<Req10_6 />);
        } else if (requirement === "11_5_2_3") {
            return (<Req11_5_2_3 />);
        } else if (requirement === "11_8_4 Repair") {
            // console.log("11.8.4: string = ",requirement);
            return (<Req11_8_4 />);
        }
    }

    getDesignContent(reqMDX) {
        // console.log("getDesignContent: reqMDX = ",reqMDX);
        let requirement = reqMDX;
        if (requirement === "1_1_1") {
            return (<Design1_1_1 />);
        } else if (requirement === "1_2_1") {
            return (<Design1_2_1 />);
        } else if (requirement === "1_2_2") {
            return (<Design1_2_2 />);
        } else if (requirement === "1_2_3") {
            return (<Design1_2_3 />);
        } else if (requirement === "1_2_4") {
            return (<Design1_2_4 />);
        } else if (requirement === "1_2_5") {
            return (<Design1_2_5 />);
        } else if (requirement === "1_3_1") {
            return (<Design1_3_1 />);
        } else if (requirement === "1_3_2") {
            return (<Design1_3_2 />);
        } else if (requirement === "1_3_3") {
            return (<Design1_3_3 />);
        } else if (requirement === "1_3_4") {
            return (<Design1_3_4 />);
        } else if (requirement === "1_3_5") {
            return (<Design1_3_5 />);
        } else if (requirement === "1_4_1") {
            return (<Design1_4_1 />);
        } else if (requirement === "1_4_2") {
            return (<Design1_4_2 />);
        } else if (requirement === "1_4_3") {
            return (<Design1_4_3 />);
        } else if (requirement === "1_4_4") {
            return (<Design1_4_4 />);
        } else if (requirement === "1_4_5") {
            return (<Design1_4_5 />);
        } else if (requirement === "1_4_10") {
            return (<Design1_4_10 />);
        } else if (requirement === "1_4_11") {
            return (<Design1_4_11 />);
        } else if (requirement === "1_4_12") {
            return (<Design1_4_12 />);
        } else if (requirement === "1_4_13") {
            return (<Design1_4_13 />);
        } else if (requirement === "2_1_1") {
            return (<Design2_1_1 />);
        } else if (requirement === "2_1_2") {
            return (<Design2_1_2 />);
        } else if (requirement === "2_1_4") {
            return (<Design2_1_4 />);
        } else if (requirement === "2_2_1") {
            return (<Design2_2_1 />);
        } else if (requirement === "2_2_2") {
            return (<Design2_2_2 />);
        } else if (requirement === "2_3_1") {
            return (<Design2_3_1 />);
        } else if (requirement === "2_4_1") {
            return (<Design2_4_1 />);
        } else if (requirement === "2_4_2") {
            return (<Design2_4_2 />);
        } else if (requirement === "2_4_3") {
            return (<Design2_4_3 />);
        } else if (requirement === "2_4_4") {
            return (<Design2_4_4 />);
        } else if (requirement === "2_4_5") {
            return (<Design2_4_5 />);
        } else if (requirement === "2_4_6") {
            return (<Design2_4_6 />);
        } else if (requirement === "2_4_7") {
            return (<Design2_4_7 />);
        } else if (requirement === "2_4_11") {
            return (<Design2_4_11 />);
        } else if (requirement === "2_5_1") {
            return (<Design2_5_1 />);
        } else if (requirement === "2_5_2") {
            return (<Design2_5_2 />);
        } else if (requirement === "2_5_3") {
            return (<Design2_5_3 />);
        } else if (requirement === "2_5_4") {
            return (<Design2_5_4 />);
        } else if (requirement === "2_5_7") {
            return (<Design2_5_7 />);
        } else if (requirement === "2_5_8") {
            return (<Design2_5_8 />);
        } else if (requirement === "3_1_1") {
            return (<Design3_1_1 />);
        } else if (requirement === "3_1_2") {
            return (<Design3_1_2 />);
        } else if (requirement === "3_2_1") {
            return (<Design3_2_1 />);
        } else if (requirement === "3_2_2") {
            return (<Design3_2_2 />);
        } else if (requirement === "3_2_3") {
            return (<Design3_2_3 />);
        } else if (requirement === "3_2_4") {
            return (<Design3_2_4 />);
        } else if (requirement === "3_2_6") {
            return (<Design3_2_6 />);
        } else if (requirement === "3_3_1") {
            return (<Design3_3_1 />);
        } else if (requirement === "3_3_2") {
            return (<Design3_3_2 />);
        } else if (requirement === "3_3_3") {
            return (<Design3_3_3 />);
        } else if (requirement === "3_3_4") {
            return (<Design3_3_4 />);
        } else if (requirement === "3_3_7") {
            return (<Design3_3_7 />);
        } else if (requirement === "3_3_8") {
            return (<Design3_3_8 />);
        } else if (requirement === "4_1_1") {
            return (<Design4_1_1 />);
        } else if (requirement === "4_1_2") {
            return (<Design4_1_2 />);
        } else if (requirement === "4_1_3") {
            return (<Design4_1_3 />);
        }
    }

    getDevelopContent(reqMDX) {
        // console.log("getDevelopContent: reqMDX = ",reqMDX);
        let requirement = reqMDX;
        if (requirement === "1_1_1") {
            return (<Develop1_1_1 />);
        } else if (requirement === "1_2_1") {
            return (<Develop1_2_1 />);
        } else if (requirement === "1_2_2") {
            return (<Develop1_2_2 />);
        } else if (requirement === "1_2_3") {
            return (<Develop1_2_3 />);
        } else if (requirement === "1_2_4") {
            return (<Develop1_2_4 />);
        } else if (requirement === "1_2_5") {
            return (<Develop1_2_5 />);
        } else if (requirement === "1_3_1") {
            return (<Develop1_3_1 />);
        } else if (requirement === "1_3_2") {
            return (<Develop1_3_2 />);
        } else if (requirement === "1_3_3") {
            return (<Develop1_3_3 />);
        } else if (requirement === "1_3_4") {
            return (<Develop1_3_4 />);
        } else if (requirement === "1_3_5") {
            return (<Develop1_3_5 />);
        } else if (requirement === "1_4_1") {
            return (<Develop1_4_1 />);
        } else if (requirement === "1_4_2") {
            return (<Develop1_4_2 />);
        } else if (requirement === "1_4_3") {
            return (<Develop1_4_3 />);
        } else if (requirement === "1_4_4") {
            return (<Develop1_4_4 />);
        } else if (requirement === "1_4_5") {
            return (<Develop1_4_5 />);
        } else if (requirement === "1_4_10") {
            return (<Develop1_4_10 />);
        } else if (requirement === "1_4_11") {
            return (<Develop1_4_11 />);
        } else if (requirement === "1_4_12") {
            return (<Develop1_4_12 />);
        } else if (requirement === "1_4_13") {
            return (<Develop1_4_13 />);
        } else if (requirement === "2_1_1") {
            return (<Develop2_1_1 />);
        } else if (requirement === "2_1_2") {
            return (<Develop2_1_2 />);
        } else if (requirement === "2_1_4") {
            return (<Develop2_1_4 />);
        } else if (requirement === "2_2_1") {
            return (<Develop2_2_1 />);
        } else if (requirement === "2_2_2") {
            return (<Develop2_2_2 />);
        } else if (requirement === "2_3_1") {
            return (<Develop2_3_1 />);
        } else if (requirement === "2_4_1") {
            return (<Develop2_4_1 />);
        } else if (requirement === "2_4_2") {
            return (<Develop2_4_2 />);
        } else if (requirement === "2_4_3") {
            return (<Develop2_4_3 />);
        } else if (requirement === "2_4_4") {
            return (<Develop2_4_4 />);
        } else if (requirement === "2_4_5") {
            return (<Develop2_4_5 />);
        } else if (requirement === "2_4_6") {
            return (<Develop2_4_6 />);
        } else if (requirement === "2_4_7") {
            return (<Develop2_4_7 />);
        } else if (requirement === "2_4_11") {
            return (<Develop2_4_11 />);
        } else if (requirement === "2_5_1") {
            return (<Develop2_5_1 />);
        } else if (requirement === "2_5_2") {
            return (<Develop2_5_2 />);
        } else if (requirement === "2_5_3") {
            return (<Develop2_5_3 />);
        } else if (requirement === "2_5_4") {
            return (<Develop2_5_4 />);
        } else if (requirement === "2_5_7") {
            return (<Develop2_5_7 />);
        } else if (requirement === "2_5_8") {
            return (<Develop2_5_8 />);
        } else if (requirement === "3_1_1") {
            return (<Develop3_1_1 />);
        } else if (requirement === "3_1_2") {
            return (<Develop3_1_2 />);
        } else if (requirement === "3_2_1") {
            return (<Develop3_2_1 />);
        } else if (requirement === "3_2_2") {
            return (<Develop3_2_2 />);
        } else if (requirement === "3_2_3") {
            return (<Develop3_2_3 />);
        } else if (requirement === "3_2_4") {
            return (<Develop3_2_4 />);
        } else if (requirement === "3_2_6") {
            return (<Develop3_2_6 />);
        } else if (requirement === "3_3_1") {
            return (<Develop3_3_1 />);
        } else if (requirement === "3_3_2") {
            return (<Develop3_3_2 />);
        } else if (requirement === "3_3_3") {
            return (<Develop3_3_3 />);
        } else if (requirement === "3_3_4") {
            return (<Develop3_3_4 />);
        } else if (requirement === "3_3_7") {
            return (<Develop3_3_7 />);
        } else if (requirement === "3_3_8") {
            return (<Develop3_3_8 />);
        } else if (requirement === "4_1_1") {
            return (<Develop4_1_1 />);
        } else if (requirement === "4_1_2") {
            return (<Develop4_1_2 />);
        } else if (requirement === "4_1_3") {
            return (<Develop4_1_3 />);
        }
    }

    getTestContent(reqMDX) {
        // console.log("getTestContent: reqMDX = ",reqMDX);
        let requirement = reqMDX;
        if (requirement === "1_1_1") {
            return (<Test1_1_1 />);
        } else if (requirement === "1_2_1") {
            return (<Test1_2_1 />);
        } else if (requirement === "1_2_2") {
            return (<Test1_2_2 />);
        } else if (requirement === "1_2_3") {
            return (<Test1_2_3 />);
        } else if (requirement === "1_2_4") {
            return (<Test1_2_4 />);
        } else if (requirement === "1_2_5") {
            return (<Test1_2_5 />);
        } else if (requirement === "1_3_1") {
            return (<Test1_3_1 />);
        } else if (requirement === "1_3_2") {
            return (<Test1_3_2 />);
        } else if (requirement === "1_3_3") {
            return (<Test1_3_3 />);
        } else if (requirement === "1_3_4") {
            return (<Test1_3_4 />);
        } else if (requirement === "1_3_5") {
            return (<Test1_3_5 />);
        } else if (requirement === "1_4_1") {
            return (<Test1_4_1 />);
        } else if (requirement === "1_4_2") {
            return (<Test1_4_2 />);
        } else if (requirement === "1_4_3") {
            return (<Test1_4_3 />);
        } else if (requirement === "1_4_4") {
            return (<Test1_4_4 />);
        } else if (requirement === "1_4_5") {
            return (<Test1_4_5 />);
        } else if (requirement === "1_4_10") {
            return (<Test1_4_10 />);
        } else if (requirement === "1_4_11") {
            return (<Test1_4_11 />);
        } else if (requirement === "1_4_12") {
            return (<Test1_4_12 />);
        } else if (requirement === "1_4_13") {
            return (<Test1_4_13 />);
        } else if (requirement === "2_1_1") {
            return (<Test2_1_1 />);
        } else if (requirement === "2_1_2") {
            return (<Test2_1_2 />);
        } else if (requirement === "2_1_4") {
            return (<Test2_1_4 />);
        } else if (requirement === "2_2_1") {
            return (<Test2_2_1 />);
        } else if (requirement === "2_2_2") {
            return (<Test2_2_2 />);
        } else if (requirement === "2_3_1") {
            return (<Test2_3_1 />);
        } else if (requirement === "2_4_1") {
            return (<Test2_4_1 />);
        } else if (requirement === "2_4_2") {
            return (<Test2_4_2 />);
        } else if (requirement === "2_4_3") {
            return (<Test2_4_3 />);
        } else if (requirement === "2_4_4") {
            return (<Test2_4_4 />);
        } else if (requirement === "2_4_5") {
            return (<Test2_4_5 />);
        } else if (requirement === "2_4_6") {
            return (<Test2_4_6 />);
        } else if (requirement === "2_4_7") {
            return (<Test2_4_7 />);
        } else if (requirement === "2_4_11") {
            return (<Test2_4_11 />);
        } else if (requirement === "2_5_1") {
            return (<Test2_5_1 />);
        } else if (requirement === "2_5_2") {
            return (<Test2_5_2 />);
        } else if (requirement === "2_5_3") {
            return (<Test2_5_3 />);
        } else if (requirement === "2_5_4") {
            return (<Test2_5_4 />);
        } else if (requirement === "2_5_7") {
            return (<Test2_5_7 />);
        } else if (requirement === "2_5_8") {
            return (<Test2_5_8 />);
        } else if (requirement === "3_1_1") {
            return (<Test3_1_1 />);
        } else if (requirement === "3_1_2") {
            return (<Test3_1_2 />);
        } else if (requirement === "3_2_1") {
            return (<Test3_2_1 />);
        } else if (requirement === "3_2_2") {
            return (<Test3_2_2 />);
        } else if (requirement === "3_2_3") {
            return (<Test3_2_3 />);
        } else if (requirement === "3_2_4") {
            return (<Test3_2_4 />);
        } else if (requirement === "3_2_6") {
            return (<Test3_2_6 />);
        } else if (requirement === "3_3_1") {
            return (<Test3_3_1 />);
        } else if (requirement === "3_3_2") {
            return (<Test3_3_2 />);
        } else if (requirement === "3_3_3") {
            return (<Test3_3_3 />);
        } else if (requirement === "3_3_4") {
            return (<Test3_3_4 />);
        } else if (requirement === "3_3_7") {
            return (<Test3_3_7 />);
        } else if (requirement === "3_3_8") {
            return (<Test3_3_8 />);
        } else if (requirement === "4_1_1") {
            return (<Test4_1_1 />);
        } else if (requirement === "4_1_2") {
            return (<Test4_1_2 />);
        } else if (requirement === "4_1_3") {
            return (<Test4_1_3 />);
        }
    }

    render() {
        // console.log("reqSwitcher render");
        let tempNumber = this.props.reqNumber;
        let reqNumber = parseInt(tempNumber.split("_")[0]);
        // console.log("reqNumber = ",reqNumber);

        return (
            <React.Fragment>
                {/* {console.log("this.props.reqNumber = ", this.props.reqNumber)} */}
                {this.getRationaleContent(this.props.reqNumber)}
                {/* {console.log(parseInt(this.props.reqNumber.replace(/_/g, ""))," > ",parseInt("5_0_0".replace(/_/g, "")),": ",parseInt(this.props.reqNumber.replace(/_/g, "")) > parseInt("5_0_0".replace(/_/g, "")))} */}

                {reqNumber < 5 ?
                    <div >
                        <Tabs>
                            <Tab label="Design" style={{ marginTop: 0 }}>
                                {this.getDesignContent(this.props.reqNumber)}
                            </Tab>
                            <Tab label="Develop">
                                {this.getDevelopContent(this.props.reqNumber)}
                            </Tab>
                            <Tab label="Test">
                                {this.getTestContent(this.props.reqNumber)}
                            </Tab>
                        </Tabs>
                    </div>

                    : ""
                }

            </React.Fragment>
        )
    }
}

export default reqSwitcher