import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Button from 'carbon-components-react/lib/components/Button';
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import { withPrefix } from "gatsby";
import EventBodyCard from "../../components/events/eventBodyCard.js";
import EventTitleCard from "../../components/events/eventTitleCard.js";
import EventsCSUN from '../../images/csunEvents.png';
import EventsAccessU from '../../images/AccessU.png';
import EventsAccessIsrael from '../../images/access\ israel.png';
import EventsFAccT from '../../images/FAccT.png';
import EventsThink from '../../images/EventsThink.png';
import EventsMEnabling from '../../images/EventsMEnabling.png';
import EventsAssets from '../../images/EventsAssets.png';
import launch32 from "@carbon/icons-react/lib/launch/32";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import Launch16 from '@carbon/icons-react/lib/launch/16';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  _frontmatter
}) => <React.Fragment>{children}</React.Fragment>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="eventsPage eventsBody" style={{
      marginBottom: "48px"
    }}>
      <EventTitleCard mdxType="EventTitleCard">
      </EventTitleCard>
      <hr width="95%"></hr>
      <EventBodyCard href="https://knowbility.org/programs/accessu-2024" image={EventsAccessU} eventName="AccessU 2024" eventDate="13 – 16 May 2024" eventLocation="Austin, TX" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
John Slatin AccessU 2024 is a hybrid conference that allows attendees to connect with the best of the best, including IBM Accessibility, in digital accessibility training from wherever they are. 
AccessU is designed to bring attendees the accessibility skills they need for an increasingly digital world, no matter their schedule or location.
        </div>
  <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://knowbility.org/programs/accessu-2024" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          AccessU 2024 site
      </div>
  </Button>
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong> Tom Brunet and Jess Lin</strong><br />
        <a href="https://knowbility.org/programs/accessu-2024/accessibility-101-core-concepts" target="_blank" rel="noopener noreferrer" id="IBMatAccessU2024Concepts">Accessibility 101+ Core Concepts</a>
        <br />
        <a href="https://ibm.box.com/v/IBMatAccessU2024-Concepts" target="_blank" rel="noopener noreferrer" aria-describedby="IBMatAccessU2024Concepts">View slide deck <Launch16 style={{
            marginBottom: "-2px"
          }} mdxType="Launch16" /></a>
        <br /><br />
        <strong> Josiah Hoskins and Phill Jenkins</strong><br />
        <a href="https://knowbility.org/programs/accessu-2024/wcag-2-2-and-the-ibm-accessibility-equal-access-toolkit" target="_blank" rel="noopener noreferrer" id="IBMatAccessU2024Toolkit">WCAG 2.2 and the IBM Accessibility Equal Access Toolkit</a>
        <br />
        <a href="https://ibm.box.com/v/IBMatAccessU2024-WCAG22" target="_blank" rel="noopener noreferrer" aria-describedby="IBMatAccessU2024Toolkit">View slide deck <Launch16 style={{
            marginBottom: "-2px"
          }} mdxType="Launch16" /></a>
        <br />
      </EventBodyCard>
 
      <hr width="95%"></hr>
      <EventBodyCard href="https://knowbility.org/programs/john-slatin-accessu-2023" image={EventsAccessU} eventName="AccessU 2023" eventDate="09 – 12 May 2023" eventLocation="Austin, TX" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
John Slatin AccessU 2023 is a hybrid conference that allows attendees to connect with the best of the best, including IBM Accessibility, in digital accessibility training from wherever they are. 
AccessU is designed to bring attendees the accessibility skills they need for an increasingly digital world, no matter their schedule or location.
        </div>
  <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://knowbility.org/programs/john-slatin-accessu-2023" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          AccessU 2023 site
      </div>
  </Button>
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong> Tom Brunet and Josiah Hoskins</strong><br />
        <a href="https://knowbility.org/programs/john-slatin-accessu-2023/automated-open-source-keyboard-checking-and-related-tools-by-ibm" target="_blank" rel="noopener noreferrer" id="IBMatAccessU2023Checker">Automated open-source keyboard checking and related tools by IBM</a>
        <br />
        <a href="https://ibm.box.com/v/IBMatAccessU2023" target="_blank" rel="noopener noreferrer" aria-describedby="IBMatAccessU2023Checker">View slide deck <Launch16 style={{
            marginBottom: "-2px"
          }} mdxType="Launch16" /></a>
        <br />
      </EventBodyCard>
 
      <hr width="95%"></hr>
      <EventBodyCard href="https://www.ibm.com/ibm/history/ibm100/us/en/icons/" image={EventsCSUN} eventName="CSUN Assistive Technology Conference 2023" eventDate="13 – 17 March 2023" eventLocation="Anaheim, CA" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
  For over 34 years, the Center on Disabilities, through the CSUN Assistive Technology Conference, has provided an inclusive setting for researchers, practitioners, exhibitors, end users, speakers and other participants to share knowledge and best practices in the field of assistive technology. In 2019, the conference drew more than 5,000 people to Anaheim.
        </div>
        <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://www.csun.edu/cod/conference/sessions/2023/" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          CSUN 2023 Conference site
      </div>
  </Button>
  
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong> Josiah Hoskins, Ali Unwala, Phill Jenkins</strong><br />
        <a href="https://www.csun.edu/cod/conference/sessions/2023/index.php/public/presentations/view/1324" target="_blank" rel="noopener noreferrer" id="AutomatedKeyboardChecker">Automated Open-Sourced Keyboard Checker</a>
        <br />
        <a href="https://ibm.box.com/v/AutomatedKeyboardChecker" target="_blank" rel="noopener noreferrer" aria-describedby="AutomatedKeyboardChecker">View slide deck <Launch16 style={{
            marginBottom: "-2px"
          }} mdxType="Launch16" /></a>
        <br /><br />
        <strong> Phill Jenkins, Alexandra Grossi</strong><br />
        <a href="https://www.csun.edu/cod/conference/sessions/2023/index.php/public/presentations/view/1466" target="_blank" rel="noopener noreferrer" id="IBMatCSUN23Stumpthedesigners">A Fun Stump-the-Designers</a>
        <br />
        <a href="https://ibm.box.com/v/IBMatCSUN23Stumpthedesigners" target="_blank" rel="noopener noreferrer" aria-describedby="IBMatCSUN23Stumpthedesigners">View slide deck <Launch16 style={{
            marginBottom: "-2px"
          }} mdxType="Launch16" /></a>
        <br /><br />
        <strong> Michael Gower, Jessica Lin</strong><br />
        <a href="https://www.csun.edu/cod/conference/sessions/2023/index.php/public/presentations/view/1409" target="_blank" rel="noopener noreferrer" id="DesignerGamification">Learning Accessible Design through Gamification</a>
        <br />
        <a href="https://ibm.biz/DesignerGamification" target="_blank" rel="noopener noreferrer" aria-describedby="DesignerGamification">View slide deck <Launch16 style={{
            marginBottom: "-2px"
          }} mdxType="Launch16" /></a>
        <br /><br />
        <strong> Josiah Hoskins, Ali Unwala, Phill Jenkins</strong><br />
        <a href="https://www.csun.edu/cod/conference/sessions/2023/index.php/public/presentations/view/1433" target="_blank" rel="noopener noreferrer" id="IBMatCSUN23AutomatedChecker">Automated Accessibility Checking Open-Sourced</a>
        <br />
        <a href="https://ibm.box.com/v/IBMatCSUN23AutomatedChecker" target="_blank" rel="noopener noreferrer" aria-describedby="IBMatCSUN23AutomatedChecker">View slide deck <Launch16 style={{
            marginBottom: "-2px"
          }} mdxType="Launch16" /></a>
        <br />
      </EventBodyCard>
      <hr width="95%"></hr>
      <EventBodyCard href="https://knowbility.org/programs/accessu-2022" image={EventsAccessU} eventName="AccessU 2022" eventDate="09 – 12 May 2022" eventLocation="Austin, TX" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
John Slatin AccessU 2022 is a hybrid conference that allows you to connect with the best of the best in digital accessibility trainings from wherever you are. AccessU is designed to bring you the accessibility skills that you need for an increasingly digital world, no matter your schedule or location.
        </div>
  <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://knowbility.org/programs/accessu-2022" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          AccessU 2022 site
      </div>
  </Button>
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong> Tom Brunet, Jess Lin, Josiah Hoskins</strong><br />
        <a href="https://knowbility.org/programs/accessu-2022/live-accessibility-tools-and-guidance-for-designers-and-developers" target="_blank" rel="noopener noreferrer">Accessibility tools and guidance for designers and developers</a>
        <br />
      </EventBodyCard>
      <hr width="95%"></hr>
      <EventBodyCard href="https://www.ibm.com/ibm/history/ibm100/us/en/icons/" image={EventsCSUN} eventName="CSUN Assistive Technology Conference 2022" eventDate="14 – 18 March 2022" eventLocation="Anaheim, CA" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
For 37 years, the Center on Disabilities, through the CSUN Assistive Technology Conference, has provided an inclusive setting for researchers, practitioners, exhibitors, end users, speakers and other participants to share knowledge and best practices in the field of assistive technology. In 2018, the conference drew more than 5,000 people to San Diego.
        </div>
  <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://www.csun.edu/cod/conference/sessions/2022/" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          CSUN 2022 Conference site
      </div>
  </Button>
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong>Phill Jenkins</strong><br />
        <a href="https://www.csun.edu/cod/conference/sessions/2022/index.php/public/presentations/view/2388">Equitable AI in the Workplace</a>
        <br /><br />
        <strong>Will Scott, Alexandra Grossi</strong><br />
        <a href="https://www.csun.edu/cod/conference/sessions/2022/index.php/public/presentations/view/2267">IBM's Playbook and Tools for Accessible Project Development</a>
        <br /><br />
        <strong>Michael Gower, Jessica Lin, Alexandra Grossi</strong><br />
        <a href="https://www.csun.edu/cod/conference/sessions/2022/index.php/public/presentations/view/2400">Accessibility for Designers, Where They Work</a>
        <br /><br />
        <strong>Josiah Hoskins, Ali Unwala, Phill Jenkins</strong><br />
        <a href="https://www.csun.edu/cod/conference/sessions/2022/index.php/public/presentations/view/2361">Accessibility Checker Open-Sourced and Redesigned</a>
        <br /><br />
        <strong>Ali Unwala, Josiah Hoskins, Phill Jenkins</strong><br />
        <a href="https://www.csun.edu/cod/conference/sessions/2022/index.php/public/presentations/view/2348">Automating Keyboard Testing Using Visualizations</a>
        <br />
      </EventBodyCard>
      <hr width="95%"></hr>
      <EventBodyCard href="https://assets21.sigaccess.org/" image={EventsAssets} eventName="ACM Assets" eventDate="18 – 22 October 2021" eventLocation="Virtual" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
The premier forum for presenting research on the design, evaluation, use, and education related to
computing for people with disabilities and older adults.
        </div>
  <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://assets21.sigaccess.org/" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          ACM Assets 2021 site
      </div>
  </Button>
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong>Dr Shari Trewin</strong><br />
        <a href="https://www.youtube.com/watch?v=evE1L4XDV5Y" target="_blank" rel="noopener noreferrer">The History of SIGACCESS</a>
        <br />
      </EventBodyCard>
      <hr width="95%"></hr>
      <EventBodyCard href="https://m-enabling.com/" image={EventsMEnabling} eventName="M-Enabling Summit" eventDate="04 – 06 October 2021" eventLocation="Virtual" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
The M-Enabling Summit Conference and Showcase is dedicated to promoting digital environments and assistive 
technologies for senior citizens and users of all abilities</div>
  <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://m-enabling.com/" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          M-Enabling Summit Conference site
      </div>
  </Button>
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong>Phill Jenkins</strong><br />
Panel Discussion | Successes and Failures: What Worked and What Did Not Work during the Pandemic?
        <br />
      </EventBodyCard>
      <hr width="95%"></hr>
      <EventBodyCard href="https://knowbility.org/programs/accessu-2021" image={EventsAccessU} eventName="AccessU 2021" eventDate="13, 18 – 20 May 2021" eventLocation="Virtual" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
AccessU is the only three-day, hands-on conference that provides tools that can immediately be put to use by designers, developers, project managers, administrators, and anyone who is responsible for online content and development. John Slatin AccessU teaches those skills in an interactive and communal environment where you will learn everything you need to know about integrating accessibility into your company.
        </div>
  <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://knowbility.org/programs/accessu-2021" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          AccessU 2021 site
      </div>
  </Button>
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong> Mary Jo Mueller, Hope Turner</strong><br />
        <a href="https://knowbility.org/programs/accessu-2021/inclusive-design-workshop" target="_blank" rel="noopener noreferrer" id="InclusiveDesignWorkshop">Inclusive Design Workshop</a>
        <br />
        <a href="https://ibm.ent.box.com/v/Inclusive-design-AccessU" target="_blank" rel="noopener noreferrer" aria-describedby="InclusiveDesignWorkshop">View slide deck <Launch16 style={{
            marginBottom: "-2px"
          }} mdxType="Launch16" /></a>
        <br /><br />
        <strong> Mary Jo Mueller</strong><br />
        <a href="https://knowbility.org/programs/accessu-2021/use-of-the-ibm-equal-access-accessibility-checker-to-check-wcag-compliance" target="_blank" rel="noopener noreferrer" id="AccessibilityCheckerPresentation">Accessibility Checker presentation</a>
        <br />
        <a href="https://ibm.ent.box.com/v/AccessU2021-A11y-checker" target="_blank" rel="noopener noreferrer" aria-describedby="AccessibilityCheckerPresentation">View slide deck <Launch16 style={{
            marginBottom: "-2px"
          }} mdxType="Launch16" /></a>
        <br /><br />
        <strong> Alexandra Grossi, Will Scott</strong><br />
        <a href="https://knowbility.org/programs/accessu-2021/accessible-project-development-planning-to-delivery-here-s-the-playbook-and-tools-to-get-it-done" target="_blank" rel="noopener noreferrer">Accessible Project Development, Planning to Delivery: <br />Here’s the Playbook and Tools to Get It Done</a>
        <br />
      </EventBodyCard>
      <hr width="95%"></hr>
      <EventBodyCard href="https://www.ibm.com/ibm/history/ibm100/us/en/icons/" image={EventsCSUN} eventName="CSUN Assistive Technology Conference 2021" eventDate="06 – 14 March 2021" eventLocation="Virtual" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
For over 34 years, the Center on Disabilities, through the CSUN Assistive Technology Conference, has provided an inclusive setting for researchers, practitioners, exhibitors, end users, speakers and other participants to share knowledge and best practices in the field of assistive technology. In 2018, the conference drew more than 5,000 people to San Diego.
        </div>
  <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://www.csun.edu/cod/conference/sessions/2021/" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          CSUN 2021 Conference site
      </div>
  </Button>
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong> Mary Jo Mueller</strong><br />
        <a href="https://www.csun.edu/cod/conference/sessions/2021/index.php/public/presentations/view/1179" target="_blank" rel="noopener noreferrer" id="VPATtraining">Accessibility Conformance Reports Using the VPAT</a>
        <br />
        <a href="https://www.youtube.com/playlist?list=PLDqZ4Q20mwxNt6cL71isdMDOmPB7WeucA" target="_blank" rel="noopener noreferrer" aria-describedby="VPATtraining">Watch videos <Launch16 style={{
            marginBottom: "-2px"
          }} mdxType="Launch16" /></a>
        <br />
      </EventBodyCard>
      <hr width="95%"></hr>
      <EventBodyCard href="https://facctconference.org/2021/" image={EventsFAccT} eventName="FAccT 2021" eventDate="04 – 10 March 2021" eventLocation="Virtual" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
The ACM FAccT conference brings together researchers and practitioners interested in fairness, accountability, and transparency in socio-technical systems.</div>
  <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://facctconference.org/2021/" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          FAccT 2021 site
      </div>
  </Button>
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong>Dr Shari Trewin</strong><br />
An Equality Opportunity: Combating Disability Discrimination in AI
        <br />
      </EventBodyCard>
      <hr width="95%"></hr>
      <EventBodyCard href="https://www.aisrael.org/eng" image={EventsAccessIsrael} eventName="Access Israel" eventDate="26 January 2021" eventLocation="Virtual" mdxType="EventBodyCard">
        <div style={{
          marginBottom: "48px"
        }}>
This webinar focused on "Innovation in Web & App accessibility". The webinar was a huge success with over 850 people registering from 79 countries. During the three and half long event the participants heard from leading experts in the field and learned about the newest and most innovative solutions for accessibility in websites and applications. For this webinar Access Israel partnered with IAAP, who are leaders in the field of web accessibility.
        </div>
  <Button disabled={false} iconDescription="Button icon" kind="tertiary" size="default" renderIcon={launch32} type="button" className="EventButton" href="https://www.aisrael.org/eng" target="_blank" rel="noopener noreferrer" mdxType="Button">
      <div style={{
            fontSize: "14px",
            "padding-top": "12px",
            "padding-bottom": "12px"
          }}>
          Access Israel site
      </div>
  </Button>
        <div role="heading" aria-level="4" style={{
          paddingBottom: "1.5rem",
          marginTop: "20px",
          fontSize: "20px"
        }}>
Participating IBM employees
        </div>
        <strong>Thomas Brunet</strong><br />
        <a href="https://www.youtube.com/watch?v=e9DwvbcLz4s&t=2933s" target="_blank" rel="noopener noreferrer">The IBM Equal Access Toolkit: Guidance and Tools from Plan to Launch</a><br /><br />
        <br />
      </EventBodyCard>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      