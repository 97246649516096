import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`All non-text content that is presented to the user has a text alternative that serves the equivalent purpose.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`Any information conveyed by means other than text needs to be provided in text as well.
The text should provide equivalent information.
Examples of non-text content are images, graphs, media, animations, CAPTCHA, and audio alerts.`}</p>
    <p>{`Short and long text alternatives can be used as needed to convey the equivalent of the non-text content.
The most common short text alternative is the use of ALT text attributes for images.
Examples of long alternatives include descriptions for graphs, charts, or complex images.`}</p>
    <p><strong parentName="p">{`Exceptions:`}</strong>{` There are situations where providing a text equivalent is either not possible or not desirable.
Exceptions include controls, inputs, tests, specific sensory experiences, and CAPTCHA.
Content that is decorative, used to provide formatting, or visually hidden does not require a text alternative.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/non-text-content.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.1.1 Non-text Content`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      