import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
import "../../../topics.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where similar content appears across multiple pages, provide consistency of experience so users can more easily locate and understand content. `}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Provide help mechanisms in the same location on all pages where they appear (`}<strong parentName="p">{`WCAG 2.2 only`}</strong>{`)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide navigation mechanisms in the same order on all pages `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Across related content, use the same labels and icons to represent the same functions `}</p>
      </li>
    </ul>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g61' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g197' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G220.html">
  G220: Provide a contact-us link in a consistent location
        </a>, WCAG 2.2 technique</li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_2_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_2_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><a href="https://www.ibm.com/able/requirements/requirements/?version=v7_3#3_2_6">
  3.2.6 Consistent Help
        </a>, IBM accessibility requirements (7.3 only)</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      