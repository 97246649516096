import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/L2_ux_3_1_eg.png";
import img2 from "./../../../../../visuals/design/L2_ux_3_2_eg.png";
import img3 from "./../../../../../visuals/design/L2_ux_3_3_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Any pointer action should be achievable by simple pointer actions, such as single clicks (or taps), on a target of sufficient size. It is fine to provide user actions through dragging, multipoint touch, and path-based gestures. However, designers should include a simpler way of achieving the same function, because these more complex pointer operations all pose challenges for users with reduced dexterity, who have tremors, lack fine motor skills, or are missing a thumb or finger. Many of the same users will be further challenged by small targets, especially if they are near other targets.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Provide an alternative to complete all tasks with simple pointer actions`}</li>
      <li parentName="ul">{`Don’t rely on multipoint gestures like pinch to zoom for custom widgets`}</li>
      <li parentName="ul">{`Don’t require dragging, swiping, or path-based gestures for custom widgets`}</li>
      <li parentName="ul">{`Ensure all targets are a minimum of 24x24 CSS pixels, or have sufficient space around them. (`}<strong parentName="li">{`WCAG 2.2 only`}</strong>{`)`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img1} alt="slider ranging from 0 to 50 with 18 as the selected value"></img>
        <Caption fullWidth mdxType="Caption">The user can drag the slider or tap and click on the slide bar to change the value.
        </Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img2} alt="an interactive map with a plus and a minus button"></img>
        <Caption fullWidth mdxType="Caption">Provide simple pointer actions in addition to multi-point gestures. The map's buttons support taps and clicks to zoom.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img3} alt="carousel of colored squares with left and right arrow buttons for scrolling"></img>
        <Caption fullWidth mdxType="Caption">Provide simple pointer actions in addition to swiping. Here, the carousel's arrow keys support taps and clicks.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G212" target="_blank" rel="noopener noreferrer">G212: Using native controls to ensure functionality is triggered on the up-event</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G215" target="_blank" rel="noopener noreferrer">G215: Providing controls to achieve the same result as path based multipoint gestures</a>, WCAG 2.1 technique
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G216" target="_blank" rel="noopener noreferrer">G216: Providing single point activation for a control slider</a>, WCAG 2.1 technique
      * <a href="https://diginclusion.com/industry-updates/wcag-2-1-what-is-pointer-gestures/" target="_blank" rel="noopener noreferrer">WCAG 2.1 – What are pointer gestures?</a>, Dig Inclusion
      * <a href="https://www.linkedin.com/pulse/why-implementing-swipe-gestures-causes-mobile-issue-jennison-asuncion" target="_blank" rel="noopener noreferrer">Why implementing swipe gestures causes a mobile accessibility issue</a>, LinkedIn article
      * <a href="https://buffer.com/resources/announce-actions/" target="_blank" rel="noopener noreferrer">Announce your actions: making gesture actions accessible</a>, Buffer blog
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g212' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g215' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g216' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G219" target="_blank" rel="noopener noreferrer">G219: Ensuring that an alternative is available for dragging movements that operate on content</a>, WCAG 2.2 technique</li>
      <li parentName="ul"><ToolkitReference qid='linkedin_article_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='buffer_blog_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_5_1")} target="_blank" rel="noopener noreferrer">2.5.1 Pointer Gestures</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_5_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/?version=7_3#2_5_7")} target="_blank" rel="noopener noreferrer">2.5.7 Dragging Movements</a>, IBM accessibility requirements (7.3 only)</li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/?version=7_3#2_5_8")} target="_blank" rel="noopener noreferrer">2.5.8 Target Size (Minimum)</a>, IBM accessibility requirements (7.3 only)</li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Mouse operable](/toolkit/design/ux/#mouse-operable), this section
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_mouse_operable' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      