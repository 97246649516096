import { withPrefix } from "gatsby";
import Default from "../../../../src/gatsby-theme-carbon/templates/LandingPage";
import { TkFooter } from "../../../../src/components/toolkit/TkFooter";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import WhatWeDoPanel from "../../../../src/components/home/whatWeDoPanel";
import WhatsNewPanel from "../../../../src/components/home/whatsNewPanel";
import NeedHelpPanel from "../../../../src/components/home/needHelpPanel";
import "../../../../src/pages/index.scss";
import * as React from 'react';
export default {
  withPrefix,
  Default,
  TkFooter,
  Row,
  Column,
  WhatWeDoPanel,
  WhatsNewPanel,
  NeedHelpPanel,
  React
};