import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from 'gatsby';
import ToolkitRequirement from '../../../../components/rms_resources/requirements';
import ToolkitReference from '../../../../components/rms_resources/references';
import ToolkitTechnique from '../../../../components/rms_resources/techniques';
import ToolkitTopic from '../../../../components/rms_resources/topics';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Initial focus is evident`}</li>
      <li parentName="ul">{`There is a visual indicator every time a key press/action changes focus`}</li>
      <li parentName="ul">{`A focused item is differentiated from a selected item`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`Although checking the focus indicator is given here as a separate testing step, it
is normally carried out in tandem with the preceding and following steps, `}<ToolkitTopic qid='verify_manual_validate_pointer' mdxType="ToolkitTopic"></ToolkitTopic>{`, `}<ToolkitTopic qid='verify_manual_check_tab' mdxType="ToolkitTopic"></ToolkitTopic>{`, and `}<ToolkitTopic qid='verify_manual_check_component' mdxType="ToolkitTopic"></ToolkitTopic>{`. This is because the focus indicator should
only change as a result of a user action.`}</p>
    <p>{`Verify that the
indicator of the keyboard focus (or in the case of VoiceOver on iOS, the
navigation focus) is always highly visible or the browser default. Where it is not highly visible, further
testing is required to determine if the lack of visibility is the default for the
user agent or platform (which is acceptable, since it can be enhanced by
user settings) or if it is a result of coding, which may have suppressed or
attempted to modify the focus indicator. If testing for WCAG 2.2, another possible way the focus may not be visible is if it has been obscured by other content.`}</p>
    <p>{`Where designers or developers have altered the default focus indicator, it is critical that such
an alteration does not prevent enhancements to focus indication by the
user’s platform settings or by assistive technologies. This is tested more
thoroughly in the `}<ToolkitTopic qid='verify_manual_high_contrast' mdxType="ToolkitTopic"></ToolkitTopic>{` step.`}</p>
    <p>{`Ensure that there is a focus indicator within a complex widget or composite component,
such as when navigating items in a menu, grid, or tree. Ensure
that where an element can both receive keyboard focus and be selected, the
indicator for focus is different than the indicator for selection. An example
would be an item in an expanded list box or toolbar. One item may be selected
by default, but because there is a different indicator for keyboard focus, a
user can navigate to any of the items while knowing which item retains its
selected state.`}</p>
    <h5 className="accordionH5">Completes testing for:</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req1_4_3" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid="req2_4_7" mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><a href="../../../requirements/requirements/?version=7_3#2_4_11">
  2.4.11 Focus Not Obscured
        </a>, IBM accessibility requirements (7.3 only)</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      