import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../components/rms_resources/requirements";
import ToolkitReference from "../../../components/rms_resources/references";
import ToolkitTechnique from "../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`If a project must use custom widgets, there are several things developers can do to ensure better accessibility. Browsers and assistive technologies support the `}<ToolkitReference qid='w3c_8' source="hide" mdxType="ToolkitReference"></ToolkitReference>{` (ARIA) technical specification from the W3C. Applying the correct role to an element will give assistive technology users critical context and semantics. But applying the wrong role will confuse them.`}</p>
    <p>{`The `}<ToolkitReference qid='w3c_4' mdxType="ToolkitReference"></ToolkitReference>{` provide component-specific guidance and recommended keyboard interaction. Take time to learn the following:`}</p>
    <ul>
      <li parentName="ul">{`Specify correct ARIA roles, names, and descriptions on `}<ToolkitReference qid='aria_authoring_practices_1' source="hide" mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_2' name="Follow ARIA keyboard guidance" source="hide" mdxType="ToolkitReference"></ToolkitReference> per component</li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_12' name="Use ARIA landmark regions" source="hide" mdxType="ToolkitReference"></ToolkitReference> to denote content structure</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      