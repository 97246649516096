import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4><strong parentName="h4">{`Level 1`}</strong></h4>
    <ul>
      <li parentName="ul">{`No level 1 developer tasks for this requirement`}</li>
    </ul>
    <h4><strong parentName="h4">{`Level 2`}</strong></h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/dynamic-updates/#new-content")} target="_blank" rel="noopener noreferrer">Ensure Esc dismisses new content</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/dynamic-updates/#content-on-hover")} target="_blank" rel="noopener noreferrer">If content appears on hover, the new content needs to remain visible until dismissed</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/dynamic-updates/#moving-pointer")} target="_blank" rel="noopener noreferrer">Moving the pointer away from the trigger should not be the action that dismisses the new content</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/dynamic-updates/#hover-text-trigger")} target="_blank" rel="noopener noreferrer">Ensure custom tooltips and similar hover text can be triggered by keyboard</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/dynamic-updates/#hover-text")} target="_blank" rel="noopener noreferrer">Discuss alternatives to hover text</a></li>
      <li parentName="ul"><a href={withPrefix("toolkit/develop/dynamic-updates/#interactive-controls")} target="_blank" rel="noopener noreferrer">Use caution where interactive controls appear in the hover text</a></li>
    </ul>
    <h4><strong parentName="h4">{`Level 3`}</strong></h4>
    <ul>
      <li parentName="ul">{`No level 3 developer tasks for this requirement`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      