import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`For each time limit that is set by the content, the user can turn off or adjust the time limit before encountering it, or the user is warned before time expires and allowed to extend it`}</li>
      <li parentName="ul">{`If there are no means to turn off or extend time limits, confirm it is an `}<a href={withPrefix("/requirements/requirements/#2_2_1")} target="_blank" rel="noopener noreferrer">{`allowable exception`}</a></li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`If you are unfamiliar with the system being verified, it can be difficult to test for accessible time limits since many are based on a period of inactivity. For transaction-based applications with known time limits, it is just a question of waiting. For application-wide timeouts, the easiest way to surface timeout functionality may be leaving an application dormant but connected to the network overnight. Consider including accessible timing verification during functional or system testing that exercise time limit situations.`}</p>
    <p>{`Allowable timing exceptions include real-time events such as an auction and time limits longer than 20 hours.`}</p>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_2_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      