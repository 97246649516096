import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`When platform high contrast is activated, the application adopts the high contrast theme`}</li>
      <li parentName="ul">{`After high contrast is activated, focus indicators and component interaction remain visible via keyboard`}</li>
      <li parentName="ul">{`After high contrast is activated, all images and icons that convey important information are visible or replaced by a text alternative`}</li>
      <li parentName="ul">{`After high contrast is activated, pointer effects are still visible`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`The requirements for applications to support operating system platform accessibility features and user preferences are covered in different ways by existing and developing standards. A relatively simple test that confirms an application’s ability to support both user preferences and platform settings is to activate the Windows operating system’s high contrast mode. Traverse the page with a keyboard, confirming the continued presence of a visible focus indicator. Where applicable, interact with a pointer and confirm `}<inlineCode parentName="p">{`Onmouseover`}</inlineCode>{` effects are still visible.`}</p>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><a href="https://www.ibm.com/able/requirements/requirements/#1_4_3">1.4.3 Contrast (Minimum)</a>, IBM accessibility requirements</li>
    </ul>
    <p><strong parentName="p">{`Software:`}</strong></p>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req502_2_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req503_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      