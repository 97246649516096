import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Audio description is provided for all prerecorded video content in synchronized media.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures that users who cannot see have access to the visual information in a media presentation through an audio description,
also sometimes called video descriptions or descriptive narration, to augment the audio portion of a presentation.
This audio description is synchronized with the content;
during existing pauses in the main soundtrack,
the user hears an audio description of actions, characters, scene changes,
and on-screen text that are important to understand the presentation.
Secondary or alternate audio tracks are commonly used for this purpose.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` This requirement overlaps with requirement `}<em parentName="p">{`1.2.3: Audio Description or Media Alternative (Prerecorded)`}</em>{`.
Compliance requirements for `}<em parentName="p">{`1.2.3`}</em>{` allow `}<em parentName="p">{`either`}</em>{` an audio description or a text alternative.
However, this requirement, `}<em parentName="p">{`1.2.5`}</em>{`, requires an audio description to comply.`}</p>
    <p><strong parentName="p">{`Exception:`}</strong>{` If all important visual information is already announced in the audio track,
or there is no important information in the video track, such as a “talking head” where a person is talking in front of an unchanging background,
no audio description is necessary.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/audio-description-prerecorded.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.2.5 Audio Description (Prerecorded)`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      