import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../src/components/rms_resources/requirements";
import ToolkitReference from "../../../../src/components/rms_resources/references";
import ToolkitTechnique from "../../../../src/components/rms_resources/techniques";
import ToolkitTopic from "../../../../src/components/rms_resources/topics";
import img1 from "../../../../src/visuals/design/content_4_1_do.png";
import img2 from "../../../../src/visuals/design/content_4_1_dont.png";
import img3 from "../../../../src/visuals/design/content_text_updates_2_do.png";
import img4 from "../../../../src/visuals/design/content_text_updates_2_dont.png";
import "../../../../src/pages/toolkit/topics.scss";
import * as React from 'react';
export default {
  Row,
  Column,
  withPrefix,
  ToolkitRequirement,
  ToolkitReference,
  ToolkitTechnique,
  ToolkitTopic,
  img1,
  img2,
  img3,
  img4,
  React
};