import React, { Component } from 'react';
import { AccordionItem } from "carbon-components-react";

export class TkAccordionItemCL extends Component {
    state = {
        first: true
    }
    render() {
        let {
            children,
            level,
            open,   // added open prop so as to be able to open and close accordion programmatically
            title,
            hash
        } = this.props;
        if (!hash) {
            hash = "";
        } else if (hash[0] === "#") {
            hash = hash.substring(1);
        }
        hash = hash.split(' Repair')[0]; //fix oddity for 11.8.4 which comes out 11.8.4 Repair
        let docHash = typeof document !== "undefined" ? document.location.hash : "";
        let activeItem = docHash === `#${hash}`;
        if (activeItem && this.state.first) {
            setTimeout(() => {
                let buttonElem = document.getElementById(`jump_${hash}`).parentElement.previousElementSibling;
                if (buttonElem.getAttribute("aria-expanded") !== "true") {
                    buttonElem.click();
                    buttonElem.focus();
                }
                setTimeout(() => {
                    document.getElementById(`jump_${hash}`).scrollIntoView(); // scroll the AccordionItem into view
                    document.defaultView.scrollBy(0,-70);
                },400)
            },400)
            this.setState({first: false});
        }

        return <div role="heading" aria-level="4">
            <h4 style={{display: "none"}}>{title}</h4>
            <AccordionItem open={open} title={title} iconDescription={null}>
                <a id={`jump_${hash}`} name={hash} style={{display:"block", height: "100px", visibility: "hidden", marginTop: "-100px"}} />
                {children}
            </AccordionItem>
        </div>;
    }
}