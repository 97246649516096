import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
import img1 from "./../../../../../visuals/design/content_4_1_do.png";
import img2 from "./../../../../../visuals/design/content_4_1_dont.png";
import img3 from "./../../../../../visuals/design/content_text_updates_2_do.png";
import img4 from "./../../../../../visuals/design/content_text_updates_2_dont.png";
import "../../../topics.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Vague errors are especially problematic for users with cognitive disabilities who may struggle to find, understand, and fix them.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Identify and describe errors`}</li>
      <li parentName="ul">{`Suggest corrections, with enough context for blind users`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" aspectRatio="1:1" captionTitle="" caption="Provide helpful feedback so users understand that either the username or password was entered incorrectly." mdxType="DoDontExample">
    <img src={img1} alt="Good example: text in red that says 'Incorrect username or password'"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" aspectRatio="1:1" captionTitle="" caption="Don’t provide little or no guidance where users understand that they are unable to sign in but don't know why there is an error." mdxType="DoDontExample">
    <img src={img2} alt="Bad example: text in red that says 'Unable to sign in'"></img>
        </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" aspectRatio="1:1" captionTitle="" caption="Provide suggestions so users know the proper format they need." mdxType="DoDontExample">
    <img src={img3} alt="Good example: text in red that says 'Enter username in the format: yourname@example.com'"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" aspectRatio="1:1" captionTitle="" caption="Don't provide ambiguous information in an error message that doesn't tell users how to fix it." mdxType="DoDontExample">
    <img src={img4} alt="Bad example: text in red that says 'The username is not entered in the correct format'"></img>
        </DoDontExample>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g83' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_25' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='ux_design_world_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      