import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Column } from 'gatsby-theme-carbon/src/components/Grid';
import Launch16 from '@carbon/icons-react/lib/launch/16';
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import DocumentDownload16 from '@carbon/icons-react/lib/document--download/16';
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/32b791abfc57a8c893e63797debdc531/Web___Version_7.3.xlsx" type="application/vnd.ms-excel">Web___Version_7.3.xlsx</a><br />
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/c2f772ae7edf5f6f2957901b93854054/Web_based_Documentation___Version_7.3.xlsx" type="application/vnd.ms-excel">Web_based_Documentation___Version_7.3.xlsx</a><br />
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/d5233cae03bcc96c668b8b3b24381446/Authoring_Tools_Capabilities___Version_7.3.xlsx" type="application/vnd.ms-excel">Authoring_Tools_Capabilities___Version_7.3.xlsx</a><br />
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/f70547362d50b519d3eb1077a36368d0/Software___Version_7.3.xlsx" type="application/vnd.ms-excel">Software___Version_7.3.xlsx</a><br />
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/41f6378591edcde632858225e44e4382/Command_line_Software___Version_7.3.xlsx" type="application/vnd.ms-excel">Command_line_Software___Version_7.3.xlsx</a><br />
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/4bfb6c2938031b88089ea5b138c7299c/Non_Web_Documentation___Version_7.3.xlsx" type="application/vnd.ms-excel">Non_Web_Documentation___Version_7.3.xlsx</a>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      