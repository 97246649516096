import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Web pages, non-web documents,
and software have titles that describe topic or purpose.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures descriptive titles are provided.
Descriptive titles help all users orient themselves when navigating through content or when moving between multiple applications or documents.`}</p>
    <p>{`Screen readers announce titles before other content.
If titles are missing, screen reader users must explore more content to determine the purpose. Conversely, if titles are identical for multiple pages,
or repeat the same information before providing unique information,
a user will hear repetitive content before understanding context.`}</p>
    <p>{`The title should:`}</p>
    <ul>
      <li parentName="ul">{`Succinctly identify the subject`}</li>
      <li parentName="ul">{`Make sense when read out of context, for example by a screen reader, in a site map,
list of search results, window title, or on a browser tab title`}</li>
    </ul>
    <p>{`If the page or document belongs to a collection, the title should:`}</p>
    <ul>
      <li parentName="ul">{`List the most unique identifying information first`}</li>
      <li parentName="ul">{`Be unique within the collection`}</li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{` While the European `}<a parentName="p" {...{
        "href": "https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=73"
      }}>{`EN 301 539, clause 11.2.4.2 Void`}</a>{` standard does not require `}<em parentName="p">{`2.4.2 Page Title`}</em>{` for non-web software,
it is still required by `}<a parentName="p" {...{
        "href": "https://www.access-board.gov/ict/#chapter-5-software"
      }}>{`US 508`}</a>{`.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/page-titled" target="_blank">{`Understanding 2.4.2 Page Titled`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      