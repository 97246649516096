import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Standard HTML elements already have well thought out keyboard functionality built in. The keyboard operation closely models what works for similar components in desktop operating systems, making navigation and usage predictable for the user.`}</p>
    <p>{`However, there are many operating system components that don’t exist natively in HTML, such as menus and sliders. Accessible Rich Internet Application (ARIA) markup was created so developers can build custom components that consistently work with assistive technologies. Implement keyboard handlers using established conventions to give
full keyboard access to the functionality of the component. For predictable usage via a keyboard, it is best to implement
keyboard access consistent with the patterns found in the ARIA authoring practices.`}</p>
    <p>{`Be cautious when using a code framework or design system with custom-built components and patterns. Many frameworks were not built or maintained with accessibility in mind. Check the documentation and verify keyboard operability of all functionality. Any time the keyboard operation of a custom component departs from convention, discuss the reasons and risks with design.`}</p>
    <h5 className="accordionH5">Requirements and resources</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='webaim_6' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    <ul>
      <li parentName="ul"><ToolkitTopic qid='dev_testing_confirm_component' mdxType="ToolkitTopic"></ToolkitTopic>, Develop - Unit testing</li>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, UX design - Keyboard</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      