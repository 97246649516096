import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Color is not the only indicator to convey meaning`}</li>
      <li parentName="ul">{`Links not underlined are distinguishable when viewed in gray scale`}</li>
      <li parentName="ul">{`There is clear contrast between all text and its background`}</li>
      <li parentName="ul">{`All parts of any gradient background have clear contrast with their text`}</li>
      <li parentName="ul">{`Confirm images of text pass testing for alternative text and contrast`}</li>
      <li parentName="ul">{`There is clear contrast between components and the background`}</li>
      <li parentName="ul">{`All parts of meaningful graphics have a  contrast of 3:1 against adjacent colors`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`This step checks whether the content achieves the WCAG principle of `}<ToolkitReference qid='wcag_3' source="hide" name="Perceivable" mdxType="ToolkitReference"></ToolkitReference>{`, and that users with low vision or color deficiency (‘color
blindness’) can understand everything. Differences in color should not be the only means of conveying information, both in text, charts, and in graphical elements. Look for color words in the content, such as red, yellow, or green as a tip for which types of content to check. Look for the correct use of 3 to 1 contrast in addition to use of color to distinguish the color differences. After these checks, it can also be helpful to view the page without color to confirm all content is still useable.`}</p>
    <p>{`Automated testing should have already detected most text contrast issues.  Check for links that are not underlined to see if they are distinguishable from the surrounding text as links by their contrasting color. Any occurrences of color background gradations should be inspected manually. Teams can use a tool like `}<ToolkitReference qid='paciello_group_2' source="hide" mdxType="ToolkitReference"></ToolkitReference>{` for spot checking.`}</p>
    <p>{`Check for images of text. They are simple to identify with a
mouse – the text in the image cannot be selected. Alternatively, removing or changing the color of real text can help isolate images of text.`}</p>
    <p>{`Identify parts of components (such as the square outline on a checkbox or the indicator for text input) and check that each has a 3 to 1 contrast with the background and adjacent (touching) graphical elements. Also check contrast on meaningful graphics, such as bar charts and button icons. They must meet 3 to 1 contrast against the background but also against adjacent parts of the same graphic. For example, in a pie chart, each of the colored slices needs to have a 3 to 1 contrast ratio with its neighbors, or needs to have a contrasting outline width of at least 3 pixels`}</p>
    <h5 className="accordionH5">Partially addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_5' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_11' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      