import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`When the sequence in which content is presented affects its meaning, a correct reading sequence can be programmatically determined.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures if the visual order of reading information on the page is important to its meaning,
then the sequence, such as reading order, of the information is available programmatically.`}</p>
    <p>{`Teams need to do two things to achieve the goal of this requirement:`}</p>
    <ul>
      <li parentName="ul">{`Determine if any information being presented has a meaningful reading order`}</li>
      <li parentName="ul">{`Ensure that the meaningful order is available to assistive technologies`}</li>
    </ul>
    <p>{`An example of meaningful sequence, such as reading order, is text in a two-column article.
A user must read the lines of text in the first column sequentially,
then move to the second column and do the same.
If an assistive technology reads across both columns (e.g., reads the first line of the cell in the first cell and then reads the first line of the cell in the second column) before proceeding to the next line,
the user will obviously not understand the meaning.
Other potential failures of meaningful sequence can occur when using CSS, layout tables,
or white space to position content.`}</p>
    <p>{`It is important not to confuse the reading order with the navigation order.
The ability to navigate (i.e., move by keyboard between interactive controls)
in a way that preserves meaning is covered by `}<a href={withPrefix("requirements/requirements/#2_4_3")} target="_blank" rel="noopener noreferrer">{`2.4.3 Focus Order`}</a>{`.
Meaningful Sequence is concerned solely with the reading order,
and so cannot normally be tested without assistive technology.
Screen readers render content in a serialized manner.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Sequence is not important for some content.
For example, it does not normally affect meaning whether side navigation is read before or after the main content.
So while matching the visual and reading order is a way to ensure this requirement is met,
a difference in visual order and reading order is not a failure where the sequence does not affect the meaning.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/meaningful-sequence.html" target="_blank" rel="noopener noreferrer">{`Understanding 1.3.2 Meaningful Sequence`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      