import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import { IconList, IconListRow, IconListIcon, IconListContent } from '../../../components/IconList';
import Checkmark32 from "../../../images/Checkmark32.svg";
import ToolkitRequirement from "../../../components/rms_resources/requirements";
import ToolkitReference from "../../../components/rms_resources/references";
import ToolkitTechnique from "../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`When testing a larger portion of the content, repeatedly press the Tab key to traverse all interactive elements or groups of elements.
This allows you to reconfirm several things:`}</p>
    <IconList mdxType="IconList">
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
        You can press the Tab key to reach all operable parts of the content*
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
       From the bottom of the page, you can press Shift+Tab to reach all operable parts of the page
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
      Elements take tab order in a way that preserves meaning or operation
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
      At each tab stop, there is a visual focus indicator
        </IconListContent>
    </IconListRow>
    <IconListRow mdxType="IconListRow">
        <IconListIcon mdxType="IconListIcon">
            <img src={Checkmark32} alt="Checkmark" />
        </IconListIcon>
        <IconListContent mdxType="IconListContent">
      Actively look for items missing from or incorrectly added to the tab order.
        </IconListContent>
    </IconListRow>
    </IconList>
    <p>{`*Acceptable behavior for complex components and groups of widgets, such a menus or toolbars, is to use a single tab stop, with arrow key navigation between the items in the group or complex component.`}</p>
    <p>{`Confirms `}<ToolkitRequirement qid='req2_4_3' source="hide" mdxType="ToolkitRequirement"></ToolkitRequirement>{`  and `}<ToolkitRequirement qid='req2_4_7' source="hide" mdxType="ToolkitRequirement"></ToolkitRequirement>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      