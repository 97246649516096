import React, { Component } from "react";
import ImageCard from "gatsby-theme-carbon/src/components/ImageCard";
import ResourceCard from "gatsby-theme-carbon/src/components/ResourceCard";
import ArrowRight20 from "@carbon/icons-react/lib/arrow--right/20";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";

// import Styles from './ImpactCard.module.scss'
// import testImg from './img_avatar.png';
import { Launch20 } from "@carbon/icons-react";

class ImpactCard extends Component {
  render() {
    return (
      <div className="impactCard">
        <a
          href={this.props.href}
          target="_blank"
          rel="noopener noreferrer"
          style={{ padding: 0, display:"block" }}
        >
          <ImageCard aspectRatio="16:9">
            <img
              alt={this.props.alt}
              src={this.props.image}
              style={{ width: "100%" }}
            />
          </ImageCard>
          <ResourceCard
            title={this.props.hrefText}
            aspectRatio="2:1"
            actionIcon="launch"
            titleHeadingLevel={3}
          />
        </a>
      </div>
    );
  }
}
export default ImpactCard;
