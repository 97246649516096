import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Moving the mouse pointer between the hover trigger and the new content, in either direction, must not dismiss the hover content, even when there is space between them. Users with disabilities may need to change magnification, move the pointer, or simply \`bring the new content into their visual field’.`}</p>
    <p>{`Requirements for dismissing the hover content are covered in the section on keeping hover content visible until dismissed.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/failures/F95.html" target="_blank" rel="noopener noreferrer">F95: Failure of Success Criterion 1.4.13 due to content shown on hover not being hoverable</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR39" target="_blank" rel="noopener noreferrer">SCR39: Making content on focus or hover hoverable, dismissible, and persistent</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='f95' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='scr39' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_13")} target="_blank" rel="noopener noreferrer">1.4.13 Content on Hover or Focus</a>, IBM accessibility requirements
      * <a href="https://www.carbondesignsystem.com/components/tooltip/code#icon-tooltip" target="_blank" rel="noopener noreferrer">Icon tooltip</a>, Carbon design system
      * <a href="https://react.carbondesignsystem.com/?path=/story/tooltipdefinition--default" target="_blank" rel="noopener noreferrer">Definition tooltip</a>, Carbon design system
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_13' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_5' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_14' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      