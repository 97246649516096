import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "../../../../src/visuals/design/visual_1_1_do.png";
import img2 from "../../../../src/visuals/design/visual_1_1_dont.png";
import ToolkitRequirement from "../../../../src/components/rms_resources/requirements";
import ToolkitReference from "../../../../src/components/rms_resources/references";
import ToolkitTechnique from "../../../../src/components/rms_resources/techniques";
import ToolkitTopic from "../../../../src/components/rms_resources/topics";
import * as React from 'react';
export default {
  Row,
  Column,
  Caption,
  withPrefix,
  img1,
  img2,
  ToolkitRequirement,
  ToolkitReference,
  ToolkitTechnique,
  ToolkitTopic,
  React
};