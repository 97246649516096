import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For all user interface components (including but not limited to: form elements, links,
and components generated by scripts), the name and role can be programmatically determined;
states, properties, and values that can be set by the user can be programmatically set;
and notification of changes to these items is available to user agents,
including assistive technologies.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that Assistive Technologies (AT) can gather information about,
activate, set, and receive status of all user interface components.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` This requirement is primarily for developers who develop or script their own custom user interface components.
Standard HTML controls already meet this requirement when used according to specification.`}</p>
    <p>{`Custom interactive user interface components often do not expose proper role, state,
and property information needed for assistive technologies.
As a result, people with disabilities have difficulty identifying and interacting with the components.
Common examples are custom menus, trees, data grids, accordions, and dialog boxes.
Ensuring proper markup and keyboard operation enables people with disabilities to identify and interact with the custom components in the same manner they identify and interact with standard components.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/name-role-value" target="_blank">{`Understanding 4.1.2: Name, Role, Value`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      