import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Captions are provided for all live audio content in synchronized media.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`This requirement ensures people who are deaf or hearing-impaired,
work in noisy environments,
or turn off sounds to avoid disturbing others have access to the auditory information in real-time (live) presentations.
Live captions do this by identifying who is speaking,
displaying the dialogue and noting non-speech information conveyed through sound,
such as sound effects, music, laughter, and location as it occurs.`}</p>
    <p>{`Live captions must be synchronized with the presentation and be as accurate as possible,
typically accomplished through `}<a href="https://en.wikipedia.org/wiki/Communication_access_real-time_translation" target="_blank" rel="noopener noreferrer">{`Wikipedia® - Communication Access Real-time Translation (CART)`}</a>{` real-time captioning.`}</p>
    <p>{`As an added benefit,
captioning live presentation can often produce a transcript
that everyone who missed the live presentation can use for review.`}</p>
    <p>{`This requirement also allows for equivalent facilitation when open or closed captions cannot be provided within the live video content via a separate communication channel,
such as a third-party transcription service or by having meeting participants use a group chat to transcribe the discussion.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/captions-live" target="_blank" rel="noopener noreferrer">{`Understanding 1.2.4 Captions (Live)`}</a>{` (external link to WCAG) for more information on exceptions, intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      