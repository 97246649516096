import { withPrefix } from "gatsby";
import Default from "../../../../src/gatsby-theme-carbon/templates/Default";
import { TkFooter } from "../../../../src/components/toolkit/TkFooter";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import spaceship from "../../../../src/images/spaceship.svg";
import touchScreen from "../../../../src/images/touch--screen.svg";
import build from "../../../../src/images/build.svg";
import expandVert from "../../../../src/images/expand--vert.svg";
import magNode from "../../../../src/visuals/toolkit_landing/npm_icon.svg";
import magKarma from "../../../../src/visuals/toolkit_landing/karma_icon.svg";
import magFirefox from "../../../../src/visuals/toolkit_landing/firefox_icon.svg";
import magChrome from "../../../../src/visuals/toolkit_landing/chrome_icon.svg";
import ToolkitBanner from "../../../../src/visuals/toolkit_landing/banner_illustration.svg";
import locked from "../../../../src/images/locked.svg";
import ibmLogo from "../../../../src/visuals/toolkit_landing/ibm_internal_icon.svg";
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import ArrowDown20 from '@carbon/icons-react/lib/arrow--down/20';
import "../../../../src/pages/toolkit/toolkit-home.scss";
import "../../../../src/pages/toolkit/toolkit-resource-cards.scss";
import plan from "../../../../src/visuals/toolkit_landing/plan_pictogram.svg";
import design from "../../../../src/visuals/toolkit_landing/design_pictogram.svg";
import develop from "../../../../src/visuals/toolkit_landing/develop_pictogram.svg";
import verify from "../../../../src/visuals/toolkit_landing/verify_pictogram.svg";
import launch from "../../../../src/visuals/toolkit_landing/launch_pictogram.svg";
import tools from "../../../../src/visuals/toolkit_landing/tools.svg";
import * as React from 'react';
export default {
  withPrefix,
  Default,
  TkFooter,
  Row,
  Column,
  spaceship,
  touchScreen,
  build,
  expandVert,
  magNode,
  magKarma,
  magFirefox,
  magChrome,
  ToolkitBanner,
  locked,
  ibmLogo,
  ArrowRight16,
  ArrowDown20,
  plan,
  design,
  develop,
  verify,
  launch,
  tools,
  React
};