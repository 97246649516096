import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Level 1`}</h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("/toolkit/develop/keyboard/#tab-order")} target="_blank" rel="noopener noreferrer">Where possible, achieve the desired tab order by adjusting the DOM, rather than overriding the tabindex</a></li>
      <li parentName="ul"><a href={withPrefix("/toolkit/develop/keyboard/#standard-html")} target="_blank" rel="noopener noreferrer">Use standard HTML elements where possible, using CSS to alter appearance not behavior</a></li>
      <li parentName="ul"><a href={withPrefix("/toolkit/develop/keyboard/#keyboard-conventions")} target="_blank" rel="noopener noreferrer">Be familiar with established keyboard conventions</a></li>
      <li parentName="ul"><a href={withPrefix("/toolkit/develop/keyboard/#custom-elements")} target="_blank" rel="noopener noreferrer">Implement keyboard operation for custom elements</a></li>
      <li parentName="ul"><a href={withPrefix("/toolkit/develop/overview#reducing-effort")} target="_blank" rel="noopener noreferrer">Follow core considerations in reducing effort</a></li>
      <li parentName="ul"><a href={withPrefix("/toolkit/develop/overview#confirm-keyboard-interaction")} target="_blank" rel="noopener noreferrer">Unit test - confirm component keyboard interaction</a></li>
    </ul>
    <h4>{`Level 2`}</h4>
    <ul>
      <li parentName="ul"><a href={withPrefix("/toolkit/develop/keyboard/#player-kbd-accessible")} target="_blank" rel="noopener noreferrer">Make custom media player and animation controls keyboard accessible</a></li>
    </ul>
    <h4>{`Level 3`}</h4>
    <ul>
      <li parentName="ul">{`No level 3 developer tasks for this requirement`}</li>
    </ul>
    <h4>{`Supplemental techniques`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Note for iOS platform:`}</strong>{` For keyboards connected to iOS devices,
Apple has its own set of `}<inlineCode parentName="li">{`Tab`}</inlineCode>{` and arrow keys behaviors as noted in `}<a href="https://support.apple.com/guide/ipad/use-voiceover-with-an-apple-external-keyboard-ipad9a246749/ipados" target="_blank">{`Wireless Keyboard with VoiceOver`}</a>{`. `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      