
import React, { Component } from 'react'
import { ContentSwitcher, Switch, SelectSkeleton, Select, SelectItem, Link } from 'carbon-components-react';

import Violation16 from "../../images/Violation16.svg";
import Recommendation16 from "../../images/Recommendation16.svg";
import NeedsReview16 from "../../images/NeedsReview16.svg";

import './checkerRuleSets.scss'

class CheckerRuleSets extends Component {
    state = {
        archiveInfo: null,
        loadingRules: true,
        selectedVersion: null,
        selectedRS: null
    }

    componentDidMount() {
        fetch("https://unpkg.com/accessibility-checker-engine@next/archives.json").then(async(resp) => {
            let archiveInfo = await resp.json();
            let latestArchive = archiveInfo.filter(archive => archive.latest === true)[0];
            this.setState({ archiveInfo, selectedVersion: latestArchive.version });
        });
    }

    componentDidUpdate(_prevProps, prevState) {
        if (prevState.selectedVersion !== this.state.selectedVersion) {
            this.setState({ loadingRules: true });
            if (typeof window !== "undefined" && typeof window.ace !== "undefined") {
                window.ace = null;
            }
            let script = document.createElement('script')
            script.src = `https://unpkg.com/accessibility-checker-engine@${this.state.selectedVersion}/ace.js`;
            script.class = "external-script";
            script.addEventListener("load", () => {
                // Add it to the ace object so that it is garbage collected with the ace object
                window.ace.checkerInst = new window.ace.Checker();
                this.setState({ 
                    loadingRules: false,
                    selectedRS: window.ace.checkerInst.rulesets.filter(ruleset => ruleset.type !== "extension")[0].id
                });
            })
            document.body.appendChild(script);
        }
    }
    
    processRules(ruleset) {
        let rulesV4 = window.ace.checkerInst.engine.ruleMap;
        let rsInfo = {
            id: ruleset.id,
            name: ruleset.name,
            checkpoints: []
        }
        for (const cp of ruleset.checkpoints) {
            let cpInfo = {
                num: cp.num,
                name: cp.name,
                wcagLevel: cp.wcagLevel,
                summary: cp.summary,
                rules: []
            }
            for (const ruleId in rulesV4) {
                let includeRuleCodes = new Set();
                let rule = rulesV4[ruleId];
                let rsLevel = "";
                let toolkitLevel;
                if (rule.rulesets) {
                    for (const rsInfo of rule.rulesets) {
                        if ((rsInfo.id === ruleset.id || rsInfo.id.includes(ruleset.id)) && (rsInfo.num === cp.num || rsInfo.num.includes(cp.num))) {
                            if (rsInfo.reasonCodes) {
                                rsInfo.reasonCodes.forEach(code => includeRuleCodes.add(code));
                            } else {
                                for (const code in rule.messages["en-US"]) {
                                    includeRuleCodes.add(code);
                                }
                            }
                            rsLevel = rsInfo.level;
                            toolkitLevel = rsInfo.toolkitLevel;
                            break;
                        }
                    }
                } else {
                    let lookupRS = window.ace.checkerInst.rulesets.filter(rs => rs.id === ruleset.id)[0];
                    let lookupCP = lookupRS.checkpoints.filter(myCP => myCP.num === cp.num)[0];
                    let lookupRule = lookupCP.rules.find(myRule => myRule.id === rule.id);
                    if (lookupRule) {
                        let re = new RegExp(`\\.Rule([^()) ]+)[ ()]+["']([^"']+)["']`, "g");
                        let reMatch;
                        while (reMatch = re.exec(rule.run.toString())) {
                            includeRuleCodes.add(reMatch[2]);
                        }
                        rsLevel = lookupRule.level;
                        toolkitLevel = lookupRule.toolkitLevel;
                    }
                }
                if (includeRuleCodes.size > 0) {
                    if (!rule.messages || !rule.messages["en-US"]) {
                        rule.messages = {
                            "en-US": window.ace.checkerInst.engine.nlsMap[rule.id] || {}
                        }
                        rule.messages["en-US"].group = rule.messages["en-US"]["0"];
                        rule.help = {
                            "en-US": window.ace.checkerInst.engine.helpMap[rule.id] || {}
                        }
                    }
                    let ruleInfo = {
                        level: rsLevel,
                        toolkitLevel: toolkitLevel,
                        rule: rule,
                        reasons: []
                    }
                    includeRuleCodes.forEach((msgCode) => {
                        if (msgCode === "group") return;
                        let re = new RegExp(`\\.Rule([^()) ]+)[ ()]+["']${msgCode}["']`);
                        let reMatch = re.exec(rule.run.toString());
                        if (reMatch && reMatch[1] !== "Pass") {
                            ruleInfo.reasons.push({
                                id: msgCode,
                                message: rule.messages["en-US"][msgCode],
                                help: rule.help["en-US"][msgCode],
                                type: reMatch[1]
                            })
                        }
                    });
                    ruleInfo.reasons.sort((a,b) => {
                        if (a.type === b.type) return 0;
                        if (a.level === "Fail") return -1;
                        if (b.level === "Fail") return 1;
                        if (a.level === "Potential") return -1;
                        if (b.level === "Potential") return 1;
                        if (a.level === "Manual") return -1;
                        if (b.level === "Manual") return 1;
                        return 0;
                    })
                    cpInfo.rules.push(ruleInfo);
                }
            }
            cpInfo.rules.sort((a,b) => {
                if (a.level === b.level) {
                    let retVal = b.reasons.filter(reasonInfo => (reasonInfo.type === "Fail")).length - a.reasons.filter(reasonInfo => (reasonInfo.type === "Fail")).length;
                    if (retVal === 0) {
                        retVal = b.reasons.filter(reasonInfo => (reasonInfo.type === "Potential")).length - a.reasons.filter(reasonInfo => (reasonInfo.type === "Potential")).length;
                    }
                    if (retVal === 0) {
                        retVal = b.reasons.filter(reasonInfo => (reasonInfo.type === "Manual")).length - a.reasons.filter(reasonInfo => (reasonInfo.type === "Manual")).length;
                    }
                    return retVal;
                }
                if (a.level === "VIOLATION") return -1;
                if (b.level === "VIOLATION") return 1;
                if (a.level === "RECOMMENDATION") return -1;
                if (b.level === "RECOMMENDATION") return 1;
                return 0;
            });
            rsInfo.checkpoints.push(cpInfo);
        }
        return rsInfo;
    }

    getSVG(rsLevel, ruleLevel) {
        const valueMap = {
            "VIOLATION": {
                "Potential": "Needs review",
                "Fail": "Violation",
                "Pass": "Pass",
                "Manual": "Needs review"
            },
            "RECOMMENDATION": {
                "Potential": "Recommendation",
                "Fail": "Recommendation",
                "Pass": "Pass",
                "Manual": "Recommendation"
            },
            "INFORMATION": {
                "POTENTIAL": "Needs review",
                "FAIL": "Violation",
                "PASS": "Pass",
                "MANUAL": "Recommendation"
            }
        };
        
        const val = valueMap[rsLevel][ruleLevel];
        let icon = <></>;
        if (val === "Violation") icon = (<img src={Violation16} alt="" />);
        if (val === "Needs review") icon = (<img src={NeedsReview16} alt="" />);
        if (val === "Recommendation") icon = (<img src={Recommendation16} alt="" />);
        return <span className="issueLevel">{icon}&nbsp;<strong style={{display: "inline"}}>{val}</strong></span>;
    }

    render() {
        let ruleset = null;
        let rsInfo = null;
        if (!this.state.loadingRules && this.state.selectedRS) {
            ruleset = window.ace.checkerInst.rulesets.filter(ruleset => ruleset.id === this.state.selectedRS)[0];
            rsInfo = this.processRules(ruleset);
        }
        return (
            // Overview heading text removed - but not div and class
            <div className="chkRS">
                <div className="headerLeftOV" >
                    <div className="bx--row dynamicHeaderTopBot" >
                        <div className="bx--col-lg-3 bx--col-sm-4">{/** Left col buffer */}</div>
                        <div className="bx--col-lg-6 bx--col-sm-4">
                            <h3 className="eventTitleCardBody">
                                Here are the latest published rule sets in the IBM Equal Access Accessibility Checker.
                            </h3>
                            <div style={{marginTop: "3rem"}} />
                            { !this.state.archiveInfo && <SelectSkeleton /> }
                            { this.state.archiveInfo && <Select disabled={this.state.loadingRules} id="archiveSelect" light={true} labelText="Rule sets" onChange={(evt) => {
                                this.setState({ selectedVersion: evt.target.value });
                            }}>
                                {this.state.archiveInfo.map((archive) => (
                                    <SelectItem key={archive.version} text={archive.name} value={archive.version} />
                                ))}
                            </Select>}
                            <div style={{marginTop: "3rem"}} />
                            { !this.state.loadingRules && this.state.selectedVersion && (
                                <ContentSwitcher onChange={({ name }) => {
                                    this.setState({ selectedRS: name });
                                }}>
                                    {window.ace.checkerInst.rulesets.filter(ruleset => ruleset.type !== "extension").map(ruleset => (
                                        <Switch
                                            key={ruleset.id}
                                            name={ruleset.id}
                                            text={ruleset.name}
                                        />

                                    ))}
                                </ContentSwitcher>
                            )}
                            <div style={{marginTop: "3rem"}} />
                            { rsInfo?.checkpoints.map(cp => (<>
                                <h3>{cp.num} {cp.name} ({cp.wcagLevel})</h3>
                                <div className="cpSummary">{cp.summary}</div>
                                <div style={{ marginTop: "1.5rem" }} />
                                {cp.rules.map(ruleInfo => (<div key={ruleInfo.rule.id} style={{marginLeft: "4rem"}}>
                                    <h4><strong>{ruleInfo.rule.id}</strong>: <span className="groupMsg">{ruleInfo.rule.messages["en-US"].group}</span></h4>
                                    { ruleInfo.reasons.map(reasonInfo => {
                                        let helpDetail = {
                                            message: reasonInfo.message,
                                            msgArgs: ["{0}", "{1}", "{3}", "{4}"],
                                            value: [ruleInfo.level, reasonInfo.type.toUpperCase()],
                                            reasonId: reasonInfo.id
                                        }
                                        return <div key={reasonInfo.id} className="ruleReason">
                                            {this.getSVG(ruleInfo.level,reasonInfo.type)} {reasonInfo.help 
                                                && <Link target="_blank" rel="noopener noreferrer" href={`https://unpkg.com/accessibility-checker-engine@${this.state.selectedVersion}/help/en-US/${reasonInfo.help}#${encodeURIComponent(JSON.stringify(helpDetail))}`}>{reasonInfo.message}</Link>
                                            }{!reasonInfo.help && reasonInfo.message}
                                        </div>})}
                                </div>))}
                            </>))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckerRuleSets