import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './PageHeaderCL.scss';
import { pageBanner, withTabs } from './PageHeaderCL.module.scss';
import Bee from '../../../visuals/homepage/banner_bee_logo.svg';

 

const PageHeaderCL = ({ title, description, tabs = [] }) => (
  <div className={cx(pageBanner, { [withTabs]: tabs.length })}  style={{position: "relative" }} >
      <div className="pageBannerCL">
      <div style={{maxWidth: "99rem"}}>
      <div className="bx--grid" style={{height:"100%"}}>
        <div style={{ position: "absolute", bottom: "2rem", width: "100%", maxWidth: "99rem" }}>
            <div className="bx--row" style={{paddingLeft:"16px", maxWidth: "100%" }}>
              <div className="expressive04Bold" style={{"marginTop":"16px"}}>  
                {/* <strong role="heading" aria-level="1" id="page-title" tabindex="-1">{title.slice(0,17)} {title.slice(18,34)}</strong> */}
                <strong role="heading" aria-level="1" id="page-title" tabindex="-1" style={{fontFamily:"IBM Plex Sans"}}>IBM Accessibility Requirements</strong>
                <br />
              </div>
            </div>
            <div className="bx--row" style={{ maxWidth: "100%" }}>
              <div className="expressive04 dynamicPageHeader2 bx--col-sm-4 bx--col-md-6 bx--col-lg-6" style={{fontSize:"28px"}}>
                <span style={{float:"left"}}><p style={{fontSize:"28px", lineHeight:"inherit"}}>Empower your diverse user base by creating accessible products</p></span>
              </div>
            </div>
            {/* <div className="bx--row">
              <div className="bx--col-sm-4 bx--col-md-4 bx--col-lg-6" style={{"paddingBottom":"32px"}}>
                <img src={Bee} alt="" style={{"height":"48px"}}/> 
              </div>
            </div> */}
            {/* <img src={Bee} className="beeStyleCL" alt="bee icon" /> */}
            {/* <img src={Bee} alt="" className="beeStyleCL" style={{"height":"56px", marginLeft:"0px"}}/>  */}


            <div className="bx--col-sm-4 bx--col-md-4 bx--col-lg-6">
                <div className="bannerImage">
                    <div className="bannerImage2" />
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
);

PageHeaderCL.propTypes = {
  /**
   * Specify the title for the page
   */
  title: PropTypes.node,
};

export default PageHeaderCL;
