import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Any hierarchical (parent-child) relationship that a component has as a container for,
or being contained by, another component shall be programmatically determinable.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirements is in addition to the requirement that a component (widget or UI object) have a `}<inlineCode parentName="p">{`name`}</inlineCode>{` such as icon names,
window titles, button labels, and edit field labels as covered in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.
It is important for the assistive technology (AT) user to understand the `}<em parentName="p">{`relationship`}</em>{` a
component may have as a `}<inlineCode parentName="p">{`container for`}</inlineCode>{` or be `}<inlineCode parentName="p">{`contained by`}</inlineCode>{` another component,
and that the AT can programmatically determine that relationship and render it to the user where applicable.
This allows AT users to understand how to navigate through and interact with content and compound controls.`}</p>
    <p>{`For example, AT users may be exploring a Folder (container or tree) view.
They tab to the topmost folder (node) in the view and the AT announces that it has five items (children).
Users can then use the arrow keys to move through the five items under the parent folder and discover the folder’s contents.`}</p>
    <p>{`Refer to `}<a href="https://www.access-board.gov/ict/#502.3.7" target="_blank">{`502.3.7 Hierarchical Relationships`}</a>{` (external link to US 508) for more information.`}</p>
    <h4>{`Resources`}</h4>
    <ul>
      <li parentName="ul">{`See resources listed in `}<a href={withPrefix("/requirements/requirements/#502_3_1")} target="_blank">{`502.3.1 Object Information`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      