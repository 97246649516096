import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/visual_4_1_eg.png";
import img2 from "./../../../../../visuals/design/visual_4_2_eg.png";
import img3 from "./../../../../../visuals/design/visual_4_3_eg.png";
import img4 from "./../../../../../visuals/design/visual_4_4_eg.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users with low vision need sufficient contrast to perceive the different visual states of a component, such as focused, selected, hovered, pressed, expanded, or checked. Visual treatments to indicate state changes may just be a difference of color (such as a button changing hue). Others will involve altering the size or opacity of all or part of a component. Note that every state does not need to contrast with every other state 3:1 (although the more distinguishable, the better).`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Make sure visual states for components meet 3:1 contrast with adjacent colors`}</li>
      <li parentName="ul">{`Make sure focus and non-focus states meet contrast requirements`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img1} alt="1. checkbox is both checked and the box is filled black
2. radio button selected is filled with black circle"></img>
        <Caption fullWidth mdxType="Caption">Ensure various states, such as selected and unselected, have good contrast.
        </Caption>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img2} alt="3 different examples of white text, 'Primary button on blue background', with 3rd example showing a white bounding border"></img>
        <Caption fullWidth mdxType="Caption">The visual treatment for the enabled, hover, active, and focus states of buttons have at least a 3:1 color contrast ratio against the background.
        </Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
    <img src={img3} alt="2 similar examples of white text: Button on blue background, with 2nd example showing a white bounding border"></img>
        <Caption fullWidth mdxType="Caption">Use strokes that are at least 2px thick for focus indicators that work well in light or dark mode.
        </Caption>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img4} alt="2 states of Entry fields, labels in dark grey with blue border box when focused, on white background"></img>
        <Caption fullWidth mdxType="Caption">Maintain at least 3:1 color contrast between the focus indicator and its adjacent color and between the focused and unfocused states of the component.
        </Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://medium.com/ingeniouslysimple/contrasting-ui-components-passing-the-new-standards-cd1f96f96494#4fe5" target="_blank" rel="noopener noreferrer">Different states</a>, Contrasting UI components, Medium
      * <a href="https://www.w3.org/WAI/WCAG22/Understanding/non-text-contrast.html#active-user-interface-component-examples" target="_blank" rel="noopener noreferrer">Active user interface component examples</a>, Understanding 1.4.11 Non-text Contrast, WCAG
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g165' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g195' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='deque_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='medium_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='wcag_6' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_11")} target="_blank" rel="noopener noreferrer">1.4.11 Non-text Contrast</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_11' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_7' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Contrast focus and non-focus states at least 3:1](/toolkit/design/visual/color-and-contrast/#contrast-focus) this section
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='ux_keyboard_interaction' mdxType="ToolkitTopic"></ToolkitTopic>, UX design</li>
      <li parentName="ul"><ToolkitTopic qid='dev_keyboard_when_overriding' mdxType="ToolkitTopic"></ToolkitTopic>, Develop – Keyboard interactions</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      