import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Help mechanisms are positioned consistently in sets of pages`}</li>
      <li parentName="ul">{`User input is not repetitive in a process`}</li>
      <li parentName="ul">{`Login processes provide alternatives`}</li>
    </ul>
    <p>
  <strong role="heading" aria-level="5">
    Why?
  </strong>
    </p>
    <p>{`This step checks for requirements relevant to consistent behavior across multiple pages or processes. Most accessibility considerations focus on a single page at a time. However, pages are often part of a cohesive user experience. These new cross-page considerations were introduced in WCAG 2.2, and are not yet officially part of IBM requirements, though some teams are choosing to proactively adopt them.`}</p>
    <p>{`Check that mechanisms that provide page-level help are consistently placed on any page that includes them. Such mechanisms can include contact information, chat clients and links to Frequently Asked Questions.`}</p>
    <p>{`Check that users are not asked to re-enter the same information multiple times within the same process, whether on the same page or later in the process.`}</p>
    <p>{`Finally, if you have developed your own login process, confirm there is a way for users to reset their passwords that does not rely on them remembering their username.`}</p>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><a href="../../../requirements/requirements/?version=7_3#3_2_6">
  3.2.6 Consistent Help
        </a>, IBM accessibility requirements (7.3 only)</li>
      <li parentName="ul"><a href="../../../requirements/requirements/?version=7_3#3_3_7">
  3.3.7 Redundant Entry
        </a>, IBM accessibility requirements (7.3 only)</li>
      <li parentName="ul"><a href="../../../requirements/requirements/?version=7_3#3_3_8">
  3.3.8 Accessible Authentication (Minimum)
        </a>, IBM accessibility requirements (7.3 only)</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      