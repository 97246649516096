import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "../../../../src/pages/toolkit/develop/text-and-non-text/images/decorative_do.png";
import img2 from "../../../../src/pages/toolkit/develop/text-and-non-text/images/decorative_dont.png";
import img11 from "../../../../src/visuals/develop/coding_info_nontext_1_do.png";
import img12 from "../../../../src/visuals/develop/coding_info_nontext_1_dont.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../src/components/rms_resources/requirements";
import ToolkitReference from "../../../../src/components/rms_resources/references";
import ToolkitTechnique from "../../../../src/components/rms_resources/techniques";
import ToolkitTopic from "../../../../src/components/rms_resources/topics";
import * as React from 'react';
export default {
  Row,
  Column,
  img1,
  img2,
  img11,
  img12,
  withPrefix,
  ToolkitRequirement,
  ToolkitReference,
  ToolkitTechnique,
  ToolkitTopic,
  React
};