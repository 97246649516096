import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Dynamic and any potentially problematic components are noted for further testing with the screen reader`}</li>
      <li parentName="ul">{`Technology-specific requirements and considerations are noted`}</li>
      <li parentName="ul">{`Icons used for meaningful content are noted for high contrast testing`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`This initial manual inspection is a good time to actively look for potential problems or curiosities. As you become more experienced with accessibility verification testing, looking holistically at the page can surface items that need further investigation with either manual testing or with a screen reader. Make a note of anything that needs extra attention, such as dynamic custom components, technology-specific or platform-specific implementations, or potential oddities. Components like embedded media players and carousels should always be identified for thorough investigation.`}</p>
    <p>{`If you are doing software testing, this is also a good time to look for some of the
additional requirements for non-web applications in the `}<a href={withPrefix("/requirements/requirements/")} target="_blank" rel="noopener noreferrer">{`IBM accessibility requirements`}</a>{`.`}</p>
    {
      /* **Software:** */
    }
    {
      /* * <ToolkitRequirement qid='req5_6'></ToolkitRequirement> */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      