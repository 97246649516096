import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import cx from 'classnames';
import {
    SideNavLink,
    SideNavMenu,
    SideNavMenuItem,
} from 'carbon-components-react';
import { useWindowSize } from 'gatsby-theme-carbon/src/util/hooks';
import styles, { currentItem } from './LeftNav.module.scss';
import NavContext from 'gatsby-theme-carbon/src/util/context/NavContext';
import usePathprefix from 'gatsby-theme-carbon/src/util/hooks/usePathprefix';

const LeftNavItem = props => {
    const { items, category } = props;
    const { toggleNavState } = useContext(NavContext);
    const windowSize = useWindowSize();
    const closeLeftNav = () => {
        if (windowSize.innerWidth < 1056) {
            toggleNavState('leftNavIsOpen', 'close');
        }
    };
    const pathPrefix = usePathprefix();

    return (
        <Location>
            {({ location }) => {
                const pathname = pathPrefix
                    ? location.pathname.replace(pathPrefix, '')
                    : location.pathname;
                if (!items) return <React.Fragment></React.Fragment>;
                if (items.length === 1) {
                    const isActive = items.some(
                        item => item.path === pathname
                    );
                    return (
                        <SideNavLink
                            onClick={closeLeftNav}
                            element={Link}
                            className={cx({ [currentItem]: isActive })}
                            isActive={isActive}
                            to={`${items[0].path}`}
                        >
                            {category}
                        </SideNavLink>
                    );
                }
                const isActive = items.some(
                    item => item.path === pathname
                );

                let pathTrim = pathname;
                if (pathTrim[pathTrim.length - 1] === "/") {
                    pathTrim = pathTrim.substring(0, pathTrim.length - 1);
                }
                var isExpanded = items.some(
                    item => {
                        let hasActiveTab = false;

                        if (pathTrim.includes('/toolkit') && pathTrim.split('/').length >= 4) {
                            // this case covers deeper tabed submenu pages to make 
                            // them all map to the same nav bar entry
                            // 1. THESE INDICES MAY NEED TO CHANGE IF THERE ARE UPDATES TO THE NAV STRUCTURE (WSCOTT)
                            // 2. INDICES MUST MATCH SIMILIAR CODE BELOW
                            hasActiveTab = item.path.split('/')[2] === pathTrim.split('/')[2];
                        } else {
                            hasActiveTab = item.path === pathTrim;
                        }
                        return hasActiveTab
                    }
                )

                return (
                    <SideNavMenu
                        isActive={isExpanded} // TODO similar categories                      
                        defaultExpanded={isExpanded}
                        title={category}
                    >
                        <SubNavItems
                            onClick={closeLeftNav}
                            items={items}
                            pathname={pathTrim}
                        />
                    </SideNavMenu>

                );
            }}
        </Location>
    );
};

const SubNavItems = ({ items, pathname, onClick }) =>
    items.map((item, i) => {

        let hasActiveTab = false;

        if (pathname.includes('/toolkit') && pathname.split('/').length >= 4) {
            // this case covers deeper tabed submenu pages to make 
            // them all map to the same nav bar entry
            // 1. THESE INDICES MAY NEED TO CHANGE IF THERE ARE UPDATES TO THE NAV STRUCTURE (WSCOTT)
            // 2. INDICES MUST MATCH SIMILIAR CODE ABOVE
            hasActiveTab = item.path.split('/')[2] === pathname.split('/')[2];
        } else {
            hasActiveTab = item.path === pathname;
        }

        return (
            <SideNavMenuItem
                to={`${item.path}`}
                className={cx({
                    [styles.linkText__dark]: pathname === '/',
                })}
                onClick={onClick}
                element={Link}
                isActive={hasActiveTab}
                key={i}
            >
                <span
                    className={cx(styles.linkText, {
                        [styles.linkText__active]: hasActiveTab,
                    })}
                >
                    {item.title}
                </span>
            </SideNavMenuItem>
        );
    });

export default LeftNavItem;
