import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
import img1 from "./../../../../../visuals/design/content_3_1_do.png";
import img2 from "./../../../../../visuals/design/content_3_1_dont.png";
import img3 from "./../../../../../visuals/design/content_3_2_do.png";
import img4 from "./../../../../../visuals/design/content_3_2_dont.png";
import img5 from "./../../../../../visuals/design/content_3_3_eg.png";
import "../../../topics.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Properly written and positioned input labels helps users with cognitive disabilities or have low vision. When low vision users zoom to navigate a page, labels positioned far from its input may get lost in the process.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Provide clear and concise labels for every input element`}</li>
      <li parentName="ul">{`Consistently locate labels near inputs`}</li>
      <li parentName="ul">{`Persistently display labels`}</li>
      <li parentName="ul">{`Annotate labels for inputs without visible labels`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" aspectRatio="1:1" captionTitle="" caption="Provide clear and concise labels so users know what kind of input is needed." mdxType="DoDontExample">
    <img src={img1} alt="Good example: Email label immediately above input field followed by button labeled Log in"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" aspectRatio="1:1" captionTitle="" caption="Don't use unclear labels or rely on placeholder text without labels." mdxType="DoDontExample">
    <img src={img2} alt="Bad example: Input field with placeholder text: Input answer, followed by button: Submit"></img>
        </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" aspectRatio="1:1" captionTitle="" caption="For radio buttons and checkboxes, place the text label immediately to the right of the icon." mdxType="DoDontExample">
    <img src={img3} alt="Good example: input field labelled 'Email'"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" aspectRatio="1:1" captionTitle="" caption="Don’t place labels in inconsistent locations, which will confuse the association with the user input." mdxType="DoDontExample">
    <img src={img4} alt="Bad example: unlabelled input field"></img>
        </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img5} alt="annotations marking two images as 'decorative'"></img>
        <Caption fullWidth mdxType="Caption">Annotate any labels that apply to more than one input field, and annotate hidden aria labels where each input lacks a unique label.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g131' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g162' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_20' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_3' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_16' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_10' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='balsamiq_wireframing_academy_2' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_6' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      