import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When any component receives focus, it does not initiate a change of context.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`UI components receiving focus must not automatically change the context of the screen content. This ensures users navigating content can predict the circumstances that trigger a change of context.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Changes to the ‘content’ do not always cause a “change of context”.
The following examples of context changes do not meet this requirement:`}</p>
    <ul>
      <li parentName="ul">{`Forms submitted automatically when a component receives focus.`}</li>
      <li parentName="ul">{`New windows launched when a component receives focus.`}</li>
      <li parentName="ul">{`Focus is changed to another component when a component receives focus.`}</li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{` Some compound documents and their user agents are designed to provide different viewing and editing functionality depending on which portion of the compound document the user moves focus to. For example, a presentation can contain an embedded spreadsheet where menus and toolbars change based upon whether the user interacts with the presentation content or the embedded spreadsheet content. If an additional mechanism, such as a menu choice or special keyboard command, allows this change of context, then even though a focus change may
trigger this context change, such a compound document would include a means of changing context without a change of focus and so would pass.
See `}<a href="https://www.w3.org/TR/wcag2ict/#consistent-behavior-receive-focus.html" target="_blank" rel="noopener noreferrer">{`Guidance on Applying WCAG to Non-Web Information and Communications Technologies (WCAG2ICT)`}</a></p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/on-focus" target="_blank" rel="noopener noreferrer">{`Understanding 3.2.1 On Focus`}</a>{` (external link to WCAG) for more information on exceptions,
intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      