import React, { Component } from 'react';
import { AccordionItem } from "carbon-components-react";

export class TkAccordionItem extends Component {
    state = {
        first: true
    }
    render() {
        let {
            children,
            level,
            title,
            hash
        } = this.props;
        if (!hash) {
            hash = "";
        } else if (hash[0] === "#") {
            hash = hash.substring(1);
        }
        let docHash = typeof document !== "undefined" ? document.location.hash : "";
        let activeItem = docHash === `#${hash}`;
        if (activeItem && this.state.first) {
            setTimeout(() => {
                document.getElementById(`jump_${hash}`).parentElement.previousElementSibling.click();
                document.getElementById(`jump_${hash}`).parentElement.previousElementSibling.focus();
                setTimeout(() => {
                    document.getElementById(`jump_${hash}`).scrollIntoView();
                    document.defaultView.scrollBy(0,-70);
                },400)
            },400)
            this.setState({first: false});
        }

        return <div role="heading" aria-level="4">
            <h4 style={{display: "none"}}>{title}</h4>
            <AccordionItem title={title} iconDescription={null} >
                    <a id={`jump_${hash}`} name={hash} style={{display:"block", height: "100px", visibility: "hidden", marginTop: "-100px"}} />
                {children}
            </AccordionItem>
        </div>;
    }
}