import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "./../../../../../visuals/design/L2_visual_4_eg.png";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users with disabilities may have a need or preference to view content on their device in a certain orientation. A device may be mounted on a wheelchair in a set position. Users with low vision often orient their screen horizontally and use large fonts or screen magnification to read content more easily.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Provide layouts for both landscape and portrait orientations`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="Mobile layouts shown in landscape and portrait orientations."></img>
        <Caption fullWidth mdxType="Caption">Design layouts for both landscape and portrait orientations.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.smashingmagazine.com/2012/08/designing-device-orientation-portrait-landscape/" target="_blank" rel="noopener noreferrer">Designing for device orientation: from portrait to landscape</a>, Avi Itzkovitch, Smashing magazine
      * <a href="https://noeticforce.com/mobile-app-ux-with-diifferent-screen-sizes-rotation" target="_blank" rel="noopener noreferrer">Mobile app design: screen sizes and rotation for better UX</a>, noeticforce
      * <a href="https://blog.appstudio.dev/2015/06/responsive-design-rotating-your-device/" target="_blank" rel="noopener noreferrer">Responsive design: rotating your device</a>, George Henne, Appstudio blog
      * <a href="https://intopia.digital/articles/which-way-is-up-orientation-in-wcag-2-1/" target="_blank" rel="noopener noreferrer">Which way is up? Orientation in WCAG 2.1</a>, Intopia
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='smashing_magazine_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='noeticforce_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='appstudio_blog_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='intopia_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_3_4")} target="_blank" rel="noopener noreferrer">1.3.4 Orientation</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Responsive reflow](/toolkit/design/visual/#responsive-reflow), this page
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='visual_responsive_reflow' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      