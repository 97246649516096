import React from 'react'
import WhatWeDoContent from "../../content/home/whatWeDo.mdx"
import "./homePanel.scss"; 

export default () => {
    return (<React.Fragment>
        <div>
            <div className="homePanel">
                <div className="bx--row">
                        <div className="bx--col-lg-4">
                            <h2 className="h2Top">What we offer</h2>
                        </div>
                        <div className="bx--col-lg-6">
                            <p className="headerText">Tools and resources to build offerings that are usable and delightful for people of all abilities.</p>
                        </div>
                </div>
                <WhatWeDoContent />
            </div>
        </div>

    </React.Fragment>
    )
}

