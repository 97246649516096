import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Note:`}</strong>{` Authors no longer need to test against this requirement in order to mark it as satisfied for WCAG 2.1 or 2.0, as per `}<a href="https://www.w3.org/TR/WCAG21/#parsing:~:text=allow%20these%20features.-,NOTE,be%20reported%20under%20those%20criteria%20rather%20than%20as%20issues%20with%204.1.1.,-Success%20Criterion%204.1.2" target="_blank" rel="noopener noreferrer">{`updates to the WCAG standard`}</a>{`. “In practice, this criterion no longer provides any benefit to people with disabilities in itself.”`}</p>
    <h5 style={{
      fontSize: '16px',
      fontWeight: '600'
    }}>Rationale</h5>
    <p>{`IBM’s Accessibility Conformance Reports (ACRs) will automatically report 4.1.1 as being met for any standards that reference WCAG 2.0 or 2.1. Requirement 4.1.1 has been removed from the WCAG 2.2 standard.`}</p>
    <p>{`The original purpose of this requirement was to ensure that user agents, including assistive technology such as screen readers, were able to accurately interpret content that uses markup languages. The concern was that if the markup of the content is ambiguous or incorrect, then it may be interpreted differently by different user agents and the content will not be accessible. This is no longer the case due to standards changes.`}</p>
    <p>{`Where any accessibility issues arise from poorly formed code, they should now be reported against other appropriate Success Criteria. For example, if improper markup results in a component having a missing or inappropriate role, it would be fail `}<a href={withPrefix("/requirements/requirements/?version=7_2#4_1_2")}><u>4.1.2 Name, Role, Value</u></a>. Where code leads to incorrectly defined relationships between different elements on the page, it would likely fail <a href={withPrefix("/requirements/requirements/?version=7_2#1_3_1")}><u>{`1.3.1 Info and Relationships`}</u></a>{`.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG21/Understanding/parsing" target="_blank" rel="noopener noreferrer">{`Understanding 4.1.1 Parsing`}</a>{` (external link to WCAG 2.1) for more information and history on this requirement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      