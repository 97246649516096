import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
import img1 from "./../../../../../visuals/design/L2_content_user_input_3_eg.png";
import "../../../topics.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Screen reader users navigating form inputs will only hear instructions (and labels) directly associated with the inputs. Users with cognitive disabilities need relevant guidance located near the input field to reduce the amount of short-term memory required to recall and act on the instructions. If instructions are located far from the input, users with low vision will also miss it when they zoom in to navigate the page. `}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Include instructions near the input when a particular data format is expected`}</li>
      <li parentName="ul">{`Make sure instructions are associated with the input for implementation`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="Birth date input field followed by 'MM/DD/YYYY' and Password input field followed by 'Password must have at least eight characters'"></img>
        <Caption fullWidth mdxType="Caption">
Provide instructions near the input when a specific format is required.
        </Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g89' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='g184' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_12' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='baymard_institute_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    <ul>
      <li parentName="ul"><ToolkitTopic qid='content_placeholder_text' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      