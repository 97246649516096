import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import "./levelTable.scss";
import Level1Icon from "../visuals/plan/level_1_icon.svg";
import Level2Icon from "../visuals/plan/level_2_icon.svg";
import Level3Icon from "../visuals/plan/level_3_icon.svg";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../components/rms_resources/requirements";
import ToolkitReference from "../components/rms_resources/references";
import ToolkitTechnique from "../components/rms_resources/techniques";
import ToolkitTopic from "../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  _frontmatter
}) => <React.Fragment>{children}</React.Fragment>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Pace of completion`}</h2>
    <p>{`We designed this site to allow teams to adopt accessibility at their own pace.
It divides tasks and considerations into three levels that help achieve the `}<a href={withPrefix("/requirements/requirements/")} target="_blank" rel="noopener noreferrer">{`IBM accessibility requirements`}</a>{`.`}</p>
    <div className="levelTable">
      <Row mdxType="Row">
        <Column colLg={1} colMd={1} colSm={4} mdxType="Column">
          <img src={Level1Icon} alt="" />
        </Column>
        <Column colLg={3} colMd={2} colSm={4} mdxType="Column">
          <div className="levelTableHeader" role="heading" aria-level="3">Level 1</div>
        </Column>
        <Column colLg={7} colMd={5} colSm={4} mdxType="Column">
          <p>{`Essential tasks with high user impact, normally with the least investment. Completing these tasks addresses many of the top concerns of people with disabilities.`}</p>
        </Column>
        <Column colLg={1} colMd={0} colSm={0} mdxType="Column">
        </Column>
      </Row>
      <Row mdxType="Row">
        <Column colLg={1} colMd={1} colSm={4} mdxType="Column">
          <img src={Level2Icon} alt="" />
        </Column>
        <Column colLg={3} colMd={2} colSm={4} mdxType="Column">
          <div className="levelTableHeader" role="heading" aria-level="3">Level 2</div>
        </Column>
        <Column colLg={7} colMd={5} colSm={4} mdxType="Column">
          <p>{`You should complete these tasks in addition to the first level to address the next-most important issues that may keep certain users from fully using your product.`}</p>
        </Column>
        <Column colLg={1} colMd={0} colSm={0} mdxType="Column">
        </Column>
      </Row>
      <Row mdxType="Row">
        <Column colLg={1} colMd={1} colSm={4} mdxType="Column">
          <img src={Level3Icon} alt="" />
        </Column>
        <Column colLg={3} colMd={2} colSm={4} mdxType="Column">
          <div className="levelTableHeader" role="heading" aria-level="3">Level 3</div>
        </Column>
        <Column colLg={7} colMd={5} colSm={4} mdxType="Column">
          <p>{`Completing all three levels achieves compliance with the A and AA criteria of the Web Content Accessibility Guidelines (WCAG).`}</p>
        </Column>
        <Column colLg={1} colMd={0} colSm={0} mdxType="Column">
        </Column>
      </Row>
    </div>
    <Row mdxType="Row">
      <Column colLg={6} colMd={6} colSm={6} mdxType="Column">
        <p style={{
          "marginTop": "0px",
          "fontWeight": 600,
          "marginBottom": "-2em"
        }}>Release targets</p>
        <p>{`Product teams should include accessibility targets in each release. As they do, each team member takes on specific tasks to ensure the product is set up for success. Improved, sustainable accessibility is the outcome. Remember, every feature you make more accessible improves the experience for some users. You don’t have to solve it all to improve.`}</p>
      </Column>
    </Row>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      