import React from 'react'
import WhatsNewContent from "../../content/home/whatsNew.mdx"
import "./homePanel.scss";

export default () => {
    return (<React.Fragment>
        <div>
            <div className="homePanel">
                <div>
                    <div className="bx--row">
                        <div className="bx--col-lg-4 bx--col-md-12 bx--col-sm-8">
                            <h2>What&#8217;s new?</h2>
                        </div>
                    </div> 
                    <WhatsNewContent />
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}

