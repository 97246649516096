import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`If the accessibility checking functionality of an authoring tool can detect that content does not meet a requirement of clauses 9 (Web content) or 10 (Documents) as applicable,
then the authoring tool shall provide repair suggestion(s).`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{`
The numbering of this requirement was updated to match the latest version of the EN 301 549 standard.
It is otherwise identical to the prior version 7.0 requirement `}<a href="https://www.ibm.com/able/guidelines/ci162/repair_assist_301.html" target="_blank">{`11.6.4 Repair Assistance`}</a>{`.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Rationale</h5>
    <p>{`The European accessibility standard’s requirement to help authors repair inaccessible content can exceed the
US Revised 508 Standards (`}<a href={withPrefix("/requirements/requirements/#504_3")} target="_blank" rel="noopener noreferrer">{`504.3 Prompts`}</a>{`) requirement to simply `}<em parentName="p">{`prompt`}</em>{` authors to create accessible content.
This European requirement, 11.8.4 `}<em parentName="p">{`Repair Assistance`}</em>{`,
addresses the additional authoring tool abilities to offer `}<em parentName="p">{`repair suggestions`}</em>{`.`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=83" target="_blank">{`11.8.4 Repair Assistance`}</a>{` (external link to EN 301 549) for more information.`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Development techniques</h5>
    <p>{`The `}<em parentName="p">{`output`}</em>{` of authoring tools must meet all WCAG requirements.
In addition, `}<a href="https://www.w3.org/TR/AERT/" target="_blank">{`Techniques For Accessibility Evaluation And Repair Tools (AERT)`}</a>{` should be used to meet this additional EN 301 549 requirement:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Meet B.2.3 `}<a href="https://www.w3.org/TR/ATAG20/#gl_b23" target="_blank">{`Assist authors with managing alternative content for non-text content`}</a>{` by implementing:
B.2.3.2 `}<a href="https://www.w3.org/TR/IMPLEMENTING-ATAG20/#sc_b232" target="_blank">{`Automating repair of text alternatives`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Meet B.3.2 `}<a href="https://www.w3.org/TR/ATAG20/#gl_b32" target="_blank">{`Assist authors in repairing accessibility problems`}</a>{` by implementing:
B.3.2.1 `}<a href="https://www.w3.org/TR/IMPLEMENTING-ATAG20/#sc_b321" target="_blank">{`Repair assistance`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      