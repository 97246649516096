import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../images/tooltip.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`All mouse operations need an equivalent keyboard operation. Ensure that elements where `}<inlineCode parentName="p">{`onhover`}</inlineCode>{` or `}<inlineCode parentName="p">{`mouseover`}</inlineCode>{` is used to trigger display of hover content have an equivalent `}<inlineCode parentName="p">{`onfocus`}</inlineCode>{` interaction.`}</p>
    <p>{`The HTML `}<inlineCode parentName="p">{`title`}</inlineCode>{` attribute is commonly used to present a visual tooltip. However, this method of coding a tooltip is not keyboard accessible. Keyboard accessible tooltips, word definitions, and other non-modal popups that display while hovering over a target element are best provided using a custom `}<inlineCode parentName="p">{`role="tooltip"`}</inlineCode>{` with `}<inlineCode parentName="p">{`onfocus`}</inlineCode>{` triggered keyboard equivalents.`}</p>
    <p>{`Key implementation notes for hover:`}</p>
    <ul>
      <li parentName="ul">{`Focus stays on the triggering element while the new content displays.`}</li>
      <li parentName="ul">{`The trigger element for hover content needs to reference the new content using `}<inlineCode parentName="li">{`aria-describedby`}</inlineCode></li>
      <li parentName="ul">{`The trigger element must be in the focus order (`}<em parentName="li">{`Tab`}</em>{` and `}<em parentName="li">{`Shift+Tab`}</em>{` order)`}</li>
      <li parentName="ul">{`Insert the hover content in the DOM after the trigger element to maintain correct reading order.`}</li>
      <li parentName="ul">{`When the trigger element receives focus, the new content displays.`}</li>
    </ul>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/client-side-script/SCR39" target="_blank" rel="noopener noreferrer">SCR39: Making content on focus or hover hoverable, dismissible, and persistent</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='scr39' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_13")} target="_blank" rel="noopener noreferrer">1.4.13 Content on Hover or Focus</a>, IBM accessibility requirements
      * <a href="https://www.carbondesignsystem.com/components/tooltip/code#icon-tooltip" target="_blank" rel="noopener noreferrer">Icon tooltip</a>, Carbon design system
      * <a href="https://react.carbondesignsystem.com/?path=/story/tooltipdefinition--default" target="_blank" rel="noopener noreferrer">Definition tooltip</a>, Carbon design system
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_13' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_5' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_14' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      