import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../images/decorative_do.png";
import img2 from "./../images/decorative_dont.png";
import img11 from "../../../../../visuals/develop/coding_info_nontext_1_do.png";
import img12 from "../../../../../visuals/develop/coding_info_nontext_1_dont.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h5 className="accordionH5">What to do</h5>
    <p>{`Where `}<ToolkitTopic qid='content_equivalents_identify_and' name="content designers have identified decorative images" source="hide" mdxType="ToolkitTopic"></ToolkitTopic>{`, set the HTML `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute of the image to an empty string. For other technologies, use accessible techniques, which cause assistive technologies to ignore the image.`}</p>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="do" aspectRatio="1:1" captionTitle="" caption="Give decorative images an empty string in the ALT attribute to signal assistive technologies to ignore decorative elements." mdxType="DoDontExample">
    <img src={img11} alt="Good example: screen shot of HTML source code showing alt = quote quote"></img>
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <DoDontExample type="dont" aspectRatio="1:1" captionTitle="" caption="Don't give decorative images a description as it doesn't give the user meaningful information." mdxType="DoDontExample">
    <img src={img12} alt="Bad example: screen shot of HTML source code showing unnecessary verbose alt text =  Robot with friendly face, assembled with various scraps of hardware, and mounted on an old DecTalk speech synthesizer"></img>
        </DoDontExample>
      </Column>
    </Row>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/css/C9" target="_blank" rel="noopener noreferrer">C9: Using CSS to include decorative images</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H67" target="_blank" rel="noopener noreferrer">H67: Using null alt text and no title attribute on img elements for images that AT should ignore</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='c9' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h67' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_1_1")} target="_blank" rel="noopener noreferrer">1.1.1 Non-text Content</a>, IBM accessibility requirements
      * <a href="https://www.w3.org/WAI/tutorials/images/decorative/" target="_blank" rel="noopener noreferrer">Decorative images</a>, W3C web accessibility tutorials
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_5' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      