import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from "gatsby-theme-carbon/src/components/Grid";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../components/rms_resources/requirements";
import ToolkitReference from "../../../components/rms_resources/references";
import ToolkitTechnique from "../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Developers get some things “for free” from design. Contrast and color design decisions cover many accessibility areas. All developers need to do is follow the wireframes as normal with no accessibility-specific tasks. The WCAG requirements built into the design should include:`}</p>
    <ul>
      <li parentName="ul">{`Enough contrast, for both text and non-text content`}</li>
      <li parentName="ul">{`No reliance on color as the only way to distinguish content`}</li>
      <li parentName="ul">{`No triggers for seizures from flashing content`}</li>
      <li parentName="ul">{`Videos with captions`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      