import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
import img1 from "./../../../../../visuals/design/content_5_1_eg.png";
import img2 from "./../../../../../visuals/design/content_5_2_eg.png";
import img3 from "./../../../../../visuals/design/content_5_3_eg.png";
import "../../../topics.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`There are two types of flashing content: general and red. General flashes refer to changes between contrasting brightness. Red flashes refer to changes that involve saturated red, which can be dangerous in triggering seizures. `}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Avoid flashing content`}</li>
      <li parentName="ul">{`Any flashing content should flash slower than three times per second`}</li>
      <li parentName="ul">{`If flashing more than three times per second, reduce the size of flashing content`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="annotations marking two images as 'decorative'"></img>
        <Caption fullWidth mdxType="Caption">Decide if flashing content is really necessary, or if a static image is enough.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img2} alt="animation that flashes 3 times and stops"></img>
        <Caption fullWidth mdxType="Caption">In this example, the background changes between highly contrasting colors less than three times. Copyright IBM; all rights reserved. </Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img3} alt="smaller 341 by 256 pixel animation that flashes for 2 seconds"></img>
        <Caption fullWidth mdxType="Caption">If flashing faster than three times per second, make sure the flashing content is smaller than 341 x 256 pixels, less than 10% of the central visual field, or less than 25% of screen area. Copyright IBM; all rights reserved.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g176' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='mozilla_developer_network_8' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      