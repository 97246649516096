import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Use the `}<inlineCode parentName="p">{`title`}</inlineCode>{` element to provide a page title using the page name noted in the content design.`}</p>
    <p>{`Each web page must have a meaningful programmatic title. Page titles serve multiple purposes:`}</p>
    <ul>
      <li parentName="ul">{`A label on the tab of the browser`}</li>
      <li parentName="ul">{`A window title when there are multiple browser instances`}</li>
      <li parentName="ul">{`Link text that appears in search engine results`}</li>
      <li parentName="ul">{`The first announced content on the web page for screen reader users`}</li>
    </ul>
    <p>{`When each page title is unique, a user can navigate between browser tabs or browser windows to find the right one
more easily. If the website name is in the title, be sure the unique page information comes first,
before the site name.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G88" target="_blank" rel="noopener noreferrer">G88: Providing descriptive titles for Web pages</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H25" target="_blank" rel="noopener noreferrer">H25: Providing a title using the title element</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/failures/F25" target="_blank" rel="noopener noreferrer">F25: Failure of Success Criterion 2.4.2 due to the title of a Web page not identifying the contents</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g88' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h25' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='f25' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#2_4_2")} target="_blank" rel="noopener noreferrer">2.4.2 Page Titled</a>, IBM accessibility requirements
      * <a href="https://www.w3schools.com/tags/tag_title.asp" target="_blank" rel="noopener noreferrer">HTML title tag</a>, w3schools.com
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req2_4_2' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='w3schools_com_3' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      