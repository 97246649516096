import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">Make sure that...</h5>
    <ul>
      <li parentName="ul">{`Text wraps appropriately when resized to 200%`}</li>
      <li parentName="ul">{`When zoomed, scrollbars are present and offscreen content can be brought into view`}</li>
      <li parentName="ul">{`Full content is available to the user through focus or activation when text is truncated`}</li>
      <li parentName="ul">{`Content supports increasing the letter, word, paragraph and line spacing`}</li>
    </ul>
    <h5 className="accordionH5">Why?</h5>
    <p>{`The ability to resize text is built into most operating systems and user agents. Ensure that when content is scaled it is still fully usable and understandable. Scaling can be the result of zooming all content or just resizing the text. Many teams will only test zooming all content, although arguably the ability to resize only the text is of significantly more value to many users since it allows more screen context.`}</p>
    <p>{`Check that the application accommodates changes to text spacing. A simple script can be used to modify text to meet the new spacing requirements, at which point it can be inspected to ensure no loss of content, such as through truncation.`}</p>
    <h5 className="accordionH5">Fully addresses</h5>
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_4' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_12' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      