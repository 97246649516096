import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users with low vision need new content added to the screen to be visually separated from existing content, especially when new text is overlaid on other content and the background colors are the same. This prevents dialogs and popups from blending in with the information behind them.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Make sure tooltips and overlays are visually distinct from other content`}</li>
      <li parentName="ul">{`Make sure borders of tooltips and overlays meet 3:1 contrast with its background`}</li>
    </ul>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.nngroup.com/articles/mobile-instructional-overlay/?lm=tooltip-guidelines&pt=article" target="_blank" rel="noopener noreferrer">Instructional overlays and coach marks for mobile apps</a>, Nielsen Norman Group
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/general/G209" target="_blank" rel="noopener noreferrer">G209: Provide sufficient contrast at the boundaries between adjoining colors</a>, WCAG 2.1 technique
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='g209' mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_17' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_4_11")} target="_blank" rel="noopener noreferrer">1.4.11 Non-text Contrast</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_4_11' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Component contrast](/toolkit/design/visual/#component-contrast), this page
      * [Visual states](/toolkit/design/visual/#visual-states), this page
      * [Feedback and notifications](/toolkit/design/visual/#feedback), this page
      * [Make content accessible that appears on hover or focus](/toolkit/develop/dynamic-updates#make-content-accessible-that-appears-on-hover-or-focus), Develop – Dynamic content
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='visual_components_contrast' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='visual_visual_states' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_feedback_notifications' mdxType="ToolkitTopic"></ToolkitTopic>, UX design</li>
      <li parentName="ul"><ToolkitTopic qid='dev_dynamic_make_content' mdxType="ToolkitTopic"></ToolkitTopic>, Develop - Dynamic content</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      