import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When a user interface component receives keyboard focus, the component is not entirely hidden due to author-created content.`}</p>
    <h4>{`Rationale`}</h4>
    <p>{`This requirement ensures that the item receiving focus is always visible to the user. As a sighted user navigates a page by keyboard, a focus indicator shows which control is active. If there is no focus indicator visible, the user does not know where they are.`}</p>
    <p>{`The author can unintentionally obscure the item getting focus. Common failures are sticky footers and headers, such as cookie banners. As the user presses the `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` key to navigate the page, the item with focus may be entirely hidden behind such banners, which often overlay the main content.`}</p>
    <p>{`Refer to `}<a href="https://www.w3.org/WAI/WCAG22/Understanding/focus-not-obscured-minimum.html" target="_blank">{`2.4.11 Focus not obscured (minimum)`}</a>{` (external link to WCAG 2.2) for more information on intent, benefits, and techniques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      