import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Where ICT displays captions,
the mechanism to display captions shall preserve synchronization between the audio and the corresponding captions as follows:`}</p>
    <ul>
      <li parentName="ul">{`Captions in prerecorded material: within 100 ms of the time stamp of the caption`}</li>
      <li parentName="ul">{`Live captions: within 100 ms of the availability of the caption to the player`}</li>
    </ul>
    <h4>{`Rationale`}</h4>
    <p>{`The European `}<em parentName="p">{`EN 301 549`}</em>{` standard specifies requirements for information and communication technologies (ICT) such as media players and embedded players that render captions.
This requirement is Not Applicable (N/A) to products and applications that do not play audio and video.`}</p>
    <p>{`Several “`}<em parentName="p">{`Time-based Media`}</em>{`” success criteria in WCAG, such as `}<a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">{`1.2.2 Captions (prerecorded)`}</a>{`,
require that captions are provided.
Those requirements stipulate that audio content has been transcribed and is captured in a format that can be used to display captions.
Those WCAG requirements covering the `}<em parentName="p">{`content`}</em>{` of the captions are complementary to chapter 7 `}<em parentName="p">{`ICT with video capabilities`}</em>{` of EN 301 549,
which covers the actual `}<em parentName="p">{`process and mechanisms`}</em>{` of displaying closed captions during playback,
and the controls (CC button) for turning captions on and off provided by the media player.
Ensure that the video player you’re developing or deploying preserves the time stamps in the captions.  `}</p>
    <p><strong parentName="p">{`Note:`}</strong>{`
Open captions are considered to always meet this requirement since they are permanently burnt into the video,
and so cannot have the synchronization affected by the media player.`}</p>
    <p>{`Refer to `}<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf#page=35" target="_blank" rel="noopener noreferrer">{`7.1.2 Captioning Synchronization`}</a>{` (external link to EN 301 549) for more information.`}</p>
    <p><strong parentName="p">{`Related requirements:`}</strong>{`
Applications that play audio and video are required to meet the following related requirements:`}</p>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Audio/Video Content requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.2 Captions (Prerecorded)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#1_2_2")} target="_blank" rel="noopener noreferrer">1.2.4 Captions (Live)</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#10_5")} target="_blank" rel="noopener noreferrer">10.5 Captioning Positioning (Non-web Documents)</a></li>
    </ul>
    <h5 style={{
      fontSize: "16px",
      fontWeight: "600"
    }}>Player requirements:</h5>
    <ul>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_1")} target="_blank" rel="noopener noreferrer">7.1.1 Captioning Playback</a></li>
      <li parentName="ul">{`7.1.2 Captioning Synchronization - `}<em parentName="li">{`this requirement`}</em></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_3")} target="_blank" rel="noopener noreferrer">7.1.3 Preservation of Captioning</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_4")} target="_blank" rel="noopener noreferrer">7.1.4 Captions Characteristics</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_1_5")} target="_blank" rel="noopener noreferrer">7.1.5 Spoken Subtitles</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#7_3")} target="_blank" rel="noopener noreferrer">7.3 User Controls for Captions & Audio Description</a></li>
      <li parentName="ul"><a href={withPrefix("/requirements/requirements/#503_4_1")} target="_blank" rel="noopener noreferrer">503.4.1 Caption Controls</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      