import { withPrefix } from "gatsby";
import { TkAccordionItem } from "../../../../src/components/toolkit/TkAccordionItem";
import { Accordion } from "carbon-components-react";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import "../../../../src/pages/toolkit/topics.scss";
import "../../../../src/pages/toolkit/toolkit-overviews.scss";
import "../../../../src/pages/toolkit/TKStructuredList.scss";
import DetailA11yName from "../../../../src/pages/toolkit/develop/user-input/level1/accessible-names.mdx";
import DetailStandard from "../../../../src/pages/toolkit/develop/user-input/level1/standard-component.mdx";
import DetailCustom from "../../../../src/pages/toolkit/develop/user-input/level1/custom-components.mdx";
import DetailInputs from "../../../../src/pages/toolkit/develop/user-input/level1/inputs.mdx";
import DetailVisible from "../../../../src/pages/toolkit/develop/user-input/level2/visible-label.mdx";
import DetailLabel from "../../../../src/pages/toolkit/develop/user-input/level2/label.mdx";
import DetailPasswordFields from "../../../../src/pages/toolkit/develop/user-input/level3/password-fields.mdx";
import DetailHTML from "../../../../src/pages/toolkit/develop/user-input/level3/html-autocomplete.mdx";
import Level1Icon from "../../../../src/visuals/plan/level_1_icon.svg";
import Level2Icon from "../../../../src/visuals/plan/level_2_icon.svg";
import Level3Icon from "../../../../src/visuals/plan/level_3_icon.svg";
import ExpandCollapseAccordion from "../../../../src/components/expand_collapse_accordion/expand_collapse_accordion.js";
import * as React from 'react';
export default {
  withPrefix,
  TkAccordionItem,
  Accordion,
  Row,
  Column,
  DetailA11yName,
  DetailStandard,
  DetailCustom,
  DetailInputs,
  DetailVisible,
  DetailLabel,
  DetailPasswordFields,
  DetailHTML,
  Level1Icon,
  Level2Icon,
  Level3Icon,
  ExpandCollapseAccordion,
  React
};