import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Include all perceivable content on each page into the various landmark regions, as identified in the content design. Each region may contain headings, content, controls, and even nested regions. Code each landmark region with a meaningful role using either HTML 5 sectioning elements or ARIA landmark roles. Regions include:`}</p>
    <ul>
      <li parentName="ul">{`Main`}</li>
      <li parentName="ul">{`Banner`}</li>
      <li parentName="ul">{`Navigation`}</li>
      <li parentName="ul">{`Footer`}</li>
      <li parentName="ul">{`…and so on`}</li>
    </ul>
    <p>{`Associate a label for each region using:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{`: When a heading or other visible text can serve as the label.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`aria-label`}</inlineCode>{`: When there is no visible text to label the region. Use the text label provided in the content design.`}</li>
    </ul>
    <p>{`Screen reader AT announces the role and label as the user navigates the content.  Landmark regions also help AT users orient themselves on a page and provides the user the ability to navigate to each region with ease.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA13.html" target="_blank" rel="noopener noreferrer">ARIA13: Using aria-labelledby to name regions and landmarks</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria13' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_3_1")} target="_blank" rel="noopener noreferrer">1.3.1 Info and Relationships</a>, IBM accessibility requirements
      * <a href="http://w3c.github.io/aria-practices/examples/landmarks/" target="_blank" rel="noopener noreferrer">Landmark regions</a>, ARIA authoring practices
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_4' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      