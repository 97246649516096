const autocorrectList = require('../../../data/autocorrects.json')
const synonymsList    = require('../../../data/synonyms.json')

class SearchNLP {

    replacementsMap  = {}
    synonymsMap = {}

    constructor() {

        var i = null;
        var j = null;
        var key = null;

        for (i = 0; autocorrectList.length > i; i += 1) {
            key = autocorrectList[i].MISSPELL.toLowerCase().trim();
            this.replacementsMap[key] = autocorrectList[i];
        }

        for (j = 0; synonymsList.length > j; j += 1) {
            key = synonymsList[j].TERM.toLowerCase().trim();
            this.synonymsMap[key] = synonymsList[j];
        }
    }

    isLastCharPunctuation(str){
    
        var code
        code = str.charCodeAt(str.length-1)
        if (!(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
          return true
        }
      return false
    }
    
    hasAutoCorrectEntry(query) {
    
        if(query === null || query === undefined)
            return undefined
    
        var key = query.toLowerCase().trim()
        var obj = this.replacementsMap[key]
        if(obj == undefined){
            return undefined
        }
        return obj.CORRECTED
    }
    
    hasSynonymEntry(query) {
    
        if(query === null || query === undefined)
            return undefined
    
        var key = query.toLowerCase().trim()
        var obj = this.synonymsMap[key]
        if(obj == undefined){
            return undefined
        }
        return obj.SYNONYM
    }

    nlpPreprocessor = function(text){
     
        //log.info ('[nlpPreprocessor] Processing utterance: ' + text)
    
        var processed;
        var autcorrected;

        // // if last char is punctuation, remove it
        // var endingPunctuation = (this.isLastCharPunctuation(text) === true) ? text.substr(text.length - 1) : null
        // if(endingPunctuation !== null){
        //     text = text.substring(0, text.length-1)
        // }
        
        //
        // Step 1: Auto spell correct the user query
        //
        var bagOfWords = text.trim().split(" ")    
        for(var i = 0; i < bagOfWords.length; i++){
            var word = bagOfWords[i].toLowerCase()
            var corrected = this.hasAutoCorrectEntry(word) 
            if(corrected === undefined){ 
                 corrected = word          
            }
            bagOfWords[i] = corrected
        }
        autcorrected = bagOfWords.join(" ")
        
        //
        // Step 2: replace query term with keyword synonyms
        //
        var processed = this.hasSynonymEntry(autcorrected) 
        if(processed === undefined){ 
            processed = autcorrected          
        }        
        return processed
    }
    
    
}

module.exports = { SearchNLP };