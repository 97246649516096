import PageBanner from "../../../../src/components/PageBanner";
import DefaultNoHead from "../../../../src/gatsby-theme-carbon/templates/DefaultNoHead";
import LicenseBody from "../../../../src/components/toolkit_license/licenseBody";
import DefaultCL from "../../../../src/gatsby-theme-carbon/templates/DefaultCC";
import { TkFooter } from "../../../../src/components/toolkit/TkFooter";
import * as React from 'react';
export default {
  PageBanner,
  DefaultNoHead,
  LicenseBody,
  DefaultCL,
  TkFooter,
  React
};