import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import img1 from "./../../../../../visuals/design/ux_6_1_eg.png";
import img2 from "./../../../../../visuals/design/ux_6_2_eg.png";
import img3 from "./../../../../../visuals/design/ux_6_3_eg.png";
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users with visual and cognitive disabilities are likely to miss errors that are not directly brought to their attention, but it’s important to surface error messages in a way that alerts users without being overly disruptive. Screen reader users who navigate an entire form before entering data can also be disrupted if validation occurs each time a required field is left empty.`}</p>
    <h5 className="accordionH5">What to do</h5>
    <ul>
      <li parentName="ul">{`Ensure error messages are obvious to everyone`}</li>
      <li parentName="ul">{`Avoid carrying out validation before users fill out the input and exit focus`}</li>
      <li parentName="ul">{`Ensure users can easily get to form elements that need to be fixed`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img1} alt="at the top of a webpage is a title '2 errors found' followed by two errors listed as hyperlinks"></img>
        <Caption fullwidth mdxType="Caption">If design requires the page to reload before validation, an error summary at the top of page and provide a link to each input in error. Also consider placing focus on error summary on page load.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <img src={img2} alt="a modal dialog titled 'Missing input' with text 'Phone number is a required field and must be filled in'"></img>
        <Caption fullWidth mdxType="Caption">For dialogs, one of the user actions should move the focus to the item in error. In this example, pressing the Ok button should move the focus to the Phone number field.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={4} colMd={3} colSm={4} mdxType="Column">
        <img src={img3} alt="email and Phone number input fields, both with red outlines and error messages directly below each: 'Please enter an email address' and 'Phone number can only contain numbers'"></img>
        <Caption fullWidth mdxType="Caption">For inline errors detected after form submission, focus should move to first input in error.</Caption>
      </Column>
    </Row>
    <h5 className="accordionH5">Resources</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/tutorials/forms/notifications/#inline" target="_blank" rel="noopener noreferrer">In-line feedback</a>, User notifications, W3C accessibility tutorial
      * <a href="https://www.nngroup.com/articles/errors-forms-design-guidelines/" target="_blank" rel="noopener noreferrer">How to report errors in forms: 10 design guidelines</a>, Nielsen Norman Group
      * <a href="https://www.levelaccess.com/how-to-provide-accessible-error-identification/" target="_blank" rel="noopener noreferrer">How to provide accessible form error identification</a>, Level Access
      * <a href="https://www.carbondesignsystem.com/components/modal/code" target="_blank" rel="noopener noreferrer">Modal code</a>, Carbon design system
      * <a href="https://uxmovement.com/forms/the-best-place-for-error-messages-on-forms/" target="_blank" rel="noopener noreferrer">The best place for error messages on forms</a>, UX Movement
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_24' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='nielsen_norman_group_11' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='level_access_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='carbon_design_system_19' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='ux_movement_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#3_3_1")} target="_blank" rel="noopener noreferrer">3.3.1 Error Identification</a>, IBM accessibility requirements
      * <a href={withPrefix("/requirements/requirements/#3_3_3")} target="_blank" rel="noopener noreferrer">3.3.3 Error Suggestion</a>, IBM accessibility requirements
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitRequirement qid='req3_3_3' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
    </ul>
    <h5 className="accordionH5">Related toolkit topics</h5>
    {
      /* RMS_UPDATE
      * [Describe and identify errors, and offer suggestions](/toolkit/design/content/user-feedback/#describe-and-identify-errors-and-offer-suggestions), Content design - Text updates
      * [Provide errors accessibly](/toolkit/develop/dynamic-updates#provide-errors-accessibly), Develop - Dynamic content
      * [Transactions](/toolkit/design/ux/#transactions), this page
      * [Reverse and undo](/toolkit/design/ux/#reverse), this page
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTopic qid='content_error_messages' mdxType="ToolkitTopic"></ToolkitTopic>, Content design</li>
      <li parentName="ul"><ToolkitTopic qid='dev_dynamic_provide_errors' mdxType="ToolkitTopic"></ToolkitTopic>, Develop - Dynamic content</li>
      <li parentName="ul"><ToolkitTopic qid='ux_transactions' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
      <li parentName="ul"><ToolkitTopic qid='ux_reverse_and_undo' mdxType="ToolkitTopic"></ToolkitTopic>, this page</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      