import { Row, Column, Caption } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import img1 from "../../../../src/visuals/design/visual_4_1_eg.png";
import img2 from "../../../../src/visuals/design/visual_4_2_eg.png";
import img3 from "../../../../src/visuals/design/visual_4_3_eg.png";
import img4 from "../../../../src/visuals/design/visual_4_4_eg.png";
import ToolkitRequirement from "../../../../src/components/rms_resources/requirements";
import ToolkitReference from "../../../../src/components/rms_resources/references";
import ToolkitTechnique from "../../../../src/components/rms_resources/techniques";
import ToolkitTopic from "../../../../src/components/rms_resources/topics";
import * as React from 'react';
export default {
  Row,
  Column,
  Caption,
  withPrefix,
  img1,
  img2,
  img3,
  img4,
  ToolkitRequirement,
  ToolkitReference,
  ToolkitTechnique,
  ToolkitTopic,
  React
};