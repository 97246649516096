import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Where `}<a parentName="p" {...{
        "href": "/able/toolkit/design/content"
      }}>{`content designers have provided an easy text alternative for an image`}</a>{`, in HTML set the `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute of the image to the string indicated. For other technologies, use techniques in a way that supports assistive technologies. When an image is used as a component, such as a link or button, be sure to nest the image and its alt inside the functional element, so that they present as a single interaction point.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA6.html" target="_blank" rel="noopener noreferrer">ARIA6: Using aria-label to provide labels for objects</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H2.html" target="_blank" rel="noopener noreferrer">H2: Combining adjacent image and text links for the same resource</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H35" target="_blank" rel="noopener noreferrer">H35: Providing text alternatives on applet elements</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H36" target="_blank" rel="noopener noreferrer">H36: Using alt attributes on images used as submit buttons</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H37" target="_blank" rel="noopener noreferrer">H37: Using alt attributes on img elements</a>
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H53" target="_blank" rel="noopener noreferrer">H53: Using the body of the object element</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='aria6' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h2' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h35' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h36' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h37' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitTechnique qid='h53' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href={withPrefix("/requirements/requirements/#1_1_1")} target="_blank" rel="noopener noreferrer">1.1.1 Non-text Content</a>, IBM accessibility requirements
      * <a href="https://www.w3.org/WAI/tutorials/images/functional/" target="_blank" rel="noopener noreferrer">Functional Images</a>, W3C web accessibility tutorials
      * <a href="https://w3c.github.io/alt-techniques/#scenarios" target="_blank" rel="noopener noreferrer">Common text alternative techniques</a>, W3C editor’s draft
      * <a href="https://cariefisher.com/a11y-svg-alts/" target="_blank" rel="noopener noreferrer">Accessible SVGs - methods for adding alternative content</a>, by Carie Fisher
      * <a href="https://pauljadam.com/demos/canvas.html" target="_blank" rel="noopener noreferrer">HTML `canvas` accessibility</a>, pauljadam.com
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitRequirement qid='req1_1_1' mdxType="ToolkitRequirement"></ToolkitRequirement></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_6' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_2' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='cariefisher_com_1' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='pauljadam_com_1' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      