import React, { Component } from 'react'

class ToolkitCard extends Component {
    render() {
        return (
            <div className="bx--col-border bx--col-lg-3 bx--col-md-8">
                <div className="toolkitCard">
                    <div className="toolkitContent">
                        <div>{this.props.children}</div>
                    </div>
                    <div><img alt="" src={this.props.image}/></div>
                </div>
            </div>
        )

    }


}
export default ToolkitCard