import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { withPrefix } from "gatsby";
import ToolkitRequirement from "../../../../../components/rms_resources/requirements";
import ToolkitReference from "../../../../../components/rms_resources/references";
import ToolkitTechnique from "../../../../../components/rms_resources/techniques";
import ToolkitTopic from "../../../../../components/rms_resources/topics";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h5 className="accordionH5">What to do</h5>
    <p>{`Where the design contains captions on figures or titles on tables, you must programmatically associate them with the element they describe. Take care to avoid common technical mistakes that prevent the association from being understood by assistive technologies (AT). Refer to the techniques and resources for code examples.`}</p>
    <p>{`For figure captions:`}</p>
    <ul>
      <li parentName="ul">{`Use the HTML `}<inlineCode parentName="li">{`figcaption`}</inlineCode>{` element as the first or last element in a `}<inlineCode parentName="li">{`figure`}</inlineCode>{` block to associate the caption with the figure.`}</li>
    </ul>
    <p>{`For table titles:`}</p>
    <ul>
      <li parentName="ul">{`For HTML tables, use the `}<inlineCode parentName="li">{`caption`}</inlineCode>{` element immediately after the `}<inlineCode parentName="li">{`table`}</inlineCode>{` element.`}</li>
      <li parentName="ul">{`If the design shows the caption visually after the table, use CSS `}<inlineCode parentName="li">{`caption-side:bottom`}</inlineCode>{` on either element.`}</li>
    </ul>
    <p>{`Note: When ARIA is used to construct the tables, figures, or their accessible names, `}<inlineCode parentName="p">{`caption`}</inlineCode>{` and `}<inlineCode parentName="p">{`figcaption`}</inlineCode>{` do not get used by AT the same as pure HTML. Use the ARIA authoring practices resources to ensure proper coding.`}</p>
    <h5 className="accordionH5">Techniques</h5>
    {
      /* RMS_UPDATE
      * <a href="https://www.w3.org/WAI/WCAG22/Techniques/html/H39.html" target="_blank" rel="noopener noreferrer">H39: Using caption elements to associate data table captions with data tables</a>
      * <a href="https://www.w3.org/WAI/tutorials/tables/caption-summary/#identifying-a-table-using-a-caption" target="_blank" rel="noopener noreferrer">Example 1: Identifying a table using a caption</a>
      * <a href="https://www.w3.org/WAI/tutorials/tables/caption-summary/#summaries-for-more-complex-tables" target="_blank" rel="noopener noreferrer">Example 2: Summaries for more complex tables</a>
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitTechnique qid='h39' source="hide" mdxType="ToolkitTechnique"></ToolkitTechnique></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_14' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='w3c_web_accessibility_tutorials_15' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>
    <h5 className="accordionH5">Requirements and resources</h5>
    {
      /* RMS_UPDATE
      * <a href="http://w3c.github.io/aria-practices/#naming_with_captions" target="_blank" rel="noopener noreferrer">Naming figures and tables with captions</a>, ARIA authoring practices
      * <a href="http://w3c.github.io/aria-practices/#describing_with_captions" target="_blank" rel="noopener noreferrer">Describing tables and figures with captions</a>, ARIA authoring practices
      * <a href="http://w3c.github.io/aria-practices/#table" target="_blank" rel="noopener noreferrer">Table</a>, ARIA authoring practices
      /RMS_UPDATE */
    }
    <ul>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_9' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_7' mdxType="ToolkitReference"></ToolkitReference></li>
      <li parentName="ul"><ToolkitReference qid='aria_authoring_practices_10' mdxType="ToolkitReference"></ToolkitReference></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      